import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCustomer, updateCustomer } from '../../../actions/customers';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { MODIFY_CUSTOMERS } from '../../../permissions';

function UpdateCustomer({
  isLoading,
  customerId,
  auth: { permissions },
  getCustomer,
  updateCustomer,
  customers: { customer }
}) {
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    id: ''
  });

  const { name, phone, email, address, id } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (customerId) {
      getCustomer(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    setFormData({
      ...formData,
      name: customer ? customer.name : '',
      phone: customer ? customer.phone : '',
      email: customer ? customer.email : '',
      address: customer ? customer.address : '',
      id: customer ? customer.id : ''
    });
  }, [customer]);

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const saveCustomer = (e) => {
    e.preventDefault();

    updateCustomer(formData);
  };

  return (
    <Fragment>
      <LoadingBackdrop loading={isLoading} />

      {customer && (
        <div className="container-fluid">
          <form id="card-content" onSubmit={(e) => saveCustomer(e)}>
            <div className="card-header">
              <div className="d-flex justify-content-between align-items center">
                <div>
                  <Fragment>
                    <h5>Customer Details</h5>
                    <p>Update customer details</p>
                  </Fragment>
                </div>
                {permissions.find((item) => item.name === MODIFY_CUSTOMERS) && (
                  <div>
                    <button
                      type={'submit'}
                      className="btn btn-primary p-2 me-2"
                      disabled={isLoading}
                    >
                      Update
                    </button>
                    <button
                      onClick={onCancelButtonClicked}
                      type={'button'}
                      disabled={isLoading}
                      className="btn btn-danger p-2 text-light"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="card-body mt-2">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor={'name'}>
                      Name <span className={'text-danger'}>*</span>
                    </label>
                    <input
                      type="text"
                      name={'name'}
                      required
                      value={name}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Enter customer name'}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor={'phone'}>
                      Phone <span className={'text-danger'}>*</span>
                    </label>
                    <input
                      type="text"
                      name={'phone'}
                      value={phone}
                      required
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Enter phone'}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor={'email'}>Email</label>
                    <input
                      type="email"
                      name={'email'}
                      value={email}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Enter email'}
                    />
                  </div>
                </div>

                <div className="col-md-4 mt-4">
                  <div className="form-group">
                    <label htmlFor={'address'}>Address</label>
                    <input
                      type="text"
                      name={'address'}
                      value={address}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Enter address'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </Fragment>
  );
}

UpdateCustomer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getCustomer: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  customers: state.customers,
  auth: state.auth
});

export default connect(mapStateToProps, { updateCustomer, getCustomer })(UpdateCustomer);
