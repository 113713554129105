import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

function AllSupplierSelector({ setFormData, formData, suppliers }) {
  const onSupplierSelected = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      supplierId: value ? value.id : ''
    });
  };

  return (
    <Autocomplete
      onChange={(event, value) => onSupplierSelected(event, value)}
      options={suppliers}
      renderOption={(option) => `${option.name} - ${option.phone}`}
      getOptionLabel={(option) => `${option.name} - ${option.phone}`}
      renderInput={(params) => (
        <TextField
          {...params}
          size={'small'}
          placeholder={'Select Supplier'}
          required
          variant="outlined"
        />
      )}
    />
  );
}

export default AllSupplierSelector;
