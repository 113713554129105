import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import {
  addPackaging,
  clearPackedItems,
  getExchangePackagingDetails,
  getPackagingDetails,
  markDeliver,
  markExchangeDeliver
} from '../../../actions/sale';
import MaterialTable from 'material-table';
import Swal from 'sweetalert2';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { getColors, getSizes } from '../../../actions/products';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { MANAGE_PACKAGING } from '../../../permissions';
import { getBranches } from '../../../actions/system';
import { REGEX_STRING } from '../../../utils';
import NumberInput from '../../common/NumberInput';

function PackagingDetails({
  isLoading,
  sale: { packagingDetails },
  getPackagingDetails,
  location,
  markExchangeDeliver,
  getExchangePackagingDetails,
  markDeliver,
  getColors,
  clearPackedItems,
  getSizes,

  addPackaging,
  getBranches,
  system: { branches },
  auth: { permissions },
  products: { sizes, colors }
}) {
  const history = useHistory();
  const [saleId, setSaleId] = useState('');
  const [isExchange, setIsExchange] = useState(false);
  const [packagingDialog, setPackagingDialog] = useState(false);
  const [packagingForm, setPackagingForm] = useState({
    qty: '',
    code: '',
    color: '',
    size: '',
    branchId: ''
  });
  const [item, setItem] = useState(null);
  const { code, color, size, qty, branchId } = packagingForm;
  const onChange = (e) => setPackagingForm({ ...packagingForm, [e.target.name]: e.target.value });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');
    const isExchange = query.get('is_exchange');
    setIsExchange(JSON.parse(isExchange));

    if (id) {
      if (JSON.parse(isExchange)) {
        getExchangePackagingDetails(id);
      } else {
        getPackagingDetails(id);
        setSaleId(id);
      }
    }

    getColors();
    getSizes();
    getBranches();
  }, []);

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === MANAGE_PACKAGING)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const showOrHidePackagingDialog = (value, item = {}) => {
    setPackagingDialog(value);
    setItem(item);
    setPackagingForm({
      qty: '',
      code: '',
      color: '',
      size: '',
      branchId: ''
    });
  };

  const moveTo = (link, id = '') => {
    if (id) {
      history.push({
        pathname: link,
        search: `?id=${id}`
      });

      return;
    }
    history.push(link);
  };

  const savePackaging = async (e) => {
    e.preventDefault();

    showOrHidePackagingDialog(false);

    const unpackedQty = item.qty - item.packedQty;

    if (item) {
      if (qty > unpackedQty) {
        await Swal.fire('Error!', 'You cannot enter qty more than unpacked item', 'error');
        return;
      }

      if (item.branchId !== branchId) {
        await Swal.fire('Error!', 'Picked from wrong branch', 'error');
        return;
      }

      if (code !== item.item.code.replace('OB ', '')) {
        await Swal.fire('Error!', 'Wrong code entered', 'error');
        return;
      }

      if (item.colorId) {
        if (color !== item.colorId) {
          await Swal.fire('Error!', 'Wrong attributes selected', 'error');
          return;
        }
      }

      if (item.sizeId) {
        if (size !== item.sizeId) {
          await Swal.fire('Error!', 'Wrong attributes selected', 'error');
          return;
        }
      }

      addPackaging(
        {
          qty,
          saleOrderDetailId: item.id
        },
        isExchange ? (packagingDetails ? packagingDetails.id : saleId) : saleId,
        isExchange
      );

      setItem(null);
      setPackagingForm({
        qty: '',
        code: '',
        color: '',
        size: '',
        branchId: ''
      });
    }
  };

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const onBranchSelected = (e, value) => {
    e.preventDefault();

    setPackagingForm({
      ...packagingForm,
      branchId: value ? value.id : ''
    });
  };

  const onColorSelected = (e, value) => {
    e.preventDefault();

    setPackagingForm({
      ...packagingForm,
      color: value ? value.id : ''
    });
  };

  const onSizeSelected = (e, value) => {
    e.preventDefault();

    setPackagingForm({
      ...packagingForm,
      size: value ? value.id : ''
    });
  };

  const tableHead = [
    {
      field: 'tableData.id',
      title: 'SL',
      cellStyle: {
        width: '5%'
      },
      render: (rowData) => {
        return rowData.tableData.id + 1;
      }
    },
    {
      field: 'description',
      title: 'Description',
      cellStyle: {
        width: '35%'
      },
      render: (rowData) => {
        return rowData.description.includes('(') ? (
          <p className={'m-0'}>
            {rowData.description.split('(')[0]} (
            <span className={'font-weight-bold'}>{REGEX_STRING.exec(rowData.description)[1]}</span>)
          </p>
        ) : (
          rowData.description
        );
      }
    },
    { field: 'branch.name', title: 'Branch' },
    { field: 'qty', title: 'Quantity' },
    { field: 'packedQty', title: 'Packed Qty' }
  ];

  const packagingHistoryHeader = [
    {
      field: 'createdAt',
      title: 'Date',
      render: (rowData) => moment(rowData.createdAt).format('YYYY-MM-DD')
    },
    {
      field: 'description',
      title: 'Item',
      cellStyle: { width: '35%' }
    },
    { field: 'qty', title: 'Qty' },
    { field: 'user.username', title: 'By' }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/packaging'}>Packaging</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/packaging/details'}>Details</Link>
      </li>
    </ol>
  );

  function showClearPackedItemsDialog(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        clearPackedItems(
          { saleOrderDetailId: id },
          isExchange ? (packagingDetails ? packagingDetails.id : saleId) : saleId,
          isExchange
        );
      }
    });
  }

  const handleDeliver = () => {
    isExchange
      ? markExchangeDeliver(packagingDetails ? packagingDetails.id : '')
      : markDeliver(saleId);
  };

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>{isExchange ? 'Exchange' : ''} Invoice Details</h5>
                <p className="mb-0">Check invoice items & manage packaging</p>
              </div>
              <div>
                {packagingDetails && packagingDetails.statusId === 8 && (
                  <button
                    className={'btn btn-success text-light me-2'}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleDeliver}
                  >
                    {' '}
                    Mark as Deliver
                  </button>
                )}
                <button
                  className={'btn btn-primary me-2'}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() =>
                    moveTo(
                      '/sale/details',
                      packagingDetails ? packagingDetails.saleOrderId ?? saleId : saleId
                    )
                  }
                >
                  {' '}
                  View Sale
                </button>

                {isExchange && (
                  <button
                    className={'btn btn-primary me-2'}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={() =>
                      moveTo('/sale/exchange/print', packagingDetails ? packagingDetails.id : '')
                    }
                  >
                    <i className="fas fa-print me-2" /> Print Exchange
                  </button>
                )}

                <button className={'btn btn-outline-primary'} onClick={onCancelButtonClicked}>
                  {' '}
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <div id="card-content" className={'mt-4'}>
              <div className="card-body">
                <div className="row justify-content-between">
                  <div className="col-md-5">
                    <p className={' mb-1'}>
                      <span className={'font-weight-bold'}>Customer Name: &emsp;</span>{' '}
                      {packagingDetails ? packagingDetails.customer.name : ''}
                    </p>
                    <p className={'mb-1'}>
                      <span className={'font-weight-bold'}>Customer Phone: &emsp;</span>{' '}
                      {packagingDetails ? packagingDetails.customer.phone : ''}
                    </p>
                    {packagingDetails && (
                      <p>
                        <span className={'font-weight-bold'}>Address: &emsp;</span>{' '}
                        {packagingDetails.customer.address}
                      </p>
                    )}
                  </div>
                  <div className="col-md-5">
                    <p className={' mb-1'}>
                      <span className={'font-weight-bold'}>Invoice No: &emsp;</span>{' '}
                      {isExchange
                        ? packagingDetails
                          ? `INV-${packagingDetails.saleOrderId}`
                          : ''
                        : packagingDetails
                        ? `INV-${packagingDetails.id}`
                        : ''}
                    </p>
                    {isExchange && (
                      <p className={' mb-1'}>
                        <span className={'font-weight-bold'}>Exchange Inv. No: &emsp;</span>{' '}
                        {packagingDetails ? `SEI-${packagingDetails.id}` : ''}
                      </p>
                    )}
                    <p className={' mb-1'}>
                      <span className={'font-weight-bold'}>Date: &emsp;</span>{' '}
                      {packagingDetails ? packagingDetails.date : ''}
                    </p>
                  </div>
                </div>

                {packagingDetails && (
                  <div className={'mt-4 d-grid'}>
                    <div style={{ overflow: 'auto' }}>
                      <MaterialTable
                        columns={tableHead}
                        data={packagingDetails?.saleOrderItems}
                        totalCount={packagingDetails?.saleOrderItems.length}
                        options={{
                          toolbar: false,
                          exportButton: false,
                          showTitle: false,
                          headerStyle: {
                            padding: 4,
                            backgroundColor: '#F5F7FA'
                          },
                          padding: 'default',
                          pageSize: packagingDetails.saleOrderItems.length,
                          paging: false,
                          actionsColumnIndex: -1
                        }}
                        actions={[
                          (rowData) => ({
                            icon: 'outbox',
                            isFreeAction: true,
                            disabled: rowData.qty === rowData.packedQty,
                            tooltip: 'Prepare package',
                            onClick: () => showOrHidePackagingDialog(true, rowData)
                          }),
                          (rowData) => ({
                            icon: 'clear',
                            isFreeAction: true,
                            disabled: rowData.packedQty <= 0,
                            tooltip: 'Clear packed items',
                            onClick: () => showClearPackedItemsDialog(rowData.id)
                          })
                        ]}
                      />
                    </div>

                    <div className="row justify-content-end mt-4">
                      <div className="col-2 p-0">
                        <p className={'font-weight-bold'}>Total Qty:</p>
                        <p className={'font-weight-bold text-danger'}>Unpacked Qty:</p>
                      </div>
                      <div className="col-1 p-0">
                        <p>{packagingDetails.totalQty ? packagingDetails.totalQty : 0}</p>
                        <p>
                          {packagingDetails.totalUnpackedQty
                            ? packagingDetails.totalUnpackedQty
                            : 0}
                        </p>
                      </div>
                      <div className="col-1" />
                    </div>

                    <div className={'mt-3'}>
                      <p>
                        <strong>Notes: </strong>
                        {packagingDetails ? packagingDetails.notes : ''}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="card-content" className={'mt-4'}>
              <div className="card-header">
                <h6>Packaging Activities</h6>
                <p>All the packaging details of this invoice will be listed here.</p>
              </div>
              <div className="card-body">
                {packagingDetails && (
                  <div className={'mt-3 d-grid'}>
                    <div style={{ overflow: 'auto' }}>
                      <MaterialTable
                        columns={packagingHistoryHeader}
                        data={packagingDetails?.packagingHistory}
                        totalCount={packagingDetails?.packagingHistory.length}
                        options={{
                          toolbar: false,
                          exportButton: false,
                          showTitle: false,
                          headerStyle: {
                            padding: 4,
                            backgroundColor: '#F5F7FA'
                          },
                          padding: 'default',
                          pageSize: packagingDetails?.packagingHistory.length,
                          paging: false,
                          actionsColumnIndex: -1
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={packagingDialog}
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        onClose={() => showOrHidePackagingDialog(false)}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">Package an Item</DialogTitle>
        <DialogContent>
          <div className="card-body">
            <Fragment>
              <div className="form-group">
                <label htmlFor={'qty'}>Qty</label>
                <NumberInput
                  name={'qty'}
                  value={qty}
                  min={1}
                  onChange={(e) => onChange(e)}
                  placeholder={'Enter quantity'}
                />
              </div>

              <div className="form-group mt-3">
                <label htmlFor={'code'}>Code</label>
                <input
                  type="text"
                  name={'code'}
                  value={code}
                  onChange={(e) => onChange(e)}
                  placeholder={'Enter code manually'}
                  className="form-control"
                />

                <div className={'d-flex justify-content-center'}>
                  <h6 className={'text-primary mt-3 hover p-2'}>or Scan here...</h6>
                </div>
              </div>

              <h5 className={'mt-4'}>Did you pick up the right products?</h5>
              <p>Before start packaging be sure check the attributes again.</p>

              <div className="form-group mt-3">
                <label htmlFor={'branches'}>Branch</label>
                <Autocomplete
                  id="checkboxes-tags-demo"
                  options={branches}
                  size={'small'}
                  onChange={(event, value) => onBranchSelected(event, value)}
                  disableCloseOnSelect={false}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select branch" />
                  )}
                />
              </div>

              <div className="form-group mt-3">
                <label htmlFor={'colors'}>Color</label>
                <Autocomplete
                  id="checkboxes-tags-demo"
                  options={colors}
                  size={'small'}
                  onChange={(event, value) => onColorSelected(event, value)}
                  disableCloseOnSelect={false}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select color" />
                  )}
                />
              </div>

              <div className="form-group mt-3">
                <label htmlFor={'size'}>Size</label>
                <Autocomplete
                  id="checkboxes-tags-demo"
                  options={sizes}
                  size={'small'}
                  onChange={(event, value) => onSizeSelected(event, value)}
                  disableCloseOnSelect={false}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select size" />
                  )}
                />
              </div>
            </Fragment>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => showOrHidePackagingDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={qty.length === 0 || code.length === 0}
            onClick={(e) => savePackaging(e)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

PackagingDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getPackagingDetails: PropTypes.func.isRequired,
  sale: PropTypes.object.isRequired,
  getColors: PropTypes.func.isRequired,
  getSizes: PropTypes.func.isRequired,
  addPackaging: PropTypes.func.isRequired,
  markDeliver: PropTypes.func.isRequired,
  getExchangePackagingDetails: PropTypes.func.isRequired,
  markExchangeDeliver: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  sale: state.sale,
  products: state.products,
  auth: state.auth,
  system: state.system
});

export default connect(mapStateToProps, {
  getPackagingDetails,
  getColors,
  getSizes,
  addPackaging,
  clearPackedItems,
  markDeliver,
  getExchangePackagingDetails,
  markExchangeDeliver,
  getBranches
})(PackagingDetails);
