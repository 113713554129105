import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded, Print } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import moment from 'moment';
import {
  acceptSaleOrder,
  addSaleOrderPayment,
  cancelSale,
  getSaleList
} from '../../../actions/sale';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getBankAccounts } from '../../../actions/bankings';
import { getIncomeCategories, getPaymentMethods } from '../../../actions/system';
import Swal from 'sweetalert2';
import { MANAGE_PACKAGING, MODIFY_SALES, SALES_ENTRY, SALES_INVOICES } from '../../../permissions';
import { DEFAULT_INCOME_CATEGORY, DEFAULT_PAYMENT_METHOD } from '../../../const';
import { SALE_SECTIONS, STATUSES } from '../../../utils';
import NumberInput from '../../common/NumberInput';
import FilterMaterialTable from '../../common/FilterMaterialTable';

function SaleInvoices({
  isLoading,
  getBankAccounts,
  getPaymentMethods,
  getIncomeCategories,
  getSaleList,
  cancelSale,
  addSaleOrderPayment,
  acceptSaleOrder,
  sale: { sale },
  auth: { permissions },
  bankings: { bankAccounts },
  system: { paymentMethods, incomeCategories }
}) {
  const history = useHistory();
  const from_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const to_date = moment().format('YYYY-MM-DD');
  const [statusId, setStatusId] = useState('');

  const [inputFocus, setInputFocus] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [salePaymentForm, setSalePaymentForm] = useState({
    id: '',
    bankAccountId: '',
    paymentMethodId: '',
    categoryId: '',
    amount: '',
    date: moment().format('YYYY-MM-DD'),
    description: '',
    reference: '',
    total: ''
  });

  const {
    id,
    bankAccountId,
    paymentMethodId,
    categoryId,
    amount,
    date,
    description,
    reference,
    total
  } = salePaymentForm;

  const onChange = (e) =>
    setSalePaymentForm({ ...salePaymentForm, [e.target.name]: e.target.value });

  useEffect(() => {
    getPaymentMethods();
    getBankAccounts();
    getIncomeCategories();
  }, []);

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === SALES_INVOICES)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    if (paymentMethods.length > 0) {
      setSalePaymentForm({
        ...salePaymentForm,
        paymentMethodId: paymentMethods.find((item) => item.name === DEFAULT_PAYMENT_METHOD).id
      });
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (bankAccounts.length > 0) {
      setSalePaymentForm({
        ...salePaymentForm,
        bankAccountId: bankAccounts.find((item) => item.default_sale === true).id
      });
    }
  }, [bankAccounts]);

  useEffect(() => {
    if (incomeCategories.length > 0) {
      setSalePaymentForm({
        ...salePaymentForm,
        categoryId: incomeCategories.find((item) => item.name === DEFAULT_INCOME_CATEGORY).id
      });
    }
  }, [incomeCategories]);

  const openDialog = (data) => {
    setSalePaymentForm({
      ...salePaymentForm,
      id: data.id,
      amount: data.due,
      description: `Payment for INV-${data.id}`,
      reference: `INV-${data.id}`,
      total: data.due,
      phone: data.customer.phone
    });
    setDialogOpen(true);
    setInputFocus(false);
  };

  const hideDialog = () => {
    setDialogOpen(false);
    setInputFocus(true);
  };

  const handleHistory = () => {
    history.push('#');
  };

  const onBankAccountSelected = (e, value) => {
    e.preventDefault();

    setSalePaymentForm({
      ...salePaymentForm,
      bankAccountId: value ? value.id : ''
    });
  };

  const onPaymentMethodSelected = (e, value) => {
    e.preventDefault();

    setSalePaymentForm({
      ...salePaymentForm,
      paymentMethodId: value ? value.id : ''
    });
  };

  const onCategorySelected = (e, value) => {
    e.preventDefault();

    setSalePaymentForm({
      ...salePaymentForm,
      categoryId: value ? value.id : ''
    });
  };

  const handleSaleDetails = (id) => {
    history.push({
      pathname: '/sale/details',
      search: `?id=${id}`
    });
  };

  const submitPayNow = (e) => {
    e.preventDefault();

    addSaleOrderPayment(salePaymentForm, from_date, to_date);
    hideDialog();
  };

  const acceptSaleInvoice = async (e, id) => {
    e.preventDefault();

    const data = await acceptSaleOrder(id, from_date, to_date);

    if (data) {
      let htmlContent = '';
      let index = 1;
      for (const item of data) {
        const content = `<div>
          <b>${index}.</b>&nbsp;
          <b>${item.itemDesc}</b><br>
          <span>${item.description}</span><br>
          <u>Available Qty:</u> <strong>${item.availableQty}</strong> &nbsp;&nbsp;
          <u>Required Qty:</u> <strong>${item.needQty}</strong>
        </div><br>`;

        htmlContent += content;
        index++;
      }

      await Swal.fire({
        title: 'Stock Availability Error',
        icon: 'error',
        html: htmlContent,
        confirmButtonText: 'Ok'
      });
    }
  };

  const cancelSaleInvoice = (e, id) => {
    e.preventDefault();

    Swal.fire({
      title: 'Cancel Invoice?',
      text: "Are you sure? You won't be able to revert this order!",
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'With charge!',
      denyButtonText: 'Without charge!'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          isDeliveryCharge: true
        };
        cancelSale(id, data);
      }

      if (result.isDenied) {
        cancelSale(id);
      }
    });
  };
  const moveToSaleUpdate = (id) => {
    history.push({
      pathname: '/sale/update',
      search: `?id=${id}`
    });
  };

  const moveToPackageDetails = (id) => {
    history.push({
      pathname: '/packaging/details',
      search: `?id=${id}&&is_exchange=false`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale'}>Sale</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale/all'}>Invoices</Link>
      </li>
    </ol>
  );

  const tableHead = [
    {
      field: 'id',
      title: 'Inv. No',
      render: (rowData) => (
        <Link
          className={'font-weight-bold link-primary hover text-decoration-none'}
          to={`/sale/details?id=${rowData.id}`}>{`INV-${rowData.id}`}</Link>
      )
    },
    {
      field: 'customer.name',
      title: 'Customer',
      render: (rowData) => (
        <Link
          className={'font-weight-bold link-primary hover text-decoration-none'}
          to={`/customers/details?id=${rowData.customer.id}`}>
          {rowData.customer.name}
        </Link>
      )
    },
    {
      field: 'customer.phone',
      title: 'Phone',
      render: (rowData) => (
        <Link
          className={'font-weight-bold link-primary hover text-decoration-none'}
          to={`/customers/details?id=${rowData.customer.id}`}>
          {rowData.customer.phone}
        </Link>
      )
    },
    { field: 'date', searchable: false, title: 'Date', cellStyle: { width: '7%' } },
    // {field: 'discount', searchable: false, title: 'Discount (৳)', type: 'numeric'},
    { field: 'total', searchable: false, title: 'Total(৳)', cellStyle: { width: '5%' } },
    { field: 'paid', searchable: false, title: 'Paid(৳)', cellStyle: { width: '5%' } },
    { field: 'due', searchable: false, title: 'Due(৳)', cellStyle: { width: '5%' } },
    { field: 'user.username', searchable: false, title: 'Sold By', cellStyle: { width: '6%' } },
    {
      field: 'deliveredBy.username',
      searchable: false,
      title: 'Packed By',
      cellStyle: { width: '7%' }
    },
    {
      field: 'status.name',
      searchable: false,
      title: 'Status',
      cellStyle: { width: '5%' },
      render: (rowData) => (
        <span className={rowData.status.name.toLowerCase().trim().replace(' ', '-')}>
          {rowData.status.name}
        </span>
      )
    },
    {
      field: 'payment_method.name',
      searchable: false,
      title: 'Pay Method',
      cellStyle: { width: '8%' }
    },
    {
      field: 'isWeb',
      title: 'Web Sale',
      render: (rowData) => <Checkbox className={'saleCheckbox'} disabled checked={rowData.isWeb} />
    },
    { field: 'tempDesc', title: 'Desc' }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SALE_SECTIONS} />

      <div className="container-fluid mt-4">
        {permissions.find((item) => item.name === SALES_ENTRY) && (
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>New Sale Entry</h5>
                  <p className={'mb-0'}>Add new sale</p>
                </div>
                <div>
                  <Link
                    to={'/sale/new'}
                    className="btn btn-primary p-2 rounded text-decoration-none">
                    <i className="far fa-plus-square me-2" /> Sale Entry
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <FilterMaterialTable
          title={'Sale Invoices'}
          isLoading={isLoading}
          columns={tableHead}
          data={sale}
          tableContainerClass={'mt-3 invoice-table'}
          fetchMethod={getSaleList}
          inputFocus={inputFocus}
          isCustomPagination
          allTime
          args={{
            statusId
          }}
          options={{
            rowStyle: (rowData) => {
              return {
                fontSize: '14px',
                color: '#000',
                height: 50,
                backgroundColor:
                  (rowData.status.id === 7 && '#fddede') || (rowData.status.id === 9 && '#e1e1e1')
              };
            }
          }}
          actions={[
            (rowData) => ({
              icon: 'visibility_on',
              onClick: () => handleSaleDetails(rowData.id),
              tooltip: 'Sale details'
            }),
            (rowData) => ({
              icon: () => (
                <Link className={'table-icon'} to={`/sale/print?id=${rowData.id}`} target="_blank">
                  <Print />
                </Link>
              ),
              onClick: rowData,
              tooltip: 'Print Invoice'
            }),
            permissions.find((item) => item.name === MODIFY_SALES) &&
              ((rowData) => ({
                icon: 'edit',
                onClick: () => moveToSaleUpdate(rowData.id),
                tooltip: 'Edit Invoice',
                disabled:
                  rowData.statusId !== 5 &&
                  rowData.statusId !== 2 &&
                  rowData.statusId !== 8 &&
                  rowData.statusId !== 10
              })),
            permissions.find((item) => item.name === MODIFY_SALES) &&
              ((rowData) => ({
                icon: 'shopping_cart_checkout',
                onClick: (e) => acceptSaleInvoice(e, rowData.id),
                tooltip: 'Accept Sale',
                disabled: !(rowData.statusId === 9 && rowData.isWeb)
              })),
            permissions.find((item) => item.name === MANAGE_PACKAGING) &&
              ((rowData) => ({
                icon: 'outbox',
                onClick: (e) => moveToPackageDetails(rowData.id),
                tooltip: 'Prepare Package',
                disabled:
                  rowData.statusId !== 5 && rowData.statusId !== 8 && rowData.statusId !== 10
              })),
            permissions.find((item) => item.name === MODIFY_SALES) &&
              ((rowData) => ({
                icon: 'payment',
                onClick: () => openDialog(rowData),
                tooltip: 'Pay Now',
                disabled:
                  rowData.statusId === 7 ||
                  rowData.statusId === 3 ||
                  rowData.statusId === 8 ||
                  rowData.due === '0.00' ||
                  rowData.statusId === 9
              })),
            permissions.find((item) => item.name === MODIFY_SALES) &&
              ((rowData) => ({
                // if status is pending
                icon: 'close',
                onClick: (e) => cancelSaleInvoice(e, rowData.id),
                tooltip: 'Cancel Sale',
                disabled:
                  rowData.statusId !== 5 &&
                  rowData.statusId !== 2 &&
                  rowData.statusId !== 8 &&
                  rowData.statusId !== 9
              }))
          ]}
          toolbarChildren={
            <form action="#" style={{ width: '150px' }}>
              <div className="form-group">
                <select
                  name={'statusId'}
                  value={statusId}
                  onChange={(e) => setStatusId(e.target.value)}
                  className="form-control small border-primary text-primary"
                  placeholder="Select status">
                  <option value={''}>All</option>
                  {STATUSES.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          }
        />
      </div>

      <Dialog
        open={dialogOpen}
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        onClose={hideDialog}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="alert-dialog-slide-title">New Sale Payment</DialogTitle>
        <DialogContent>
          <div className="card-body">
            <React.Fragment>
              <div className="form-group">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Account <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Autocomplete
                      id={'select-branch'}
                      onChange={(event, value) => onBankAccountSelected(event, value)}
                      options={bankAccounts}
                      getOptionLabel={(option) => option.account_name}
                      renderOption={(option) => option.account_name}
                      defaultValue={bankAccounts.find((item) => item.id === bankAccountId)}
                      freeSolo
                      size={'small'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Select One'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Payment Method <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Autocomplete
                      id={'select-branch'}
                      onChange={(event, value) => onPaymentMethodSelected(event, value)}
                      options={paymentMethods}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => option.name}
                      defaultValue={paymentMethods.find((item) => item.id === paymentMethodId)}
                      freeSolo
                      size={'small'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Select One'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Category <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Autocomplete
                      id={'select-branch'}
                      onChange={(event, value) => onCategorySelected(event, value)}
                      options={incomeCategories}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => option.name}
                      defaultValue={incomeCategories.find((item) => item.name === 'Sales')}
                      freeSolo
                      size={'small'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Select One'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'subTotal'}>
                      Amount <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <NumberInput
                      name={'amount'}
                      value={amount}
                      required
                      max={total}
                      onChange={(e) => onChange(e)}
                      placeholder={'Amount'}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Date <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="date"
                      name={'date'}
                      required
                      defaultValue={date}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Choose a date'}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'description'}>
                      Description <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      name={'description'}
                      value={description}
                      required
                      onChange={(e) => onChange(e)}
                      disabled
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'reference'}>Reference</label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      name={'reference'}
                      value={reference}
                      onChange={(e) => onChange(e)}
                      placeholder={'Reference'}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideDialog} disabled={isLoading} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!bankAccountId || !paymentMethodId || !categoryId || isLoading}
            onClick={(e) => submitPayNow(e)}>
            Pay Now
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

SaleInvoices.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getSaleList: PropTypes.func.isRequired,
  sale: PropTypes.object.isRequired,
  system: PropTypes.object.isRequired,
  bankings: PropTypes.object.isRequired,
  getBankAccounts: PropTypes.func.isRequired,
  getIncomeCategories: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  addSaleOrderPayment: PropTypes.func.isRequired,
  cancelSale: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  sale: state.sale,
  bankings: state.bankings,
  system: state.system,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getSaleList,
  getBankAccounts,
  getIncomeCategories,
  getPaymentMethods,
  addSaleOrderPayment,
  cancelSale,
  acceptSaleOrder
})(SaleInvoices);
