import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import { Checkbox, TextField } from '@material-ui/core';
import {
  addBrand,
  addUnit,
  getBrands,
  getCategories,
  getColors,
  getSizes,
  getTypes,
  getUnits,
  updateProduct,
  replaceProductSize,
  replaceProductColor
} from '../../../../actions/products';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InsertImageIcon from '../../../../assets/icons/photo_main.png';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import API, { base_url_image } from '../../../../api';
import { MODIFY_PRODUCTS } from '../../../../permissions';
import { addLocation, getBranches, getLocations } from '../../../../actions/system';
import { getProductVariationPrice } from '../../../../utils';
import ReplaceSizeColor from './ReplaceSizeColor';
import NumberInput from '../../../common/NumberInput';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon color={'primary'} fontSize="small" />;

const filter = createFilterOptions();

function UpdateProduct({
  isLoading,
  getBrands,
  getUnits,
  getSizes,
  getTypes,
  getCategories,
  getColors,
  getBranches,
  addLocation,
  getLocations,
  auth: { permissions },
  products: { brands, categories, units, sizes, colors, types },
  addBrand,
  addUnit,
  location,
  updateProduct,
  system: { branches, locations },
  replaceProductSize,
  replaceProductColor
}) {
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    code: '',
    price: '',
    purchase_price: '',
    discount_price: '',
    re_order_lvl: 0,
    brandId: '',
    brandText: '',
    unitId: '',
    typeId: '',
    unitText: '',
    typeText: '',
    youtube_url: '',
    inventory_desc: '',
    short_desc: '',
    rackLocText: '',
    rackLocId: '',
    product_locations: [],
    dataVariations: []
  });
  const [newCategories, setNewCategory] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);
  const [selectedColorIds, setSelectedColorId] = useState([]);
  const [selectedSizeId, setSelectedSizeId] = useState([]);
  const [variations, setVariations] = useState([]);
  const [imageInputs, setImageInput] = useState([
    InsertImageIcon,
    InsertImageIcon,
    InsertImageIcon,
    InsertImageIcon,
    InsertImageIcon,
    InsertImageIcon
  ]);
  const [removedImageId, setRemovedImageId] = useState([]);
  const [files, setFiles] = useState([null, null, null, null, null, null]);
  const [productDetails, setProductDetails] = useState();
  const [replaceSizeDialog, setReplaceSizeDialog] = useState(false);
  const [replaceSizeDialogForm, setReplaceSizeDialogForm] = useState(null);
  const [replaceColorDialog, setReplaceColorDialog] = useState(false);
  const [replaceColorDialogForm, setReplaceColorDialogForm] = useState(null);
  const {
    name,
    description,
    code,
    price,
    purchase_price,
    discount_price,
    re_order_lvl,
    brandId,
    brandText,
    unitText,
    typeText,
    unitId,
    typeId,
    youtube_url,
    inventory_desc,
    short_desc,
    product_locations,
    rackLocText,
    rackLocId,
    dataVariations
  } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === MODIFY_PRODUCTS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getBrands();
    getUnits();
    getCategories();
    getColors();
    getSizes();
    getTypes();
    getLocations();
    getBranches();
  }, []);

  useEffect(() => {
    const newCategory = [];
    if (categories) {
      categories.map((category) => {
        newCategory.push({
          createdAt: category.createdAt,
          id: category.id,
          name: category.name,
          parentId: category.parentId,
          updatedAt: category.updatedAt
        });

        for (const subcategory of category.subcategories) {
          newCategory.push(subcategory);
        }
      });
    }

    setNewCategory(newCategory);
  }, [categories]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      getProductDetails(id);
    }
  }, []);

  useEffect(() => {
    const vars = getProductVariationPrice(name, selectedColorIds, selectedSizeId);
    if (vars.length > 0) {
      const newArray = [];
      for (const variation of vars) {
        const existing = dataVariations.find(
          (item) =>
            item.colorId === (variation.colorId ? variation.colorId : null) &&
            item.sizeId === (variation.sizeId ? variation.sizeId : null)
        );
        if (existing) {
          variation.price = existing.price;
          variation.purchase_price = existing.purchase_price;
        } else {
          variation.price = '';
          variation.purchase_price = '';
        }
        newArray.push(variation);
      }
      setVariations(newArray);
    }
  }, [selectedColorIds, selectedSizeId, name, dataVariations]);

  const getProductDetails = (id) => {
    API.products
      .getProductDetails(id)
      .then((res) => {
        const data = res.data.data;
        setProductDetails(data);

        const productLocations = [];
        for (const loc of data.locations) {
          productLocations.push({
            branchId: loc.branch.branchId,
            locationId: loc.id
          });
        }

        setFormData({
          ...formData,
          name: data.name,
          description: data.description,
          code: data.code,
          price: data.price,
          purchase_price: data.purchase_price,
          discount_price: data.discount_price ? data.discount_price : '',
          re_order_lvl: data.re_order_lvl,
          brandId: data.brandId ?? '',
          brandText: data.brand ? data.brand.name : '',
          unitId: data.unitId ?? '',
          typeId: data.typeId ?? '',
          unitText: data.unit ? data.unit.name : '',
          typeText: data.type ? data.type.name : '',
          youtube_url: data.youtube_url,
          inventory_desc: data.inventory_desc,
          short_desc: data.short_desc,
          rackLocText: data.rackLoc ? data.rackLoc.name : '',
          rackLocId: data.rackLocId,
          product_locations: productLocations,
          dataVariations: data.variations
        });

        setImageInput([
          data.image_1 ? base_url_image + data.image_1 : InsertImageIcon,
          data.image_2 ? base_url_image + data.image_2 : InsertImageIcon,
          data.image_3 ? base_url_image + data.image_3 : InsertImageIcon,
          data.image_4 ? base_url_image + data.image_4 : InsertImageIcon,
          data.image_5 ? base_url_image + data.image_5 : InsertImageIcon,
          data.inventory_image ? base_url_image + data.inventory_image : InsertImageIcon
        ]);

        setSelectedColorId(data.colors);
        setSelectedSizeId(data.sizes);
        setSelectedCategoryId(data.categories);
      })
      .catch((err) => {
        console.log('Error', err, err.response);
      });
  };

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const saveProduct = async (e) => {
    e.preventDefault();
    const query = new URLSearchParams(location.search);
    const id = query.get('id');
    const data = await updateProduct(
      id,
      formData,
      selectedCategoryId,
      selectedSizeId,
      files,
      selectedColorIds,
      removedImageId,
      variations
    );
    if (data) {
      history.push('/inventory');
    }
  };

  const onBrandSelected = (e, value) => {
    setFormData({
      ...formData,
      brandId: value ? value.id : '',
      brandText: value ? value.name : ''
    });

    if (value && value.inputValue) {
      setFormData({
        ...formData,
        brandText: ''
      });
      addBrand({ name: value.inputValue });
    }
  };

  const onUnitSelected = (e, value) => {
    setFormData({
      ...formData,
      unitId: value ? value.id : '',
      unitText: value ? value.name : ''
    });

    if (value && value.inputValue) {
      setFormData({
        ...formData,
        unitText: ''
      });
      addUnit({ name: value.inputValue });
    }
  };

  const onVariationPriceChange = (e, item) => {
    e.preventDefault();

    const value = e.target.value;
    const existingItem = variations.find((i) => i.s_code === item.s_code);
    if (existingItem) {
      existingItem[e.target.name] = value;
      updateVariations();
    }
  };

  const updateVariations = () => {
    const vars = getProductVariationPrice(name, selectedColorIds, selectedSizeId);
    const newArray = [...variations];
    for (const variation of vars) {
      const existingPrice = newArray.find((item) => item.s_code === variation.s_code);
      if (existingPrice) {
        existingPrice.name = variation.name;
        existingPrice.color = variation.color;
        existingPrice.size = variation.size;
        existingPrice.colorId = variation.colorId;
        existingPrice.sizeId = variation.sizeId;
      } else {
        newArray.push(variation);
      }
    }

    setVariations(newArray);

    if (vars.length !== newArray.length) {
      const vars_code = vars.map((item) => item.s_code);
      setVariations(newArray.filter((item) => vars_code.includes(item.s_code)));
    }
  };

  const onProductLocationChange = async (e, branch, value) => {
    const newLocations = product_locations.filter((item) => item.branchId !== branch.id);

    if (value && value.inputValue) {
      const data = await addLocation({ name: value.inputValue });

      if (data) {
        newLocations.push({
          branchId: branch.id,
          locationId: data.id
        });
      }
    } else {
      if (value) {
        newLocations.push({
          branchId: branch.id,
          locationId: value.id
        });
      } else {
        newLocations.push({
          branchId: branch.id,
          locationId: ''
        });
      }
    }

    setFormData({
      ...formData,
      product_locations: newLocations
    });
  };

  const onRackLocSelected = async (e, value) => {
    setFormData({
      ...formData,
      rackLocId: value ? value.id : ''
    });

    if (value && value.inputValue) {
      const data = await addLocation({ name: value.inputValue });
      if (data) {
        setFormData({
          ...formData,
          rackLocId: data.id
        });
      }
    }
  };

  const onTypeSelected = (e, value) => {
    setFormData({
      ...formData,
      typeId: value ? value.id : '',
      typeText: value ? value.name : ''
    });

    if (value && value.inputValue) {
      setFormData({
        ...formData,
        typeText: ''
      });
      addUnit({ name: value.inputValue });
    }
  };

  const onCategorySelected = (e, value) => {
    setSelectedCategoryId(value);
  };

  const onColorSelected = (e, value) => {
    setSelectedColorId(value);
  };

  const onSizeSelected = (e, value) => {
    setSelectedSizeId(value);
  };

  const setImage = (e, img_index) => {
    if (typeof e.target.files[0] !== 'undefined') {
      let image = URL.createObjectURL(e.target.files[0]);
      let image_file = e.target.files[0];
      let newData = [...imageInputs];
      let newData_2 = [...files];
      newData_2[img_index] = image_file;
      newData[img_index] = image;
      setFiles(newData_2);
      setImageInput(newData);
    }
  };
  const deleteImage = (img_index) => {
    let newData = [...imageInputs];
    newData[img_index] = InsertImageIcon;
    setImageInput(newData);
    if (imageInputs[img_index].includes('/images/')) {
      removedImageId.push(img_index + 1);
    }
  };

  const setColorImage = (e, colorId) => {
    if (typeof e.target.files[0] !== 'undefined') {
      let image_file = e.target.files[0];

      const img = document.getElementById(`colorImage_${colorId}`);
      const reader = new FileReader();
      reader.onload = function (event) {
        img.src = event.target.result;
      };
      reader.readAsDataURL(image_file);

      const colorIndex = selectedColorIds.findIndex((item) => item.id === colorId);
      const color = selectedColorIds[colorIndex];
      selectedColorIds[colorIndex] = {
        ...color,
        image: image_file
      };
    }
  };

  const deleteColorImage = (colorId) => {
    const img = document.getElementById(`colorImage_${colorId}`);
    img.src = InsertImageIcon;

    const colorIndex = selectedColorIds.findIndex((item) => item.id === colorId);
    const color = selectedColorIds[colorIndex];
    delete color.image;
    if (color.item_colors) {
      delete color.item_colors;
    }
    selectedColorIds[colorIndex] = color;
  };

  const setSizeImage = (e, sizeId) => {
    if (typeof e.target.files[0] !== 'undefined') {
      let image_file = e.target.files[0];

      const img = document.getElementById(`sizeImage_${sizeId}`);
      const reader = new FileReader();
      reader.onload = function (event) {
        img.src = event.target.result;
      };
      reader.readAsDataURL(image_file);

      const sizeIndex = selectedSizeId.findIndex((item) => item.id === sizeId);
      const size = selectedSizeId[sizeIndex];
      selectedSizeId[sizeIndex] = {
        ...size,
        image: image_file
      };
    }
  };

  const deleteSizeImage = (sizeId) => {
    const img = document.getElementById(`sizeImage_${sizeId}`);
    img.src = InsertImageIcon;

    const sizeIndex = selectedSizeId.findIndex((item) => item.id === sizeId);
    const size = selectedSizeId[sizeIndex];
    delete size.image;
    if (size.item_sizes) {
      delete size.item_sizes;
    }
    selectedSizeId[sizeIndex] = size;
  };

  const replaceSize = (size) => {
    setReplaceSizeDialog(true);
    setReplaceSizeDialogForm({
      oldRef: size.id,
      data: size
    });
  };

  const replaceColor = (color) => {
    setReplaceColorDialog(true);
    setReplaceColorDialogForm({
      oldRef: color.id,
      data: color
    });
  };

  const saveReplacedSize = () => {
    productDetails &&
      replaceProductSize(productDetails.id, replaceSizeDialogForm, getProductDetails);
    setReplaceSizeDialog(false);
    setReplaceSizeDialogForm(null);
  };

  const saveReplacedColor = () => {
    productDetails &&
      replaceProductColor(productDetails.id, replaceColorDialogForm, getProductDetails);
    setReplaceColorDialog(false);
    setReplaceColorDialogForm(null);
  };

  return (
    <Fragment>
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid">
        <div id="card-content">
          <form className="card-header" onSubmit={(e) => saveProduct(e)}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Product Details</h5>
                <p className={'mb-0'}>Manage your product</p>
              </div>
              <div>
                <button
                  type={'submit'}
                  className="btn btn-primary p-2 me-2"
                  disabled={
                    name.length === 0 ||
                    description.length === 0 ||
                    code.length === 0 ||
                    price.length === 0 ||
                    isLoading
                  }
                >
                  Update
                </button>
                <button
                  onClick={onCancelButtonClicked}
                  type={'button'}
                  disabled={isLoading}
                  className="btn btn-danger p-2 text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>

          <div className="card-body mt-2">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'code'}>
                    Code <span className={'text-danger'}>*</span>
                  </label>
                  <input
                    type="text"
                    name={'code'}
                    required
                    value={code}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter code'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'name'}>
                    Name <span className={'text-danger'}>*</span>
                  </label>
                  <input
                    type="text"
                    name={'name'}
                    required
                    value={name}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter product name'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'price'}>
                    Price <span className={'text-danger'}>*</span>
                  </label>
                  <NumberInput
                    name={'price'}
                    required
                    value={price}
                    onChange={(e) => onChange(e)}
                    placeholder={'Enter price'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group mt-3">
                  <label htmlFor={'discount_price'}>Discount Price</label>
                  <NumberInput
                    name={'discount_price'}
                    value={discount_price}
                    onChange={(e) => onChange(e)}
                    placeholder={'Enter discount price'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group mt-3">
                  <label htmlFor={'purchase_price'}>
                    Purchase Price <span className={'text-danger'}>*</span>
                  </label>
                  <NumberInput
                    name={'purchase_price'}
                    required
                    value={purchase_price}
                    onChange={(e) => onChange(e)}
                    placeholder={'Enter purchase price'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group mt-3">
                  <label htmlFor={'re_order_lvl'}>Re-order Level</label>
                  <NumberInput
                    name={'re_order_lvl'}
                    value={re_order_lvl}
                    onChange={(e) => onChange(e)}
                    placeholder={'Enter re-order level'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group mt-3">
                  <label htmlFor={'youtube_url'}>Youtube Video Url</label>
                  <input
                    name={'youtube_url'}
                    type="text"
                    value={youtube_url}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter product video url'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group mt-3">
                  <label htmlFor={'inventory_desc'}>Inventory Description</label>
                  <input
                    name={'inventory_desc'}
                    type="text"
                    value={inventory_desc}
                    maxLength={150}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter inventory product description'}
                  />

                  <p className={'m-0'}>This will only visible in Admin Panel. Max length 150</p>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label htmlFor={'short_desc'}>Short Description</label>
                  <textarea
                    name={'short_desc'}
                    rows={3}
                    value={short_desc}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter product short description for website'}
                  />
                </div>
              </div>

              <div className="col-md-8">
                <div className="form-group mt-3">
                  <label htmlFor={'description'}>
                    Description <span className={'text-danger'}>*</span>
                  </label>
                  <textarea
                    name={'description'}
                    required
                    rows={6}
                    value={description}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter product description for website'}
                  />

                  <p className={'m-0 text-danger'}>
                    *Product description for website. Html supported.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <h6 className={'text-primary mt-4'}>Preview Description</h6>
                <div
                  className={'border p-2'}
                  style={{ maxWidth: '100%' }}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <h5>Product Images</h5>
                    <p className={'text-muted mb-0'}>
                      Select up to 5 product images. This images will be shown in website
                    </p>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="d-flex image_area rounded">
                      <div className="d-flex" style={{ flex: '1' }}>
                        <div className="w-100 image_area" style={{ position: 'relative' }}>
                          <HighlightOffIcon
                            className="delete-image-icon"
                            onClick={() => deleteImage(0)}
                          />
                          <img src={imageInputs[0]} className="add_image_bck" alt="img-input" />
                          <input
                            accept="image/*"
                            name="images"
                            className="add_image_btn"
                            type="file"
                            onChange={(event) => setImage(event, 0)}
                          />
                        </div>
                      </div>
                      <div style={{ flex: '1' }}>
                        <div className="d-flex">
                          <div
                            className={'image_area'}
                            style={{
                              position: 'relative',
                              flex: '1'
                            }}
                          >
                            <HighlightOffIcon
                              className="delete-image-icon"
                              onClick={() => deleteImage(1)}
                            />
                            <img src={imageInputs[1]} className="add_image_bck" alt="img-input" />
                            <input
                              name="images"
                              accept="image/*"
                              className="add_image_btn"
                              type="file"
                              onChange={(event) => setImage(event, 1)}
                            />
                          </div>
                          <div
                            className={'image_area'}
                            style={{
                              position: 'relative',
                              flex: '1'
                            }}
                          >
                            <HighlightOffIcon
                              className="delete-image-icon"
                              onClick={() => deleteImage(2)}
                            />
                            <img src={imageInputs[2]} className="add_image_bck" alt="img-input" />
                            <input
                              name="images"
                              accept="image/*"
                              className="add_image_btn"
                              type="file"
                              onChange={(event) => setImage(event, 2)}
                            />
                          </div>
                        </div>
                        <div className="d-flex">
                          <div
                            className={'image_area'}
                            style={{
                              position: 'relative',
                              flex: '1'
                            }}
                          >
                            <HighlightOffIcon
                              className="delete-image-icon"
                              onClick={() => deleteImage(3)}
                            />
                            <img src={imageInputs[3]} className="add_image_bck" alt="img-input" />
                            <input
                              name="images"
                              accept="image/*"
                              className="add_image_btn"
                              type="file"
                              onChange={(event) => setImage(event, 3)}
                            />
                          </div>
                          <div
                            className={'image_area'}
                            style={{
                              position: 'relative',
                              flex: '1'
                            }}
                          >
                            <HighlightOffIcon
                              className="delete-image-icon"
                              onClick={() => deleteImage(4)}
                            />
                            <img src={imageInputs[4]} className="add_image_bck" alt="img-input" />
                            <input
                              name="images"
                              accept="image/*"
                              className="add_image_btn"
                              type="file"
                              onChange={(event) => setImage(event, 4)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-4">
                    <h5>Inventory Image (Optional)</h5>
                    <p className={'text-muted mb-0'}>
                      Select a inventory image, that will only show in admin panel product list
                    </p>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="d-flex image_area rounded" style={{ width: 150, height: 150 }}>
                      <div
                        className={'image_area'}
                        style={{
                          position: 'relative',
                          flex: '1'
                        }}
                      >
                        <HighlightOffIcon
                          className="delete-image-icon"
                          onClick={() => deleteImage(5)}
                        />
                        <img src={imageInputs[5]} className="add_image_bck" alt="img-input" />
                        <input
                          name="images"
                          accept="image/*"
                          className="add_image_btn"
                          type="file"
                          onChange={(event) => setImage(event, 5)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {branches.length > 0 && (
                  <div className="col-md-12 mt-4">
                    <div className="row">
                      <div className="col-md-12 mt-4">
                        <h5>Product Location</h5>
                        <p className={'text-muted mb-0'}>
                          Select a product location for multiple branches
                        </p>
                      </div>

                      {branches.map((branch) => (
                        <div className="col-md-6 mt-4">
                          <div className="form-group">
                            <label htmlFor={`select-${branch.id}-location`}>{branch.name}</label>
                            {product_locations.length > 0 && locations.length > 0 && (
                              <Autocomplete
                                id={`select-${branch.id}-location`}
                                onChange={(event, value) =>
                                  onProductLocationChange(event, branch, value)
                                }
                                options={locations}
                                style={{ width: '100%' }}
                                size="small"
                                renderOption={(option) => option.name}
                                defaultValue={locations.find(
                                  (v) =>
                                    v.id ===
                                    product_locations.find((item) => item.branchId === branch.id)
                                      ?.locationId
                                )}
                                getOptionLabel={(option) => {
                                  // e.g value selected with enter, right from the input
                                  if (typeof option === 'string') {
                                    return option;
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue;
                                  }
                                  return option ? option.name : '';
                                }}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  if (params.inputValue !== '') {
                                    filtered.push({
                                      inputValue: params.inputValue,
                                      name: `Add "${params.inputValue}"`
                                    });
                                  }
                                  return filtered;
                                }}
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: {
                                        padding: 0,
                                        height: '36px',
                                        marginBottom: 4
                                      }
                                    }}
                                    placeholder={'Select a location'}
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}

                            {product_locations.length === 0 && (
                              <Autocomplete
                                id={`select-${branch.id}-location`}
                                onChange={(event, value) =>
                                  onProductLocationChange(event, branch, value)
                                }
                                options={locations}
                                style={{ width: '100%' }}
                                size="small"
                                renderOption={(option) => option.name}
                                getOptionLabel={(option) => {
                                  // e.g value selected with enter, right from the input
                                  if (typeof option === 'string') {
                                    return option;
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue;
                                  }
                                  return option ? option.name : '';
                                }}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  if (params.inputValue !== '') {
                                    filtered.push({
                                      inputValue: params.inputValue,
                                      name: `Add "${params.inputValue}"`
                                    });
                                  }
                                  return filtered;
                                }}
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: {
                                        padding: 0,
                                        height: '36px',
                                        marginBottom: 4
                                      }
                                    }}
                                    placeholder={'Select a location'}
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                          </div>
                        </div>
                      ))}

                      <div className="col-md-6 mt-4">
                        <div className="form-group">
                          <label htmlFor={`select-rack-location`}>Rack Location</label>
                          <Autocomplete
                            id={`select-rack-location`}
                            value={rackLocText}
                            onChange={(event, value) => onRackLocSelected(event, value)}
                            options={locations}
                            style={{ width: '100%' }}
                            size="small"
                            renderOption={(option) => option.name}
                            getOptionLabel={(option) => {
                              // e.g value selected with enter, right from the input
                              if (typeof option === 'string') {
                                return option;
                              }
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              return option.name;
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              if (params.inputValue !== '') {
                                filtered.push({
                                  inputValue: params.inputValue,
                                  name: `Add "${params.inputValue}"`
                                });
                              }
                              return filtered;
                            }}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    padding: 0,
                                    height: '36px',
                                    marginBottom: 4
                                  }
                                }}
                                placeholder={'Select a location'}
                                variant="outlined"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <h6>Attributes</h6>
                    <p className={'text-muted mb-0'}>
                      Select in the following attributes for your product
                    </p>
                  </div>

                  <div className="col-md-6 mt-3">
                    <div className="form-group">
                      <label htmlFor={'brand'}>Brand</label>
                      <Autocomplete
                        id={'select-brand'}
                        value={brandText}
                        onChange={(event, value) => onBrandSelected(event, value)}
                        options={brands}
                        style={{ width: '100%' }}
                        size="small"
                        renderOption={(option) => option.name}
                        getOptionLabel={(option) => {
                          // e.g value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.name;
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          if (params.inputValue !== '') {
                            filtered.push({
                              inputValue: params.inputValue,
                              name: `Add "${params.inputValue}"`
                            });
                          }
                          return filtered;
                        }}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                padding: 0,
                                height: '36px',
                                marginBottom: 4
                              }
                            }}
                            placeholder={'Select a brand'}
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mt-3">
                    <div className="form-group">
                      <label htmlFor={'categories'}>Category</label>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={newCategories}
                        size={'small'}
                        value={selectedCategoryId}
                        onChange={(event, value) => onCategorySelected(event, value)}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <div
                              style={
                                option.parentId
                                  ? {
                                      marginLeft: 30,
                                      backgroundColor: '#F1F3F5',
                                      paddingRight: 20
                                    }
                                  : { marginRight: 0 }
                              }
                            >
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </div>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select categories"
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mt-3">
                    <div className="form-group">
                      <label htmlFor={'unit'}>Unit</label>
                      <Autocomplete
                        id={'select-unit'}
                        value={unitText}
                        onChange={(event, value) => onUnitSelected(event, value)}
                        options={units}
                        style={{ width: '100%' }}
                        size="small"
                        renderOption={(option) => option.name}
                        getOptionLabel={(option) => {
                          // e.g value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.name;
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          if (params.inputValue !== '') {
                            filtered.push({
                              inputValue: params.inputValue,
                              name: `Add "${params.inputValue}"`
                            });
                          }
                          return filtered;
                        }}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                padding: 0,
                                height: '36px',
                                marginBottom: 4
                              }
                            }}
                            placeholder={'Select a unit'}
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mt-3">
                    <div className="form-group">
                      <label htmlFor={'type'}>Type</label>
                      <Autocomplete
                        id={'select-type'}
                        value={typeText}
                        onChange={(event, value) => onTypeSelected(event, value)}
                        options={types}
                        style={{ width: '100%' }}
                        size="small"
                        renderOption={(option) => option.name}
                        getOptionLabel={(option) => {
                          // e.g value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.name;
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          if (params.inputValue !== '') {
                            filtered.push({
                              inputValue: params.inputValue,
                              name: `Add "${params.inputValue}"`
                            });
                          }
                          return filtered;
                        }}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                padding: 0,
                                height: '36px',
                                marginBottom: 4
                              }
                            }}
                            placeholder={'Select a type'}
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label htmlFor={'colors'}>Color</label>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={colors}
                        size={'small'}
                        value={selectedColorIds}
                        onChange={(event, value) => onColorSelected(event, value)}
                        disableCloseOnSelect={false}
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={option.parentId ? { marginLeft: 16 } : { marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" placeholder="Select colors" />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="list-group">
                      <div className="row">
                        {selectedColorIds.map((color) => {
                          return (
                            <Fragment key={color.id}>
                              <div className="col-md-2 list-group-item bg-light d-flex flex-column align-items-center">
                                <div
                                  className={'image_area'}
                                  style={{
                                    position: 'relative',
                                    flex: '1',
                                    maxHeight: 80,
                                    maxWidth: 80
                                  }}
                                >
                                  <HighlightOffIcon
                                    className="delete-image-icon"
                                    onClick={() => deleteColorImage(color.id)}
                                  />
                                  <img
                                    id={`colorImage_${color.id}`}
                                    src={
                                      color.item_colors
                                        ? color.item_colors.image
                                          ? base_url_image + color.item_colors.image
                                          : InsertImageIcon
                                        : InsertImageIcon
                                    }
                                    width={80}
                                    height={80}
                                    style={{ objectFit: 'scale-down' }}
                                    alt="img-input"
                                  />
                                  <input
                                    name="images"
                                    accept="image/*"
                                    className="add_image_btn"
                                    type="file"
                                    onChange={(event) => setColorImage(event, color.id)}
                                  />
                                </div>
                                <h6 className={'mb-0 mt-2'}>{color.name}</h6>
                                {color.hasStock && (
                                  <button
                                    onClick={() => replaceColor(color)}
                                    type={'button'}
                                    className="replace-btn btn btn-danger btn-sm text-light"
                                  >
                                    Replace
                                  </button>
                                )}
                              </div>
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label htmlFor={'sizes'}>Size</label>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={sizes}
                        size={'small'}
                        value={selectedSizeId}
                        onChange={(event, value) => onSizeSelected(event, value)}
                        disableCloseOnSelect={false}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={option.parentId ? { marginLeft: 16 } : { marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" placeholder="Select sizes" />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="list-group">
                      <div className="row">
                        {selectedSizeId.map((size) => {
                          return (
                            <Fragment key={size.id}>
                              <div className="col-md-2 list-group-item bg-light d-flex flex-column align-items-center">
                                <div
                                  className={'image_area'}
                                  style={{
                                    position: 'relative',
                                    flex: '1',
                                    maxHeight: 80,
                                    maxWidth: 80
                                  }}
                                >
                                  <HighlightOffIcon
                                    className="delete-image-icon"
                                    onClick={() => deleteSizeImage(size.id)}
                                  />
                                  <img
                                    id={`sizeImage_${size.id}`}
                                    src={
                                      size.item_sizes
                                        ? size.item_sizes.image
                                          ? base_url_image + size.item_sizes.image
                                          : InsertImageIcon
                                        : InsertImageIcon
                                    }
                                    width={80}
                                    height={80}
                                    style={{ objectFit: 'scale-down' }}
                                    alt="img-input"
                                  />
                                  <input
                                    name="images"
                                    accept="image/*"
                                    className="add_image_btn"
                                    type="file"
                                    onChange={(event) => setSizeImage(event, size.id)}
                                  />
                                </div>
                                <h6 className={'mb-0 mt-2'}>{size.name}</h6>
                                {size.hasStock && (
                                  <button
                                    onClick={() => replaceSize(size)}
                                    type={'button'}
                                    className="replace-btn btn btn-danger btn-sm text-light"
                                  >
                                    Replace
                                  </button>
                                )}
                              </div>
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  {variations.length > 0 && (
                    <div className="col-md-12 mt-4">
                      <div className="row">
                        <div className="col-md-12 mt-4">
                          <h5>Price Variations</h5>
                          <p className={'text-muted mb-0'}>
                            Add retail and purchase price variation for color and size wise.
                          </p>
                        </div>

                        <div className="col-md-6 mt-4">
                          <h6>Description</h6>
                          {variations.map((item, index) => (
                            <div
                              key={index}
                              className={'variation-price w-100 border rounded mt-3'}
                            >
                              <h6
                                className={'m-0'}
                              >{`${item.name} - (${item.color}, ${item.size})`}</h6>
                            </div>
                          ))}
                        </div>

                        <div className="col-md-3 mt-4">
                          <h6>Retail Price</h6>
                          {variations.map((item, index) => (
                            <div>
                              <div className="form-group mt-3">
                                <NumberInput
                                  name={'price'}
                                  value={item.price}
                                  onChange={(e) => onVariationPriceChange(e, item)}
                                  placeholder={'Retail Price'}
                                />
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="col-md-3 mt-4">
                          <h6>Purchase Price</h6>
                          {variations.map((item, index) => (
                            <div>
                              <div className="form-group mt-3">
                                <NumberInput
                                  name={'purchase_price'}
                                  value={item.purchase_price}
                                  onChange={(e) => onVariationPriceChange(e, item)}
                                  placeholder={'Purchase Price'}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {replaceSizeDialogForm && (
        <ReplaceSizeColor
          setReplaceData={setReplaceSizeDialogForm}
          replaceData={replaceSizeDialogForm}
          replaceDialog={replaceSizeDialog}
          setReplaceDialog={setReplaceSizeDialog}
          save={saveReplacedSize}
          data={sizes}
          isSize={true}
        />
      )}

      {replaceColorDialogForm && (
        <ReplaceSizeColor
          setReplaceData={setReplaceColorDialogForm}
          replaceData={replaceColorDialogForm}
          replaceDialog={replaceColorDialog}
          setReplaceDialog={setReplaceColorDialog}
          save={saveReplacedColor}
          data={colors}
          isSize={false}
        />
      )}
    </Fragment>
  );
}

UpdateProduct.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getBrands: PropTypes.func.isRequired,
  getUnits: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  addBrand: PropTypes.func.isRequired,
  getBranches: PropTypes.func.isRequired,
  addLocation: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  products: state.products,
  auth: state.auth,
  system: state.system
});

export default connect(mapStateToProps, {
  getBrands,
  getColors,
  getSizes,
  getUnits,
  getTypes,
  getCategories,
  addBrand,
  addUnit,
  updateProduct,
  getBranches,
  addLocation,
  getLocations,
  replaceProductSize,
  replaceProductColor
})(UpdateProduct);
