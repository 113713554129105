import {
  ALL_SUPPLIER_PAYMENTS,
  LOADING,
  LOADING_FALSE,
  SUPPLIER,
  SUPPLIER_PAYMENTS,
  SUPPLIERS
} from './types';
import { returnErrors } from './messages';
import API from '../api';
import Swal from 'sweetalert2';
import { GENERAL_SUPPLIER } from '../const';

export const getSuppliers =
  (purchase = false) =>
  async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const res = await API.suppliers.getSuppliers();
      dispatch({ type: LOADING_FALSE });

      const data = [];
      if (purchase) {
        data.push({
          id: GENERAL_SUPPLIER,
          name: GENERAL_SUPPLIER,
          email: '',
          phone: '',
          address: '',
          due: 0.0
        });
      }

      res.data.data.forEach((supplier) => data.push(supplier));

      dispatch({
        type: SUPPLIERS,
        payload: data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const addSupplier = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.suppliers.addSupplier(data);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully created a supplier.',
      showConfirmButton: false,
      timer: 1500
    });

    return res.data.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateSupplier = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.suppliers.updateSupplier(data);

    dispatch({ type: LOADING_FALSE });
    await Swal.fire(res.data.message, 'You have successfully updated a supplier.', 'success');
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addSupplierPayment = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.suppliers.addSupplierPayments(data);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully created a supplier payment.',
      showConfirmButton: false,
      timer: 1500
    });

    window.history.back();
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getSupplierPayment =
  ({ id, from_date, to_date, size, page, isExportData }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const res = await API.suppliers.getSupplierPayments({ id, from_date, to_date, size, page });
      dispatch({ type: LOADING_FALSE });
      if (isExportData) return res.data.data;
      dispatch({
        type: SUPPLIER_PAYMENTS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getAllSupplierPayment =
  ({ from_date, to_date, size, page, isExportData }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const res = await API.suppliers.getAllSupplierPayments({ from_date, to_date, size, page });
      dispatch({ type: LOADING_FALSE });
      if (isExportData) return res.data.data;
      dispatch({
        type: ALL_SUPPLIER_PAYMENTS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getSupplier = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.suppliers.getSupplier(id);
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: SUPPLIER,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};
