import React from 'react';

const DragOverlayCat = ({ category }) => {
  return (
    <div className="col-md-3 mb-4" style={{ minWidth: '95%' }}>
      <div id="card-content-cat">
        <div className="card-header">
          <div className="d-flex justify-content-between mb-4">
            <div className={'d-flex align-items-center gap-3'}>
              <i
                className="fa fa-arrows ms-2 user-select-none"
                style={{
                  color: '#919191',
                  fontSize: '1.4rem',
                  cursor: 'grab'
                }}
              />
              <h5 className={'m-0 user-select-none'}>{category.name}</h5>
            </div>
            <div className={'d-flex gap-3 align-items-center'}>
              <i className="fa fa-plus hover fa-lg me-2 mt-1" />
              <i className="far fa-edit hover fa-lg me-2 mt-1" />
            </div>
          </div>
        </div>
        <div className="card-body overflow-auto" style={{ height: '450px' }}>
          <ul className={'list-group gap-2'}>
            {category.subcategories?.map((subCat, index) => {
              return (
                <li
                  className="list-group-item d-flex justify-content-between align-items-center me-2 px-2 py-3 rounded-2 drag-hover"
                  style={{
                    border: '1px dashed #DEE2E6'
                  }}
                >
                  <div className={'d-flex align-items-center gap-3'}>
                    <i
                      className="fa fa-arrows ms-2 user-select-none"
                      style={{ color: '#919191' }}
                    />
                    <p
                      className="m-0 font-weight-bold"
                      style={{ fontSize: '14px', userSelect: 'none' }}
                    >
                      {subCat?.name}
                    </p>
                  </div>
                  <i
                    className="far fa-edit fa-md me-2"
                    style={{
                      cursor: 'pointer'
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DragOverlayCat;
