import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import IncomeExpenseCharts from '../../layout/IncomeExpenseCharts';
import moment from 'moment';
import SalePaidDueDonut from '../../layout/SalePaidDueDonut';
import ExpenseDonut from '../../layout/ExpenseDonut';
import { Link, useHistory } from 'react-router-dom';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import DueDonut from '../../layout/DueDonut';
import { isNotAdminHidden, isNotPurchaseHidden, isNotSalesHidden } from '../../../permissions';
import { getIncomeExpenseReportsYearly } from '../../../actions/reports';
import {
  getBankAccountsDashboard,
  getDashboardStatistics,
  getLowStockProducts,
  getMostSoldProducts,
  getPopularCategories
} from '../../../actions/dashboard';
import { base_url_image } from '../../../api';
import { getBankAccounts } from '../../../actions/bankings';
import { getLoggedUserPermissions } from '../../../actions/auth';
import { numberWithCommas } from '../../../utils';

const Dashboard = ({
  isLoading,
  getLoggedUserPermissions,
  getIncomeExpenseReportsYearly,
  getMostSoldProducts,
  getBankAccountsDashboard,
  getBankAccounts,
  getLowStockProducts,
  getPopularCategories,
  getDashboardStatistics,
  auth: { permissions, user },
  reports: { incomeExpenseReportsYearly },
  dashboard: { statistics, lowStockProducts, mostSoldProducts, popularCategories, bankAccounts }
}) => {
  const history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!isNotAdminHidden(permissions)) {
        if (isNotSalesHidden(permissions)) {
          return history.push('/sale');
        } else if (isNotPurchaseHidden(permissions)) {
          return history.push('/purchase');
        } else {
          return history.push('/inventory');
        }
      }
    }
  }, [permissions]);

  useEffect(() => {
    if (user) {
      getLoggedUserPermissions(user.id);
    }
  }, [user]);

  useEffect(() => {
    getIncomeExpenseReportsYearly({ year: moment().format('YYYY') });
    getDashboardStatistics();
    getLowStockProducts({ size: 5 });
    getMostSoldProducts({ size: 5 });
    getBankAccountsDashboard({ size: 5 });
    getBankAccounts();
    getPopularCategories({ size: 5 });
  }, []);

  return (
    <Fragment>
      <LoadingBackdrop loading={isLoading} />

      {permissions.length > 0 && statistics ? (
        <div className="container-fluid mt-3">
          <h4>Hi {user.username}, welcome back!</h4>
          <p>Your finance profile and monitor business data</p>

          <div className="row mt-4">
            <div className="col">
              <div id="card-content">
                <div className="card-header p-0">
                  <div className="d-flex align-items-center justify-content-between header_icons">
                    <h6 className={'m-0'}>Total Customers</h6>
                    <i className="fas fa-users customers" />
                  </div>
                  <h4 className={'mt-3'}>{numberWithCommas(statistics.totalCustomers)}</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className={'m-0'}>Within 30 days</p>
                    <p className={'m-0 success'}>
                      {statistics.newCustomersIn30Days} <i className="fa fa-arrow-up" />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div id="card-content">
                <div className="card-header p-0">
                  <div className="d-flex align-items-center justify-content-between header_icons">
                    <h6 className={'m-0'}>Today Sales</h6>
                    <i className="fas fa-chart-line sales" />
                  </div>
                  <h4 className={'mt-3'}>৳{numberWithCommas(statistics.todaySales)}</h4>
                  <div className="d-flex justify-content-between align-items-center ">
                    <p className={'m-0'}>To yesterday</p>
                    {statistics.getProfitPercentage < 0 ? (
                      <p className={'m-0 danger'}>
                        {Math.abs(statistics.getProfitPercentage)}%{' '}
                        <i className="fa fa-arrow-down" />
                      </p>
                    ) : (
                      <p className={'m-0 success'}>
                        {Math.abs(statistics.getProfitPercentage)}% <i className="fa fa-arrow-up" />
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div id="card-content">
                <div className="card-header p-0">
                  <div className="d-flex align-items-center justify-content-between header_icons">
                    <h6 className={'m-0'}>Today Paid</h6>
                    <i className="fas fa-hand-holding-usd paid" />
                  </div>
                  <h4 className={'mt-3'}>৳{numberWithCommas(statistics.todayPaid)}</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className={'m-0'}>To yesterday</p>
                    {statistics.getPaidPercentage < 0 ? (
                      <p className={'m-0 danger'}>
                        {Math.abs(statistics.getPaidPercentage)}% <i className="fa fa-arrow-down" />
                      </p>
                    ) : (
                      <p className={'m-0 success'}>
                        {Math.abs(statistics.getPaidPercentage)}% <i className="fa fa-arrow-up" />
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div id="card-content">
                <div className="card-header p-0">
                  <div className="d-flex align-items-center justify-content-between header_icons">
                    <h6 className={'m-0'}>Today Due</h6>
                    <i className="fas fa-search-dollar due" />
                  </div>
                  <h4 className={'mt-3'}>৳{numberWithCommas(statistics.todayDue)}</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className={'m-0'}>To yesterday</p>
                    {statistics.getDuePercentage < 0 ? (
                      <p className={'m-0 danger'}>
                        {Math.abs(statistics.getDuePercentage)}% <i className="fa fa-arrow-down" />
                      </p>
                    ) : (
                      <p className={'m-0 success'}>
                        {Math.abs(statistics.getDuePercentage)}% <i className="fa fa-arrow-up" />
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div id="card-content">
                <div className="card-header p-0">
                  <div className="d-flex align-items-center justify-content-between header_icons">
                    <h6 className={'m-0'}>Low in Stock</h6>
                    <i className="fas fa-warehouse stock" />
                  </div>
                  <h4 className={'mt-3'}>{numberWithCommas(statistics.lowStockItems)}</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className={'m-0'}>Out of stock</p>
                    <p className={'m-0 danger'}>
                      {statistics.outOfStockItems} <i className="fa fa-arrow-down" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-8">
              <div id="card-content">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Financial Projections - {moment().format('YYYY')}</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body text-center">
                  {incomeExpenseReportsYearly.length > 0 ? (
                    <IncomeExpenseCharts incomeExpense={incomeExpenseReportsYearly} />
                  ) : (
                    <CircularProgress className={'m-5'} />
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-8">
                  <div id="card-content" className={'h-100'}>
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h5>Top Most Sold Products</h5>
                          <p>Top most sold products in last 90 days</p>
                        </div>
                        <div>
                          <Link to={'/inventory'} className="btn btn-outline-primary">
                            View All
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-body mt-1">
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Image</TableCell>
                              <TableCell>Code</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Total Sold</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {mostSoldProducts &&
                              mostSoldProducts.paginatedData.map((row, i) => (
                                <TableRow className={'hover'} key={i}>
                                  <TableCell>
                                    <img
                                      className={'list-product-image'}
                                      src={
                                        base_url_image +
                                        `${row.item.inventory_image ?? row.item.image_1}`
                                      }
                                      alt={row.item.code}
                                    />
                                  </TableCell>
                                  <TableCell>{row.item.code}</TableCell>
                                  <TableCell>{row.item.name}</TableCell>
                                  <TableCell>{row.totalQty}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div id="card-content" className={'h-100'}>
                    <div className="card-header">
                      <h5>Popular Categories</h5>
                      <p>Popular categories of all time</p>
                    </div>
                    <div className="card-body">
                      <ul className="list-group ">
                        {popularCategories &&
                          popularCategories.map((row) => (
                            <li
                              key={row.name}
                              className="list-group-item d-flex align-items-center justify-content-between "
                            >
                              <h6>{row.name}</h6>
                              <h6 className={'text-primary'}>{row.soldQty} Sold</h6>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-6">
                  <div id="card-content" className={'h-100'}>
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h5>Bank Accounts</h5>
                          <p>Registered bank accounts & holding amounts</p>
                        </div>
                        <Link to={'/accounts'} className="btn btn-outline-primary">
                          View All
                        </Link>
                      </div>
                    </div>
                    <div className="card-body">
                      <ul className="list-group ">
                        {bankAccounts &&
                          bankAccounts.paginatedData.map((row, i) => (
                            <li
                              key={i}
                              className="list-group-item d-flex align-items-center justify-content-between "
                            >
                              <div>
                                <h6>{row.bank_account.bank_name}</h6>
                                <p className={'m-0'}>{row.bank_account.account_name}</p>
                              </div>
                              <h6 className={'text-primary'}>৳{row.amount}</h6>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div id="card-content" className={'h-100'}>
                    <div className="card-header">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h5>Low Stock Products</h5>
                          <p>Low stock inventory products</p>
                        </div>
                        <div>
                          <Link to={'/inventory'} className="btn btn-outline-primary">
                            View All
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-body mt-1">
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Code</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Stock</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {lowStockProducts &&
                              lowStockProducts.paginatedData.map((row, i) => (
                                <TableRow className={'hover'} key={i}>
                                  <TableCell>{row.item.code}</TableCell>
                                  <TableCell>{row.item.name}</TableCell>
                                  <TableCell>{row.totalQty}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div id="card-content">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Monthly Sales: {statistics.monthlyData.totalInvoices} Invoices</h5>
                      <p>All the sales invoices of this month</p>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <SalePaidDueDonut
                    sales={Math.abs(statistics.monthlyData.totalSales)}
                    due={Math.abs(statistics.monthlyData.totalPaid)}
                    paid={Math.abs(statistics.monthlyData.totalDue)}
                  />
                </div>
              </div>

              <div id="card-content" className={'mt-4'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Expenses: ৳{statistics.totalExpense}</h5>
                      <p>Expenses in this month</p>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ExpenseDonut
                    names={statistics.expenseData.map((item) => item.name)}
                    values={statistics.expenseData.map((item) => Math.abs(item.amount))}
                  />
                </div>
              </div>

              <div id="card-content" className={'mt-4'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Total Dues: ৳{statistics.totalDues}</h5>
                      <p>Total customer & supplier due of all time</p>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <DueDonut
                    customer={Math.abs(statistics.customerDue)}
                    supplier={Math.abs(statistics.supplierDue)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  auth: state.auth,
  reports: state.reports,
  dashboard: state.dashboard
});

export default connect(mapStateToProps, {
  getLoggedUserPermissions,
  getIncomeExpenseReportsYearly,
  getDashboardStatistics,
  getLowStockProducts,
  getMostSoldProducts,
  getPopularCategories,
  getBankAccounts,
  getBankAccountsDashboard
})(Dashboard);
