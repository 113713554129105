import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import Slide from '@material-ui/core/Slide';
import { logout } from '../../actions/auth';
import PropTypes from 'prop-types';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PersonIcon from '@material-ui/icons/Person';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Button } from '@material-ui/core';
import {
  CUSTOMER_ENTRY,
  EXPENSES,
  isNotAccountsHidden,
  isNotAdminHidden,
  isNotCustomerHidden,
  isNotEmployeeHidden,
  isNotPackagingHidden,
  isNotPurchaseHidden,
  isNotReportsHidden,
  isNotSalesHidden,
  isNotSettingsHidden,
  isNotSupplierHidden,
  PRODUCT_ENTRY,
  PURCHASE_ENTRY,
  SALES_ENTRY,
  SALES_INVOICES
} from '../../permissions';
import { List } from '@material-ui/icons';
import moment from 'moment';

const drawerWidth = 230;
const headerColor = '#0052FF';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    background: '#ffffff',
    color: headerColor,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#fff'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    ['@media (min-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      height: 54
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  grow: {
    flexGrow: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Header = ({ children, logout, auth: { permissions } }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = navigator.userAgent.match(
    /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i
  );

  const isLaptop = () => window.matchMedia('(min-width: 1200px) and (max-width: 1600px)').matches;

  const [open, setOpen] = useState(isMobile === null);

  useEffect(() => {}, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onLogoutBtnClicked = () => {
    logout();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{ height: 54 }}
        position="fixed"
        className={`${clsx(classes.appBar, { [classes.appBarShift]: open })} shadow-sm`}
      >
        <Toolbar style={{ minHeight: 54 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Link to={'/'} className={'text-decoration-none'}>
            <Typography
              variant="h6"
              style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#000' }}
              noWrap
            >
              ECOM ERP
            </Typography>
          </Link>
          {!isMobile && (
            <div className={isLaptop() ? 'ms-3' : 'ms-5'}>
              {permissions.find((item) => item.name === SALES_ENTRY) && (
                <Link to={'/sale/new'} className={'text-decoration-none'}>
                  <Button
                    variant="outlined"
                    size={'small'}
                    color={'primary'}
                    startIcon={<ShoppingCartIcon />}
                  >
                    New Sale
                  </Button>
                </Link>
              )}

              {permissions.find((item) => item.name === PURCHASE_ENTRY) && (
                <Link to={'/purchase/new'} className={'text-decoration-none'}>
                  <Button
                    variant="outlined"
                    size={'small'}
                    color={'primary'}
                    startIcon={<AddShoppingCartIcon />}
                  >
                    New Purchase
                  </Button>
                </Link>
              )}

              {permissions.find((item) => item.name === SALES_INVOICES) && (
                <Link to={'/sale/all'} className={'text-decoration-none'}>
                  <Button variant="outlined" size={'small'} color={'primary'} startIcon={<List />}>
                    Recent Sales
                  </Button>
                </Link>
              )}

              {permissions.find((item) => item.name === CUSTOMER_ENTRY) && (
                <Link to={'/customers/add-customer'} className={'text-decoration-none'}>
                  <Button
                    variant="outlined"
                    size={'small'}
                    color={'primary'}
                    startIcon={<PersonIcon />}
                  >
                    Add Customer
                  </Button>
                </Link>
              )}

              {permissions.find((item) => item.name === PRODUCT_ENTRY) && (
                <Link to={'/inventory/product'} className={'text-decoration-none'}>
                  <Button
                    variant="outlined"
                    size={'small'}
                    color={'primary'}
                    startIcon={<AddBoxIcon />}
                  >
                    Add Item
                  </Button>
                </Link>
              )}

              {permissions.find((item) => item.name === EXPENSES) && (
                <Link to={'/accounts/expenses/new'} className={'text-decoration-none'}>
                  <Button
                    variant="outlined"
                    size={'small'}
                    color={'primary'}
                    startIcon={<MoneyOffIcon />}
                  >
                    Add Expense
                  </Button>
                </Link>
              )}
            </div>
          )}
          <div className={classes.grow} />
          <div className={classes.grow} />

          <div className="logoutArea">
            <p
              className={'d-none d-lg-inline-block d-md-inline-block'}
              style={{ color: '#000', marginBottom: 0 }}
            >
              {isLaptop() ? '' : moment().format('LL, dddd')}
            </p>
            <Link to={'#!'} onClick={() => onLogoutBtnClicked()}>
              <i className="fas fa-sign-out-alt p-3 " />
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <section className={'leftSideMenu'}>
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon className={'text-primary'} />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>

          <nav onClick={() => isMobile !== null && handleDrawerClose()}>
            {isNotAdminHidden(permissions) && (
              <NavLink
                className={'a'}
                to={'/home'}
                activeClassName="active"
                style={{ marginTop: 30 }}
              >
                <i className="fa fa-home" />
                Home
              </NavLink>
            )}

            {isNotSalesHidden(permissions) && (
              <NavLink className={'a'} to={'/pos'} activeClassName="active">
                <i className="fa fa fas fa-th-large" />
                POS
              </NavLink>
            )}

            {isNotSalesHidden(permissions) && (
              <NavLink className={'a'} to={'/sale'} activeClassName="active">
                <i className="fa fa fas fa-chart-line" />
                Sales
              </NavLink>
            )}

            {isNotPurchaseHidden(permissions) && (
              <NavLink className={'a'} to={'/purchase'} activeClassName="active">
                <i className="far fas fa-cart-plus" />
                Purchase
              </NavLink>
            )}

            <NavLink className={'a'} to={'/inventory'} activeClassName="active">
              <i className="far fas fa-warehouse" />
              Inventory
            </NavLink>

            {isNotPackagingHidden(permissions) && (
              <NavLink className={'a'} to={'/packaging'} activeClassName="active">
                <i className="far fas fa-boxes" />
                Packaging
              </NavLink>
            )}

            {isNotCustomerHidden(permissions) && (
              <NavLink className={'a'} to={'/customers'} activeClassName="active">
                <i className="far fas fa-users" />
                Customer
              </NavLink>
            )}

            {isNotSupplierHidden(permissions) && (
              <NavLink className={'a'} to={'/suppliers'} activeClassName="active">
                <i className="far fas fa-users" />
                Supplier
              </NavLink>
            )}

            {isNotAccountsHidden(permissions) && (
              <NavLink className={'a'} to={'/accounts'} activeClassName="active">
                <i className="fa fas fa-university" />
                Accounts
              </NavLink>
            )}

            {isNotEmployeeHidden(permissions) && (
              <NavLink className={'a'} to={'/employee'} activeClassName="active">
                <i className="fa fas fa-user-friends" />
                Employee
              </NavLink>
            )}

            {isNotReportsHidden(permissions) && (
              <NavLink className={'a'} to={'/reports'} activeClassName="active">
                <i className="far fas fa-chart-area" />
                Reports
              </NavLink>
            )}

            {isNotAdminHidden(permissions) && (
              <NavLink className={'a'} to={'/administration'} activeClassName="active">
                <i className="far fas fa-user-cog" />
                Administration
              </NavLink>
            )}

            {isNotSettingsHidden(permissions) && (
              <NavLink className={'a'} to={'/settings'} activeClassName="active">
                <i className="far fas fa-cog" />
                Settings
              </NavLink>
            )}

            <NavLink className={'a'} to={'/website'} activeClassName="active">
              <i className="far fas fa-globe" />
              Website
            </NavLink>

            {/*<div className={"bottom_menus"}>*/}
            {/*    {isNotAdminHidden(permissions) &&*/}
            {/*    <a className={"a"} href={DOCUMENTATION_URL} rel={"noreferrer"} target={"_blank"}>API Docs</a>}*/}
            {/*    <a className={"a"} href={"#"}>FAQ</a>*/}
            {/*</div>*/}
          </nav>
          {/*<Divider/>*/}
          {/*<p className={"text-center mt-5"}>{APP_VERSION}</p>*/}
        </section>
      </Drawer>

      {/*Page Content*/}
      <main className={clsx(classes.content, { [classes.contentShift]: open })}>
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  auth: state.auth
});

export default connect(mapStateToProps, { logout })(Header);
