import React from 'react';

const NumberInput = (props) => {
  const handleChange = (e) => {
    const { value } = e.target;
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/)
    if (validated) {
      props.onChange(e);
    }
  };

  return (
    <input
      type="number"
      min={0}
      step={.01}
      {...props}
      onChange={handleChange}
      className={`form-control ${props.className}`}
    />
  );
};

export default NumberInput;
