import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import moment from 'moment';
import { getBranches } from '../../../actions/system';
import { getSuppliers } from '../../../actions/suppliers';
import { getProducts, getSearchedProducts } from '../../../actions/products';
import {
  getProductVariationString,
  numberWithCommas,
  PURCHASE_SECTIONS,
  REGEX_STRING
} from '../../../utils';
import { getBankAccounts } from '../../../actions/bankings';
import { addPurchase } from '../../../actions/purchase';
import Swal from 'sweetalert2';
import { DEFAULT_BRANCH, GENERAL_SUPPLIER, PURCHASE_ACCOUNT } from '../../../const';
import { PURCHASE_ENTRY } from '../../../permissions';
import BranchSelector from '../../common/BranchSelector';
import BankAccountSelector from '../../common/BankAccountSelector';
import SupplierSelector from '../../common/SupplierSelector';
import GeneralSupplierLayout from '../../common/GeneralSupplierLayout';
import AllProductSelector from '../../common/AllProductSelector';
import CartsTable from '../../common/CartsTable';
import NumberInput from '../../common/NumberInput';

function AddPurchase({
  isLoading,
  getBranches,
  getSuppliers,
  getProducts,
  getSearchedProducts,
  addPurchase,
  getBankAccounts,
  system: { branches },
  suppliers: { suppliers },
  products: { products, searchLoading },
  auth: { user, permissions },
  bankings: { bankAccounts }
}) {
  const history = useHistory();
  const autocompleteRef = useRef();

  const initState = {
    date: moment().format('YYYY-MM-DD'),
    userId: user ? user.id : '',
    branchId: '',
    bankAccountId: '',
    availableBalance: '',

    supplierName: '',
    supplierId: '',
    supplierPhone: '',
    supplierAddress: '',
    supplierPreDue: 0,

    totalAmount: 0,

    cartsItem: [],

    subTotal: 0,
    vat: 0,
    vatValue: 0,
    transportCost: 0,
    discount: 0,
    total: 0,
    paid: 0,
    due: 0,
    note: ''
  };
  const [formData, setFormData] = useState(initState);

  const {
    branchId,
    supplierPhone,
    supplierAddress,
    supplierName,
    supplierId,
    supplierPreDue,
    subTotal,
    paid,
    totalAmount,
    cartsItem,
    due,
    note,
    total,
    discount,
    vat,
    vatValue,
    transportCost,
    date,
    bankAccountId,
    availableBalance
  } = formData;

  const [generalSupplierSelected, setGeneralSupplierSelected] = useState(false);

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === PURCHASE_ENTRY)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getBranches();
    getSuppliers(true);
    getProducts({ size: 5 });
    getBankAccounts();
  }, []);

  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        userId: user.id
      });
    }
  }, [user]);

  useEffect(() => {
    if (bankAccounts.length > 0) {
      setFormData({
        ...formData,
        bankAccountId: bankAccounts.find((item) => item.bank_account_type.name === PURCHASE_ACCOUNT)
          .id,
        availableBalance: bankAccounts.find(
          (item) => item.bank_account_type.name === PURCHASE_ACCOUNT
        ).balance
      });
    }
  }, [bankAccounts]);

  useEffect(() => {
    if (branches.length > 0) {
      setFormData({
        ...formData,
        branchId: branches.find((branch) => branch.name === DEFAULT_BRANCH).id
      });
    }
  }, [branches]);

  const onCancelButtonClicked = (e) => {
    e.preventDefault();

    history.goBack();
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const onProductSelected = (e, value) => {
    e.preventDefault();
    setTimeout(
      () =>
        autocompleteRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click(),
      10
    );

    if (!branchId) {
      Swal.fire({
        icon: 'warning',
        title: 'No Branch Selected!',
        text: 'You must choose a branch before add item to cart',
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }

    if (value) {
      // Check duplicate
      let duplicateItemIndex = cartsItem.findIndex(
        (item) => item.id === value.id && item.s_code === value.s_code && item.branchId === branchId
      );
      if (duplicateItemIndex !== -1) {
        cartsItem[duplicateItemIndex].qty++;
        cartsItem[duplicateItemIndex].total = parseFloat(
          (
            parseInt(cartsItem[duplicateItemIndex].qty) *
            parseFloat(cartsItem[duplicateItemIndex].rate)
          ).toFixed(2)
        );
        setFormData({
          ...formData,
          cartsItem,
          subTotal: getSubTotal(cartsItem),
          total: getTotal(getSubTotal(cartsItem), totalAmount),
          paid: getTotal(getSubTotal(cartsItem), totalAmount),
          due: 0
        });
      } else {
        const updatedCarts = cartsItem.concat({
          id: value.id,
          description: getProductVariationString(value),
          s_code: value.s_code,
          qty: 1,
          rate: value.purchase_price,
          total: 1 * value.purchase_price,
          colorId: value.color ? value.color.id : '',
          sizeId: value.size ? value.size.id : '',
          branchId: branchId
        });
        setFormData({
          ...formData,
          cartsItem: updatedCarts,
          subTotal: getSubTotal(updatedCarts),
          total: getTotal(getSubTotal(updatedCarts), totalAmount),
          paid: getTotal(getSubTotal(updatedCarts), totalAmount),
          due: 0
        });
      }
    }
  };

  const getTotal = (subTotalAmount, totalAmount) => {
    return parseFloat(
      (
        parseFloat(
          (
            parseFloat(subTotalAmount) +
            parseFloat(((vat / 100) * (getSubTotal(cartsItem) - totalAmount)).toFixed(2)) +
            parseFloat(transportCost)
          ).toFixed(2)
        ) - parseFloat(discount)
      ).toFixed(2)
    );
  };

  const onQuantityChange = (e, id, s_code, value, branchId) => {
    e.preventDefault();

    let index = cartsItem.findIndex(
      (item) => item.id === id && item.s_code === s_code && item.branchId === branchId
    );
    cartsItem[index].qty = parseInt(value);
    cartsItem[index].total = parseFloat(
      (parseInt(value) * parseFloat(cartsItem[index].rate)).toFixed(2)
    );

    setFormData({
      ...formData,
      cartsItem,
      subTotal: getSubTotal(cartsItem),
      total: getTotal(getSubTotal(cartsItem), totalAmount),
      paid: getTotal(getSubTotal(cartsItem), totalAmount),
      vatValue: (vat / 100) * (getSubTotal(cartsItem) - totalAmount),
      due: 0
    });
  };

  const onPurchaseRateChange = (id, s_code, value, branchId) => {
    let index = cartsItem.findIndex(
      (item) => item.id === id && item.s_code === s_code && item.branchId === branchId
    );
    cartsItem[index].rate = value;
    cartsItem[index].total = parseFloat(
      (parseInt(cartsItem[index].qty) * parseFloat(value)).toFixed(2)
    );

    setFormData({
      ...formData,
      cartsItem,
      subTotal: getSubTotal(cartsItem),
      total: getTotal(getSubTotal(cartsItem), totalAmount),
      paid: getTotal(getSubTotal(cartsItem), totalAmount),
      vatValue: (vat / 100) * (getSubTotal(cartsItem) - totalAmount),
      due: 0
    });
  };

  const deleteItemsFromCart = (id, s_code, totalAmount) => {
    const updatedCarts = cartsItem.filter((item) => item.id !== id || item.s_code !== s_code);
    setFormData({
      ...formData,
      cartsItem: updatedCarts,
      subTotal: getSubTotal(updatedCarts),
      total: getTotal(getSubTotal(updatedCarts), totalAmount),
      vatValue: (vat / 100) * (getSubTotal(updatedCarts) - totalAmount),
      paid: getTotal(getSubTotal(updatedCarts), totalAmount),
      due: 0
    });

    if (cartsItem.length === 1) {
      setFormData({
        ...formData,
        cartsItem: [],
        subTotal: 0,
        vat: 0,
        vatValue: 0,
        transportCost: 0,
        discount: 0,
        total: 0,
        paid: 0,
        due: 0
      });
    }
  };

  const getSubTotal = (carts) => {
    if (carts.length > 0) {
      // Get array of all the items total value
      const array = carts.map((singleObject) => parseFloat(singleObject.total));
      return parseFloat(calculateTwoIntegersFromIntegersOfArray(array).toFixed(2));
    }
    return 0;
  };

  const calculateTwoIntegersFromIntegersOfArray = (array) => {
    // Calculate all the array values
    const add = (a, b) => a + b;
    return array.reduce(add);
  };

  const onVatChange = (e) => {
    setFormData({
      ...formData,
      vat: parseFloat(e.target.value),
      vatValue: parseFloat(
        (parseFloat((parseFloat(e.target.value) / 100).toFixed(2)) * subTotal).toFixed(2)
      ),
      total: getTotalAfterVat(
        parseFloat(
          (parseFloat((parseFloat(e.target.value) / 100).toFixed(2)) * subTotal).toFixed(2)
        )
      ),
      paid: getTotalAfterVat(
        parseFloat(
          (parseFloat((parseFloat(e.target.value) / 100).toFixed(2)) * subTotal).toFixed(2)
        )
      ),
      due: 0
    });
  };

  const getTotalAfterVat = (value) => {
    return parseFloat(
      (
        parseFloat(
          (parseFloat(subTotal) + parseFloat(value) + parseFloat(transportCost)).toFixed(2)
        ) - parseFloat(discount)
      ).toFixed(2)
    );
  };

  const onTransportChange = (e) => {
    setFormData({
      ...formData,
      transportCost: parseFloat(e.target.value),
      total: getTotalAfterTransfer(parseFloat(e.target.value)),
      paid: getTotalAfterTransfer(parseFloat(e.target.value)),
      due: 0
    });
  };

  const getTotalAfterTransfer = (value) => {
    return parseFloat(
      (
        parseFloat((parseFloat(subTotal) + parseFloat(vatValue) + parseFloat(value)).toFixed(2)) -
        parseFloat(discount)
      ).toFixed(2)
    );
  };

  const onDiscountChange = (e) => {
    setFormData({
      ...formData,
      discount: parseFloat(e.target.value),
      total: getTotalAfterDiscount(parseFloat(e.target.value)),
      paid: getTotalAfterDiscount(parseFloat(e.target.value)),
      due: 0
    });
  };

  const getTotalAfterDiscount = (value) => {
    return parseFloat(
      (
        parseFloat(
          (parseFloat(subTotal) + parseFloat(vatValue) + parseFloat(transportCost)).toFixed(2)
        ) - parseFloat(value)
      ).toFixed(2)
    );
  };

  const onPaidChange = (e) => {
    setFormData({
      ...formData,
      paid: parseFloat(e.target.value),
      due: parseFloat((parseFloat(total) - parseFloat(e.target.value)).toFixed(2))
    });
  };

  const savePurchase = async (e) => {
    e.preventDefault();

    if (!branchId) {
      Swal.fire(
        'No Branch Selected!',
        'You must choose a branch before made any purchase',
        'warning'
      ).then();
      return;
    }

    if (!supplierId) {
      Swal.fire(
        'Choose a Supplier',
        'You must choose a supplier before made any purchase',
        'warning'
      ).then();
      return;
    }

    if (!bankAccountId) {
      Swal.fire(
        'Choose an Account',
        'You must choose an account before made any purchase',
        'warning'
      ).then();
      return;
    }

    if (cartsItem.length <= 0) {
      Swal.fire(
        'Choose an Item',
        'You must choose an item before made any purchase',
        'warning'
      ).then();
      return;
    }

    if (supplierId === GENERAL_SUPPLIER) {
      if (supplierName === '' || supplierPhone === '') {
        Swal.fire(
          'Missing Supplier Information',
          'Supplier information required inorder to make any purchase',
          'warning'
        ).then();
        return;
      }
    }

    if (isNaN(due)) {
      Swal.fire('Paid amount missing', 'Please enter paid amount', 'warning').then();
      return;
    }

    if (paid > availableBalance) {
      Swal.fire('Limited balance', 'This account has limited balance', 'error').then();
      return;
    }

    const data = await addPurchase(formData);
    history.push({
      pathname: '/purchase/details',
      search: `?id=${data.id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/purchase'}>Purchase</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/purchase/new'}>Add Purchase</Link>
      </li>
    </ol>
  );

  const tableHead = [
    {
      field: 'tableData.id',
      title: 'SL',
      cellStyle: {
        width: '5%'
      },
      render: (rowData) => {
        return <p className="mb-0">{rowData.tableData.id + 1}</p>;
      }
    },
    {
      field: 'description',
      title: 'Description',
      cellStyle: {
        width: '35%'
      },
      render: (rowData) => {
        return rowData.description.includes('(') ? (
          <p className={'m-0'}>
            {rowData.description.split('(')[0]} (
            <span className={'font-weight-bold'}>{REGEX_STRING.exec(rowData.description)[1]}</span>)
          </p>
        ) : (
          rowData.description
        );
      }
    },
    {
      field: 'qty',
      title: 'Quantity',
      render: (rowData) => (
        <NumberInput
          value={rowData.qty}
          min={1}
          step={1}
          onChange={(e) =>
            onQuantityChange(e, rowData.id, rowData.s_code, e.target.value, rowData.branchId)
          }
          onKeyDown={handleKeyDown}
        />
      )
    },
    {
      field: 'rate',
      title: 'P. Rate',
      render: (rowData) => (
        <NumberInput
          value={rowData.rate}
          onChange={(e) =>
            onPurchaseRateChange(rowData.id, rowData.s_code, e.target.value, rowData.branchId)
          }
          onKeyDown={handleKeyDown}
        />
      )
    },
    {
      field: 'total',
      title: 'Amount (৳)',
      render: (rowData) => <NumberInput disabled value={rowData.total} onKeyDown={handleKeyDown} />
    },
    {
      field: 'branchId',
      title: 'Branch',
      render: (rowData) => (
        <p className="mb-0">{branches.find((branch) => branch.id === rowData.branchId).name}</p>
      )
    }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={PURCHASE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form onSubmit={(e) => savePurchase(e)}>
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>Add New Purchase</h5>
                  <p className={'mb-0'}>Enter a new purchase</p>
                </div>
                <div>
                  <button
                    type={'submit'}
                    disabled={!supplierId || cartsItem.length <= 0 || !bankAccountId || isLoading}
                    className="btn btn-primary p-2 me-2"
                  >
                    Save
                  </button>
                  <button
                    onClick={onCancelButtonClicked}
                    type={'button'}
                    disabled={isLoading}
                    className="btn btn-danger p-2 text-light"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          {branches.length > 0 && bankAccounts.length > 0 && (
            <div className="row mt-4">
              <div className="col-md-9">
                <div id="card-content">
                  <div className="card-body">
                    <div className="row justify-content-between">
                      <div className="col-md-5">
                        <div className="form-group">
                          <div className="d-flex align-items-center justify-content-between">
                            <BranchSelector
                              formData={formData}
                              setFormData={setFormData}
                              branches={branches}
                            />
                          </div>
                        </div>

                        <div className="form-group mt-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <SupplierSelector
                              formData={formData}
                              setFormData={setFormData}
                              suppliers={suppliers}
                              setGeneralSupplierSelected={setGeneralSupplierSelected}
                            />
                          </div>
                        </div>

                        {generalSupplierSelected && (
                          <GeneralSupplierLayout
                            setFormData={setFormData}
                            formData={formData}
                            supplierName={supplierName}
                            supplierAddress={supplierAddress}
                            supplierPhone={supplierPhone}
                          />
                        )}
                      </div>

                      <div className="col-md-7">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="d-flex align-items-center justify-content-between">
                                <BankAccountSelector
                                  setFormData={setFormData}
                                  formData={formData}
                                  bankAccounts={bankAccounts}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="d-flex align-items-center justify-content-between">
                                <input
                                  type="date"
                                  name={'date'}
                                  required
                                  defaultValue={date}
                                  onChange={(e) => onChange(e)}
                                  className="form-control"
                                  placeholder={'Choose a date'}
                                  onKeyDown={handleKeyDown}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {products && branchId && (
                          <div className="form-group mt-2">
                            <div className="d-flex align-items-center justify-content-between">
                              <AllProductSelector
                                autocompleteRef={autocompleteRef}
                                getSearchedProducts={getSearchedProducts}
                                onProductSelected={onProductSelected}
                                searchLoading={searchLoading}
                                products={products}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <CartsTable
                  cartsItem={cartsItem}
                  deleteItemsFromCart={deleteItemsFromCart}
                  tableHead={tableHead}
                />

                <div id="card-content" className="mt-4">
                  <div className="form-group">
                    <textarea
                      name={'note'}
                      value={note}
                      placeholder={'Write notes...'}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div id="card-content">
                  <div className="card-header">
                    <h5>Amount Details</h5>
                    <p className={'mb-0'}>Check the purchase amount details before saving</p>
                  </div>

                  <div className="form-group mt-3">
                    <label className={'text-primary'}>Available Balance</label>
                    <input
                      type={'text'}
                      name={'availableBalance'}
                      value={numberWithCommas(availableBalance)}
                      onChange={(e) => onChange(e)}
                      className="form-control border-primary text-primary"
                      disabled
                    />
                  </div>

                  <div className="card-body mt-2">
                    <div className="form-group mt-3">
                      <label htmlFor={'subTotal'}>
                        Sub Total <span className={'text-danger'}>*</span>
                      </label>
                      <NumberInput
                        name={'subTotal'}
                        value={subTotal}
                        required
                        disabled
                        placeholder={'Sub Total'}
                        onKeyDown={handleKeyDown}
                      />
                    </div>

                    <div className="row justify-content-start mt-2">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor={'vat'}>Vat(%)</label>
                          <NumberInput
                            name={'vat'}
                            value={vat}
                            onChange={(e) => onVatChange(e)}
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor={'vatValue'}>Vat Amount</label>
                          <NumberInput
                            name={'vatValue'}
                            disabled
                            value={vatValue}
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor={'transportCost'}>Shipping Charge</label>
                      <NumberInput
                        name={'transportCost'}
                        value={transportCost}
                        onChange={(e) => onTransportChange(e)}
                        onKeyDown={handleKeyDown}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor={'discount'}>Discount</label>
                      <NumberInput
                        name={'discount'}
                        value={discount}
                        onChange={(e) => onDiscountChange(e)}
                        onKeyDown={handleKeyDown}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor={'total'}>
                        Total <span className={'text-danger'}>*</span>
                      </label>
                      <NumberInput
                        name={'total'}
                        value={total}
                        disabled
                        required
                        onKeyDown={handleKeyDown}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor={'paid'}>
                        Paid <span className={'text-danger'}>*</span>
                      </label>
                      <NumberInput
                        name={'paid'}
                        value={paid}
                        required
                        onChange={(e) => onPaidChange(e)}
                        onKeyDown={handleKeyDown}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor={'supplierPreDue'}>Previous Due</label>
                      <NumberInput
                        name={'supplierPreDue'}
                        value={supplierPreDue}
                        disabled
                        className="text-danger border-danger"
                        onKeyDown={handleKeyDown}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor={'supplierPreDue'}>Due</label>
                      <NumberInput
                        name={'due'}
                        value={due}
                        disabled
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </Fragment>
  );
}

AddPurchase.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getBranches: PropTypes.func.isRequired,
  system: PropTypes.object.isRequired,
  getSuppliers: PropTypes.func.isRequired,
  suppliers: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  getProducts: PropTypes.func.isRequired,
  addPurchase: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  getSearchedProducts: PropTypes.func.isRequired,
  bankings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  system: state.system,
  suppliers: state.suppliers,
  products: state.products,
  auth: state.auth,
  bankings: state.bankings
});

export default connect(mapStateToProps, {
  getBranches,
  addPurchase,
  getSuppliers,
  getProducts,
  getBankAccounts,
  getSearchedProducts
})(AddPurchase);
