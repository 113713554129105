import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { Link, useHistory } from 'react-router-dom';
import { isNotSettingsHidden, SETTINGS } from '../../../permissions';
import {
  addCategory,
  getCategories,
  updateAllCategory,
  updateCategory
} from '../../../actions/products';
import DraggableCategory from './DraggableCategory';
import DragOverlayCat from './DragOverlayCat';
import { HomeRounded } from '@material-ui/icons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';

const TYPE_CATEGORY = 'Add Category';
const TYPE_SUBCATEGORY = 'Add Subcategory';

const IET_CATEGORIES = ['income', 'expense', 'transfer'];

const ReorderCategories = ({
  isLoading,
  addCategory,
  getCategories,
  updateCategory,
  updateAllCategory,
  products: { categories },
  auth: { permissions }
}) => {
  const [cats, setCategories] = useState([]);
  const [dialogOpen, setDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [activeId, setActiveId] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    id: '',
    categoryId: '',
    type: IET_CATEGORIES[0]
  });

  const history = useHistory();
  const { name } = formData;

  useEffect(() => {
    if (permissions.length > 0) {
      if (!isNotSettingsHidden(permissions)) {
        history.push('/access-denied');
      }
    }
  }, [permissions, history]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    setCategories(categories);
  }, [categories]);

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to="/home">
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to="/settings">Settings</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to="/settings/manage-categories">Manage Categories</Link>
      </li>
    </ol>
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(TouchSensor)
  );

  const handleSubCategoryDragEnd = ({ source, destination }) => {
    if (destination === undefined || destination === null) return;

    if (source.droppableId === destination.droppableId && destination.index === source.index)
      return;

    const start = cats?.find((cat) => cat.name === source.droppableId);
    const end = cats?.find((cat) => cat.name === destination.droppableId);
    const startIndex = cats.findIndex((cat) => cat.id === start.id && cat.name === start.name);
    const endIndex = cats.findIndex((cat) => cat.id === end.id && cat.name === end.name);

    const categoriesCopy = JSON.parse(JSON.stringify(cats));
    const removedSubCat = categoriesCopy[startIndex].subcategories.splice(source.index, 1);
    removedSubCat[0].parentId = end.id;
    categoriesCopy[endIndex].subcategories.splice(destination.index, 0, removedSubCat[0]);

    const newCats = generateCategoryPositions(categoriesCopy);
    setCategories(newCats);

    updateAllCategory({ categories: JSON.stringify(newCats) });
  };

  const generateCategoryPositions = (newCategories) => {
    const categories = [];
    let i = 0;
    for (const cat of newCategories) {
      const subs = [];
      let j = 0;
      for (const subcategory of cat.subcategories) {
        subcategory['position'] = j;
        subs.push(subcategory);
        j++;
      }
      cat.subcategories = subs;
      cat['position'] = i;
      categories.push(cat);
      i++;
    }

    return categories;
  };

  const handleDialogEditOpen = (type, id, name, ietType) => {
    setIsEdit(true);
    setDialogType(type);
    setDialog(true);

    if (id) {
      setFormData({
        ...formData,
        name,
        id,
        type: ietType || ''
      });
    }
  };
  const handleDialogOpen = (type, id) => {
    setIsEdit(false);
    setDialogType(type);
    setDialog(true);

    if (id) {
      setFormData({
        ...formData,
        categoryId: id,
        type: IET_CATEGORIES[0]
      });
    }
  };

  const handleDialogClose = () => {
    setDialogType('');
    setIsEdit(false);
    setDialog(false);
    setFormData({
      name: '',
      categoryId: '',
      type: IET_CATEGORIES[0]
    });
  };

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const save = (e) => {
    e.preventDefault();

    if (name.length === 0) {
      return;
    }

    if (dialogType === TYPE_CATEGORY || dialogType === TYPE_SUBCATEGORY) {
      if (isEdit) {
        updateCategory(formData);
      } else {
        addCategory(formData);
      }
    }

    handleDialogClose();
  };

  const getIndex = (id) => {
    let index = -1;
    cats.forEach((image) => {
      if (image.id === id) {
        index = cats.indexOf(image);
      }
    });

    return index;
  };

  const activeIndex = activeId ? getIndex(activeId) : -1;

  const handleDndStart = (e) => {
    setActiveId(e.active.id);
  };

  const handleMainCategoryDndEnd = (e) => {
    const { active, over } = e;

    if (!over) return;

    if (active.id !== over?.id) {
      const oldIndex = getIndex(active.id);
      const newIndex = getIndex(over?.id);

      const catsCopy = JSON.parse(JSON.stringify(cats));
      const removedImageObject = catsCopy.splice(oldIndex, 1);
      catsCopy.splice(newIndex, 0, removedImageObject[0]);

      const newCats = generateCategoryPositions(catsCopy);
      setCategories(newCats);
      updateAllCategory({ categories: JSON.stringify(newCats) });
    }

    setActiveId(null);
  };

  const handleDndCancel = () => {
    setActiveId(null);
  };

  return (
    <Fragment>
      <Breadcrumb
        breadcrumbs={breadcrumbs}
        sectionNames={[{ link: '/settings', name: 'settings' }]}
      />
      <LoadingBackdrop loading={isLoading} />
      <div className="container-fluid mt-4">
        <div className="row">
          {permissions.find((item) => item.name === SETTINGS) && (
            <Fragment>
              <div className="col-md-4 mb-4">
                <div id="card-content">
                  <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h5>Categories</h5>
                        <p>Create and manage categories</p>
                      </div>
                      <div>
                        <Link
                          className="btn btn-outline-primary"
                          onClick={() => handleDialogOpen(TYPE_CATEGORY)}
                        >
                          Add New
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <DragDropContext onDragEnd={handleSubCategoryDragEnd}>
                <div className="col-md-12">
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDndStart}
                    onDragEnd={handleMainCategoryDndEnd}
                    onDragCancel={handleDndCancel}
                  >
                    <SortableContext items={cats} strategy={rectSortingStrategy}>
                      <div className="row">
                        {cats.map((category) => (
                          <DraggableCategory
                            key={category?.id}
                            category={category}
                            handleDialogOpen={handleDialogOpen}
                            handleDialogEditOpen={handleDialogEditOpen}
                            TYPE_CATEGORY={TYPE_CATEGORY}
                            TYPE_SUBCATEGORY={TYPE_SUBCATEGORY}
                          />
                        ))}
                      </div>
                    </SortableContext>
                    <DragOverlay adjustScale>
                      {activeId ? <DragOverlayCat category={cats[activeIndex]} /> : null}
                    </DragOverlay>
                  </DndContext>
                </div>
              </DragDropContext>
            </Fragment>
          )}
        </div>
      </div>

      <Dialog
        open={dialogOpen}
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        onClose={handleDialogClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {isEdit ? dialogType.replace('Add', 'Update') : dialogType}
        </DialogTitle>
        <DialogContent>
          <div className="bg-white shadow-sm rounded">
            <div className="card-body">
              <React.Fragment>
                <p className="my-auto">
                  <strong>Name</strong>
                </p>
                <TextField
                  id="name"
                  value={name}
                  onChange={(e) => onChange(e)}
                  margin="normal"
                  name={'name'}
                  placeholder="Enter name"
                  type="text"
                  fullWidth
                />
              </React.Fragment>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button color="primary" onClick={(e) => save(e)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  system: state.system,
  products: state.products,
  auth: state.auth
});

export default connect(mapStateToProps, {
  addCategory,
  getCategories,
  updateCategory,
  updateAllCategory
})(ReorderCategories);
