import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import MaterialTable from 'material-table';
import moment from 'moment';
import { getSuppliers } from '../../../actions/suppliers';
import { MODIFY_SUPPLIERS, SUPPLIER_ENTRY, SUPPLIERS, SUPPLIERS_DUE } from '../../../permissions';
import { SUPPLIER_SECTIONS } from '../../../utils';

function Suppliers({ isLoading, getSuppliers, suppliers, auth: { permissions } }) {
  let history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === SUPPLIERS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getSuppliers();
  }, []);

  const handleHistory = () => {
    history.push('#');
  };

  const handleDetails = (id) => {
    history.push({
      pathname: '/suppliers/details',
      search: `?id=${id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/suppliers'}>Suppliers</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/suppliers/all'}>All Supplier</Link>
      </li>
    </ol>
  );

  const tableHead = [
    { field: 'id', title: 'ID', editable: 'never' },
    { field: 'name', title: 'Supplier Name' },
    { field: 'email', title: 'Email' },
    { field: 'phone', title: 'Phone' },
    { title: 'address', field: 'address' }
  ];

  if (permissions.find((item) => item.name === SUPPLIERS_DUE)) {
    tableHead.push({ field: 'due', title: 'Due (৳)' });
  }

  tableHead.push({
    title: 'Status',
    field: 'status.name',
    render: (rowData) => (
      <p className={`mb-0 ${rowData.status.name.toLowerCase()}_status`}>{rowData.status.name}</p>
    )
  });

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SUPPLIER_SECTIONS} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            {permissions.find((item) => item.name === SUPPLIER_ENTRY) && (
              <div className="d-flex mb-4 justify-content-between align-items-center">
                <div>
                  <h5>Add New Supplier</h5>
                  <p className={'mb-0'}>Create and manage suppliers</p>
                </div>
                <div>
                  <Link
                    to={'/suppliers/add-supplier'}
                    className="btn btn-primary p-2 rounded text-decoration-none"
                  >
                    <i className="far fa-plus-square me-2" /> Add Supplier
                  </Link>
                </div>
              </div>
            )}

            <div className="row heading_columns">
              <div className="col-md-2 border-right">
                <p>Total suppliers</p>
                <h4>{suppliers.length}</h4>
              </div>
              <div className="col-md-2  border-right">
                <p>Active suppliers</p>
                <h4>{suppliers.filter((supplier) => supplier.status.name === 'Active').length}</h4>
              </div>
              <div className="col-md-2">
                <p>Inactive suppliers</p>
                <h4>{suppliers.filter((supplier) => supplier.status.name !== 'Active').length}</h4>
              </div>
              <div className="col-md-6 text-end">
                {permissions.find((item) => item.name === SUPPLIER_ENTRY) && (
                  <button className="btn btn-primary me-3">
                    Upload suppliers <i className="ms-2 fas fa-cloud-upload-alt" />
                  </button>
                )}

                <button className="btn btn-primary">
                  Download suppliers <i className="ms-2 fas fa-cloud-download-alt" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="card-content" className={'mt-4'}>
          <div className={'overflow-auto'}>
            <MaterialTable
              title={'All Supplier'}
              columns={tableHead}
              data={suppliers}
              isLoading={isLoading}
              totalCount={suppliers.length}
              options={{
                headerStyle: {
                  backgroundColor: '#F6F9FC',
                  color: '#000'
                },
                rowStyle: () => {
                  return {
                    fontSize: '14px',
                    color: '#000'
                  };
                },
                actionsColumnIndex: -1,
                padding: 'default',
                pageSize: 20,
                pageSizeOptions: [10, 20, 25, 50],
                showFirstLastPageButtons: true,
                exportAllData: true,
                exportFileName: `Suppliers - ${moment(new Date()).format('LL')}`,
                exportButton: true,
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  marginLeft: '3rem'
                }
              }}
              actions={[
                {
                  icon: 'refresh',
                  onClick: () => {},
                  isFreeAction: true,
                  tooltip: 'refresh'
                }
              ]}
              onRowClick={(event, rowData) => handleDetails(rowData.id)}
              editable={
                permissions.find((item) => item.name === MODIFY_SUPPLIERS) && {
                  onRowDelete: () =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        // deleteSupplier(oldData.id)

                        resolve();
                      }, 1000);
                    })
                }
              }
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

Suppliers.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  suppliers: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  suppliers: state.suppliers.suppliers,
  auth: state.auth
});

export default connect(mapStateToProps, { getSuppliers })(Suppliers);
