import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { getProducts, getSearchedProducts } from '../../../actions/products';
import {
  getAvailableQtyItem,
  getProductVariationString,
  REGEX_STRING,
  SALE_SECTIONS
} from '../../../utils';
import { exchangeSale, getSaleDetails } from '../../../actions/sale';
import Swal from 'sweetalert2';
import { DEFAULT_BRANCH, DEFAULT_PAYMENT_METHOD, GENERAL_CUSTOMER } from '../../../const';
import { SALES_EXCHANGE } from '../../../permissions';
import BranchSelector from '../../common/BranchSelector';
import PaymentMethodSelector from '../../common/PaymentMethodSelector';
import FilterProductSelector from '../../common/FilterProductSelector';
import { getBranches, getPaymentMethods } from '../../../actions/system';
import { getCustomers } from '../../../actions/customers';
import CartsTable from '../../common/CartsTable';
import NumberInput from '../../common/NumberInput';

function SaleExchange({
  isLoading,
  getProducts,
  exchangeSale,
  getSaleDetails,
  getCustomers,
  getPaymentMethods,
  getBranches,
  getSearchedProducts,
  auth: { permissions, user },
  system: { branches, paymentMethods },
  products: { products, searchLoading },
  sale: { saleDetails },
  location
}) {
  const history = useHistory();
  const autocompleteRef = useRef();
  const initState = {
    date: saleDetails ? saleDetails.date : '',
    userId: user ? user.id : '', // Logged in user ID
    id: saleDetails ? saleDetails.id : '',

    paymentMethodId: saleDetails ? saleDetails.paymentMethodId : '', // CASH

    customerName: saleDetails ? saleDetails.customer.name : '',
    customerId: saleDetails ? saleDetails.customer.id : '',
    customerPhone: saleDetails ? saleDetails.customer.phone : '',
    customerPreDue: saleDetails ? saleDetails.customer.due : '',

    totalAmount: 0,

    cartsItem: [],
    oldCartsItem: saleDetails ? saleDetails.saleOrderItems : [],

    subTotal: 0,
    vat: 0,
    vatValue: 0,
    transportCost: 80,
    returned: saleDetails ? saleDetails.returnedDue : 0,
    discount: 0,
    total: 0,
    due: 0,
    note: saleDetails ? saleDetails.notes : ''
  };
  const [formData, setFormData] = useState({
    ...initState,
    branchId: ''
  });

  const {
    branchId,
    customerPhone,
    customerName,
    customerId,
    customerPreDue,
    subTotal,
    totalAmount,
    cartsItem,
    oldCartsItem,
    due,
    note,
    total,
    discount,
    vat,
    id,
    vatValue,
    transportCost,
    date,
    paymentMethodId,
    returned
  } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === SALES_EXCHANGE)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      getSaleDetails(id);
    }
    getBranches();
    getCustomers(true);
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        userId: user.id
      });
    }
  }, [user]);

  useEffect(() => {
    setFormData({
      ...formData,
      ...initState
    });
  }, [saleDetails]);

  useEffect(() => {
    if (paymentMethods.length > 0) {
      setFormData({
        ...formData,
        paymentMethodId: paymentMethods.find((item) => item.name === DEFAULT_PAYMENT_METHOD).id
      });
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (branches.length > 0) {
      const branchId = branches.find((branch) => branch.name === DEFAULT_BRANCH).id;
      setFormData({
        ...formData,
        branchId
      });

      getProducts({ size: 5, branch: branchId });
    }
  }, [branches]);

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const onBranchSelected = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      branchId: value ? value.id : ''
    });
  };

  const onPaymentMethodSelected = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      paymentMethodId: value ? value.id : ''
    });
  };

  const onProductSelected = (e, value) => {
    e.preventDefault();
    setTimeout(
      () =>
        autocompleteRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click(),
      10
    );

    if (!branchId) {
      Swal.fire({
        icon: 'warning',
        title: 'No Branch Selected!',
        text: 'You must choose a branch before add item to cart',
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }

    if (value) {
      const availableQty = getAvailableQtyItem(value, branchId);

      if (!availableQty) {
        Swal.fire({
          icon: 'error',
          title: 'Stock empty',
          text: 'Not enough stock, Please choose another product.',
          showConfirmButton: false,
          timer: 1500
        }).then();
        return;
      }

      // check oldCartsItem already has this item
      // if (oldCartsItem.find(item => item.s_code === value.s_code)) {
      //     Swal.fire({
      //         icon: 'error',
      //         title: "Item already added in sale",
      //         text: "Please, Add unique item when making an exchange.",
      //     }).then()
      //     return
      // }

      // Check duplicate
      let duplicateItemIndex = cartsItem.findIndex(
        (item) =>
          (item.createdAt ? item.itemId : item.id) === value.id &&
          item.s_code === value.s_code &&
          item.branchId === branchId
      );
      if (duplicateItemIndex !== -1) {
        if (cartsItem[duplicateItemIndex].qty + 1 <= availableQty.qty) {
          cartsItem[duplicateItemIndex].qty++;
          cartsItem[duplicateItemIndex].total = getItemTotal(
            cartsItem[duplicateItemIndex].qty,
            cartsItem[duplicateItemIndex].rate,
            cartsItem[duplicateItemIndex].discount
          );

          setFormData({
            ...formData,
            cartsItem,
            subTotal: getSubTotal(cartsItem),
            total: getTotal(getSubTotal(cartsItem), totalAmount),
            due: getTotal(getSubTotal(cartsItem), totalAmount)
          });
        }
      } else {
        const updatedCarts = cartsItem.concat({
          id: value.id,
          itemId: value.id,
          description: getProductVariationString(value),
          s_code: value.s_code,
          qty: 1,
          rate: value.price,
          discount: value.discount ?? 0,
          total: value.price - (value.discount / 100) * value.price,
          colorId: value.color ? value.color.id : '',
          sizeId: value.size ? value.size.id : '',
          availableQty: availableQty ? availableQty.qty : 0,
          branchId: branchId
        });
        setFormData({
          ...formData,
          cartsItem: updatedCarts,
          subTotal: getSubTotal(updatedCarts),
          total: getTotal(getSubTotal(updatedCarts), totalAmount),
          due: getTotal(getSubTotal(updatedCarts), totalAmount)
        });
      }
    }
  };

  const getTotal = (subTotalAmount, totalAmount) => {
    return parseFloat(
      (
        parseFloat(
          (
            parseFloat(subTotalAmount) +
            parseFloat(((vat / 100) * (getSubTotal(cartsItem) - totalAmount)).toFixed(2)) +
            parseFloat(transportCost)
          ).toFixed(2)
        ) -
        parseFloat(discount) -
        returned
      ).toFixed(2)
    );
  };

  const getItemTotal = (qty, rate, discount) => {
    const discountPrice = (discount / 100) * rate * qty;
    return parseFloat((parseInt(qty) * parseFloat(rate) - discountPrice).toFixed(2));
  };

  const onQuantityChange = (e, id, s_code, value, branchId) => {
    e.preventDefault();

    let index = cartsItem.findIndex(
      (item) => item.id === id && item.s_code === s_code && item.branchId === branchId
    );

    if (cartsItem[index].availableQty === 0) {
      let oldCartItemsQty = oldCartsItem.find(
        (item) => item.id === id && item.s_code === s_code
      ).qty;

      // When user wants to put less number than qty
      if (parseInt(value) > 0 && parseInt(value) <= oldCartItemsQty) {
        cartsItem[index].qty = parseInt(value);
        cartsItem[index].total = getItemTotal(
          value,
          cartsItem[index].rate,
          cartsItem[index].discount
        );

        setFormData({
          ...formData,
          cartsItem,
          subTotal: getSubTotal(cartsItem),
          total: getTotal(getSubTotal(cartsItem), totalAmount),
          vatValue: (vat / 100) * (getSubTotal(cartsItem) - totalAmount),
          due: getTotal(getSubTotal(cartsItem), totalAmount)
        });
      }
    } else {
      let quantity;

      // When user enter bigger number than available qty
      if (value !== '' && parseInt(value) > cartsItem[index].availableQty) {
        quantity = cartsItem[index].availableQty;
      } else {
        quantity = parseInt(value);
      }

      cartsItem[index].qty = parseInt(quantity);
      cartsItem[index].total = getItemTotal(
        quantity,
        cartsItem[index].rate,
        cartsItem[index].discount
      );

      setFormData({
        ...formData,
        cartsItem,
        subTotal: getSubTotal(cartsItem),
        total: getTotal(getSubTotal(cartsItem), totalAmount),
        vatValue: (vat / 100) * (getSubTotal(cartsItem) - totalAmount),
        due: getTotal(getSubTotal(cartsItem), totalAmount)
      });
    }
  };

  const onSaleRateChange = (id, s_code, value, branchId) => {
    let index = cartsItem.findIndex(
      (item) => item.id === id && item.s_code === s_code && item.branchId === branchId
    );
    cartsItem[index].rate = value;
    cartsItem[index].total = getItemTotal(cartsItem[index].qty, value, cartsItem[index].discount);

    setFormData({
      ...formData,
      cartsItem,
      subTotal: getSubTotal(cartsItem),
      total: getTotal(getSubTotal(cartsItem), totalAmount),
      vatValue: (vat / 100) * (getSubTotal(cartsItem) - totalAmount),
      due: getTotal(getSubTotal(cartsItem), totalAmount)
    });
  };

  const onSaleDiscountChange = (id, s_code, value, branchId) => {
    let index = cartsItem.findIndex(
      (item) => item.id === id && item.s_code === s_code && item.branchId === branchId
    );
    cartsItem[index].discount = value;
    cartsItem[index].total = getItemTotal(cartsItem[index].qty, cartsItem[index].rate, value);

    setFormData({
      ...formData,
      cartsItem,
      subTotal: getSubTotal(cartsItem),
      total: getTotal(getSubTotal(cartsItem), totalAmount),
      vatValue: (vat / 100) * (getSubTotal(cartsItem) - totalAmount),
      due: getTotal(getSubTotal(cartsItem), totalAmount)
    });
  };

  const deleteItemsFromCart = (id, s_code, totalAmount) => {
    const updatedCarts = cartsItem.filter((item) => item.id !== id || item.s_code !== s_code);
    setFormData({
      ...formData,
      cartsItem: updatedCarts,
      subTotal: getSubTotal(updatedCarts),
      total: getTotal(getSubTotal(updatedCarts), totalAmount),
      vatValue: (vat / 100) * (getSubTotal(updatedCarts) - totalAmount),
      due: getTotal(getSubTotal(updatedCarts), totalAmount)
    });

    if (cartsItem.length === 1) {
      setFormData({
        ...formData,
        cartsItem: [],
        subTotal: 0,
        vat: 0,
        vatValue: 0,
        discount: 0,
        total: 0,
        due: 0
      });
    }
  };

  const getSubTotal = (carts) => {
    if (carts.length > 0) {
      // Get array of all the items total value
      const array = carts.map((singleObject) => parseFloat(singleObject.total));
      return parseFloat(calculateTwoIntegersFromIntegersOfArray(array).toFixed(2));
    }
    return 0;
  };

  const calculateTwoIntegersFromIntegersOfArray = (array) => {
    // Calculate all the array values
    const add = (a, b) => a + b;
    return array.reduce(add);
  };

  const onVatChange = (e) => {
    setFormData({
      ...formData,
      vat: parseFloat(e.target.value),
      vatValue: parseFloat(
        (parseFloat((parseFloat(e.target.value) / 100).toFixed(2)) * subTotal).toFixed(2)
      ),
      total: getTotalAfterVat(
        parseFloat(
          (parseFloat((parseFloat(e.target.value) / 100).toFixed(2)) * subTotal).toFixed(2)
        )
      ),
      due: getTotalAfterVat(
        parseFloat(
          (parseFloat((parseFloat(e.target.value) / 100).toFixed(2)) * subTotal).toFixed(2)
        )
      )
    });
  };

  const getTotalAfterVat = (value) => {
    return parseFloat(
      (
        parseFloat(
          (parseFloat(subTotal) + parseFloat(value) + parseFloat(transportCost)).toFixed(2)
        ) -
        parseFloat(discount) -
        returned
      ).toFixed(2)
    );
  };

  const onTransportChange = (e) => {
    setFormData({
      ...formData,
      transportCost: parseFloat(e.target.value),
      total: getTotalAfterTransfer(parseFloat(e.target.value)),
      due: getTotalAfterTransfer(parseFloat(e.target.value))
    });
  };

  const getTotalAfterTransfer = (value) => {
    return parseFloat(
      (
        parseFloat((parseFloat(subTotal) + parseFloat(vatValue) + parseFloat(value)).toFixed(2)) -
        parseFloat(discount) -
        returned
      ).toFixed(2)
    );
  };

  const onDiscountChange = (e) => {
    setFormData({
      ...formData,
      discount: parseFloat(e.target.value),
      total: getTotalAfterDiscount(parseFloat(e.target.value)),
      due: getTotalAfterDiscount(parseFloat(e.target.value))
    });
  };

  const getTotalAfterDiscount = (value) => {
    return parseFloat(
      (
        parseFloat(
          (parseFloat(subTotal) + parseFloat(vatValue) + parseFloat(transportCost)).toFixed(2)
        ) -
        parseFloat(value) -
        returned
      ).toFixed(2)
    );
  };

  const exchangeInvoice = async (e) => {
    e.preventDefault();

    if (!branchId) {
      Swal.fire(
        'No Branch Selected!',
        'You must choose a branch before update any sale',
        'warning'
      ).then();
      return;
    }

    if (!customerId) {
      Swal.fire(
        'Choose a Customer',
        'You must choose a customer before update any sale',
        'warning'
      ).then();
      return;
    }

    if (cartsItem.length <= 0) {
      Swal.fire(
        'Choose an Item',
        'You must choose an item before update any sale',
        'warning'
      ).then();
      return;
    }

    if (customerId === GENERAL_CUSTOMER) {
      if (customerName === '' || customerPhone === '') {
        Swal.fire(
          'Missing Customer Information',
          'Customer information required inorder to update any sale',
          'warning'
        ).then();
        return;
      }
    }

    if (!paymentMethodId) {
      Swal.fire(
        'Choose a Payment Method',
        'You must choose a payment method before update any sale',
        'warning'
      ).then();
      return;
    }

    const data = await exchangeSale(formData, id);
    history.push({
      pathname: '/sale/details',
      search: `?id=${data.id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale'}>Sale</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale/all'}>Invoices</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={`/sale/details?id=${saleDetails && saleDetails.id}`}>Details</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={`/sale/exchange?id=${saleDetails && saleDetails.id}`}>Exchange Sale</Link>
      </li>
    </ol>
  );

  const tableHead = [
    {
      field: 'tableData.id',
      title: 'SL',
      cellStyle: {
        width: '5%'
      },
      render: (rowData) => {
        return <p className={'mb-0'}>{rowData.tableData.id + 1}</p>;
      }
    },
    {
      field: 'description',
      title: 'Description',
      cellStyle: {
        width: '30%'
      },
      render: (rowData) => {
        return rowData.description.includes('(') ? (
          <p className={'m-0'}>
            {rowData.description.split('(')[0]} (
            <span className={'font-weight-bold'}>{REGEX_STRING.exec(rowData.description)[1]}</span>)
          </p>
        ) : (
          rowData.description
        );
      }
    },
    {
      field: 'qty',
      title: 'Quantity',
      render: (rowData) => (
        <NumberInput
          value={rowData.qty}
          min={1}
          step={1}
          onChange={(e) =>
            onQuantityChange(e, rowData.id, rowData.s_code, e.target.value, rowData.branchId)
          }
        />
      )
    },
    {
      field: 'rate',
      title: 'Rate (৳)',
      render: (rowData) => (
        <NumberInput
          value={rowData.rate}
          onChange={(e) =>
            onSaleRateChange(rowData.id, rowData.s_code, e.target.value, rowData.branchId)
          }
        />
      )
    },
    {
      field: 'discount',
      title: 'Discount (%)',
      render: (rowData) => (
        <NumberInput
          max={100}
          value={rowData.discount}
          onChange={(e) =>
            onSaleDiscountChange(rowData.id, rowData.s_code, e.target.value, rowData.branchId)
          }
        />
      )
    },
    {
      field: 'total',
      title: 'Amount (৳)',
      render: (rowData) => <NumberInput disabled value={rowData.total} />
    },
    {
      field: 'availableQty',
      title: 'A. Qty',
      render: (rowData) => <NumberInput disabled value={rowData.availableQty} />
    },
    {
      field: 'branchId',
      title: 'Branch',
      render: (rowData) => (
        <p className="mb-0">{branches.find((branch) => branch.id === rowData.branchId).name}</p>
      )
    }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SALE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form onSubmit={(e) => exchangeInvoice(e)}>
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>Exchange Sale</h5>
                  <p className={'mb-0'}>
                    Add new items to exchange a sale. Total amount will be adjusted with returned
                    sale.
                  </p>
                </div>
                <div>
                  <button
                    type={'submit'}
                    disabled={!customerId || cartsItem.length <= 0 || !paymentMethodId || isLoading}
                    className="btn btn-primary p-2 me-2"
                  >
                    Exchange
                  </button>
                  <button
                    onClick={onCancelButtonClicked}
                    type={'button'}
                    disabled={isLoading}
                    className="btn btn-danger p-2 text-light"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          {branches.length > 0 && paymentMethods.length > 0 && (
            <div className="row mt-4">
              <div className="col-md-9">
                <div id="card-content">
                  <div className="card-body">
                    <div className="row justify-content-between">
                      <div className="col-md-5">
                        <div className="form-group">
                          <div className="d-flex align-items-center justify-content-between">
                            <BranchSelector
                              formData={formData}
                              setFormData={setFormData}
                              branches={branches}
                              getProducts={getProducts}
                            />
                          </div>
                        </div>

                        <div className="form-group mt-2">
                          <input
                            type="text"
                            name={'customerName'}
                            value={customerName}
                            disabled
                            onChange={(e) => onChange(e)}
                            className="form-control input-sm"
                            placeholder={'Enter customer name'}
                          />
                        </div>

                        <div className="form-group mt-2">
                          <input
                            type="text"
                            name={'customerPhone'}
                            disabled
                            value={customerPhone}
                            onChange={(e) => onChange(e)}
                            className="form-control input-sm"
                            placeholder={'Enter customer phone'}
                          />
                        </div>
                      </div>

                      <div className="col-md-7">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="d-flex align-items-center justify-content-between">
                                <PaymentMethodSelector
                                  setFormData={setFormData}
                                  formData={formData}
                                  paymentMethods={paymentMethods}
                                  paymentMethodId={paymentMethodId}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="d-flex align-items-center justify-content-between">
                                <input
                                  type="date"
                                  name={'date'}
                                  defaultValue={date}
                                  onChange={(e) => onChange(e)}
                                  className="form-control"
                                  placeholder={'Choose a date'}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {products && branchId && (
                          <div className="form-group mt-2">
                            <div className="d-flex align-items-center justify-content-between">
                              <FilterProductSelector
                                onProductSelected={onProductSelected}
                                products={products}
                                branchId={branchId}
                                searchLoading={searchLoading}
                                getSearchedProducts={getSearchedProducts}
                                autocompleteRef={autocompleteRef}
                              />
                            </div>

                            <p className={'mb-0 mt-1 text-muted'}>
                              Items that are in stocks for selected branch!
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <CartsTable
                  cartsItem={cartsItem}
                  deleteItemsFromCart={deleteItemsFromCart}
                  tableHead={tableHead}
                />

                <div id="card-content" className="mt-4">
                  <div className="form-group">
                    <textarea
                      name={'note'}
                      value={note}
                      placeholder={'Write notes...'}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div id="card-content">
                  <div className="card-header">
                    <h5>Amount Details</h5>
                    <p className={'mb-0'}>Check the sale amount details before updating</p>
                  </div>

                  <div className="card-body mt-2">
                    <div className="form-group">
                      <label htmlFor={'subTotal'}>
                        Sub Total <span className={'text-danger'}>*</span>
                      </label>
                      <NumberInput
                        name={'subTotal'}
                        value={subTotal}
                        required
                        disabled
                        placeholder={'Sub Total'}
                      />
                    </div>

                    <div className="row justify-content-start mt-2">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor={'vat'}>Vat(%)</label>
                          <NumberInput
                            name={'vat'}
                            value={vat}
                            onChange={(e) => onVatChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor={'vatValue'}>Vat Amount</label>
                          <NumberInput
                            name={'vatValue'}
                            min={0}
                            disabled
                            value={vatValue}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor={'transportCost'}>Shipping Charge</label>
                      <NumberInput
                        name={'transportCost'}
                        value={transportCost}
                        onChange={(e) => onTransportChange(e)}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor={'discount'}>Discount</label>
                      <NumberInput
                        name={'discount'}
                        value={discount}
                        onChange={(e) => onDiscountChange(e)}
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label className={'text-danger'} htmlFor={'returned'}>
                        Returned
                      </label>
                      <NumberInput
                        name={'returned'}
                        value={returned}
                        disabled
                        className="border-danger text-danger"
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor={'total'}>
                        Total <span className={'text-danger'}>*</span>
                      </label>
                      <NumberInput name={'total'} value={total} disabled />
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor={'customerPreDue'}>Previous Due</label>
                      <NumberInput
                        name={'customerPreDue'}
                        value={customerPreDue}
                        disabled
                        className="text-danger"
                      />
                    </div>

                    <div className="form-group mt-2">
                      <label htmlFor={'customerPreDue'}>Due</label>
                      <NumberInput name={'due'} value={due} disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </Fragment>
  );
}

SaleExchange.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  system: PropTypes.object.isRequired,
  getCustomers: PropTypes.func.isRequired,
  products: PropTypes.object.isRequired,
  getProducts: PropTypes.func.isRequired,
  addSale: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  getSearchedProducts: PropTypes.func.isRequired,
  sale: PropTypes.object.isRequired,
  exchangeSale: PropTypes.func.isRequired,
  getSaleDetails: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  system: state.system,
  customers: state.customers,
  products: state.products,
  auth: state.auth,
  sale: state.sale
});

export default connect(mapStateToProps, {
  getProducts,
  exchangeSale,
  getSearchedProducts,
  getSaleDetails,
  getBranches,
  getCustomers,
  getPaymentMethods
})(SaleExchange);
