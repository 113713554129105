import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import {
  getPurchaseDamageReturnList,
  getPurchaseList,
  getPurchaseReturnList
} from '../../../actions/purchase';
import {
  isNotPurchaseHidden,
  PURCHASE_ENTRY,
  PURCHASE_INVOICES,
  PURCHASE_RETURN
} from '../../../permissions';
import { PURCHASE_SECTIONS } from '../../../utils';

function Purchase({
  isLoading,
  getPurchaseList,
  getPurchaseReturnList,
  auth: { permissions },
  getPurchaseDamageReturnList,
  purchase: { purchase, purchaseReturns, purchaseDamageReturns }
}) {
  const history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!isNotPurchaseHidden(permissions)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getPurchaseList({ size: 5 });
    getPurchaseDamageReturnList({ size: 5 });
    getPurchaseReturnList({ size: 5 });
  }, []);

  const handlePurchaseDetails = (id) => {
    history.push({
      pathname: '/purchase/details',
      search: `?id=${id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/purchase'}>Purchase</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={PURCHASE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        {permissions.find((item) => item.name === PURCHASE_ENTRY) && (
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>New Purchase Entry</h5>
                  <p className={'mb-0'}>Add new purchase</p>
                </div>
                <div>
                  <Link
                    to={'/purchase/new'}
                    className="btn btn-primary p-2 rounded text-decoration-none"
                  >
                    <i className="far fa-plus-square me-2" /> Purchase Entry
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row mt-4">
          {permissions.find((item) => item.name === PURCHASE_INVOICES) && (
            <div className="col-md-5 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Recent Purchase</h5>
                      <p>Recent purchase invoices</p>
                    </div>
                    <div>
                      <Link
                        to={'/purchase/all'}
                        className="btn btn-primary p-2 rounded text-decoration-none"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Inv. No</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Supplier</TableCell>
                          <TableCell>Total (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {purchase &&
                          purchase.paginatedData.map((row) => (
                            <TableRow
                              className={'hover'}
                              onClick={() => handlePurchaseDetails(row.id)}
                              key={row.id}
                            >
                              <TableCell>{row.id}</TableCell>
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.supplier.name}</TableCell>
                              <TableCell>{row.total}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === PURCHASE_RETURN) && (
            <div className="col-md-7 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Recent Returns</h5>
                      <p>Check to see all recent purchase returns</p>
                    </div>
                    <div>
                      <Link
                        to={'/purchase/return-list'}
                        className="btn btn-primary p-2 rounded text-decoration-none"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Code</TableCell>
                          <TableCell>Supplier</TableCell>
                          <TableCell>Return Qty</TableCell>
                          <TableCell>Return Amount (৳)</TableCell>
                          <TableCell>Reason</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {purchaseReturns && purchaseReturns.paginatedData.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell>
                              {row.purchase_order_detail ? row.purchase_order_detail.item.code : ''}
                            </TableCell>
                            <TableCell>{row.purchase_order.supplier.name}</TableCell>
                            <TableCell>{row.qty}</TableCell>
                            <TableCell>{row.total}</TableCell>
                            <TableCell>{row.reason}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === PURCHASE_RETURN) && (
            <div className="col-md-7 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Damage Returns</h5>
                      <p>Check to see all recent purchase damage returns</p>
                    </div>
                    <div>
                      <Link
                        to={'/purchase/damage-return-list'}
                        className="btn btn-primary p-2 rounded text-decoration-none"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Code</TableCell>
                          <TableCell>Supplier</TableCell>
                          <TableCell>Return Qty</TableCell>
                          <TableCell>Return Amount (৳)</TableCell>
                          <TableCell>Reason</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {purchaseDamageReturns && purchaseDamageReturns.paginatedData.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell>
                              {row.purchase_order_detail ? row.purchase_order_detail.item.code : ''}
                            </TableCell>
                            <TableCell>{row.purchase_order.supplier.name}</TableCell>
                            <TableCell>{row.qty}</TableCell>
                            <TableCell>{row.total}</TableCell>
                            <TableCell>{row.reason}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

Purchase.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getPurchaseList: PropTypes.func.isRequired,
  purchase: PropTypes.object.isRequired,
  getPurchaseReturnList: PropTypes.func.isRequired,
  getPurchaseDamageReturnList: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  purchase: state.purchase,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getPurchaseList,
  getPurchaseReturnList,
  getPurchaseDamageReturnList
})(Purchase);
