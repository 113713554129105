import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import { getBankTransactions } from '../../../actions/bankings';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import Breadcrumb from '../../layout/Breadcrumb';
import { TRANSACTIONS } from '../../../permissions';
import { ACCOUNTS_SECTIONS } from '../../../utils';
import FilterMaterialTable from '../../common/FilterMaterialTable';

function Transactions({
  isLoading,
  getBankTransactions,
  auth: { permissions },
  bankings: { transactions }
}) {
  const history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === TRANSACTIONS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const tableHead = [
    { field: 'date', title: 'Date' },
    { field: 'bank_account.account_name', title: 'Account Name' },
    { field: 'bank_account.account_no', title: 'Account Number' },
    { field: 'bank_account.bank_name', title: 'Bank Name' },
    { field: 'trans_type', title: 'Trxn Type' },
    { field: 'income_expanse_category.name', title: 'Category' },
    { field: 'description', title: 'Description' },
    { field: 'amount', title: 'Amount (৳)', render: (rowData) => Math.abs(rowData.amount) }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts'}>Accounts</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts/transactions'}>Transactions</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ACCOUNTS_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <FilterMaterialTable
        title={'All Transactions'}
        isLoading={isLoading}
        columns={tableHead}
        data={transactions}
        tableContainerClass={'container-fluid mt-4'}
        fetchMethod={getBankTransactions}
        isCustomPagination
        options={{
          rowStyle: () => {
            return {
              fontSize: '14px',
              color: '#000'
            };
          }
        }}
      />
    </Fragment>
  );
}

Transactions.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getBankTransactions: PropTypes.func.isRequired,
  bankings: PropTypes.object
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  bankings: state.bankings,
  auth: state.auth
});

export default connect(mapStateToProps, { getBankTransactions })(Transactions);
