import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import {
  getQuotationList,
  getSaleExchanges,
  getSaleList,
  getSaleReturnList
} from '../../../actions/sale';
import {
  isNotSalesHidden,
  QUOTATIONS,
  SALES_ENTRY,
  SALES_EXCHANGE,
  SALES_INVOICES,
  SALES_RETURN
} from '../../../permissions';
import { SALE_SECTIONS } from '../../../utils';

function Sale({
  isLoading,
  getSaleList,
  getQuotationList,
  getSaleReturnList,
  getSaleExchanges,
  sale: { sale, saleReturn, quotation, saleExchanges },
  auth: { permissions }
}) {
  const history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!isNotSalesHidden(permissions)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getSaleList({ size: 5 });
    getSaleReturnList({ size: 5 });
    getSaleExchanges({ size: 5 });
    getQuotationList({ size: 5 });
  }, []);

  const handleSaleDetails = (id) => {
    history.push({
      pathname: '/sale/details',
      search: `?id=${id}`
    });
  };

  const handleQuotationDetails = (id) => {
    history.push({
      pathname: '/sale/quotations/details',
      search: `?id=${id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale'}>Sale</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SALE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        {permissions.find((item) => item.name === SALES_ENTRY) && (
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>New Sale Entry</h5>
                  <p className={'mb-0'}>Add new sale</p>
                </div>
                <div>
                  <Link
                    to={'/sale/new'}
                    className="btn btn-primary p-2 rounded text-decoration-none"
                  >
                    <i className="far fa-plus-square me-2" /> Sale Entry
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row mt-4">
          {permissions.find((item) => item.name === SALES_INVOICES) && (
            <div className="col-md-6 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Recent Sale</h5>
                      <p>Recent sale invoices</p>
                    </div>
                    <div>
                      <Link
                        to={'/sale/all'}
                        className="btn btn-primary p-2 rounded text-decoration-none"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Web</TableCell>
                          <TableCell>Inv. No</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Total (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sale &&
                          sale.paginatedData.map((row) => (
                            <TableRow
                              className={'hover'}
                              onClick={() => handleSaleDetails(row.id)}
                              key={row.id}
                            >
                              <TableCell>
                                <Checkbox className={'saleCheckbox'} disabled checked={row.isWeb} />
                              </TableCell>
                              <TableCell>{row.id}</TableCell>
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.customer.name}</TableCell>
                              <TableCell>{row.total}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === SALES_RETURN) && (
            <div className="col-md-6 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Recent Returns</h5>
                      <p>Check to see all recent sale returns</p>
                    </div>
                    <div>
                      <Link
                        to={'/sale/return-list'}
                        className="btn btn-primary p-2 rounded text-decoration-none"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Code</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Return Qty</TableCell>
                          <TableCell>Return Amount (৳)</TableCell>
                          <TableCell>Paid (৳)</TableCell>
                          <TableCell>Reason</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {saleReturn &&
                          saleReturn.paginatedData.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.sale_order_detail.item.code}</TableCell>
                              <TableCell>{row.sale_order.customer.name}</TableCell>
                              <TableCell>{row.qty}</TableCell>
                              <TableCell>{row.total}</TableCell>
                              <TableCell>{row.returned_amount}</TableCell>
                              <TableCell>{row.reason}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === QUOTATIONS) && (
            <div className="col-md-6 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5>Quotations</h5>
                      <p className={'mb-0'}>Add and manage new quotations</p>
                    </div>
                    <div>
                      <Link
                        to={'/sale/quotations/new'}
                        className="btn btn-primary p-2 rounded text-decoration-none me-2"
                      >
                        Add
                      </Link>
                      <Link to={'/sale/quotations/all'} className="btn btn-outline-primary">
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Inv. No</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Total (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {quotation &&
                          quotation.paginatedData.map((row) => (
                            <TableRow
                              className={'hover'}
                              onClick={() => handleQuotationDetails(row.id)}
                              key={row.id}
                            >
                              <TableCell>{row.id}</TableCell>
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.customer.name}</TableCell>
                              <TableCell>{row.total}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === SALES_EXCHANGE) && (
            <div className="col-md-6 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Exchanges</h5>
                      <p>Check to see all sale exchanges</p>
                    </div>
                    <div>
                      <Link
                        to={'/sale/exchange/all'}
                        className="btn btn-primary p-2 rounded text-decoration-none"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Inv. No</TableCell>
                          <TableCell>Sale</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Returned (৳)</TableCell>
                          <TableCell>Total (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {saleExchanges &&
                          saleExchanges.paginatedData.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.id}</TableCell>
                              <TableCell>{`INV-${row.saleOrderId}`}</TableCell>
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.customer.name}</TableCell>
                              <TableCell>{row.returned}</TableCell>
                              <TableCell>{row.total}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

Sale.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getSaleList: PropTypes.func.isRequired,
  sale: PropTypes.object.isRequired,
  getSaleReturnList: PropTypes.func.isRequired,
  getQuotationList: PropTypes.func.isRequired,
  getSaleExchanges: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  sale: state.sale,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getSaleList,
  getSaleReturnList,
  getQuotationList,
  getSaleExchanges
})(Sale);
