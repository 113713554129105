import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteCoupon, getCoupons } from '../../../actions/coupon';
import Breadcrumb from '../../layout/Breadcrumb';
import { COUPON_SECTIONS } from '../../../utils';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { HomeRounded } from '@material-ui/icons';
import FilterMaterialTable from '../../common/FilterMaterialTable';

const AllCoupon = ({ isLoading, getCoupons, coupons, deleteCoupon }) => {
  const history = useHistory();

  useEffect(() => {
    getCoupons({});
  }, []);

  const handleUpdate = (id) => {
    history.push(`/website/update-coupon?id=${id}`);
  };

  const handleDeleteCoupon = (id) => {
    deleteCoupon(id);
  };

  const tableHead = [
    { field: 'id', title: 'ID', editable: 'never', cellStyle: { width: '10%' } },
    { field: 'code', title: 'Code' },
    { field: 'discount', title: 'Discount' },
    { field: 'discountType', title: 'Discount Type' },
    { field: 'validityFrom', title: 'Validity From' },
    { field: 'validityTo', title: 'Validity To' },
    { field: 'description', title: 'Description' },
    {
      title: 'Status',
      field: 'status.name',
      render: (rowData) => (
        <p className={`mb-0 ${rowData.status.name.toLowerCase()}_status`}>{rowData.status.name}</p>
      )
    }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/website'}>Website</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/website/all'}>All</Link>
      </li>
    </ol>
  );

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={COUPON_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>All Coupon</h5>
                <p className={'mb-0'}>All coupon details</p>
              </div>
              <div>
                <Link
                  to={'/website/add-coupon'}
                  className="btn btn-primary p-2 rounded text-decoration-none">
                  <i className="far fa-plus-square me-2" /> Add Coupon
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FilterMaterialTable
        title={'All Coupon'}
        isLoading={isLoading}
        columns={tableHead}
        data={coupons}
        tableContainerClass={'mt-4'}
        fetchMethod={getCoupons}
        isCustomPagination
        options={{
          rowStyle: () => {
            return {
              fontSize: '14px',
              color: '#000'
            };
          }
        }}
        actions={[
          {
            icon: 'edit',
            onClick: () => {},
            isFreeAction: true,
            tooltip: 'update'
          },
          (rowData) => ({
            icon: 'edit',
            onClick: () => handleUpdate(rowData.id),
            tooltip: 'Update'
          })
        ]}
        editable={{
          onRowDelete: (data) =>
            new Promise((resolve) => {
              setTimeout(() => {
                handleDeleteCoupon(data.id);

                resolve();
              }, 1000);
            })
        }}
      />
    </>
  );
};

AllCoupon.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  coupons: PropTypes.object
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  coupons: state.coupons.coupons,
  auth: state.auth
});

export default connect(mapStateToProps, { getCoupons, deleteCoupon })(AllCoupon);
