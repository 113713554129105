import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStockTransfers, setInventorySearch } from '../../../actions/stocks';
import FilterMaterialTable from '../../common/FilterMaterialTable';

function AllTransfers({
  isLoading,
  getStockTransfers,
  setInventorySearch,
  stocks: { transfers, searchText }
}) {
  const tableHead = [
    { field: 'item.code', title: 'Code' },
    { field: 'description', title: 'Description' },
    { field: 'from_branch.name', title: 'From Branch' },
    { field: 'to_branch.name', title: 'To Branch' },
    { field: 'qty', title: 'Qty' }
  ];

  return (
    <Fragment>
      <div className="container-fluid p-0">
        <div id="card-content" className={'shadow-none pt-0'}>
          {/*<div className="card-header">*/}
          {/*  <div className="d-flex justify-content-between align-items-center">*/}
          {/*    <div>*/}
          {/*      <h5>Stock transfers</h5>*/}
          {/*      <p className={'mb-0'}>All the stock transfers between branches</p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <FilterMaterialTable
            title={'Stock transfers'}
            isLoading={isLoading}
            columns={tableHead}
            data={transfers}
            tableContainerClass={'card-body mt-3'}
            fetchMethod={getStockTransfers}
            search={searchText}
            setSearch={setInventorySearch}
            isCustomPagination
            allTime
            options={{
              rowStyle: (rowData) => {
                return {
                  fontSize: '14px',
                  color: '#000'
                };
              }
            }}
          />
        </div>
      </div>
    </Fragment>
  );
}

AllTransfers.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getStockTransfers: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  stocks: state.stocks
});

export default connect(mapStateToProps, { getStockTransfers, setInventorySearch })(AllTransfers);
