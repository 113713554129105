export const GENERAL_SUPPLIER = 'General Supplier';
export const GENERAL_CUSTOMER = 'General Customer';
export const PURCHASE_ACCOUNT = 'Purchase Account';
export const DEFAULT_BRANCH = 'Warehouse';
export const DEFAULT_PAYMENT_METHOD = 'Cash';
export const DEFAULT_INCOME_CATEGORY = 'Sales';
export const ACTIVE_STATUS = 1;
export const INACTIVE_STATUS = 9;
export const PAID_STATUS = 3;
export const UNPAID_STATUS = 2;
export const PARTIAL_STATUS = 4;
export const CANCELLED_STATUS = 7;
export const PENDING_STATUS = 5;
export const ADVANCE_STATUS = 10;
export const PACKED_STATUS = 8;
export const DELIVERED_STATUS = 6;

export const DOCUMENTATION_URL = 'https://documenter.getpostman.com/view/9205272/TzskEPDj';
