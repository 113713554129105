import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import { getBankTransferTransactions } from '../../../../actions/bankings';
import { TRANSFERS } from '../../../../permissions';
import { ACCOUNTS_SECTIONS } from '../../../../utils';
import FilterMaterialTable from '../../../common/FilterMaterialTable';

function AllTransfers({
  isLoading,
  getBankTransferTransactions,
  auth: { permissions },
  bankings: { transferTransactions }
}) {
  const history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === TRANSFERS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const tableHead = [
    { field: 'id', title: 'ID' },
    { field: 'date', title: 'Date' },
    { field: 'bank_account.account_name', title: 'Account Name' },
    { field: 'bank_account.bank_name', title: 'Bank Name' },
    { field: 'description', title: 'Description' },
    { field: 'amount', title: 'Amount (৳)' }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts'}>Accounts</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts/transfers/all'}>Transfers</Link>
      </li>
    </ol>
  );
  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ACCOUNTS_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>New Transfer</h5>
                <p className={'mb-0'}>Add new transfer</p>
              </div>
              <div>
                <Link
                  to={'/accounts/transfers/new'}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add Transfer
                </Link>
              </div>
            </div>
          </div>
        </div>

        <FilterMaterialTable
          title={'All Transfers'}
          isLoading={isLoading}
          columns={tableHead}
          data={transferTransactions}
          tableContainerClass={'mt-4'}
          fetchMethod={getBankTransferTransactions}
          isCustomPagination
          options={{
            rowStyle: (rowData) => {
              return {
                fontSize: '14px',
                color: '#000'
              };
            }
          }}
        />
      </div>
    </Fragment>
  );
}

AllTransfers.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getBankTransferTransactions: PropTypes.func.isRequired,
  bankings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  bankings: state.bankings,
  auth: state.auth
});

export default connect(mapStateToProps, { getBankTransferTransactions })(AllTransfers);
