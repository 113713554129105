import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import { getExpensesReports } from '../../../actions/reports';
import moment from 'moment';
import { EXPENSE_REPORTS } from '../../../permissions';
import { getMonthName, numberWithCommas, REPORTS_SECTIONS } from '../../../utils';
import FilterTypeSelector from '../../common/FilterTypeSelector';
import YearSelector from '../../common/YearSelector';
import MonthSelector from '../../common/MonthSelector';
import FilterLayout from '../../layout/FilterLayout';
import MaterialTable from 'material-table';

function ExpenseReports({
  isLoading,
  getExpensesReports,
  auth: { permissions },
  reports: { expensesReports }
}) {
  const from_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const to_date = moment().format('YYYY-MM-DD');
  let history = useHistory();

  const initState = {
    type: 'daily',
    month: '',
    year: ''
  };
  const [showFilterLayout, setShowFilterLayout] = useState(false);
  const [showYearLayout, setShowYearLayout] = useState(false);
  const [searchExecuted, setSearchExecuted] = useState(false);
  const [formData, setFormData] = useState(initState);

  const { type, year, month } = formData;

  useEffect(() => {
    setShowFilterLayout(type === 'daily');
    setShowYearLayout(type === 'yearly');
    setSearchExecuted(false);
  }, [type]);

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === EXPENSE_REPORTS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getAllExpenseReports({ from_date, to_date });
  }, []);

  const getAllExpenseReports = ({ from_date, to_date }) => {
    setSearchExecuted(true);
    getExpensesReports({ from_date, to_date, type, month, year });
  };

  const handleReportsDetailsMonthly = (month, year) => {
    setFormData({
      ...formData,
      type: 'yearly',
      year,
      month
    });

    getExpensesReports({
      from_date,
      to_date,
      type: 'yearly',
      year,
      month
    });

    setSearchExecuted(true);
  };

  const handleReportsDetailsYearly = (year) => {
    setFormData({
      ...formData,
      type: 'yearly',
      year,
      month: ''
    });

    getExpensesReports({
      from_date,
      to_date,
      type: 'yearly',
      year,
      month: ''
    });

    setSearchExecuted(true);
  };

  const tableHead = [
    { field: 'date', title: 'Date' },
    { field: 'income_expanse_category.name', title: 'Name' },
    { field: 'amount', title: 'Amount', render: (rowData) => numberWithCommas(rowData.amount) }
  ];

  if (searchExecuted) {
    if (type === 'monthly' || (year && !month && type !== 'daily')) {
      tableHead[0] = {
        field: 'month',
        title: 'Month',
        render: (rowData) => (
          <span
            className={'font-weight-bold link-primary hover'}
            onClick={() =>
              handleReportsDetailsMonthly(rowData.month, year ? year : moment().format('YYYY'))
            }>
            {`${getMonthName(rowData.month)}-${year ? year : moment().format('YYYY')}`}
          </span>
        )
      };
    }

    if (type === 'yearly' && !month && !year) {
      tableHead[0] = {
        field: 'year',
        title: 'Year',
        render: (rowData) => (
          <span
            className={'font-weight-bold link-primary hover'}
            onClick={() => handleReportsDetailsYearly(rowData.year)}>
            {rowData.year}
          </span>
        )
      };
    }
  }

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/reports'}>Reports</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/reports/expense'}>Expenses Reports</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={REPORTS_SECTIONS} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div>
              <h5>Expense Reports</h5>
              <div className="row mt-4">
                <div className={showFilterLayout || showYearLayout ? 'col-md-3' : 'col-md-2'}>
                  <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between">
                      <FilterTypeSelector
                        formData={formData}
                        setFormData={setFormData}
                        type={type}
                      />
                    </div>
                  </div>
                </div>

                {!showFilterLayout && !showYearLayout && (
                  <div className="col-md-2 align-items-center d-flex">
                    <button
                      className="btn btn-primary btn-block"
                      onClick={() => getAllExpenseReports({ from_date, to_date })}>
                      Search
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {showYearLayout && (
          <div id="card-content" className={'mt-4'}>
            <div className="card-header">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between">
                      <YearSelector formData={formData} setFormData={setFormData} year={year} />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between">
                      <MonthSelector formData={formData} setFormData={setFormData} month={month} />
                    </div>
                  </div>
                </div>
                <div className="col-md-1 align-items-center d-flex">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={() => getAllExpenseReports({ from_date, to_date })}>
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showFilterLayout && <FilterLayout handleActions={getAllExpenseReports} />}

        {expensesReports && (
          <div id="card-content" className={'mt-4'}>
            <div className={'overflow-auto'}>
              <MaterialTable
                title={'Expense Reports'}
                columns={tableHead}
                data={expensesReports}
                isLoading={isLoading}
                totalCount={expensesReports.length}
                options={{
                  headerStyle: {
                    backgroundColor: '#F6F9FC',
                    color: '#000'
                  },
                  rowStyle: () => {
                    return {
                      fontSize: '14px',
                      color: '#000'
                    };
                  },
                  padding: 'default',
                  pageSize: 20,
                  actionsColumnIndex: -1,
                  pageSizeOptions: [10, 20, 25, 50],
                  showFirstLastPageButtons: true,
                  exportAllData: true,
                  exportFileName: `Expense Reports - ${moment(new Date()).format('LL')}`,
                  exportButton: true,
                  searchFieldAlignment: 'left',
                  searchFieldStyle: {
                    marginLeft: '3rem'
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

ExpenseReports.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getExpensesReports: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  reports: state.reports,
  auth: state.auth
});

export default connect(mapStateToProps, { getExpensesReports })(ExpenseReports);
