import { axios, base_url, headers } from '../api';

const qs = require('qs');

const addCoupon = async (data) => {
  return await axios.post(`${base_url}/coupons/new`, qs.stringify(data), {
    headers: headers()
  });
};

const getCoupons = async (data) => {
  return await axios.post(`${base_url}/coupons/`, qs.stringify(data), {
    headers: headers()
  });
};
const getCoupon = async (id) => {
  return await axios.get(`${base_url}/coupons/${id}`, {
    headers: headers()
  });
};

const updateCoupon = async (data) => {
  return await axios.put(`${base_url}/coupons/${data.id}`, qs.stringify(data), {
    headers: headers()
  });
};

const deleteCoupon = async (id) => {
  return await axios.delete(`${base_url}/coupons/${id}`, {
    headers: headers()
  });
};

export default {
  addCoupon,
  getCoupons,
  getCoupon,
  updateCoupon,
  deleteCoupon
};
