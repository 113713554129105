import { axios, base_url, headers } from '../api';

const sendSms = async (phone, message) => {
  try {
    const API_KEY = '01793314871.k9ZFSlxbuai9zSyXi7';
    const SENDER_ID = '8809604903051';
    const PHONE_NUMBER = '88' + phone;
    const MESSAGE = encodeURIComponent(message);
    await axios.post(
      `${base_url}/sendsms?api_key=${API_KEY}&type=text&phone=${PHONE_NUMBER}&senderid=${SENDER_ID}&message=${MESSAGE}`,
      {},
      {
        headers: headers()
      }
    );
  } catch (e) {
    console.log(e);
  }
};

export default {
  sendSms
};
