import { ACCOUNTS, LOADING_FALSE, LOADING } from './types';
import { returnErrors } from './messages';
import API from '../api';
import Swal from 'sweetalert2';

export const getAccounts = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.accounts.getAccounts();
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: ACCOUNTS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addAccount = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.accounts.postAccount(data);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully created an user.',
      showConfirmButton: false,
      timer: 1500
    });

    window.history.back();
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};
