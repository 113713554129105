import { COUPONS, COUPON } from '../actions/types';

const initialState = {
  coupon: null,
  coupons: null
};

export default function (state = initialState, action) {
  const payload = action.payload;

  switch (action.type) {
    case COUPON:
      return {
        ...state,
        coupon: payload
      };
    case COUPONS:
      return {
        ...state,
        coupons: payload
      };

    default:
      return state;
  }
}
