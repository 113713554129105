import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllSupplierPayment } from '../../../actions/suppliers';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import { SUPPLIER_SECTIONS } from '../../../utils';
import FilterMaterialTable from '../../common/FilterMaterialTable';

function AllSupplierPayments({
  isLoading,
  getAllSupplierPayment,
  suppliers: { allSupplierPayments }
}) {
  const history = useHistory();

  const handleSaleDetails = (id) => {
    history.push({
      pathname: '/sale/details',
      search: `?id=${id}`
    });
  };

  const handleSupplierDetails = (id) => {
    history.push({
      pathname: '/suppliers/details',
      search: `?id=${id}`
    });
  };

  const tableHead = [
    { field: 'date', title: 'Date' },
    {
      field: 'saleOrderId',
      title: 'Inv. No',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleSaleDetails(rowData.saleOrderId)}
        >{`INV-${rowData.saleOrderId}`}</span>
      )
    },
    {
      field: 'supplier.name',
      title: 'Supplier Name',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleSupplierDetails(rowData.supplier.id)}
        >
          {rowData.supplier.name}
        </span>
      )
    },
    { field: 'supplier.phone', title: 'Supplier Phone' },
    { field: 'bank_tran.bank_account.account_name', title: 'Account' },
    { field: 'description', title: 'Description' },
    { field: 'reference', title: 'Reference' },
    { field: 'amount', title: 'Amount (৳)' }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/suppliers'}>Supplier</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/suppliers/payments'}>Payments</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SUPPLIER_SECTIONS} />

      <div className="container-fluid">
        {/*<div id="card-content">*/}
        {/*    <div className="card-header">*/}
        {/*        <div className="d-flex justify-content-between align-items-center">*/}
        {/*            <div>*/}
        {/*                <h5>All Supplier Payments</h5>*/}
        {/*                <p className={"mb-0"}>Supplier payments of all the invoices</p>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        <FilterMaterialTable
          title={'All Supplier Payments'}
          isLoading={isLoading}
          columns={tableHead}
          data={allSupplierPayments}
          tableContainerClass={'mt-4'}
          fetchMethod={getAllSupplierPayment}
          isCustomPagination
          options={{
            rowStyle: (rowData) => {
              return {
                fontSize: '14px',
                color: '#000'
              };
            }
          }}
        />
      </div>
    </Fragment>
  );
}

AllSupplierPayments.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  suppliers: PropTypes.object.isRequired,
  getAllSupplierPayment: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  suppliers: state.suppliers
});

export default connect(mapStateToProps, { getAllSupplierPayment })(AllSupplierPayments);
