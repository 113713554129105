import React, { Component, Fragment } from 'react';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getToken } from '../../actions/auth';
import Swal from 'sweetalert2';

class Alerts extends Component {
  static propTypes = {
    error: PropTypes.object.isRequired,
    getToken: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { error, alert, message } = this.props;
    if (error !== prevProps.error) {
      if (error.status === 429) {
        Swal.fire(error.msg.message, 'Please try again later', 'error').then();
        return;
      }

      if (error.status === 403) {
        this.props.getToken();
        Swal.fire('Your session expired!', 'Please login again.', 'error').then();
        return;
      }
      if (error.msg.message) Swal.fire(error.msg.message, 'Please try again', 'error').then();
    }

    if (message !== prevProps.message) {
      if (message.success) Swal.fire(message.success, '', 'success').then();
    }
  }

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages
});

export default connect(mapStateToProps, { getToken })(withAlert()(Alerts));
