import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import UpdateProduct from './UpdateProduct';
import ProductTransaction from './ProductTransaction';
import ProductStatus from './ProductStatus';
import { MODIFY_PRODUCTS } from '../../../../permissions';
import { connect } from 'react-redux';
import { INVENTORY_SECTIONS } from '../../../../utils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff'
  }
}));

function ProductDetails({ isLoading, location, auth: { permissions } }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/inventory'}>Inventory</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/inventory/productDetails'}>Product Details</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={INVENTORY_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      {permissions.find((item) => item.name === MODIFY_PRODUCTS) ? (
        <div id={'card-content'} className={`${classes.root} mt-4 pt-0`}>
          <div className="card-header">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Product Details" {...a11yProps(0)} />
              <Tab label="Transaction" {...a11yProps(1)} />
              <Tab label="Status" {...a11yProps(2)} />
            </Tabs>
          </div>
          <TabPanel value={value} index={0}>
            <UpdateProduct location={location} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ProductTransaction location={location} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ProductStatus location={location} />
          </TabPanel>
        </div>
      ) : (
        <div id={'card-content'} className={`${classes.root} mt-4 pt-0`}>
          <div className="card-header">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Transaction" {...a11yProps(0)} />
              <Tab label="Status" {...a11yProps(1)} />
            </Tabs>
          </div>
          <TabPanel value={value} index={0}>
            <ProductTransaction location={location} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ProductStatus location={location} />
          </TabPanel>
        </div>
      )}
    </Fragment>
  );
}

ProductDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  auth: state.auth
});

export default connect(mapStateToProps, {})(ProductDetails);
