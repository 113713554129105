import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import moment from 'moment';
import { getProductStockReports, getStockReports } from '../../../../actions/stocks';
import { Pagination } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { TableCell } from '@material-ui/core';

function ProductTransaction({
  isLoading,
  getProductStockReports,
  stocks: { productStockReports },
  location
}) {
  const history = useHistory();
  const [productId, setProductId] = useState('');
  const [range, setRange] = useState({
    from_date: moment(Date()).subtract(30, 'days').format('YYYY-MM-DD'),
    to_date: moment(Date()).format('YYYY-MM-DD')
  });
  const { from_date, to_date } = range;

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      setProductId(id);
      getProductStockReports({ id, from_date, to_date });
    }
  }, []);

  const handlePurchaseDetails = (id) => {
    history.push({
      pathname: '/purchase/details',
      search: `?id=${id}`
    });
  };

  const handleSaleDetails = (id) => {
    history.push({
      pathname: '/sale/details',
      search: `?id=${id}`
    });
  };

  const tableHead = [
    {
      field: 'trans_type',
      title: 'Trans Type',
      render: (rowData) =>
        rowData.saleOrderId ? 'Sale' : rowData.purchaseOrderId ? 'Purchase' : ''
    },
    { field: 'date', title: 'Date' },
    { field: 'branch.name', title: 'Branch' },
    {
      field: 'trans_type',
      title: 'Quantity In',
      render: (rowData) => (rowData.trans_type === 'stock_in' ? rowData.qty : '-')
    },
    {
      field: 'trans_type',
      title: 'Quantity Out',
      render: (rowData) => (rowData.trans_type === 'stock_out' ? rowData.qty : '-')
    },
    {
      field: 'stockInHand',
      title: 'Quantity On Hand'
    }
  ];

  return (
    <Fragment>
      <div className="container-fluid p-0">
        <div id="card-content" className={'shadow-none'}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Stock Transactions</h5>
                <p className={'mb-0'}>All stock transactions</p>
              </div>
            </div>
          </div>

          {productStockReports && (
            <div className="card-body d-grid">
              <div className={'overflow-auto'}>
                <MaterialTable
                  title={'Transactions'}
                  columns={tableHead}
                  data={productStockReports?.paginatedData}
                  isLoading={isLoading}
                  totalCount={productStockReports?.total}
                  options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                      backgroundColor: '#F6F9FC',
                      color: '#000'
                    },
                    rowStyle: (rowData) => {
                      return {
                        fontSize: '14px',
                        color: '#000'
                      };
                    },
                    padding: 'dense',
                    pageSize: 20,
                    pageSizeOptions: [10, 20, 25, 50],
                    showFirstLastPageButtons: true,
                    exportAllData: true,
                    exportFileName: `Stock report - ${moment(new Date()).format('LL')}`
                  }}
                  onRowClick={(event, rowData) =>
                    rowData.saleOrderId
                      ? handleSaleDetails(rowData.saleOrderId)
                      : rowData.purchaseOrderId
                      ? handlePurchaseDetails(rowData.purchaseOrderId)
                      : ''
                  }
                  components={{
                    Pagination: (props) => (
                      <TableCell className="text-center d-flex justify-content-end pagination mt-2">
                        <Pagination
                          color={'primary'}
                          count={productStockReports?.lastPage}
                          page={productStockReports?.currentPage}
                          siblingCount={2}
                          onChange={(e, value) =>
                            getStockReports({
                              id: productId,
                              from_date,
                              to_date,
                              page: value
                            })
                          }
                        />
                      </TableCell>
                    )
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

ProductTransaction.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getProductStockReports: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  stocks: state.stocks
});

export default connect(mapStateToProps, { getProductStockReports })(ProductTransaction);
