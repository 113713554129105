import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

function MonthSelector({ setFormData, formData, month }) {
  const onMonthSelected = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      month: value ? value.value : ''
    });
  };

  const MONTHS = [
    { name: 'January', value: '1' },
    { name: 'February', value: '2' },
    { name: 'March', value: '3' },
    { name: 'April', value: '4' },
    { name: 'May', value: '5' },
    { name: 'June', value: '6' },
    { name: 'July', value: '7' },
    { name: 'August', value: '8' },
    { name: 'September', value: '9' },
    { name: 'October', value: '10' },
    { name: 'November', value: '11' },
    { name: 'December', value: '12' }
  ];

  const value = MONTHS.find((item) => item.value === month.toString());

  return (
    <Autocomplete
      value={month ? value : null}
      id={'select-months'}
      onChange={(event, value) => onMonthSelected(event, value)}
      options={MONTHS}
      renderOption={(option) => option.name}
      getOptionLabel={(option) => option.name}
      size={'small'}
      renderInput={(params) => (
        <TextField {...params} placeholder={'Select a month'} required variant="outlined" />
      )}
    />
  );
}

export default MonthSelector;
