import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';

function YearSelector({ setFormData, formData, year }) {
  const onYearSelected = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      year: value || ''
    });
  };

  const currentYear = moment().year();
  const YEARS = [];

  for (let i = 0; i < 5; i++) {
    YEARS.push((currentYear - i).toString());
  }

  const value = YEARS.find(item => item === year.toString());

  return (
    <Autocomplete
      value={year ? value : null}
      id={'select-year'}
      onChange={(event, value) => onYearSelected(event, value)}
      options={YEARS}
      size={'small'}
      renderInput={(params) => (
        <TextField {...params} placeholder={'Select a year'} required variant="outlined" />
      )}
    />
  );
}

export default YearSelector;
