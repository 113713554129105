import {
  ALL_CUSTOMER_PAYMENTS,
  CUSTOMER,
  CUSTOMER_PAYMENTS,
  CUSTOMERS,
  LOADING,
  LOADING_FALSE
} from './types';
import { returnErrors } from './messages';
import API from '../api';
import Swal from 'sweetalert2';
import { GENERAL_CUSTOMER } from '../const';

export const getCustomers =
  ({ purchase = false, page, size, search, isExportData, loading= true }) =>
  async (dispatch) => {
    if (loading) dispatch({ type: LOADING });
    try {
      const res = await API.customers.getCustomers({ page, size, search });
      dispatch({ type: LOADING_FALSE });

      if (purchase) {
        res.data.data.paginatedData.unshift({
          id: GENERAL_CUSTOMER,
          name: GENERAL_CUSTOMER,
          email: '',
          phone: '',
          address: '',
          due: 0.0
        });
      }

      if (isExportData) return res.data.data;

      dispatch({
        type: CUSTOMERS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const addCustomer = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.customers.addCustomer(data);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully created a customer.',
      showConfirmButton: false,
      timer: 1500
    });

    return res.data.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateCustomer = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.customers.updateCustomer(data);

    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully updated a customer.',
      showConfirmButton: false,
      timer: 1500
    });

    window.history.back();
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addCustomerPayment = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.customers.addCustomerPayments(data);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully created a customer payment.',
      showConfirmButton: false,
      timer: 1500
    });

    window.history.back();
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getCustomerPayment =
  ({ id, from_date, to_date, size, isExportData }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const res = await API.customers.getCustomerPayments({ id, from_date, to_date, size });
      dispatch({ type: LOADING_FALSE });
      if (isExportData) return res.data.data;
      dispatch({
        type: CUSTOMER_PAYMENTS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getAllCustomerPayments =
  ({ from_date, to_date, size, search, page, isExportData }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const res = await API.customers.getAllCustomerPayments({
        from_date,
        to_date,
        size,
        search,
        page
      });
      dispatch({ type: LOADING_FALSE });
      if (isExportData) return res.data.data;
      dispatch({
        type: ALL_CUSTOMER_PAYMENTS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getCustomer = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.customers.getCustomer(id);
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: CUSTOMER,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};
