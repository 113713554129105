import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStockReports, setInventorySearch } from '../../../../actions/stocks';
import { useHistory } from 'react-router-dom';
import FilterMaterialTable from '../../../common/FilterMaterialTable';

function StockOutReport({
  isLoading,
  setInventorySearch,
  getStockReports,
  stocks: { reports, searchText }
}) {
  const history = useHistory();

  const tableHead = [
    { field: 'date', title: 'Date' },
    { field: 'item.code', title: 'Code' },
    { field: 'item.name', title: 'Name' },
    { field: 'item.price', title: 'Unit Price' },
    { field: 'user.username', title: 'Trans By' },
    { field: 'branch.name', title: 'Branch' },
    { field: 'qty', title: 'Qty' }
  ];

  const handlePurchaseDetails = (id) => {
    history.push({
      pathname: '/purchase/details',
      search: `?id=${id}`
    });
  };

  const handleSaleDetails = (id) => {
    history.push({
      pathname: '/sale/details',
      search: `?id=${id}`
    });
  };

  return (
    <Fragment>
      <div className="container-fluid p-0">
        <div id="card-content" className={'shadow-none pt-0'}>
          {/*<div className="card-header">*/}
          {/*  <div className="d-flex justify-content-between align-items-center">*/}
          {/*    <div>*/}
          {/*      <h5>Stock Out Reports</h5>*/}
          {/*      <p className={'mb-0'}>All stock out reports</p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <FilterMaterialTable
            title={'Stock Out Reports'}
            isLoading={isLoading}
            columns={tableHead}
            data={reports}
            tableContainerClass={'card-body mt-3'}
            fetchMethod={getStockReports}
            search={searchText}
            setSearch={setInventorySearch}
            type={'stock_out'}
            isCustomPagination
            allTime
            options={{
              rowStyle: (rowData) => {
                return {
                  fontSize: '14px',
                  color: '#000'
                };
              }
            }}
            onRowClick={(event, rowData) =>
              rowData.saleOrderId
                ? handleSaleDetails(rowData.saleOrderId)
                : rowData.purchaseOrderId
                ? handlePurchaseDetails(rowData.purchaseOrderId)
                : ''
            }
          />
        </div>
      </div>
    </Fragment>
  );
}

StockOutReport.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getStockReports: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  stocks: state.stocks
});

export default connect(mapStateToProps, { getStockReports, setInventorySearch })(StockOutReport);
