import React, { Fragment, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GENERAL_CUSTOMER } from '../../const';
import { getCustomer } from '../../actions/customers';
import { connect } from 'react-redux';

function CustomerSelector({
  setFormData,
  formData,
  customers,
  setGeneralCustomerSelected,
  customerId = null,
  getCustomers,
  getCustomer,
  customer,
  update = false
}) {
  const onCustomerSelected = (e, value) => {
    e.preventDefault();

    if (value) {
      if (value.id === GENERAL_CUSTOMER) {
        setGeneralCustomerSelected(true);
      } else {
        setGeneralCustomerSelected(false);
      }

      setFormData({
        ...formData,
        customerId: value.id,
        customerPhone: value.phone,
        customerPreDue: value.due
      });
    }
  };

  useEffect(() => {
    if (customerId) {
      getCustomer(customerId);
    }
  }, [customerId]);

  return (
    <Fragment>
      {update ? (
        <Autocomplete
          onChange={(event, value) => onCustomerSelected(event, value)}
          options={customers}
          renderOption={(option) => `${option.name} - ${option.phone}`}
          getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            return `${option.name} - ${option.phone}`;
          }}
          onInputChange={(event, value, reason) => {
            if (reason !== 'reset' && value !== '') {
              getCustomers(value);
            }
          }}
          value={customer}
          renderInput={(params) => (
            <TextField
              {...params}
              size={'small'}
              placeholder={'Select Customer'}
              required
              variant="outlined"
            />
          )}
        />
      ) : (
        <Autocomplete
          onChange={(event, value) => onCustomerSelected(event, value)}
          options={customers}
          renderOption={(option) => `${option.name} - ${option.phone}`}
          getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            return `${option.name} - ${option.phone}`;
          }}
          onInputChange={(event, value, reason) => {
            if (reason !== 'reset' && value !== '') {
              getCustomers(value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size={'small'}
              placeholder={'Select Customer'}
              required
              variant="outlined"
            />
          )}
        />
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  customer: state.customers.customer
});

export default connect(mapStateToProps, { getCustomer })(CustomerSelector);
