import React, { Fragment, useState } from 'react';
import moment from 'moment';

function FilterLayout({ handleActions, allTime, setSearch }) {
  const [range, setRange] = useState({
    from_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD')
  });
  const { from_date, to_date } = range;
  const onChange = (e) => setRange({ ...range, [e.target.name]: e.target.value });

  const handleDateSearch = (e) => {
    e.preventDefault();
    handleActions({ from_date, to_date });
    setSearch && setSearch('');
  };

  const handleToday = (e) => {
    e.preventDefault();

    const today = moment().format('YYYY-MM-DD');
    handleActions({ from_date: today, to_date: today });
    setSearch && setSearch('');
  };

  const handleYesterday = (e) => {
    e.preventDefault();

    const yesterday = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD');
    handleActions({ from_date: yesterday, to_date: yesterday });
    setSearch && setSearch('');
  };

  const handleThisMonth = (e) => {
    e.preventDefault();

    let firstDayOfThisMonth = moment().startOf('month').format('YYYY-MM-DD');
    let LastDayOfThisMonth = moment().endOf('month').format('YYYY-MM-DD');
    handleActions({ from_date: firstDayOfThisMonth, to_date: LastDayOfThisMonth });
    setSearch && setSearch('');
  };

  const handleAllTime = (e) => {
    e.preventDefault();

    handleActions({ all: 'true' });
    setSearch && setSearch('');
  };

  return (
    <Fragment>
      <div id="card-content" className={'mt-3'}>
        <div className="card-header">
          <div className="row d-flex align-items-center">
            <div className={allTime ? 'col-md-6' : 'col-md-7'}>
              <div className="row d-flex align-items-center">
                <div className="col-md-5">
                  <div className="row d-flex align-items-center">
                    <div className="col-sm-2 pr-0">
                      <label className={'m-md-0 mb-1'} htmlFor={'from'}>
                        From
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <div className="input-group">
                        <input
                          type="date"
                          id={'from_date'}
                          name={'from_date'}
                          value={from_date}
                          className={'form-control date-picker'}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="row d-flex align-items-center">
                    <div className="col-sm-2 pr-0">
                      <label className={'m-md-0 mb-1'} htmlFor={'to'}>
                        To
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <div className="input-group">
                        <input
                          type="date"
                          name={'to_date'}
                          value={to_date}
                          id={'to_date'}
                          className={'form-control date-picker'}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <button
                    className="btn btn-primary btn-block search-button"
                    onClick={handleDateSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>

            <div className={allTime ? 'col-md-6' : 'col-md-5'}>
              {allTime ? (
                <div className="row">
                  <div className="col-sm-3 mt-2 mt-md-0">
                    <button className="btn btn-outline-primary btn-block" onClick={handleToday}>
                      Today
                    </button>
                  </div>
                  <div className="col-sm-3 mt-2 mt-md-0">
                    <button className="btn btn-outline-primary btn-block" onClick={handleYesterday}>
                      Yesterday
                    </button>
                  </div>
                  <div className="col-sm-3 mt-2 mt-md-0">
                    <button className="btn btn-outline-primary btn-block" onClick={handleThisMonth}>
                      This Month
                    </button>
                  </div>
                  <div className="col-sm-3 mt-2 mt-md-0">
                    <button className="btn btn-outline-primary btn-block" onClick={handleAllTime}>
                      All Time
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-sm-3 mt-2 mt-md-0">
                    <button className="btn btn-outline-primary btn-block" onClick={handleToday}>
                      Today
                    </button>
                  </div>
                  <div className="col-sm-4 mt-2 mt-md-0">
                    <button className="btn btn-outline-primary btn-block" onClick={handleYesterday}>
                      Yesterday
                    </button>
                  </div>
                  <div className="col-sm-4 mt-2 mt-md-0">
                    <button className="btn btn-outline-primary btn-block" onClick={handleThisMonth}>
                      This Month
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FilterLayout;
