import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import {
  getExpensesReports,
  getIncomeExpenseReportsYearly,
  getIncomeReports,
  getPurchaseReports,
  getSaleReports,
  getSalesHistory
} from '../../../actions/reports';
import { getMonthName, REPORTS_SECTIONS } from '../../../utils';
import moment from 'moment';
import {
  EXPENSE_REPORTS,
  INCOME_EXPENSE_REPORTS,
  INCOME_REPORTS,
  isNotEmployeeHidden,
  PURCHASE_REPORTS,
  SALES_HISTORY_REPORTS,
  SALES_REPORTS
} from '../../../permissions';

function Reports({
  isLoading,
  getSaleReports,
  getPurchaseReports,
  getSalesHistory,
  getExpensesReports,
  getIncomeReports,
  getIncomeExpenseReportsYearly,
  auth: { permissions },
  reports: {
    saleReports,
    purchaseReports,
    salesHistory,
    expensesReports,
    incomeReports,
    incomeExpenseReportsYearly
  }
}) {
  const history = useHistory();
  const from_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const to_date = moment().format('YYYY-MM-DD');

  useEffect(() => {
    if (permissions.length > 0) {
      if (!isNotEmployeeHidden(permissions)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getSaleReports({ size: 5, from_date, to_date, type: 'daily' });
    getPurchaseReports({ size: 5, from_date, to_date, type: 'daily' });
    getSalesHistory({ size: 5, from_date, to_date });
    getExpensesReports({ type: 'monthly' });
    getIncomeReports({ year: moment().year() });
    getIncomeExpenseReportsYearly({ year: moment().year() });
  }, []);

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/reports'}>Reports</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={REPORTS_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div className="row mt-4">
          {permissions.find((item) => item.name === SALES_REPORTS) && (
            <div className="col-md-7 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Sales Reports</h5>
                      <p>Recent sales reports</p>
                    </div>
                    <div>
                      <Link
                        to={'/reports/sales'}
                        className="btn btn-primary p-2 rounded text-decoration-none"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Total Invoices</TableCell>
                          <TableCell>Total Qty</TableCell>
                          <TableCell>Total Sales (৳)</TableCell>
                          <TableCell>Total Cost (৳)</TableCell>
                          <TableCell>Profit (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {saleReports &&
                          saleReports.paginatedData.map((row, i) => (
                            <TableRow key={i}>
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.totalInvoices}</TableCell>
                              <TableCell>{row.itemsQty}</TableCell>
                              <TableCell>{row.salesValue}</TableCell>
                              <TableCell>{row.costValue}</TableCell>
                              <TableCell>{row.profit}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === PURCHASE_REPORTS) && (
            <div className="col-md-5 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Purchase Reports</h5>
                      <p>Recent purchase reports</p>
                    </div>
                    <div>
                      <Link
                        to={'/reports/purchase'}
                        className="btn btn-primary p-2 rounded text-decoration-none"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Total Invoices</TableCell>
                          <TableCell>Total Qty</TableCell>
                          <TableCell>Total Cost (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {purchaseReports &&
                          purchaseReports.paginatedData.map((row, i) => (
                            <TableRow key={i}>
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.totalInvoices}</TableCell>
                              <TableCell>{row.itemsQty}</TableCell>
                              <TableCell>{row.costValue}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === SALES_HISTORY_REPORTS) && (
            <div className="col-md-7 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Sales History Reports</h5>
                      <p>Recent sales history reports</p>
                    </div>
                    <div>
                      <Link
                        to={'/reports/sales-history'}
                        className="btn btn-primary p-2 rounded text-decoration-none"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Order No</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Sales Value (৳)</TableCell>
                          <TableCell>Cost Value (৳)</TableCell>
                          <TableCell>Profit (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {salesHistory &&
                          salesHistory.paginatedData.map((row, i) => (
                            <TableRow key={i}>
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{`INV-${row.id}`}</TableCell>
                              <TableCell>{row.customer.name}</TableCell>
                              <TableCell>{row.total}</TableCell>
                              <TableCell>{row.costValue}</TableCell>
                              <TableCell>{row.profit}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === EXPENSE_REPORTS) && (
            <div className="col-md-5 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Expense Reports</h5>
                      <p>This year expenses</p>
                    </div>
                    <div>
                      <Link
                        to={'/reports/expense'}
                        className="btn btn-primary p-2 rounded text-decoration-none"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Month</TableCell>
                          <TableCell>Amount (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {expensesReports &&
                          expensesReports.slice(0, 5).map((row, i) => (
                            <TableRow key={i}>
                              <TableCell>{row['income_expanse_category.name']}</TableCell>
                              <TableCell>{row.month ? getMonthName(row.month) : ''}</TableCell>
                              <TableCell>{row.amount}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === INCOME_REPORTS) && (
            <div className="col-md-5 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Income Reports</h5>
                      <p>This year incomes</p>
                    </div>
                    <div>
                      <Link
                        to={'/reports/income'}
                        className="btn btn-primary p-2 rounded text-decoration-none"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Month</TableCell>
                          <TableCell>Amount (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {incomeReports &&
                          incomeReports.map((row, i) => (
                            <TableRow key={i}>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>
                                {row.months[0] ? getMonthName(row.months[0].month) : ''}
                              </TableCell>
                              <TableCell>{row.months[0] ? row.months[0].amount : ''}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}

          {permissions.find((item) => item.name === INCOME_EXPENSE_REPORTS) && (
            <div className="col-md-7 mb-4">
              <div id="card-content" className={'h-100'}>
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5>Income Vs Expense Reports</h5>
                      <p>This year income expense reports</p>
                    </div>
                    <div>
                      <Link
                        to={'/reports/income-expense'}
                        className="btn btn-primary p-2 rounded text-decoration-none"
                      >
                        Details
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Month</TableCell>
                          <TableCell>Total Income (৳)</TableCell>
                          <TableCell>Total Expense (৳)</TableCell>
                          <TableCell>Profit (৳)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {incomeExpenseReportsYearly &&
                          incomeExpenseReportsYearly
                            .sort((a, b) => b.month - a.month)
                            .filter((item) => item.month <= moment().month() + 1)
                            .slice(0, 5)
                            .map((row, i) => (
                              <TableRow key={i}>
                                <TableCell>{getMonthName(row.month)}</TableCell>
                                <TableCell>{row.totalIncome}</TableCell>
                                <TableCell>{row.totalExpense}</TableCell>
                                <TableCell>{row.profit}</TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

Reports.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getSaleReports: PropTypes.func.isRequired,
  getPurchaseReports: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  reports: state.reports,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getSaleReports,
  getPurchaseReports,
  getSalesHistory,
  getExpensesReports,
  getIncomeReports,
  getIncomeExpenseReportsYearly
})(Reports);
