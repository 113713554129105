import {
  DAY_PURCHASE_REPORTS,
  DAY_SALE_REPORTS,
  EXPENSES_REPORTS,
  INCOME_EXPENSE_REPORTS,
  INCOME_EXPENSE_REPORTS_YEARLY,
  INCOME_REPORTS,
  LOADING,
  LOADING_FALSE,
  PURCHASE_REPORTS,
  SALE_REPORTS,
  SALES_HISTORY
} from './types';
import API from '../api';
import { returnErrors } from './messages';

export const getSaleReports =
  ({ page, size, from_date, to_date, customerId, branchId, productId, type, month, year }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.reports.getSaleReports({
        page,
        size,
        from_date,
        to_date,
        customerId,
        branchId,
        productId,
        type,
        month,
        year
      });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: SALE_REPORTS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getSaleReportsByDate = (date) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.reports.getSaleReportsByDate(date);
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: DAY_SALE_REPORTS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getPurchaseReports =
  ({ page, size, from_date, to_date, supplierId, branchId, productId, type, month, year }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.reports.getPurchaseReports({
        page,
        size,
        from_date,
        to_date,
        supplierId,
        branchId,
        productId,
        type,
        month,
        year
      });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: PURCHASE_REPORTS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getPurchaseReportsByDate = (date) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.reports.getPurchaseReportsByDate(date);
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: DAY_PURCHASE_REPORTS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getSalesHistory =
  ({ page, size, from_date, to_date, customerId, branchId, search, isExportData }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.reports.getSalesHistory({
        page,
        size,
        from_date,
        to_date,
        customerId,
        branchId,
        search
      });
      dispatch({ type: LOADING_FALSE });
      if (isExportData) return res.data.data;
      dispatch({
        type: SALES_HISTORY,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getExpensesReports =
  ({ from_date, to_date, type, month, year }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.reports.getExpenseReports({ from_date, to_date, type, month, year });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: EXPENSES_REPORTS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getIncomeReports =
  ({ year }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.reports.getIncomeReports({ year });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: INCOME_REPORTS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getIncomeExpenseReportsYearly =
  ({ year }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.reports.getIncomeExpenseReportsYearly({ year });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: INCOME_EXPENSE_REPORTS_YEARLY,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getIncomeExpenseReports =
  ({ from_date, to_date }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.reports.getIncomeExpenseReports({ from_date, to_date });
      dispatch({ type: LOADING_FALSE });
      dispatch({
        type: INCOME_EXPENSE_REPORTS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };
