import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import { getPendingSales } from '../../../actions/sale';
import { isNotPackagingHidden } from '../../../permissions';
import FilterMaterialTable from '../../common/FilterMaterialTable';

function Packaging({ isLoading, getPendingSales, sale: { pendingSales }, auth: { permissions } }) {
  const history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!isNotPackagingHidden(permissions)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const handlePackagingDetails = (id, isExchange = false) => {
    history.push({
      pathname: '/packaging/details',
      search: `?id=${id}&&is_exchange=${isExchange}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/packaging'}>Packaging</Link>
      </li>
    </ol>
  );

  const tableHead = [
    {
      field: 'id',
      title: 'Inv. No',
      render: (rowData) => (rowData.saleOrderId ? rowData.saleOrderId : rowData.id)
    },
    {
      field: 'id',
      title: 'Exchange Inv.',
      render: (rowData) => (rowData.saleOrderId ? rowData.id : '-')
    },
    { field: 'date', title: 'Date' },
    { field: 'customer.name', title: 'Customer Name' },
    { field: 'customer.phone', title: 'Phone' },
    { field: 'total', title: 'Total (৳)' },
    {
      field: 'status.name',
      title: 'Status',
      render: (rowData) => (
        <span className={rowData.status.name.toLowerCase()}>{rowData.status.name}</span>
      )
    }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} />

      <div className="container-fluid mt-4">
        <FilterMaterialTable
          title={'Pending sales'}
          isLoading={isLoading}
          columns={tableHead}
          data={pendingSales}
          tableContainerClass={'mt-4'}
          fetchMethod={getPendingSales}
          isCustomPagination
          options={{
            rowStyle: (rowData) => {
              return {
                fontSize: '14px',
                color: '#000'
              };
            }
          }}
          onRowClick={(event, rowData) => handlePackagingDetails(rowData.id, !!rowData.saleOrderId)}
          actions={[
            (rowData) => ({
              icon: 'visibility_on',
              onClick: () => handlePackagingDetails(rowData.id, !!rowData.saleOrderId),
              isFreeAction: true,
              tooltip: 'Packaging details'
            })
          ]}
        />
      </div>
    </Fragment>
  );
}

Packaging.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getPendingSales: PropTypes.func.isRequired,
  sale: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  sale: state.sale,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getPendingSales
})(Packaging);
