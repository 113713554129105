import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import moment from 'moment';
import { MANAGE_PACKAGING, SALES_EXCHANGE } from '../../../permissions';
import { addExchangeOrderPayment, getSaleExchanges } from '../../../actions/sale';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getBankAccounts } from '../../../actions/bankings';
import { getIncomeCategories, getPaymentMethods } from '../../../actions/system';
import { SALE_SECTIONS } from '../../../utils';
import NumberInput from '../../common/NumberInput';
import FilterMaterialTable from '../../common/FilterMaterialTable';

function AllExchange({
  isLoading,
  getSaleExchanges,
  sale: { saleExchanges },
  addExchangeOrderPayment,
  getPaymentMethods,
  getBankAccounts,
  getIncomeCategories,

  bankings: { bankAccounts },
  auth: { permissions },
  system: { paymentMethods, incomeCategories }
}) {
  const history = useHistory();
  const from_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const to_date = moment().format('YYYY-MM-DD');

  const [inputFocus, setInputFocus] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [salePaymentForm, setSalePaymentForm] = useState({
    id: '',
    bankAccountId: '',
    paymentMethodId: '',
    categoryId: '',
    amount: '',
    date: moment().format('YYYY-MM-DD'),
    description: '',
    reference: '',
    total: ''
  });

  const {
    id,
    bankAccountId,
    paymentMethodId,
    categoryId,
    amount,
    date,
    description,
    reference,
    total
  } = salePaymentForm;

  const onChange = (e) =>
    setSalePaymentForm({ ...salePaymentForm, [e.target.name]: e.target.value });

  useEffect(() => {
    getPaymentMethods();
    getBankAccounts();
    getIncomeCategories();
  }, []);

  useEffect(() => {
    if (bankAccounts.length > 0 && paymentMethods.length > 0 && incomeCategories) {
      setSalePaymentForm({
        ...salePaymentForm,
        bankAccountId: bankAccounts.find((item) => item.default_sale === true).id,
        paymentMethodId: paymentMethods.find((item) => item.name === 'Cash').id,
        categoryId: incomeCategories.find((item) => item.name === 'Sales').id
      });
    }
  }, [bankAccounts, incomeCategories, incomeCategories]);

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === SALES_EXCHANGE)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const openDialog = (data) => {
    setSalePaymentForm({
      ...salePaymentForm,
      id: data.id,
      amount: data.due,
      description: `Payment for SEI-${data.id}`,
      reference: `INV-${data.saleOrderId}`,
      total: data.total
    });
    setDialogOpen(true);
    setInputFocus(false);
  };

  const hideDialog = () => {
    setDialogOpen(false);
    setInputFocus(true);
  };

  const handleSaleDetails = (id) => {
    history.push({
      pathname: '/sale/details',
      search: `?id=${id}`
    });
  };

  const handleCustomerDetails = (id) => {
    history.push({
      pathname: '/customers/details',
      search: `?id=${id}`
    });
  };

  const handlePrintSaleExchange = (id) => {
    history.push({
      pathname: '/sale/exchange/print',
      search: `?id=${id}`
    });
  };

  const moveToPackageDetails = (id) => {
    history.push({
      pathname: '/packaging/details',
      search: `?id=${id}&&is_exchange=true`
    });
  };

  const onBankAccountSelected = (e, value) => {
    e.preventDefault();

    setSalePaymentForm({
      ...salePaymentForm,
      bankAccountId: value ? value.id : ''
    });
  };

  const onPaymentMethodSelected = (e, value) => {
    e.preventDefault();

    setSalePaymentForm({
      ...salePaymentForm,
      paymentMethodId: value ? value.id : ''
    });
  };

  const onCategorySelected = (e, value) => {
    e.preventDefault();

    setSalePaymentForm({
      ...salePaymentForm,
      categoryId: value ? value.id : ''
    });
  };

  const submitPayNow = (e) => {
    e.preventDefault();

    addExchangeOrderPayment(salePaymentForm, from_date, to_date);
    hideDialog();
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale'}>Sale</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale/exchange/all'}>Exchanges</Link>
      </li>
    </ol>
  );

  const tableHead = [
    { field: 'id', title: 'Inv. No', render: (rowData) => `SEI-${rowData.id}` },
    {
      field: 'saleOrderId',
      title: 'Sale',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleSaleDetails(rowData.saleOrderId)}
        >{`INV-${rowData.saleOrderId}`}</span>
      )
    },
    { field: 'date', title: 'Date' },
    {
      field: 'customer.name',
      title: 'Customer',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleCustomerDetails(rowData.customer.id)}
        >
          {rowData.customer.name}
        </span>
      )
    },
    { field: 'discount', title: 'Discount (%)', type: 'numeric' },
    { field: 'total', title: 'Total (৳)', type: 'numeric' },
    { field: 'returned', title: 'Sale Returns (৳)', type: 'numeric' },
    { field: 'paid', title: 'Paid (৳)', type: 'numeric' },
    { field: 'due', title: 'Due (৳)', type: 'numeric' },
    { field: 'user.username', title: 'Exchanged By' },
    {
      field: 'status.name',
      title: 'Status',
      render: (rowData) => (
        <span className={rowData.status.name.toLowerCase().trim().replace(' ', '-')}>
          {rowData.status.name}
        </span>
      )
    },
    { field: 'payment_method.name', title: 'Payment Method' }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SALE_SECTIONS} />

      <div className="container-fluid mt-4">
        {/*<div id="card-content">*/}
        {/*    <div className="card-header">*/}
        {/*        <div className="d-flex justify-content-between align-items-center">*/}
        {/*            <div>*/}
        {/*                <h5>Sales exchange</h5>*/}
        {/*                <p className={"mb-0"}>All the sale exchange list</p>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        <FilterMaterialTable
          title={'All exchanges'}
          isLoading={isLoading}
          columns={tableHead}
          data={saleExchanges}
          tableContainerClass={'mt-4'}
          fetchMethod={getSaleExchanges}
          isCustomPagination
          inputFocus={inputFocus}
          options={{
            rowStyle: (rowData) => {
              return {
                fontSize: '14px',
                color: '#000'
              };
            }
          }}
          actions={[
            (rowData) => ({
              icon: 'print',
              onClick: () => handlePrintSaleExchange(rowData.id),
              tooltip: 'Print Exchange'
            }),
            permissions.find((item) => item.name === MANAGE_PACKAGING) &&
              ((rowData) => ({
                icon: 'outbox',
                onClick: (e) => moveToPackageDetails(rowData.id),
                tooltip: 'Prepare Package',
                disabled: rowData.statusId !== 5 && rowData.statusId !== 8
              })),
            (rowData) => ({
              icon: 'payment',
              onClick: () => openDialog(rowData),
              tooltip: 'Pay Now',
              disabled: rowData.statusId === 7 || rowData.statusId === 3 || rowData.statusId === 8
            })
          ]}
        />
      </div>

      <Dialog
        open={dialogOpen}
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        onClose={hideDialog}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">New Exchange Payment</DialogTitle>
        <DialogContent>
          <div className="card-body">
            <React.Fragment>
              <div className="form-group">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Account <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Autocomplete
                      id={'select-branch'}
                      onChange={(event, value) => onBankAccountSelected(event, value)}
                      options={bankAccounts}
                      getOptionLabel={(option) => option.account_name}
                      renderOption={(option) => option.account_name}
                      defaultValue={bankAccounts.find((item) => item.id === bankAccountId)}
                      freeSolo
                      size={'small'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Select One'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Payment Method <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Autocomplete
                      id={'select-branch'}
                      onChange={(event, value) => onPaymentMethodSelected(event, value)}
                      options={paymentMethods}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => option.name}
                      defaultValue={paymentMethods.find((item) => item.id === paymentMethodId)}
                      freeSolo
                      size={'small'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Select One'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Category <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Autocomplete
                      id={'select-branch'}
                      onChange={(event, value) => onCategorySelected(event, value)}
                      options={incomeCategories}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => option.name}
                      defaultValue={incomeCategories.find((item) => item.name === 'Sales')}
                      freeSolo
                      size={'small'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Select One'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'subTotal'}>
                      Amount <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <NumberInput
                      name={'amount'}
                      value={amount}
                      required
                      max={total}
                      onChange={(e) => onChange(e)}
                      placeholder={'Amount'}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Date <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="date"
                      name={'date'}
                      required
                      defaultValue={date}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Choose a date'}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'description'}>
                      Description <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      name={'description'}
                      value={description}
                      required
                      onChange={(e) => onChange(e)}
                      disabled
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'reference'}>Reference</label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      name={'reference'}
                      value={reference}
                      onChange={(e) => onChange(e)}
                      placeholder={'Reference'}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideDialog} color="secondary" disabled={isLoading}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!bankAccountId || !paymentMethodId || !categoryId || isLoading}
            onClick={(e) => submitPayNow(e)}
          >
            Pay Now
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

AllExchange.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getSaleExchanges: PropTypes.func.isRequired,
  sale: PropTypes.object.isRequired,
  getBankAccounts: PropTypes.func.isRequired,
  getIncomeCategories: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  addExchangeOrderPayment: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  sale: state.sale,
  bankings: state.bankings,
  system: state.system,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getSaleExchanges,
  getBankAccounts,
  getIncomeCategories,
  getPaymentMethods,
  addExchangeOrderPayment
})(AllExchange);
