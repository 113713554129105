export const LOADING = 'LOADING';
export const SEARCH_LOADING = 'SEARCH_LOADING';
export const SEARCH_LOADING_FALSE = 'SEARCH_LOADING_FALSE';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const GET_ERRORS = 'GET_ERRORS';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const LOADING_FALSE = 'LOADING_FALSE';
export const GET_TOKEN = 'GET_TOKEN';
export const USER_DATA = 'USER_DATA';
export const BRANCH_DETAILS = 'BRANCH_DETAILS';

export const ACCOUNTS = 'ACCOUNTS';
export const GET_ROLES = 'GET_ROLES';
export const BRANCHES = 'BRANCHES';
export const PAYMENT_METHODS = 'PAYMENT_METHODS';
export const ACCOUNT_TYPES = 'ACCOUNT_TYPES';

export const GET_COMPANY = 'GET_COMPANY';
export const CATEGORIES = 'CATEGORIES';
export const BRANDS = 'BRANDS';
export const UNITS = 'UNITS';
export const TYPES = 'TYPES';
export const COLORS = 'COLORS';
export const SIZES = 'SIZES';
export const PRODUCTS = 'PRODUCTS';
export const ALL_PRODUCTS = 'ALL_PRODUCTS';
export const LOCATIONS = 'LOCATIONS';

export const SUPPLIERS = 'SUPPLIERS';
export const SUPPLIER = 'SUPPLIER';
export const CUSTOMERS = 'CUSTOMERS';
export const CUSTOMER = 'CUSTOMER';

export const SUPPLIER_PAYMENTS = 'SUPPLIER_PAYMENTS';
export const CUSTOMER_PAYMENTS = 'CUSTOMER_PAYMENTS';
export const ALL_CUSTOMER_PAYMENTS = 'ALL_CUSTOMER_PAYMENTS';
export const ALL_SUPPLIER_PAYMENTS = 'ALL_SUPPLIER_PAYMENTS';

export const PURCHASE = 'PURCHASE';
export const PURCHASE_DETAILS = 'PURCHASE_DETAILS';
export const PURCHASE_RETURN = 'PURCHASE_RETURN';
export const PURCHASE_DAMAGE_RETURN = 'PURCHASE_DAMAGE_RETURN';
export const PURCHASE_RETURN_DETAILS = 'PURCHASE_RETURN_DETAILS';
export const PURCHASE_DAMAGE_RETURN_DETAILS = 'PURCHASE_DAMAGE_RETURN_DETAILS';
export const SALE = 'SALE';
export const PENDING_SALE = 'PENDING_SALE';
export const QUOTATION = 'QUOTATION';
export const STOCKS = 'STOCKS';
export const PRODUCT_STOCK = 'PRODUCT_STOCK';
export const SALE_DETAILS = 'SALE_DETAILS';
export const PACKAGING_DETAILS = 'PACKAGING_DETAILS';
export const QUOTATION_DETAILS = 'QUOTATION_DETAILS';
export const SALE_RETURN = 'SALE_RETURN';
export const SALE_RETURN_DETAILS = 'SALE_RETURN_DETAILS';
export const SALE_EXCHANGES = 'SALE_EXCHANGES';
export const SALE_EXCHANGE_DETAILS = 'SALE_EXCHANGE_DETAILS';
export const GET_SALE_ID = 'GET_SALE_ID';

export const BANK_ACCOUNTS = 'BANK_ACCOUNTS';
export const INCOME_CATEGORIES = 'INCOME_CATEGORIES';
export const INCOME_EXPENSE_CATEGORIES = 'INCOME_EXPENSE_CATEGORIES';
export const EXPENSE_CATEGORIES = 'EXPENSE_CATEGORIES';
export const BANK_TRANSACTIONS = 'BANK_TRANSACTIONS';
export const BANK_DEPOSIT_TRANSACTIONS = 'BANK_DEPOSIT_TRANSACTIONS';
export const BANK_TRANSFER_TRANSACTIONS = 'BANK_TRANSFER_TRANSACTIONS';
export const BANK_EXPENSE_TRANSACTIONS = 'BANK_EXPENSE_TRANSACTIONS';

export const STOCK_TRANSFERS = 'STOCK_TRANSFERS';
export const STOCK_REPORTS = 'STOCK_REPORTS';
export const PRODUCT_STOCK_REPORTS = 'PRODUCT_STOCK_REPORTS';
export const SALE_REPORTS = 'SALE_REPORTS';
export const DAY_SALE_REPORTS = 'DAY_SALE_REPORTS';
export const DAY_PURCHASE_REPORTS = 'DAY_PURCHASE_REPORTS';
export const PURCHASE_REPORTS = 'PURCHASE_REPORTS';
export const SALES_HISTORY = 'SALES_HISTORY';
export const EXPENSES_REPORTS = 'EXPENSES_REPORTS';
export const INCOME_REPORTS = 'INCOME_REPORTS';
export const INCOME_EXPENSE_REPORTS_YEARLY = 'INCOME_EXPENSE_REPORTS_YEARLY';
export const INCOME_EXPENSE_REPORTS = 'INCOME_EXPENSE_REPORTS';

export const EMPLOYEE = 'EMPLOYEE';
export const SINGLE_EMPLOYEE = 'SINGLE_EMPLOYEE';
export const CLEAR_EMPLOYEE = 'CLEAR_EMPLOYEE';
export const DESIGNATION = 'DESIGNATION';
export const DEPARTMENT = 'DEPARTMENT';
export const SALARY_HISTORY = 'SALARY_HISTORY';

export const PERMISSIONS = 'PERMISSIONS';
export const SINGLE_PERMISSIONS = 'SINGLE_PERMISSIONS';
export const USER_PERMISSIONS = 'USER_PERMISSIONS';

export const COUPON = 'COUPON';
export const COUPONS = 'COUPONS';

export const DASHBOARD = 'DASHBOARD';
export const LOW_STOCK = 'LOW_STOCK';
export const MOST_SOLD = 'MOST_SOLD';
export const POPULAR_CATEGORIES = 'POPULAR_CATEGORIES';
export const BANK_ACCOUNTS_DASH = 'BANK_ACCOUNTS_DASH';
export const INVENTORY_STATISTICS = 'INVENTORY_STATISTICS';

export const INVENTORY_SEARCH = 'INVENTORY_SEARCH';
