import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import {
  acceptSaleOrder,
  addSaleOrderPayment,
  addSaleReturn,
  cancelSale,
  getSaleDetails
} from '../../../actions/sale';
import MaterialTable from 'material-table';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import Swal from 'sweetalert2';
import {
  MANAGE_PACKAGING,
  MODIFY_SALES,
  SALES_EXCHANGE,
  SALES_INVOICES
} from '../../../permissions';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getBankAccounts } from '../../../actions/bankings';
import { getIncomeCategories, getPaymentMethods } from '../../../actions/system';
import { DEFAULT_INCOME_CATEGORY, DEFAULT_PAYMENT_METHOD } from '../../../const';
import { REGEX_STRING, SALE_SECTIONS } from '../../../utils';
import NumberInput from '../../common/NumberInput';

function SaleDetails({
  isLoading,
  cancelSale,
  addSaleOrderPayment,
  getBankAccounts,
  getPaymentMethods,
  getIncomeCategories,
  bankings: { bankAccounts },
  system: { paymentMethods, incomeCategories },
  auth: { permissions },
  addSaleReturn,
  sale: { saleDetails },
  getSaleDetails,
  location,
  acceptSaleOrder
}) {
  const history = useHistory();
  const [saleReturnDialog, setSaleReturnDialog] = useState(false);
  const [returnForm, setReturnForm] = useState({
    reason: '',
    returned_qty: 1
  });
  const [returnItem, setReturnItem] = useState({});

  const [dialogOpen, setDialogOpen] = useState(false);
  const [salePaymentForm, setSalePaymentForm] = useState({
    id: '',
    bankAccountId: '',
    paymentMethodId: '',
    categoryId: '',
    amount: '',
    date: moment().format('YYYY-MM-DD'),
    description: '',
    reference: '',
    total: ''
  });

  const {
    id,
    bankAccountId,
    paymentMethodId,
    categoryId,
    amount,
    date,
    description,
    reference,
    total
  } = salePaymentForm;

  const onPaymentFormChange = (e) =>
    setSalePaymentForm({ ...salePaymentForm, [e.target.name]: e.target.value });

  const { reason, returned_qty } = returnForm;

  const onChange = (e) => setReturnForm({ ...returnForm, [e.target.name]: e.target.value });

  useEffect(() => {
    getPaymentMethods();
    getBankAccounts();
    getIncomeCategories();
  }, []);

  useEffect(() => {
    if (paymentMethods.length > 0) {
      setSalePaymentForm({
        ...salePaymentForm,
        paymentMethodId: paymentMethods.find((item) => item.name === DEFAULT_PAYMENT_METHOD).id
      });
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (bankAccounts.length > 0) {
      setSalePaymentForm({
        ...salePaymentForm,
        bankAccountId: bankAccounts.find((item) => item.default_sale === true).id
      });
    }
  }, [bankAccounts]);

  useEffect(() => {
    if (incomeCategories.length > 0) {
      setSalePaymentForm({
        ...salePaymentForm,
        categoryId: incomeCategories.find((item) => item.name === DEFAULT_INCOME_CATEGORY).id
      });
    }
  }, [incomeCategories]);

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === SALES_INVOICES)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      getSaleDetails(id);
    }
  }, []);

  const openDialog = (data) => {
    setSalePaymentForm({
      ...salePaymentForm,
      id: data.id,
      amount: data.due,
      description: `Payment for INV-${data.id}`,
      reference: `INV-${data.id}`,
      total: data.total,
      phone: saleDetails.customer.phone
    });
    setDialogOpen(true);
  };

  const hideDialog = () => {
    setDialogOpen(false);
  };

  const onBankAccountSelected = (e, value) => {
    e.preventDefault();

    setSalePaymentForm({
      ...salePaymentForm,
      bankAccountId: value ? value.id : ''
    });
  };

  const onPaymentMethodSelected = (e, value) => {
    e.preventDefault();

    setSalePaymentForm({
      ...salePaymentForm,
      paymentMethodId: value ? value.id : ''
    });
  };

  const onCategorySelected = (e, value) => {
    e.preventDefault();

    setSalePaymentForm({
      ...salePaymentForm,
      categoryId: value ? value.id : ''
    });
  };

  const submitPayNow = (e) => {
    e.preventDefault();

    addSaleOrderPayment(salePaymentForm);
    hideDialog();
  };

  const calculateTwoIntegersFromIntegersOfArray = (array) => {
    if (array.length === 0) return 0;

    const add = (a, b) => a + b;
    return array.reduce(add);
  };

  const moveTo = (link, id) => {
    history.push({
      pathname: link,
      search: `?id=${id}`
    });
  };

  const goBackButton = () => {
    history.goBack();
  };

  const showOrHideSaleReturnedDialog = (value, item = {}) => {
    setSaleReturnDialog(value);
    setReturnItem(item);
    setReturnForm({
      reason: '',
      returned_qty: 1
    });
  };

  const saveSaleReturn = (e) => {
    e.preventDefault();

    if (returnItem.totalReturned >= returnItem.qty) {
      showOrHideSaleReturnedDialog(false);
      Swal.fire('Invalid quantity', 'All qty already returned', 'error').then();
      return;
    }

    if (returnItem.qty < returned_qty) {
      showOrHideSaleReturnedDialog(false);
      Swal.fire('Invalid quantity', 'Returned qty higher than sale qty', 'warning').then();
      return;
    }

    const data = {
      branchId: returnItem.branchId,
      orderDetailId: returnItem.id,
      returned_qty: returned_qty,
      reason: reason
    };

    addSaleReturn(saleDetails.id, data);

    showOrHideSaleReturnedDialog(false);
  };

  const cancelSaleInvoice = (e) => {
    e.preventDefault();

    Swal.fire({
      title: 'Cancel Invoice?',
      text: "Are you sure? You won't be able to revert this order!",
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'With charge!',
      denyButtonText: 'Without charge!'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          isDeliveryCharge: true
        };
        cancelSale(saleDetails.id, data);
      }

      if (result.isDenied) {
        cancelSale(saleDetails.id);
      }
    });
  };

  const printSale = (id) => {
    history.push({
      pathname: '/sale/print',
      search: `?id=${id}`
    });
  };

  const saleExchange = (id) => {
    history.push({
      pathname: '/sale/exchange',
      search: `?id=${id}`
    });
  };

  const handlePrintSaleExchange = (id) => {
    history.push({
      pathname: '/sale/exchange/print',
      search: `?id=${id}`
    });
  };

  const moveToPackaging = (id) => {
    history.push({
      pathname: '/packaging/details',
      search: `?id=${id}&&is_exchange=false`
    });
  };

  const tableHead = [
    {
      field: 'tableData.id',
      title: 'SL',
      cellStyle: {
        width: '5%'
      },
      render: (rowData) => {
        return rowData.tableData.id + 1;
      }
    },
    {
      field: 'description',
      title: 'Description',
      cellStyle: {
        width: '30%'
      },
      render: (rowData) => {
        return rowData.description.includes('(') ? (
          <p className={'m-0'}>
            {rowData.description.split('(')[0]} (
            <span className={'font-weight-bold'}>{REGEX_STRING.exec(rowData.description)[1]}</span>)
          </p>
        ) : (
          rowData.description
        );
      }
    },
    { field: 'branch.name', title: 'Branch' },
    {
      field: 'qty',
      title: 'Quantity'
    },
    {
      field: 'totalReturned',
      title: 'Returned'
    },
    {
      field: 'unit_price',
      title: 'Rate (৳)'
    },
    {
      field: 'discount',
      title: 'Discount (%)'
    },
    {
      field: 'total',
      title: 'Amount (৳)'
    }
  ];

  const invoiceActivityHeader = [
    {
      field: 'createdAt',
      title: 'Date',
      render: (rowData) => moment(rowData.createdAt).format('MMM DD')
    },
    {
      field: 'description',
      title: 'Description',
      cellStyle: {
        width: '60%'
      },
      render: (rowData) => {
        return rowData.description.includes('(') ? (
          <p className={'m-0'}>
            {rowData.description.split('(')[0]} (
            <span className={'font-weight-bold'}>{REGEX_STRING.exec(rowData.description)[1]}</span>)
          </p>
        ) : (
          rowData.description
        );
      }
    },
    {
      field: 'qty',
      title: 'Qty',
      render: (rowData) => (rowData.qty === 0 ? '-' : rowData.qty)
    },
    { field: 'user.username', title: 'By' }
  ];

  const paymentHistoryHeader = [
    { field: 'reference', title: 'INV No.' },
    { field: 'payment_method.name', title: 'Method' },
    { field: 'amount', title: 'Amount (৳)' },
    { field: 'user.username', title: 'By' }
  ];

  const packagingHistoryHeader = [
    {
      field: 'createdAt',
      title: 'Date',
      render: (rowData) => moment(rowData.createdAt).format('YYYY-MM-DD')
    },
    {
      field: 'description',
      title: 'Item',
      cellStyle: { width: '35%' }
    },
    {
      field: 'qty',
      title: 'Qty',
      render: (rowData) => (rowData.qty === 0 ? '-' : rowData.qty)
    },
    { field: 'user.username', title: 'By' }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale'}>Sale</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/sale/all'}>Invoices</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={`/sale/details?id=${saleDetails && saleDetails.id}`}>Details</Link>
      </li>
    </ol>
  );

  const acceptSaleInvoice = async (e, id) => {
    e.preventDefault();

    const data = await acceptSaleOrder(id);

    if (data) {
      let htmlContent = '';
      let index = 1;
      for (const item of data) {
        const content = `<div>
          <b>${index}.</b>&nbsp;
          <b>${item.itemDesc}</b><br>
          <span>${item.description}</span><br>
          <u>Available Qty:</u> <strong>${item.availableQty}</strong> &nbsp;&nbsp;
          <u>Required Qty:</u> <strong>${item.needQty}</strong>
        </div><br>`;

        htmlContent += content;
        index++;
      }

      await Swal.fire({
        title: 'Stock Availability Error',
        icon: 'error',
        html: htmlContent,
        confirmButtonText: 'Ok'
      });
    }
  };

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SALE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Sale Details</h5>
                <p className="mb-0">See sale details, manage returns and edit sale</p>
              </div>
              <div>
                {permissions.find((item) => item.name === MANAGE_PACKAGING) &&
                  saleDetails &&
                  (saleDetails.statusId === 5 ||
                    saleDetails.statusId === 8 ||
                    saleDetails.statusId === 10) && (
                    <button
                      className={'btn btn-primary me-2'}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={() => moveToPackaging(saleDetails && saleDetails.id)}>
                      <i className="far fas fa-boxes me-2" /> Prepare Package
                    </button>
                  )}

                {permissions.find((item) => item.name === MODIFY_SALES) &&
                  saleDetails &&
                  saleDetails.statusId !== 7 &&
                  saleDetails.statusId !== 3 &&
                  saleDetails.statusId !== 8 &&
                  saleDetails.due !== '0.00' &&
                  saleDetails.statusId !== 9 && (
                    <button
                      className={'btn btn-success text-white me-2'}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => openDialog(saleDetails)}>
                      <i className="fas fa-credit-card me-2" /> Pay Now
                    </button>
                  )}

                {permissions.find((item) => item.name === MODIFY_SALES) &&
                  saleDetails &&
                  saleDetails.statusId === 9 && (
                    <button
                      className={'btn btn-success text-white me-2'}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => acceptSaleInvoice(e, saleDetails && saleDetails.id)}>
                      <i className="fas fa-check me-2" /> Accept Sale
                    </button>
                  )}

                {permissions.find((item) => item.name === MODIFY_SALES) &&
                  saleDetails &&
                  (saleDetails.statusId === 5 ||
                    saleDetails.statusId === 2 ||
                    saleDetails.statusId === 8 ||
                    saleDetails.statusId === 10) && (
                    <button
                      className={'btn btn-primary me-2'}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={() => moveTo('/sale/update', saleDetails && saleDetails.id)}>
                      <i className="fas fa-pen me-2" /> Edit Sale
                    </button>
                  )}

                {permissions.find((item) => item.name === SALES_EXCHANGE) &&
                  saleDetails &&
                  saleDetails.returnedDue > 0 && (
                    <button
                      className={'btn btn-success text-white me-2'}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => saleExchange(saleDetails && saleDetails.id)}>
                      <i className="fas fa-exchange-alt me-2" /> Exchange
                    </button>
                  )}

                <button
                  className={'btn btn-primary me-2'}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(e) => printSale(saleDetails && saleDetails.id)}>
                  <i className="fas fa-print me-2" /> Print
                </button>

                {permissions.find((item) => item.name === MODIFY_SALES) &&
                  saleDetails &&
                  (saleDetails.statusId === 5 ||
                    saleDetails.statusId === 2 ||
                    saleDetails.statusId === 8) && (
                    <button
                      className={'btn btn-danger text-white me-2'}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => cancelSaleInvoice(e)}>
                      <i className="far fa-times-circle me-2" /> Cancel Invoice
                    </button>
                  )}
                <button className="btn btn-outline-primary" onClick={goBackButton}>
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <div id="card-content" className={'mt-4'}>
              <div className="card-body">
                <div className="row justify-content-between">
                  <div className="col-md-5">
                    <p className={' mb-1'}>
                      <span className={'font-weight-bold'}>Customer Name: &emsp;</span>{' '}
                      {saleDetails ? saleDetails.customer.name : ''}
                    </p>
                    <p className={'mb-1'}>
                      <span className={'font-weight-bold'}>Customer Phone: &emsp;</span>{' '}
                      {saleDetails ? saleDetails.customer.phone : ''}
                    </p>
                    {saleDetails && (
                      <p>
                        <span className={'font-weight-bold'}>Address: &emsp;</span>{' '}
                        {saleDetails.customer.address}
                      </p>
                    )}
                  </div>
                  <div className="col-md-5">
                    <p className={' mb-1'}>
                      <span className={'font-weight-bold'}>Invoice No: &emsp;</span>{' '}
                      {saleDetails ? `INV-${saleDetails.id}` : ''}
                    </p>
                    <p className={' mb-1'}>
                      <span className={'font-weight-bold'}>Date: &emsp;</span>{' '}
                      {saleDetails ? saleDetails.date : ''}
                    </p>
                    <p className={' mb-1'}>
                      <span className={'font-weight-bold'}>Status: &emsp;</span>
                      <span
                        className={
                          saleDetails &&
                          saleDetails.status.name.toLowerCase().trim().replace(' ', '-')
                        }>
                        {saleDetails ? saleDetails.status.name : ''}
                      </span>
                    </p>
                  </div>
                </div>

                {saleDetails && (
                  <div className={'mt-4 d-grid'}>
                    <div style={{ overflow: 'auto' }} className={'sales-table'}>
                      <MaterialTable
                        columns={tableHead}
                        data={saleDetails?.saleOrderItems}
                        totalCount={saleDetails?.saleOrderItems.length}
                        options={{
                          toolbar: false,
                          exportButton: false,
                          showTitle: false,
                          headerStyle: {
                            padding: 4,
                            backgroundColor: '#F5F7FA'
                          },
                          padding: 'default',
                          pageSize: saleDetails?.saleOrderItems.length,
                          paging: false,
                          actionsColumnIndex: -1
                        }}
                        actions={
                          // hide for pending, cancelled, accept sale & unpaid invoice
                          saleDetails.statusId !== 5 &&
                          saleDetails.statusId !== 7 &&
                          saleDetails.statusId !== 2 &&
                          saleDetails.statusId !== 8 &&
                          saleDetails.statusId !== 9
                            ? [
                                (rowData) => ({
                                  icon: 'cached',
                                  isFreeAction: true,
                                  disabled: rowData.totalReturned >= rowData.qty,
                                  tooltip: 'Sale return',
                                  onClick: () => showOrHideSaleReturnedDialog(true, rowData)
                                })
                              ]
                            : []
                        }
                      />
                    </div>

                    <div className="row mt-4">
                      <div className="col-8 mt-4">
                        {saleDetails && saleDetails.exchangeInvoices.length > 0 && (
                          <h6>Sale exchange invoices</h6>
                        )}
                        {saleDetails &&
                          saleDetails.exchangeInvoices.map((item) => (
                            <h5
                              className={'text-primary hover d-inline-block me-3'}
                              onClick={() => handlePrintSaleExchange(item.id)}>
                              SEI-{item.id}
                            </h5>
                          ))}
                      </div>
                      <div className="col-2 p-0">
                        <p className={'font-weight-bold'}>Total Qty:</p>
                        <p className={'font-weight-bold'}>Returned Qty:</p>
                        <p className={'font-weight-bold'}>Sub Total:</p>
                        <p className={'font-weight-bold'}>vat:</p>
                        <p className={'font-weight-bold'}>Transport Cost:</p>
                        <p className={'font-weight-bold mb-0'}>Discount:</p>

                        <div className="divider" />
                        <p className={'font-weight-bold'}>Total:</p>
                        <p className={'font-weight-bold'}>Paid:</p>
                        <div className="divider" />

                        <p className={'font-weight-bold text-danger'}>Customer Due:</p>
                        <div className="divider" />

                        <p className={'font-weight-bold'}>Returned:</p>
                        <p className={'font-weight-bold mb-0'}>Paid Returned:</p>

                        <div className="divider" />
                        <p className={'font-weight-bold text-danger'}>Seller Due:</p>
                      </div>
                      <div className="col-1 p-0">
                        <p>
                          {saleDetails
                            ? calculateTwoIntegersFromIntegersOfArray(
                                saleDetails.saleOrderItems.map((item) => item.qty)
                              )
                            : 0}
                        </p>
                        <p>{saleDetails.totalReturned ? saleDetails.totalReturned : 0}</p>
                        <p>{saleDetails ? saleDetails.sub_total : 0}</p>
                        <p>{saleDetails ? saleDetails.vat + '%' : 0}</p>
                        <p>{saleDetails ? saleDetails.transport_cost : 0}</p>
                        <p className={'mb-0'}>{saleDetails ? saleDetails.discount : 0}</p>

                        <div className="divider" />
                        <p>{saleDetails ? saleDetails.total : 0}</p>
                        <p>{saleDetails ? saleDetails.paid : 0}</p>
                        <div className="divider" />
                        <p className={'text-danger'}>{saleDetails ? saleDetails.due : 0} Tk</p>

                        <div className="divider" />
                        <p>{saleDetails.returnedAmount ? saleDetails.returnedAmount : 0}</p>
                        <p className={'mb-0'}>
                          {saleDetails.paidReturnedAmount ? saleDetails.paidReturnedAmount : 0}
                        </p>
                        <div className="divider" />
                        <p className={'text-danger'}>
                          {saleDetails ? saleDetails.returnedDue : 0} Tk
                        </p>
                      </div>
                      <div className="col-1" />
                    </div>

                    <div className={'mt-3'}>
                      {/*<p><strong>Total (in*/}
                      {/*    word): </strong>{`${numWords(parseInt(saleDetails ? saleDetails.total : 0)).toUpperCase()} TAKA ONLY`}*/}
                      {/*</p>*/}
                      <p className={'mt-3'}>
                        <strong>Notes: </strong>
                        {saleDetails ? saleDetails.notes : ''}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="card-content" className={'mt-4'}>
              <div className="card-header">
                <h6>Invoice Activities</h6>
                <p>
                  All invoice activities. Returned and damage history will be listed here as well.
                </p>
              </div>
              <div className="card-body">
                {saleDetails && (
                  <div className={'mt-3 d-grid'}>
                    <div style={{ overflow: 'auto' }}>
                      <MaterialTable
                        columns={invoiceActivityHeader}
                        data={saleDetails?.invoiceHistory}
                        totalCount={saleDetails?.invoiceHistory.length}
                        options={{
                          toolbar: false,
                          exportButton: false,
                          showTitle: false,
                          headerStyle: {
                            padding: 4,
                            backgroundColor: '#F5F7FA'
                          },
                          padding: 'default',
                          pageSize: saleDetails?.saleOrderItems.length,
                          paging: false,
                          actionsColumnIndex: -1
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div id="card-content" className={'mt-4'}>
              <div className="card-header">
                <h6>Payments</h6>
                <p>All the payment history of this invoice.</p>
              </div>
              <div className="card-body">
                {saleDetails && (
                  <div className={'mt-3 d-grid'}>
                    <div style={{ overflow: 'auto' }}>
                      <MaterialTable
                        columns={paymentHistoryHeader}
                        data={saleDetails?.paymentHistory}
                        totalCount={saleDetails?.paymentHistory.length}
                        options={{
                          toolbar: false,
                          exportButton: false,
                          showTitle: false,
                          headerStyle: {
                            padding: 4,
                            backgroundColor: '#F5F7FA'
                          },
                          padding: 'default',
                          pageSize: saleDetails?.paymentHistory.length,
                          paging: false,
                          actionsColumnIndex: -1
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div id="card-content" className={'mt-4'}>
              <div className="card-header">
                <h6>Packaging Activities</h6>
                <p>All the packaging details of this invoice will be listed here.</p>
              </div>
              <div className="card-body">
                {saleDetails && (
                  <div className={'mt-3 d-grid'}>
                    <div style={{ overflow: 'auto' }}>
                      <MaterialTable
                        columns={packagingHistoryHeader}
                        data={saleDetails?.packagingHistory}
                        totalCount={saleDetails?.packagingHistory.length}
                        options={{
                          toolbar: false,
                          exportButton: false,
                          showTitle: false,
                          headerStyle: {
                            padding: 4,
                            backgroundColor: '#F5F7FA'
                          },
                          padding: 'default',
                          pageSize: saleDetails?.packagingHistory.length,
                          paging: false,
                          actionsColumnIndex: -1
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*PURCHASE RETURN DIALOG*/}
      <Dialog
        open={saleReturnDialog}
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        onClose={() => showOrHideSaleReturnedDialog(false)}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="alert-dialog-slide-title">Return an Item</DialogTitle>
        <DialogContent>
          <div className="card-body">
            <Fragment>
              <div className="form-group">
                <label htmlFor={'customerPreDue'}>Returning reason</label>
                <input
                  type="text"
                  name={'reason'}
                  value={reason}
                  onChange={(e) => onChange(e)}
                  placeholder={'Why you want to return'}
                  className="form-control"
                />
              </div>

              <div className="form-group mt-3">
                <label htmlFor={'customerPreDue'}>Item Quantity</label>
                <NumberInput
                  name={'returned_qty'}
                  value={returned_qty}
                  onChange={(e) => onChange(e)}
                  min={1}
                  placeholder={'Enter quantity'}
                />
              </div>
            </Fragment>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => showOrHideSaleReturnedDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button color="primary" disabled={returned_qty === 0} onClick={(e) => saveSaleReturn(e)}>
            Return
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpen}
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        onClose={hideDialog}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="alert-dialog-slide-title">New Sale Payment</DialogTitle>
        <DialogContent>
          <div className="card-body">
            <React.Fragment>
              <div className="form-group">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Account <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Autocomplete
                      id={'select-branch'}
                      onChange={(event, value) => onBankAccountSelected(event, value)}
                      options={bankAccounts}
                      getOptionLabel={(option) => option.account_name}
                      renderOption={(option) => option.account_name}
                      defaultValue={bankAccounts.find((item) => item.id === bankAccountId)}
                      freeSolo
                      size={'small'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Select One'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Payment Method <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Autocomplete
                      id={'select-branch'}
                      onChange={(event, value) => onPaymentMethodSelected(event, value)}
                      options={paymentMethods}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => option.name}
                      defaultValue={paymentMethods.find((item) => item.id === paymentMethodId)}
                      freeSolo
                      size={'small'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Select One'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Category <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Autocomplete
                      id={'select-branch'}
                      onChange={(event, value) => onCategorySelected(event, value)}
                      options={incomeCategories}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => option.name}
                      defaultValue={incomeCategories.find((item) => item.name === 'Sales')}
                      freeSolo
                      size={'small'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Select One'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'subTotal'}>
                      Amount <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <NumberInput
                      name={'amount'}
                      value={amount}
                      required
                      max={total}
                      onChange={(e) => onPaymentFormChange(e)}
                      placeholder={'Amount'}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Date <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="date"
                      name={'date'}
                      required
                      defaultValue={date}
                      onChange={(e) => onPaymentFormChange(e)}
                      className="form-control"
                      placeholder={'Choose a date'}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'description'}>
                      Description <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      name={'description'}
                      value={description}
                      required
                      onChange={(e) => onPaymentFormChange(e)}
                      disabled
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'reference'}>Reference</label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      name={'reference'}
                      value={reference}
                      onChange={(e) => onPaymentFormChange(e)}
                      placeholder={'Reference'}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideDialog} disabled={isLoading} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!bankAccountId || !paymentMethodId || !categoryId || isLoading}
            onClick={(e) => submitPayNow(e)}>
            Pay Now
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

SaleDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getSaleDetails: PropTypes.func.isRequired,
  sale: PropTypes.object.isRequired,
  addSaleReturn: PropTypes.func.isRequired,
  cancelSale: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  sale: state.sale,
  auth: state.auth,
  bankings: state.bankings,
  system: state.system
});

export default connect(mapStateToProps, {
  getSaleDetails,
  cancelSale,
  addSaleReturn,
  addSaleOrderPayment,
  getBankAccounts,
  getIncomeCategories,
  getPaymentMethods,
  acceptSaleOrder
})(SaleDetails);
