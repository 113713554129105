import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import MaterialTable from 'material-table';
import moment from 'moment';
import { getBankAccounts } from '../../../../actions/bankings';
import { ACCOUNTS } from '../../../../permissions';
import { ACCOUNTS_SECTIONS } from '../../../../utils';

function AllAccounts({
  isLoading,
  getBankAccounts,
  auth: { permissions },
  bankings: { bankAccounts }
}) {
  let history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === ACCOUNTS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getBankAccounts();
  }, []);

  const tableHead = [
    { field: 'id', title: 'ID' },
    { field: 'account_name', title: 'Account Name' },
    { field: 'account_no', title: 'Account Number' },
    { field: 'bank_account_type.name', title: 'Account Type' },
    { field: 'bank_name', title: 'Bank Name' },
    { field: 'bank_address', title: 'Bank Address' },
    { field: 'balance', title: 'Balance (৳)' }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts'}>Accounts</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts/all'}>All Accounts</Link>
      </li>
    </ol>
  );
  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ACCOUNTS_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Add New Account</h5>
                <p className={'mb-0'}>Add and manage bank accounts</p>
              </div>
              <div>
                <Link
                  to={'/accounts/new'}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add Account
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="card-content" className={'mt-4'}>
          <div className={'overflow-auto'}>
            <MaterialTable
              title={'Bank Accounts'}
              columns={tableHead}
              data={bankAccounts}
              isLoading={isLoading}
              totalCount={bankAccounts?.total}
              options={{
                headerStyle: {
                  backgroundColor: '#F6F9FC',
                  color: '#000'
                },
                rowStyle: () => {
                  return {
                    fontSize: '14px',
                    color: '#000'
                  };
                },
                padding: 'default',
                pageSize: 20,
                actionsColumnIndex: -1,
                pageSizeOptions: [10, 20, 25, 50],
                showFirstLastPageButtons: true,
                exportAllData: true,
                exportFileName: `Bank accounts - ${moment(new Date()).format('LL')}`,
                exportButton: true,
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  marginLeft: '3rem'
                }
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

AllAccounts.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getBankAccounts: PropTypes.func.isRequired,
  bankings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  bankings: state.bankings,
  auth: state.auth
});

export default connect(mapStateToProps, { getBankAccounts })(AllAccounts);
