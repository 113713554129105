import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import { getPurchaseReturnList } from '../../../actions/purchase';
import moment from 'moment';
import { PURCHASE_RETURN } from '../../../permissions';
import { PURCHASE_SECTIONS } from '../../../utils';
import FilterMaterialTable from '../../common/FilterMaterialTable';

function PurchaseReturnList({
  isLoading,
  auth: { permissions },
  getPurchaseReturnList,
  purchase: { purchaseReturns }
}) {
  const history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === PURCHASE_RETURN)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const handlePurchaseDetails = (id) => {
    history.push({
      pathname: '/purchase/details',
      search: `?id=${id}`
    });
  };

  const handleSupplierDetails = (id) => {
    history.push({
      pathname: '/suppliers/details',
      search: `?id=${id}`
    });
  };

  const handlePrintPurchaseReturn = (saleId) => {
    history.push({
      pathname: '/purchase/return-list/print',
      search: `?purchaseId=${saleId}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/purchase'}>Purchase</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/purchase/return-list'}>Purchase Returns</Link>
      </li>
    </ol>
  );

  const tableHead = [
    {
      field: 'id',
      title: 'Inv.',
      cellStyle: { width: '5%' },
      render: (rowData) => `SRI-${rowData.id}`
    },
    {
      field: 'purchaseOrderId',
      title: 'Sale',
      cellStyle: { width: '5%' },
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handlePurchaseDetails(rowData.purchaseOrderId)}
        >{`PO-${rowData.purchaseOrderId}`}</span>
      )
    },
    {
      field: 'createdAt',
      title: 'Date',
      render: (rowData) => {
        return moment(rowData.createdAt).format('YYYY-MM-DD');
      }
    },
    {
      field: 'purchase_order_detail.description',
      title: 'Description',
      cellStyle: { width: '20%' }
    },
    { field: 'reason', title: 'Reason' },
    {
      field: 'sale_order.supplier.name',
      title: 'S. Name',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleSupplierDetails(rowData.purchase_order.supplierId)}
        >
          {rowData.purchase_order.supplier.name}
        </span>
      )
    },
    { field: 'is_damage', title: 'Damaged' },
    { field: 'purchase_order.supplier.phone', title: 'S. Phone' },
    { field: 'qty', title: 'Qty', type: 'numeric', cellStyle: { width: '5%' } },
    { field: 'total', title: 'Total (৳)', type: 'numeric' }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={PURCHASE_SECTIONS} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Purchase Return List</h5>
                <p className={'mb-0'}>All purchase return lists</p>
              </div>
            </div>
          </div>
        </div>

        <FilterMaterialTable
          title={'Purchase Returns'}
          isLoading={isLoading}
          columns={tableHead}
          data={purchaseReturns}
          tableContainerClass={'mt-4'}
          fetchMethod={getPurchaseReturnList}
          isCustomPagination
          options={{
            rowStyle: (rowData) => {
              return {
                fontSize: '14px',
                color: '#000'
              };
            }
          }}
          actions={[
            (rowData) => ({
              icon: 'print',
              onClick: () => handlePrintPurchaseReturn(rowData.purchaseOrderId),
              tooltip: 'Print All Returns'
            })
          ]}
        />
      </div>
    </Fragment>
  );
}

PurchaseReturnList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getPurchaseReturnList: PropTypes.func.isRequired,
  purchase: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  purchase: state.purchase,
  auth: state.auth
});

export default connect(mapStateToProps, { getPurchaseReturnList })(PurchaseReturnList);
