import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import { getBankAccountTypes } from '../../../../actions/system';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addBankAccount } from '../../../../actions/bankings';
import { ACCOUNTS } from '../../../../permissions';
import { ACCOUNTS_SECTIONS } from '../../../../utils';
import NumberInput from '../../../common/NumberInput';

function AddAccount({
  isLoading,
  getBankAccountTypes,
  auth: { permissions },
  addBankAccount,
  system: { accountTypes }
}) {
  const history = useHistory();
  const [formData, setFormData] = useState({
    bankAccountTypeId: '',
    account_name: '',
    account_no: '',
    bank_name: '',
    bank_address: '',
    opening_balance: 0
  });
  const { bankAccountTypeId, account_name, account_no, bank_name, bank_address, opening_balance } =
    formData;
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === ACCOUNTS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getBankAccountTypes();
  }, []);

  const onCancelButtonClicked = (e) => {
    e.preventDefault();

    history.goBack();
  };

  const onAccountTypeSelected = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      bankAccountTypeId: value ? value.id : ''
    });
  };

  const saveAccount = (e) => {
    e.preventDefault();

    addBankAccount(formData);
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts'}>Accounts</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts/new'}>Add Account</Link>
      </li>
    </ol>
  );
  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ACCOUNTS_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form onSubmit={(e) => saveAccount(e)}>
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>Add New Account</h5>
                  <p className={'mb-0'}>Enter new account details</p>
                </div>
                <div>
                  <button
                    type={'submit'}
                    disabled={account_name.length === 0 || !bankAccountTypeId || isLoading}
                    className="btn btn-primary p-2 me-2"
                  >
                    Save
                  </button>
                  <button
                    onClick={onCancelButtonClicked}
                    type={'button'}
                    className="btn btn-danger p-2 text-light"
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Account Name <span className={'text-danger'}>*</span>
                    </label>
                    <input
                      type="text"
                      name={'account_name'}
                      value={account_name}
                      onChange={(e) => onChange(e)}
                      required
                      className="form-control"
                      placeholder={'Enter account name'}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>
                      Account Type <span className={'text-danger'}>*</span>
                    </label>
                    <Autocomplete
                      onChange={(event, value) => onAccountTypeSelected(event, value)}
                      options={accountTypes}
                      renderOption={(option) => option.name}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        return option.name;
                      }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size={'small'}
                          placeholder={'Select Account Type'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>
                      Account Number <span className={'text-danger'}>*</span>
                    </label>
                    <input
                      type="text"
                      name={'account_no'}
                      value={account_no}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      required
                      placeholder={'Enter account number'}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>
                      Bank Name <span className={'text-danger'}>*</span>
                    </label>
                    <input
                      type="text"
                      name={'bank_name'}
                      value={bank_name}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      required
                      placeholder={'Enter bank name'}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>
                      Bank Address <span className={'text-danger'}>*</span>
                    </label>
                    <input
                      type="text"
                      name={'bank_address'}
                      value={bank_address}
                      required
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Enter bank address'}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Opening Balance</label>
                    <NumberInput
                      name={'opening_balance'}
                      value={opening_balance}
                      onChange={(e) => onChange(e)}
                      placeholder={'Opening balance'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

AddAccount.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  system: PropTypes.object.isRequired,
  addBankAccount: PropTypes.func.isRequired,
  getBankAccountTypes: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  system: state.system,
  auth: state.auth
});

export default connect(mapStateToProps, { getBankAccountTypes, addBankAccount })(AddAccount);
