import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import MaterialTable from 'material-table';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';
import Breadcrumb from '../../../layout/Breadcrumb';
import { getSalaryHistory } from '../../../../actions/employee';
import { SALARY_HISTORY } from '../../../../permissions';
import { EMPLOYEE_SECTIONS } from '../../../../utils';
import { TableCell } from '@material-ui/core';

function AllSalary({
  isLoading,
  getSalaryHistory,
  auth: { permissions },
  employee: { salaryHistory }
}) {
  let history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === SALARY_HISTORY)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getSalaryHistory({});
  }, []);

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee'}>Employee</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee/salary/all'}>Salary History</Link>
      </li>
    </ol>
  );

  const tableHead = [
    { field: 'bank_tran.date', title: 'Date' },
    { field: 'employee.name', title: 'Name' },
    { field: 'employee.phone', title: 'Phone' },
    {
      field: 'bank_tran.date',
      title: 'Month',
      render: (rowData) => moment(rowData.bank_tran.date).format('MMMM')
    },
    { field: 'bank_tran.reference', title: 'Ref' },
    { field: 'bank_tran.description', title: 'Description' },
    { field: 'amount', title: 'Amount (৳)' },
    { field: 'user.username', title: 'Granted By' }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={EMPLOYEE_SECTIONS} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Add Salary</h5>
                <p className={'mb-0'}>Add employee salary</p>
              </div>
              <div>
                <Link
                  to={'/employee/salary'}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add Salary
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="card-content" className={'mt-4'}>
          <div className={'overflow-auto'}>
            <MaterialTable
              title={'Salary Histories'}
              columns={tableHead}
              data={salaryHistory?.paginatedData}
              isLoading={isLoading}
              totalCount={salaryHistory?.total}
              options={{
                headerStyle: {
                  backgroundColor: '#F6F9FC',
                  color: '#000'
                },
                rowStyle: (rowData) => {
                  return {
                    fontSize: '14px',
                    color: '#000'
                  };
                },
                padding: 'default',
                pageSize: 20,
                actionsColumnIndex: -1,
                pageSizeOptions: [10, 20, 25, 50],
                showFirstLastPageButtons: true,
                exportAllData: true,
                exportFileName: `All histories - ${moment(new Date()).format('LL')}`,
                exportButton: true,
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  marginLeft: '3rem'
                }
              }}
              components={{
                Pagination: (props) => (
                  <TableCell className="text-center d-flex justify-content-end pagination mt-2">
                    <Pagination
                      color={'primary'}
                      count={salaryHistory?.lastPage}
                      page={salaryHistory?.currentPage}
                      siblingCount={2}
                      onChange={(e, value) => getSalaryHistory({ page: value })}
                    />
                  </TableCell>
                )
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  employee: state.employee,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getSalaryHistory
})(AllSalary);
