import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import { getBankDepositTransactions } from '../../../../actions/bankings';
import { DEPOSITS } from '../../../../permissions';
import { ACCOUNTS_SECTIONS } from '../../../../utils';
import FilterMaterialTable from '../../../common/FilterMaterialTable';

function AllDeposits({
  isLoading,
  getBankDepositTransactions,
  auth: { permissions },
  bankings: { depositTransactions }
}) {
  const history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === DEPOSITS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const handleHistory = () => {
    history.push('#');
  };

  const tableHead = [
    { field: 'id', title: 'ID' },
    { field: 'date', title: 'Date' },
    { field: 'bank_account.account_name', title: 'Account Name' },
    { field: 'bank_account.account_no', title: 'Account Number' },
    { field: 'bank_account.bank_name', title: 'Bank Name' },
    { field: 'description', title: 'Description' },
    { field: 'amount', title: 'Amount (৳)' }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts'}>Accounts</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/accounts/deposits/all'}>Deposits</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ACCOUNTS_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>New Deposit Entry</h5>
                <p className={'mb-0'}>Add new deposit</p>
              </div>
              <div>
                <Link
                  to={'/accounts/deposits/new'}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add Deposit
                </Link>
              </div>
            </div>
          </div>
        </div>

        <FilterMaterialTable
          title={'All Deposits'}
          isLoading={isLoading}
          columns={tableHead}
          data={depositTransactions}
          tableContainerClass={'mt-4'}
          fetchMethod={getBankDepositTransactions}
          isCustomPagination
          options={{
            rowStyle: () => {
              return {
                fontSize: '14px',
                color: '#000'
              };
            }
          }}
          actions={[
            {
              hidden: false,
              icon: 'print',
              onClick: (event, rowData) => handleHistory(),
              isFreeAction: true,
              tooltip: 'Print'
            }
          ]}
        />
      </div>
    </Fragment>
  );
}

AllDeposits.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getBankDepositTransactions: PropTypes.func.isRequired,
  bankings: PropTypes.object
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  bankings: state.bankings,
  auth: state.auth
});

export default connect(mapStateToProps, { getBankDepositTransactions })(AllDeposits);
