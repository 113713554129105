import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import Breadcrumb from '../../layout/Breadcrumb';
import { COUPON_SECTIONS } from '../../../utils';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { HomeRounded } from '@material-ui/icons';
import { getCoupons } from '../../../actions/coupon';

const Website = ({ isLoading, getCoupons, coupons }) => {
  const history = useHistory();

  useEffect(() => {
    getCoupons({ size: 5 });
  }, []);

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/website'}>Website</Link>
      </li>
    </ol>
  );

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={COUPON_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>New Coupon</h5>
                <p className={'mb-0'}>Add a new coupon details</p>
              </div>
              <div>
                <Link
                  to={'/website/add-coupon'}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add Coupon
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={'container-fluid mt-4'}>
        <div className="row">
          <div className="col-md-7">
            <div id="card-content">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <div>
                    <h5>Coupons</h5>
                    <p>Create and manage coupons</p>
                  </div>
                  <div>
                    <Link to={'/website/add-coupon'} className="btn btn-primary me-2">
                      Add
                    </Link>
                    <Link to={'/website/all'} className="btn btn-outline-primary">
                      View All
                    </Link>
                  </div>
                </div>
              </div>

              <div className="card-body mt-1">
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>Discount</TableCell>
                        <TableCell>Discount Type</TableCell>
                        <TableCell>Validity From</TableCell>
                        <TableCell>Validity To</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {coupons?.paginatedData?.slice(0, 5).map((row) => (
                        <TableRow
                          className={'hover'}
                          onClick={() => history.push(`/website/update-coupon?id=${row.id}`)}
                          key={row.id}
                        >
                          <TableCell component="th" scope="row">
                            {row.code}
                          </TableCell>
                          <TableCell>{row.discount}</TableCell>
                          <TableCell>{row.discountType}</TableCell>
                          <TableCell>{row.validityFrom}</TableCell>
                          <TableCell>{row.validityTo}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>

          <div className="col-md-5"></div>
        </div>
      </div>
    </>
  );
};

Website.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getCoupons: PropTypes.func.isRequired,
  coupons: PropTypes.object
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  coupons: state.coupons.coupons
});

export default connect(mapStateToProps, { getCoupons })(Website);
