import React, { Fragment, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../../layout/Breadcrumb';
import { connect } from 'react-redux';
import { getAccounts } from '../../../../actions/accounts';
import MaterialTable from 'material-table';
import moment from 'moment';
import { USER_ACCESS, USERS } from '../../../../permissions';
import { ADMINISTRATION_SECTIONS } from '../../../../utils';

function Users({ isLoading, accounts, auth: { permissions }, getAccounts }) {
  let history = useHistory();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === USERS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getAccounts();
  }, []);

  const handleHistory = () => {
    history.push('#');
  };

  const customRoleAccess = (id) => {
    history.push({
      pathname: '/administration/access',
      search: `?user=${id}`
    });
  };

  const moveToUserUpdate = (id) => {
    history.push({
      pathname: '/administration/update-user',
      search: `?id=${id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/administration'}>Administration</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/administration/users'}>Users</Link>
      </li>
    </ol>
  );

  const tableHead = [
    { field: 'id', title: 'ID', editable: 'never' },
    { field: 'username', title: 'Username' },
    { field: 'email', title: 'Email' },
    { field: 'role.name', title: 'Role', editable: 'never' }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ADMINISTRATION_SECTIONS} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Register New User</h5>
                <p className={'mb-0'}>Create and manage user</p>
              </div>
              <div>
                <Link
                  to={'/administration/add-user'}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add User
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="card-content" className={'mt-4'}>
          <div className={'overflow-auto'}>
            <MaterialTable
              title={'All registered users'}
              columns={tableHead}
              data={accounts}
              isLoading={isLoading}
              totalCount={accounts.length}
              options={{
                headerStyle: {
                  backgroundColor: '#F6F9FC',
                  color: '#000'
                },
                rowStyle: (rowData) => {
                  return {
                    fontSize: '14px',
                    color: '#000'
                  };
                },
                padding: 'default',
                pageSize: 20,
                actionsColumnIndex: -1,
                pageSizeOptions: [10, 20, 25, 50],
                showFirstLastPageButtons: true,
                exportAllData: true,
                exportFileName: `Registered Users - ${moment(new Date()).format('LL')}`,
                exportButton: true,
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  marginLeft: '3rem'
                }
              }}
              actions={[
                {
                  hidden: false,
                  icon: 'print',
                  onClick: (event, rowData) => handleHistory(),
                  isFreeAction: true,
                  tooltip: 'Print'
                },
                {
                  icon: 'refresh',
                  onClick: (event, rowData) => {},
                  isFreeAction: true,
                  tooltip: 'refresh'
                },
                {
                  icon: 'edit',
                  onClick: (event, rowData) => moveToUserUpdate(rowData.id),
                  tooltip: 'Edit User'
                },
                permissions.find((item) => item.name === USER_ACCESS) && {
                  icon: 'settings',
                  onClick: (event, rowData) => customRoleAccess(rowData.id),
                  isFreeAction: false,
                  tooltip: 'Custom Access'
                }
              ]}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  accounts: state.accounts.accounts,
  auth: state.auth
});

export default connect(mapStateToProps, { getAccounts })(Users);
