import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import moment from 'moment';
import Swal from 'sweetalert2';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import { getBankAccounts } from '../../../../actions/bankings';
import { addSalary, getEmployeeList } from '../../../../actions/employee';
import { getExpenseCategories, getPaymentMethods } from '../../../../actions/system';
import { SALARY_HISTORY } from '../../../../permissions';
import { EMPLOYEE_SECTIONS, numberWithCommas } from '../../../../utils';
import NumberInput from '../../../common/NumberInput';

function EmployeeSalary({
  isLoading,
  getExpenseCategories,
  getBankAccounts,
  getEmployeeList,
  addSalary,
  getPaymentMethods,
  auth: { permissions },
  system: { expenseCategories, paymentMethods },
  bankings: { bankAccounts },
  employee: { employee }
}) {
  const history = useHistory();

  const [formData, setFormData] = useState({
    bankAccountId: '',
    employeeId: '',
    date: moment().format('YYYY-MM-DD'),
    description: '',
    amount: 0,
    categoryId: '',
    paymentMethodId: '',
    reference: '',
    availableBalance: 0
  });
  const {
    bankAccountId,
    date,
    description,
    amount,
    categoryId,
    paymentMethodId,
    availableBalance,
    reference,
    employeeId
  } = formData;
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === SALARY_HISTORY)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getBankAccounts();
    getExpenseCategories();
    getPaymentMethods();
    getEmployeeList({});
  }, []);

  const onEmployeeChangeHandler = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      employeeId: value ? value.id : ''
    });
  };

  const onAccountChangeHandler = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      bankAccountId: value ? value.id : '',
      availableBalance: value ? value.balance : ''
    });
  };

  const onCategoryChangeHandler = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      categoryId: value ? value.id : ''
    });
  };

  const onPaymentMethodChangeHandler = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      paymentMethodId: value ? value.id : ''
    });
  };

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const saveExpense = (e) => {
    e.preventDefault();

    if (amount > availableBalance) {
      Swal.fire(
        'Amount error',
        `You cannot put amount larger than available balance`,
        'error'
      ).then();
      return;
    }

    addSalary(formData);
  };
  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee'}>Accounts</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee/salary'}>Add Salary</Link>
      </li>
    </ol>
  );
  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={EMPLOYEE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form onSubmit={(e) => saveExpense(e)}>
          <div id="card-content">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>Add Salary</h5>
                  <p className={'mb-0'}>Add employee salary</p>
                </div>
                <div>
                  <button
                    type={'submit'}
                    disabled={!bankAccountId || !amount || !employeeId || isLoading}
                    className="btn btn-primary p-2 me-2"
                  >
                    Save
                  </button>
                  <button
                    onClick={onCancelButtonClicked}
                    type={'button'}
                    disabled={isLoading}
                    className="btn btn-danger p-2 text-light"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="form-group mt-3">
                    <label>
                      Employee <span className={'text-danger'}>*</span>
                    </label>
                    <Autocomplete
                      onChange={(event, value) => onEmployeeChangeHandler(event, value)}
                      options={employee?.paginatedData}
                      renderOption={(option) => option.name}
                      getOptionLabel={(option) => option.name}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size={'small'}
                          placeholder={'Select Employee'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>

                  <div className="form-group mt-3">
                    <label>
                      Account <span className={'text-danger'}>*</span>
                    </label>
                    <Autocomplete
                      onChange={(event, value) => onAccountChangeHandler(event, value)}
                      options={bankAccounts}
                      renderOption={(option) => option.account_name}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        return option.account_name;
                      }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size={'small'}
                          placeholder={'Select Account'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label className={'text-primary'}>Available Balance</label>
                    <input
                      type={'text'}
                      name={'availableBalance'}
                      value={numberWithCommas(availableBalance)}
                      onChange={(e) => onChange(e)}
                      className="form-control border-primary text-primary"
                      disabled
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>
                      Date <span className={'text-danger'}>*</span>
                    </label>
                    <input
                      type="date"
                      name={'date'}
                      value={date}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      required
                      placeholder={'Enter date'}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>
                      Amount <span className={'text-danger'}>*</span>
                    </label>
                    <NumberInput
                      name={'amount'}
                      required
                      value={amount}
                      onChange={(e) => onChange(e)}
                      placeholder={'Amount'}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>
                      Category <span className={'text-danger'}>*</span>
                    </label>
                    <Autocomplete
                      onChange={(event, value) => onCategoryChangeHandler(event, value)}
                      options={expenseCategories.filter((item) => item.name === 'Salary')}
                      renderOption={(option) => option.name}
                      getOptionLabel={(option) => option.name}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size={'small'}
                          placeholder={'Select Category'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>
                      Payment Method <span className={'text-danger'}>*</span>
                    </label>
                    <Autocomplete
                      onChange={(event, value) => onPaymentMethodChangeHandler(event, value)}
                      options={paymentMethods}
                      renderOption={(option) => option.name}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        return option.name;
                      }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size={'small'}
                          placeholder={'Select Payment Method'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Description</label>
                    <input
                      type="text"
                      name={'description'}
                      value={description}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Enter description'}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Reference</label>
                    <input
                      type="text"
                      name={'reference'}
                      value={reference}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Reference'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  system: state.system,
  bankings: state.bankings,
  employee: state.employee,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getExpenseCategories,
  getBankAccounts,
  getPaymentMethods,
  getEmployeeList,
  addSalary
})(EmployeeSalary);
