import {
  ACTIVE_STATUS, ADVANCE_STATUS,
  CANCELLED_STATUS,
  INACTIVE_STATUS,
  PAID_STATUS,
  PARTIAL_STATUS,
  PENDING_STATUS,
  UNPAID_STATUS
} from './const';

export const divisions = [
  'Barisal',
  'Chittagong',
  'Dhaka',
  'Khulna',
  'Mymensingh',
  'Rajshahi',
  'Rangpur',
  'Sylhet'
];

export const SALE_SECTIONS = [
  { link: '/sale/all', name: 'Sales' },
  { link: '/sale/return-list', name: 'Returns' },
  { link: '/sale/quotations/all', name: 'Quotations' },
  { link: '/sale/exchange/all', name: 'Exchanges' }
];

export const PURCHASE_SECTIONS = [
  { link: '/purchase/all', name: 'Purchase' },
  { link: '/purchase/return-list', name: 'Returns' }
];

export const INVENTORY_SECTIONS = [
  { link: '/inventory', name: 'Inventory' },
  { link: '/inventory/product', name: 'Product' }
];

export const CUSTOMER_SECTIONS = [
  { link: '/customers/all', name: 'Customers' },
  { link: '/customers/payments', name: 'Payments' }
];

export const COUPON_SECTIONS = [
  { link: '/website/all', name: 'Coupon' },
  { link: '/website/add-coupon', name: 'New' }
];

export const SUPPLIER_SECTIONS = [
  { link: '/suppliers/all', name: 'Suppliers' },
  { link: '/suppliers/payments', name: 'Payments' }
];

export const ACCOUNTS_SECTIONS = [
  { link: '/accounts/all', name: 'Accounts' },
  { link: '/accounts/deposits/all', name: 'Deposits' },
  { link: '/accounts/transfers/all', name: 'Transfers' },
  { link: '/accounts/expenses/all', name: 'Expenses' },
  { link: '/accounts/transactions', name: 'Transactions' }
];

export const EMPLOYEE_SECTIONS = [
  { link: '/employee/all', name: 'Employees' },
  { link: '/employee/salary/all', name: 'Salary History' },
  { link: '/employee/designation/all', name: 'Designations' },
  { link: '/employee/department/all', name: 'Departments' }
];

export const REPORTS_SECTIONS = [
  { link: '/reports/sales', name: 'Sales' },
  { link: '/reports/purchase', name: 'Purchase' },
  { link: '/reports/sales-history', name: 'Sales History' },
  { link: '/reports/expense', name: 'Expenses' },
  { link: '/reports/income', name: 'Incomes' },
  { link: '/reports/income-expense', name: 'Income vs Expense' }
];

export const ADMINISTRATION_SECTIONS = [
  { link: '/administration/company-profile', name: 'Company' },
  { link: '/administration/users', name: 'Users' },
  { link: '/administration/roles', name: 'Roles' }
];

export const STATUSES = [
  {
    value: ACTIVE_STATUS,
    label: 'Active',
  },
  {
    value: INACTIVE_STATUS,
    label: 'Inactive',
    variant: 'destructive',
  },
  {
    value: PENDING_STATUS,
    label: 'Pending',
    variant: 'outline',
  },
  {
    value: PAID_STATUS,
    label: 'Paid',
  },
  {
    value: UNPAID_STATUS,
    label: 'Unpaid',
    variant: 'outline',
  },
  {
    value: PARTIAL_STATUS,
    label: 'Partial',
    variant: 'outline',
  },
  {
    value: ADVANCE_STATUS,
    label: 'Advance',
    variant: 'outline',
  },
  {
    value: CANCELLED_STATUS,
    label: 'Cancelled',
    variant: 'destructive',
  },
];

export const districts = [
  {
    divName: 'Barisal',
    districts: ['Barisal', 'Barguna', 'Bhola', 'Jhalokati', 'Patuakhali', 'Pirojpur']
  },
  {
    divName: 'Chittagong',
    districts: [
      'Bandarban',
      'Brahmanbaria',
      'Chandpur',
      'Chattogram',
      'Comilla',
      "Cox's Bazar",
      'Feni',
      'Khagrachhari',
      'Lakshmipur',
      'Noakhali',
      'Rangamati'
    ]
  },
  {
    divName: 'Dhaka',
    districts: [
      'Dhaka',
      'Faridpur',
      'Gazipur',
      'Gopalganj',
      'Kishoreganj',
      'Madaripur',
      'Manikganj',
      'Munshiganj',
      'Narayanganj',
      'Narsingdi',
      'Rajbari',
      'Shariatpur',
      'Tangail'
    ]
  },
  {
    divName: 'Khulna',
    districts: [
      'Bagerhat',
      'Chuadanga',
      'Jessore',
      'Jhenaidah',
      'Khulna',
      'Kushtia',
      'Magura',
      'Meherpur',
      'Narail',
      'Satkhira'
    ]
  },
  {
    divName: 'Mymensingh',
    districts: ['Jamalpur', 'Mymensingh', 'Netrokona', 'Sherpur']
  },
  {
    divName: 'Rajshahi',
    districts: [
      'Bogura',
      'Joypurhat',
      'Naogaon',
      'Natore',
      'Chapainawabganj',
      'Pabna',
      'Rajshahi',
      'Sirajganj'
    ]
  },
  {
    divName: 'Rangpur',
    districts: [
      'Dinajpur',
      'Gaibandha',
      'Kurigram',
      'Lalmonirhat',
      'Nilphamari',
      'Panchagarh',
      'Rangpur',
      'Thakurgaon'
    ]
  },
  {
    divName: 'Sylhet',
    districts: ['Habiganj', 'Moulvibazar', 'Sunamganj', 'Sylhet']
  }
];

export const areas = [
  {
    disName: 'Barisal',
    areas: [
      'Muladi',
      'Barisal Sadar',
      'Kawnia',
      'Agailjhara',
      'Hizla',
      'Babuganj',
      'Mehendiganj',
      'Bondor',
      'Wazirpur',
      'Banaripara',
      'Gaurnadi',
      'Airport',
      'Bakerganj'
    ]
  },
  {
    disName: 'Barguna',
    areas: ['Amtali', 'Bamna', 'Barguna Sadar', 'Betagi', 'Patharghata', 'Taltali']
  },
  {
    disName: 'Bhola',
    areas: [
      'Bhola Sadar',
      'Burhanuddin',
      'Char Fasson',
      'Daulatkhan',
      'Tazumuddin',
      'Lalmohan',
      'Manpura'
    ]
  },
  {
    disName: 'Jhalokati',
    areas: ['Jhalokati Sadar', 'Kathalia', 'Nalchity', 'Rajapur']
  },
  {
    disName: 'Patuakhali',
    areas: [
      'Bauphal',
      'Patuakhali Sadar',
      'Dashmina',
      'Galachipa',
      'Kalapara',
      'Mirzaganj',
      'Dumki',
      'Rangabali'
    ]
  },
  {
    disName: 'Pirojpur',
    areas: [
      'Kaukhali',
      'Zianagar',
      'Nesarabad',
      'Mathbaria',
      'Nazirpur',
      'Pirojpur Sadar',
      'Bhandaria'
    ]
  },
  {
    disName: 'Bandarban',
    areas: [
      'Bandarban Sadar',
      'Thanchi',
      'Lama',
      'Naikhongchhari',
      'Ali kadam',
      'Rowangchhari',
      'Ruma'
    ]
  },
  {
    disName: 'Brahmanbaria',
    areas: [
      'Brahmanbaria Sadar',
      'Kasba',
      'Akhaura',
      'Ashuganj',
      'Bancharampur',
      'Bijoynagar',
      'Nasirnagar',
      'Nabinagar',
      'Sarail'
    ]
  },
  {
    disName: 'Chandpur',
    areas: [
      'Chandpur Sadar',
      'Faridganj',
      'Haimchar',
      'Hajiganj',
      'Kachua',
      'Matlab Dakshin',
      'Matlab Uttar',
      'Shahrasti'
    ]
  },
  {
    disName: 'Chattogram',
    areas: [
      'Anwara',
      'Banshkhali',
      'Boalkhali',
      'Chandanaish',
      'Fatikchhari',
      'Hathazari',
      'Karnaphuli',
      'Lohagara',
      'Mirsharai',
      'Patiya',
      'Rangunia',
      'Raozan',
      'Sandwip',
      'Satkania',
      'Sitakunda',
      'Akborsha',
      'Baijid bostami',
      'Bakolia',
      'Bandar',
      'Chandgaon',
      'Chokbazar',
      'Doublemooring',
      'EPZ',
      'Hali Shohor',
      'Kornafuli',
      'Kotwali',
      'Kulshi',
      'Pahartali',
      'Panchlaish',
      'Potenga',
      'Shodhorgat',
      'Others'
    ]
  },
  {
    disName: 'Comilla',
    areas: [
      'Barura',
      'Brahmanpara',
      'Burichong',
      'Comilla Sadar',
      'Comilla Sadar Dakshin',
      'Chandina',
      'Chauddagram',
      'Daudkandi',
      'Debidwar',
      'Homna',
      'Laksam',
      'Lalmai',
      'Monohorgonj',
      'Meghna',
      'Muradnagar',
      'Nangalkot',
      'Titas'
    ]
  },
  {
    disName: "Cox's Bazar",
    areas: [
      'Chakaria',
      "Cox's Bazar Sadar",
      'Kutubdia',
      'Maheshkhali',
      'Ramu',
      'Teknaf',
      'Ukhia',
      'Pekua'
    ]
  },
  {
    disName: 'Feni',
    areas: ['Feni Sadar', 'Daganbhuiyan', 'Chhagalnaiya', 'Sonagazi', 'Parshuram', 'Fulgazi']
  },
  {
    disName: 'Khagrachhari',
    areas: [
      'Dighinala',
      'Khagrachhari Sadar',
      'Lakshmichhari',
      'Mahalchhari',
      'Manikchhari',
      'Matiranga',
      'Panchhari',
      'Ramgarh',
      'Guimara'
    ]
  },
  {
    disName: 'Lakshmipur',
    areas: ['Lakshmipur Sadar', 'Ramganj', 'Raipur', 'Ramgati', 'Kamalnagar']
  },
  {
    disName: 'Noakhali',
    areas: [
      'Senbagh',
      'Begumganj',
      'Chatkhil',
      'Companiganj',
      'Noakhali Sadar',
      'Hatiya',
      'Kabirhat',
      'Sonaimuri',
      'Suborno Char',
      'Bhashan Char'
    ]
  },
  {
    disName: 'Rangamati',
    areas: [
      'Rangamati Sadar',
      'Belaichhari',
      'Bagaichhari',
      'Barkal',
      'Juraichhari',
      'Rajasthali',
      'Kaptai',
      'Langadu',
      'Naniarchar',
      'Kaukhali'
    ]
  },
  {
    disName: 'Dhaka',
    areas: [
      'Adabor',
      'Airport',
      'Badda',
      'Banani',
      'Bangshal',
      'Bhashantek',
      'Cantonment',
      'Chackbazar',
      'Darussalam',
      'Daskhinkhan',
      'Demra',
      'Dhamrai',
      'Dhanmondi',
      'Dohar',
      'Gandaria',
      'Gulshan',
      'Hazaribag',
      'Jatrabari',
      'Kafrul',
      'Kalabagan',
      'Kamrangirchar',
      'Keraniganj',
      'Khilgaon',
      'Khilkhet',
      'Kotwali',
      'Lalbag',
      'Mirpur Model',
      'Mohammadpur',
      'Motijheel',
      'Mugda',
      'Nawabganj',
      'New Market',
      'Pallabi',
      'Paltan',
      'Ramna',
      'Rampura',
      'Rupnagar',
      'Sabujbag',
      'Savar',
      'Shah Ali',
      'Shahbag',
      'Shahjahanpur',
      'Sherebanglanagar',
      'Shyampur',
      'Sutrapur',
      'Tejgaon',
      'Tejgaon I/A',
      'Turag',
      'Uttara',
      'Uttara West',
      'Uttarkhan',
      'Vatara',
      'Wari',
      'Others'
    ]
  },
  {
    disName: 'Faridpur',
    areas: [
      'Faridpur Sadar',
      'Boalmari',
      'Alfadanga',
      'Madhukhali',
      'Bhanga',
      'Nagarkanda',
      'Charbhadrasan',
      'Sadarpur',
      'Shaltha',
      'Others'
    ]
  },
  {
    disName: 'Gazipur',
    areas: ['Gazipur Sadar-Joydebpur', 'Kaliakior', 'Kapasia', 'Sripur', 'Kaliganj', 'Tongi']
  },
  {
    disName: 'Gopalganj',
    areas: ['Tungipara', 'Kotalipara', 'Gopalganj Sadar', 'Kashiani', 'Muksudpur', 'Others']
  },
  {
    disName: 'Kishoreganj',
    areas: [
      'Bhairab',
      'Hossainpur',
      'Kishoreganj Sadar',
      'Bajitpur',
      'Kuliarchar',
      'Pakundia',
      'Tarail',
      'Itna',
      'Katiadi',
      'Karimganj',
      'Astagram',
      'Mithamain',
      'Nikli'
    ]
  },
  {
    disName: 'Madaripur',
    areas: ['Rajoir', 'Madaripur Sadar', 'Kalkini', 'Shibchar']
  },
  {
    disName: 'Manikganj',
    areas: [
      'Harirampur',
      'Manikganj Sadar',
      'Shibalaya',
      'Daulatpur',
      'Ghior',
      'Saturia',
      'Singair'
    ]
  },
  {
    disName: 'Munshiganj',
    areas: ['Gazaria', 'Munshiganj Sadar', 'Lohajang', 'Tongibari', 'Sreenagar', 'Sirajdikhan']
  },
  {
    disName: 'Narayanganj',
    areas: ['Araihazar', 'Narayanganj Sadar', 'Sonargaon', 'Bandar', 'Rupganj', 'Siddirgonj']
  },
  {
    disName: 'Narsingdi',
    areas: ['Shibpur', 'Narsingdi Sadar', 'Raipura', 'Palash', 'Belabo', 'Monohardi']
  },
  {
    disName: 'Rajbari',
    areas: ['Baliakandi', 'Rajbari Sadar', 'Goalandaghat', 'Pangsha', 'Kalukhali']
  },
  {
    disName: 'Shariatpur',
    areas: ['Damudya', 'Shariatpur Sadar', 'Naria', 'Jajira', 'Bhedarganj', 'Gosairhat']
  },
  {
    disName: 'Tangail',
    areas: [
      'Delduar',
      'Tangail Sadar',
      'Kalihati',
      'Nagarpur',
      'Ghatail',
      'Gopalpur',
      'Sakhipur',
      'Madhupur',
      'Dhanbari',
      'Basail',
      'Mirzapur',
      'Bhuapur'
    ]
  },
  {
    disName: 'Bagerhat',
    areas: [
      'Bagerhat Sadar',
      'Chitalmari',
      'Fakirhat',
      'Kachua',
      'Mollahat',
      'Mongla',
      'Morrelganj',
      'Rampal',
      'Sarankhola'
    ]
  },
  {
    disName: 'Chuadanga',
    areas: ['Chuadanga Sadar', 'Jibannagar', 'Alamdanga', 'Damurhuda']
  },
  {
    disName: 'Jessore',
    areas: [
      'Jessore Sadar',
      'Chaugachha',
      'Abhaynagar',
      'Jhikargachha',
      'Keshabpur',
      'Sharsha',
      'Bagherpara',
      'Manirampur'
    ]
  },
  {
    disName: 'Jhenaidah',
    areas: ['Jhenaidah Sadar', 'Maheshpur', 'Kaliganj', 'Kotchandpur', 'Shailkupa', 'Harinakunda']
  },
  {
    disName: 'Khulna',
    areas: [
      'Khulna Sadar',
      'Terokhada',
      'Batiaghata',
      'Dacope',
      'Dumuria',
      'Dighalia',
      'Koyra',
      'Paikgachha',
      'Phultala',
      'Rupsa',
      'Aranghata',
      'Harintana',
      'Horintana',
      'Khalishpur',
      'Khanjahan Ali',
      'Labanchora',
      'Sonadanga',
      'Others'
    ]
  },
  {
    disName: 'Kushtia',
    areas: ['Kushtia Sadar', 'Daulatpur', 'Bheramara', 'Kumarkhali', 'Mirpur', 'Khoksa']
  },
  {
    disName: 'Magura',
    areas: ['Magura Sadar', 'Mohammadpur', 'Shalikha', 'Sreepur']
  },
  {
    disName: 'Meherpur',
    areas: ['Meherpur Sadar', 'Mujib Nagar', 'angni']
  },
  {
    disName: 'Narail',
    areas: ['Narail Sadar', 'Kalia Nagar', 'Lohagara']
  },
  {
    disName: 'Satkhira',
    areas: ['Satkhira Sadar', 'Assasuni', 'Kalaroa', 'Debhata', 'Kaliganj', 'Tala', 'Shyamnagar']
  },
  {
    disName: 'Jamalpur',
    areas: [
      'Jamalpur Sadar',
      'Dewanganj',
      'Baksiganj',
      'Islampur',
      'Madarganj',
      'Melandaha',
      'Sarishabari',
      'Narundi Police I.C',
      'Others'
    ]
  },
  {
    disName: 'Mymensingh',
    areas: [
      'Mymensingh Sadar',
      'Bhaluka',
      'Trishal',
      'Haluaghat',
      'Muktagachha',
      'Dhobaura',
      'Fulbaria',
      'Gaffargaon',
      'Gauripur',
      'Ishwarganj',
      'Nandail',
      'Phulpur'
    ]
  },
  {
    disName: 'Netrokona',
    areas: [
      'Netrokona Sadar',
      'Kendua',
      'Atpara',
      'Barhatta',
      'Durgapur',
      'Kalmakanda',
      'Madan',
      'Mohanganj',
      'Netrakona-S',
      'Purbadhala',
      'Khaliajuri'
    ]
  },
  {
    disName: 'Sherpur',
    areas: ['Sherpur Sadar', 'Sreebardi', 'Jhenaigati', 'Nalitabari', 'Nakla']
  },
  {
    disName: 'Bogura',
    areas: [
      'Bogura Sadar',
      'Sherpur',
      'Dhunat',
      'Dhupchanchia',
      'Gabtali',
      'Kahaloo',
      'Nandigram',
      'Sahajanpur',
      'Sariakandi',
      'Shibganj',
      'Sonatala'
    ]
  },
  {
    disName: 'Joypurhat',
    areas: ['Joypurhat Sadar', 'Joypurhat', 'Akkelpur', 'Kalai', 'Khetlal']
  },
  {
    disName: 'Naogaon',
    areas: [
      'Naogaon Sadar',
      'Mohadevpur',
      'Manda',
      'Niamatpur',
      'Atrai',
      'Raninagar',
      'Patnitala',
      'Dhamoirhat',
      'Sapahar',
      'Porsha',
      'Badalgachhi'
    ]
  },
  {
    disName: 'Natore',
    areas: ['Natore Sadar', 'Baraigram', 'Bagatipara', 'Lalpur', 'Baraigram']
  },
  {
    disName: 'Chapainawabganj',
    areas: ['Chapainawabganj Sadar', 'Bholahat', 'Gomastapur', 'Shibganj', 'Nachole']
  },
  {
    disName: 'Pabna',
    areas: [
      'Pabna Sadar',
      'Atgharia',
      'Bera',
      'Bhangura',
      'Chatmohar',
      'Faridpur',
      'Ishwardi',
      'Santhia',
      'Sujanagar'
    ]
  },
  {
    disName: 'Rajshahi',
    areas: [
      'Bagha',
      'Bagmara',
      'Charghat',
      'Durgapur',
      'Godagari',
      'Mohanpur',
      'Paba',
      'Puthia',
      'Tanore',
      'Boalia',
      'Motihar',
      'Shahmokhdum',
      'Rajpara',
      'Others'
    ]
  },
  {
    disName: 'Sirajganj',
    areas: [
      'Sirajganj Sadar',
      'Belkuchi',
      'Chauhali',
      'Kamarkhanda',
      'Raiganj',
      'Shahjadpur',
      'Tarash',
      'Ullahpara'
    ]
  },
  {
    disName: 'Dinajpur',
    areas: [
      'Dinajpur Sadar',
      'Birampur',
      'Birganj',
      'Bochaganj',
      'Chirirbandar',
      'Phulbari',
      'Ghoraghat',
      'Hakimpur',
      'Kaharole',
      'Khansama',
      'Nawabganj',
      'Parbatipur'
    ]
  },
  {
    disName: 'Gaibandha',
    areas: [
      'Gaibandha Sadar',
      'Sadullapur',
      'Saghata',
      'Gobindaganj',
      'Fulchhari',
      'Palashbari',
      'Sundarganj'
    ]
  },
  {
    disName: 'Kurigram',
    areas: [
      'Kurigram Sadar',
      'Chilmari',
      'Nageshwari',
      'Char Rajibpur',
      'Rajarhat',
      'Bhurungamari',
      'Ulipur',
      'Rowmari',
      'Phulbari'
    ]
  },
  {
    disName: 'Lalmonirhat',
    areas: ['Lalmonirhat Sadar', 'Aditmari', 'Kaliganj', 'Hatibandha', 'Patgram']
  },
  {
    disName: 'Nilphamari',
    areas: ['Nilphamari Sadar', 'Saidpur', 'Jaldhaka', 'Kishoreganj', 'Domar', 'Dimla']
  },
  {
    disName: 'Panchagarh',
    areas: ['Panchagarh Sadar', 'Tetulia', 'Atwari', 'Boda', 'Debiganj']
  },
  {
    disName: 'Rangpur',
    areas: [
      'Rangpur Sadar',
      'Gangachara',
      'Badarganj',
      'Taraganj',
      'Kaunia',
      'Mithapukur',
      'Pirgachha',
      'Pirganj'
    ]
  },
  {
    disName: 'Thakurgaon',
    areas: ['Thakurgaon Sadar', 'Haripur', 'Ranisankail', 'Baliadangi', 'Pirganj']
  },
  {
    disName: 'Habiganj',
    areas: [
      'Habiganj Sadar',
      'Lakhai',
      'Madhabpur',
      'Ajmiriganj',
      'Chunarughat',
      'Nabiganj',
      'Shaistagonj',
      'Bahubal',
      'Baniachang'
    ]
  },
  {
    disName: 'Moulvibazar',
    areas: [
      'Moulvibazar Sadar',
      'Barlekha',
      'Juri',
      'Kamalganj',
      'Kulaura',
      'Rajnagar',
      'Sreemangal'
    ]
  },
  {
    disName: 'Sunamganj',
    areas: [
      'Sunamganj Sadar',
      'Dharampasha',
      'Bishwamvarpur',
      'Tahirpur',
      'Jagannathpur',
      'Derai',
      'Dowarabazar',
      'Chhatak',
      'Jamalganj',
      'Shanthiganj',
      'Sulla'
    ]
  },
  {
    disName: 'Sylhet',
    areas: [
      'Sylhet Sadar',
      'Beanibazar',
      'Bishwanath',
      'Dakshin Surma',
      'Balaganj',
      'Companiganj',
      'Fenchuganj',
      'Golapganj',
      'Gowainghat',
      'Jaintiapur',
      'Kanaighat',
      'Zakiganj',
      'Nobigonj',
      'Airport',
      'Hazrat Shah Paran',
      'Jalalabad',
      'Kowtali',
      'Moglabazar',
      'Osmani Nagar',
      'South Surma',
      'Others'
    ]
  }
];

export const companySizes = [
  '0-5 Employees',
  '5-10 Employees',
  '10-25 Employees',
  '25-50 Employees'
];

export const DISCOUNT_TYPE = [{ name: 'Amount' }, { name: 'Percentage' }];

export const getProductVariation = (products) => {
  const newProducts = [];

  if (products.length > 0) {
    products.forEach((item) => {
      if (item.colors.length > 0) {
        item.colors.forEach((color) => {
          if (item.sizes.length > 0) {
            item.sizes.forEach((size) => {
              const variationPrice = item.variations.find(
                (i) => i.colorId === color.id && i.sizeId === size.id
              );

              newProducts.push({
                ...item,
                s_code: `${item.id}_${newProducts.length}`,
                color,
                size,
                price: variationPrice ? variationPrice.price : item.price,
                purchase_price: variationPrice
                  ? variationPrice.purchase_price
                  : item.purchase_price,
                discount:
                  Math.round(
                    ((parseFloat(item.price) - parseFloat(item.discount_price)) /
                      parseFloat(item.price)) *
                      100
                  ) || 0
              });
            });
          } else {
            const variationPrice = item.variations.find(
              (i) => i.colorId === color.id && i.sizeId === null
            );
            // Doesn't have any size, add the color variation
            newProducts.push({
              ...item,
              s_code: `${item.id}_${newProducts.length}`,
              color,
              price: variationPrice ? variationPrice.price : item.price,
              purchase_price: variationPrice ? variationPrice.purchase_price : item.purchase_price,
              discount:
                Math.round(
                  ((parseFloat(item.price) - parseFloat(item.discount_price)) /
                    parseFloat(item.price)) *
                    100
                ) || 0
            });
          }
        });
      } else if (item.sizes.length > 0) {
        // Doesn't have any color, add the size variation
        item.sizes.forEach((size) => {
          const variationPrice = item.variations.find(
            (i) => i.colorId === null && i.sizeId === size.id
          );
          newProducts.push({
            ...item,
            s_code: `${item.id}_${newProducts.length}`,
            size,
            price: variationPrice ? variationPrice.price : item.price,
            purchase_price: variationPrice ? variationPrice.purchase_price : item.purchase_price,
            discount:
              Math.round(
                ((parseFloat(item.price) - parseFloat(item.discount_price)) /
                  parseFloat(item.price)) *
                  100
              ) || 0
          });
        });
      } else {
        // doesn't have any color or size, add the product only
        newProducts.push({
          ...item,
          s_code: `${item.id}_${newProducts.length}`,
          price: item.price,
          discount:
            Math.round(
              ((parseFloat(item.price) - parseFloat(item.discount_price)) /
                parseFloat(item.price)) *
                100
            ) || 0
        });
      }
    });
  }

  return newProducts;
};

export const getProductVariationPrice = (name, colors, sizes) => {
  const variations = [];

  if (colors.length > 0) {
    colors.forEach((color) => {
      if (sizes.length > 0) {
        sizes.forEach((size) => {
          variations.push({
            s_code: variations.length,
            name,
            color,
            size
          });
        });
      } else {
        // Doesn't have any size, add the color variation
        variations.push({
          s_code: variations.length,
          name,
          color
        });
      }
    });
  } else if (sizes.length > 0) {
    // Doesn't have any color, add the size variation
    sizes.forEach((size) => {
      variations.push({
        s_code: variations.length,
        name,
        size
      });
    });
  }

  for (const variation of variations) {
    variation.colorId = variation.color ? variation.color.id : '';
    variation.color = variation.color ? variation.color.name : '';
    variation.sizeId = variation.size ? variation.size.id : '';
    variation.size = variation.size ? variation.size.name : '';
  }

  return variations;
};

export const getProductVariationString = (item) => {
  return `${item.code} - ${item.name} ${
    item.color || item.size
      ? `(${item.color ? 'Color: ' + item.color.name + ', ' : ''}${
          item.size ? 'Size: ' + item.size.name + '' : ''
        })`
      : ''
  }`;
};

export const getProductVariationStringForSale = (item, branchId) => {
  return `${item.code} - ${item.name} ${
    item.color || item.size
      ? `(${item.color ? 'Color: ' + item.color.name + ', ' : ''}
    ${item.size ? 'Size: ' + item.size.name + '' : ''})`
      : ''
  } (A. Qty: ${getAvailableQtyItem(item, branchId) ? getAvailableQtyItem(item, branchId).qty : 0})`;
};

export const getStockVariation = (stocks) => {
  const newProducts = [];
  if (stocks.length > 0) {
    stocks.forEach((stockItem) => {
      stockItem.stockVariations.forEach((item) => {
        newProducts.push({
          ...stockItem,
          ...item,
          variationItem: item,
          s_code: `${stockItem.item.id}_${newProducts.length}`,
          color: item.color,
          size: item.size,
          variationDesc: `${stockItem.item.name} (${
            item.color ? 'Color: ' + item.color.name + ', ' : ''
          }${item.size ? 'Size: ' + item.size.name + '' : ''})`
        });
      });
    });
  }

  return newProducts;
};

function compareItems(item1, item2) {
  return item1.s_code === item2.s_code && item1.branchId === item2.branchId;
}

export function findDeletedItems(oldItems, newItems) {
  return oldItems.filter((oldItem) => !newItems.some((newItem) => compareItems(oldItem, newItem)));
}

export function findAddedItems(oldItems, newItems) {
  return newItems.filter((newItem) => !oldItems.some((oldItem) => compareItems(oldItem, newItem)));
}

export function findUpdatedItems(oldItems, newItems) {
  const updatedItems = [];

  newItems.forEach((newItem) => {
    const matchedItem = oldItems.find((oldItem) => compareItems(oldItem, newItem));

    if (
      matchedItem &&
      (matchedItem.qty !== newItem.qty ||
        matchedItem.rate !== newItem.rate ||
        matchedItem.discount !== newItem.discount)
    ) {
      updatedItems.push(newItem);
    }
  });

  return updatedItems;
}

export const getAvailableQtyItem = (value, branchId) => {
  return value.stockVariations.find((item) => {
    if (item.branchId === branchId) {
      if (value.color !== undefined && value.size !== undefined) {
        if (value.color?.id === item.colorId && value.size?.id === item.sizeId) {
          return item;
        } else if (item.colorId === null && value.size?.id === item.sizeId) {
          value.color = null;
          return item;
        } else if (value.color?.id === item.colorId && item.sizeId === null) {
          value.size = null;
          return item;
        }
      } else if (value.color === undefined && value.size !== undefined) {
        if (item.colorId === null && value.size?.id === item.sizeId) {
          return item;
        }
      } else if (value.size === undefined && value.color !== undefined) {
        if (value.color?.id === item.colorId && item.sizeId === null) {
          return item;
        }
      } else {
        return item;
      }
    }
    return null;
  });
};

export const filterProductVariation = (products, branchId) => {
  for (const product of products) {
    const newStockVariations = [];
    for (const stockVariation of product.stockVariations) {
      const existingVariation = newStockVariations.find(
        (item) =>
          item.branchId === stockVariation.branchId &&
          item.itemId === stockVariation.itemId &&
          item.colorId === stockVariation.colorId &&
          item.sizeId === stockVariation.sizeId
      );
      if (existingVariation) {
        existingVariation.qty += stockVariation.qty;
      } else {
        newStockVariations.push(stockVariation);
      }
    }

    product.stockVariations = newStockVariations;
  }

  return getProductVariation(products).filter(
    (item) => getAvailableQtyItem(item, branchId) && getAvailableQtyItem(item, branchId).qty > 0
  );
};

export const getMonthName = (month) => {
  if (month === 1) {
    return 'January';
  } else if (month === 2) {
    return 'February';
  } else if (month === 3) {
    return 'March';
  } else if (month === 4) {
    return 'April';
  } else if (month === 5) {
    return 'May';
  } else if (month === 6) {
    return 'June';
  } else if (month === 7) {
    return 'July';
  } else if (month === 8) {
    return 'August';
  } else if (month === 9) {
    return 'September';
  } else if (month === 10) {
    return 'October';
  } else if (month === 11) {
    return 'November';
  } else if (month === 12) {
    return 'December';
  } else {
    return '';
  }
};

export const getFullAddress = (address) => {
  return address
    ? `${address.division}, ${address.city}, ${address.area}, ${address.address}-${address.zip}`
    : '';
};

export const numberWithCommas = (x) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getProductImage = (data) => {
  let image = '';
  if (data.color && data.color.image) {
    image = data.color.image;
  } else if (data.size && data.size.image) {
    image = data.size.image;
  } else if (data.item.inventory_image) {
    image = data.item.inventory_image;
  } else if (data.item.image_1) {
    image = data.item.image_1;
  }

  return image;
};

export const REGEX_STRING = /\(([^]+)\)/;
