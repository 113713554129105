import moment from 'moment/moment';
import MaterialTable, { MTableToolbar } from 'material-table';
import { TableCell } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { createRef, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import FilterLayout from '../layout/FilterLayout';
import jsPDF from 'jspdf';
import { CsvBuilder } from 'filefy';
import 'jspdf-autotable';

const FilterMaterialTable = ({
  args = {}, // extra arguments to pass in api
  search,
  setSearch,
  type,
  title,
  data,
  allTime,
  inputFocus = true,
  options,
  actions = [],
  fetchMethod,
  tableContainerClass,
  toolbarChildren,
  isCustomPagination = false,
  showFilterLayout = true,
  ...props
}) => {
  const from_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const to_date = moment().format('YYYY-MM-DD');
  const defaultRowsPerPage = 20;

  const tableRef = createRef();
  const [searchText, setSearchText] = useState(search);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [debouncedValue] = useDebounce(searchText, 1000);
  const [queryBuilder, setQueryBuilder] = useState(
    search ? { search } : { from_date, to_date, type, size: rowsPerPage, ...args }
  );

  useEffect(() => {
    if (queryBuilder?.type !== type) {
      setRowsPerPage(defaultRowsPerPage);
      setQueryBuilder((prevQueryBuilder) => ({
        ...prevQueryBuilder,
        type,
        size: defaultRowsPerPage
      }));
    }
  }, [type, queryBuilder]);

  useEffect(() => {
    const argsChanged = Object.keys(args).some(
      (key) => args[key] !== queryBuilder[key],
    );

    if (argsChanged) {
      setQueryBuilder((prevQueryBuilder) => ({
        ...prevQueryBuilder,
        ...args,
      }));
    }
  }, [args]);

  useEffect(() => {
    if (queryBuilder) {
      fetchData();
    }
  }, [queryBuilder]);

  const fetchData = () => {
    fetchMethod({ ...queryBuilder, ...args });
  };

  useEffect(() => {
    if (searchText && queryBuilder?.search !== searchText) {
      handleQueryBuilder({
        search: searchText,
        size: defaultRowsPerPage
      });
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (tableRef.current && !searchText) {
      tableRef.current.dataManager.changeSearchText(searchText);
      tableRef.current.setState({ searchText: searchText });
      tableRef.current.setState(tableRef.current.dataManager.getRenderState());
    }
  }, [searchText, tableRef]);

  const handleSearch = (value) => {
    setSearchText(value);
    setSearch && setSearch(value);

    if (!value) {
      handleQueryBuilder({
        from_date,
        to_date
      });
    }
  };

  const handleQueryBuilder = (value) => {
    setQueryBuilder({
      ...value,
      type,
      size: rowsPerPage,
      ...args
    });
  };

  const getAllDataToExport = async () => {
    const columnTitles = props.columns.map((columnDef) => columnDef.title);
    const exportDataRes = await fetchMethod({
      ...queryBuilder,
      page: 1,
      size: data?.total,
      isExportData: true
    });

    let exportData = exportDataRes?.paginatedData;
    exportData = exportData.map((rowData) =>
      props.columns.map((columnDef) => {
        const fieldKeys = columnDef.field.split('.');
        return fieldKeys.reduce((acc, key) => acc?.[key], rowData);
      })
    );

    return { columnTitles, exportData };
  };

  const exportToCSV = async () => {
    if (!props.columns || !data?.total) return null;

    const { columnTitles, exportData } = await getAllDataToExport();
    return new CsvBuilder(`${title} - ${moment().format('LL')}.csv`)
      .setColumns(columnTitles)
      .addRows(exportData)
      .exportFile();
  };

  const exportToPDF = async () => {
    if (!props.columns || !data?.total) return null;

    const { columnTitles, exportData } = await getAllDataToExport();
    const doc = new jsPDF();
    doc.autoTable({
      head: [columnTitles],
      body: exportData
    });

    doc.save(`${title} - ${moment().format('LL')}.pdf`);
  };

  return (
    <>
      {showFilterLayout && (
        <FilterLayout
          handleActions={handleQueryBuilder}
          allTime={allTime}
          setSearch={setSearchText}
        />
      )}

      <div id="card-content" className={`d-grid ${tableContainerClass}`}>
        <div className={'overflow-auto'}>
          <MaterialTable
            key={data?.paginatedData.length}
            title={title}
            tableRef={tableRef}
            data={data?.paginatedData}
            totalCount={data?.total}
            options={{
              headerStyle: {
                backgroundColor: '#F6F9FC',
                color: '#000'
              },
              padding: 'default',
              pageSize: rowsPerPage,
              actionsColumnIndex: -1,
              columnResizable: true,
              pageSizeOptions: [20, 50, 100],
              showFirstLastPageButtons: true,
              exportFileName: `${title} - ${moment().format('LL')}`,
              searchFieldAlignment: 'left',
              searchFieldStyle: {
                marginLeft: '3rem'
              },
              actionsCellStyle: {
                marginLeft: 'auto'
              },
              searchText,
              searchAutoFocus: inputFocus,
              exportButton: false,
              ...options
            }}
            onSearchChange={handleSearch}
            components={
              isCustomPagination && {
                Toolbar: (props) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: '30px'
                    }}>
                    {toolbarChildren}

                    <div className="w-100">
                      <MTableToolbar {...props} />
                    </div>
                  </div>
                ),
                Pagination: () => (
                  <TableCell className="text-center d-flex justify-content-end pagination mt-3 align-items-center">
                    <form action="#" className={'d-flex align-items-center form-group'}>
                      <label htmlFor={'pageSize'} className={'text-primary mb-0'}>
                        Rows Per Page
                      </label>
                      <select
                        className="form-control border-primary text-primary rows-per-page"
                        name={'pageSize'}
                        value={rowsPerPage}
                        onChange={(e) => {
                          const newSize = parseInt(e.target.value);
                          setRowsPerPage(newSize);
                          setQueryBuilder((prevState) => ({
                            ...prevState,
                            size: newSize
                          }));
                        }}>
                        {/*<option value={data?.total || defaultRowsPerPage}>All</option>*/}
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </form>

                    <Pagination
                      color={'primary'}
                      count={data?.lastPage}
                      page={data?.currentPage}
                      boundaryCount={2}
                      onChange={(e, value) => setQueryBuilder({ ...queryBuilder, page: value })}
                    />
                  </TableCell>
                )
              }
            }
            actions={[
              {
                icon: 'save_alt',
                onClick: () => exportToCSV(),
                isFreeAction: true,
                tooltip: 'Export To CSV'
              },
              {
                icon: 'print',
                onClick: () => exportToPDF(),
                isFreeAction: true,
                tooltip: 'Export To PDF'
              },
              {
                icon: 'refresh',
                onClick: () => fetchData(),
                isFreeAction: true,
                tooltip: 'Refresh'
              },
              ...actions
            ]}
            {...props}
          />
        </div>
      </div>
    </>
  );
};

export default FilterMaterialTable;
