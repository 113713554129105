import {
  GET_SALE_ID,
  LOADING,
  LOADING_FALSE,
  PACKAGING_DETAILS,
  PENDING_SALE,
  QUOTATION,
  QUOTATION_DETAILS,
  SALE,
  SALE_DETAILS,
  SALE_EXCHANGE_DETAILS,
  SALE_EXCHANGES,
  SALE_RETURN,
  SALE_RETURN_DETAILS
} from './types';
import { returnErrors } from './messages';
import API from '../api';
import Swal from 'sweetalert2';
import { GENERAL_CUSTOMER } from '../const';
import { findAddedItems, findDeletedItems, findUpdatedItems } from '../utils';

export const addSale = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  let customerId = '';
  let saleId = '';

  try {
    if (data.customerId === GENERAL_CUSTOMER) {
      const customerData = {
        name: data.customerName,
        phone: data.customerPhone,
        email: '',
        address: data.customerAddress
      };

      const customerRes = await API.customers.addCustomer(customerData);
      customerId = customerRes.data.data.id;
    } else {
      customerId = data.customerId;
    }

    const saleData = {
      customerId: customerId,
      paymentMethodId: data.paymentMethodId,
      userId: data.userId,
      date: data.date,
      notes: data.note,
      sub_total: data.subTotal,
      discount: data.discount,
      paid: data.paid,
      transport_cost: data.transportCost,
      due: data.due,
      vat: data.vat,
      vat_value: data.vatValue,
      total: data.total
    };

    // Add sale data and get the sale ID
    const res = await API.sale.addSale(saleData);

    saleId = res.data.data.id;

    // Add all the item including variations item in sale details
    // add stock variation
    // add item to stock
    for (const item of data.cartsItem) {
      const itemData = {
        itemId: item.id,
        qty: item.qty,
        unit_price: item.rate,
        total: item.total,
        description: item.description,
        colorId: item.colorId,
        sizeId: item.sizeId,
        s_code: item.s_code,
        is_first: 'true',
        discount: item.discount,
        branchId: item.branchId
      };

      const saleOrderRes = await API.sale.addSaleItem(saleId, itemData);

      // add stock variation
      const itemVariationStock = {
        qty: item.qty,
        itemId: item.id,
        branchId: item.branchId,
        colorId: item.colorId,
        sizeId: item.sizeId,
        orderDetailsId: saleOrderRes.data.data.id
      };

      await API.stocks.decrementItemVariationStock(itemVariationStock);
      const stockReportData = {
        qty: item.qty,
        date: saleData.date,
        itemId: item.id,
        branchId: item.branchId,
        saleOrderId: saleId,
        purchaseOrderId: '',
        trans_type: 'stock_out'
      };

      await API.stocks.stockReport(stockReportData);
    }

    // Unique object of array with all qty
    const uniqueArray = [];
    for (const item of data.cartsItem) {
      const index = uniqueArray.findIndex((i) => i.id === item.id && i.branchId === item.branchId);
      if (index !== -1) {
        uniqueArray[index].qty += parseInt(item.qty);
      } else {
        uniqueArray.push(item);
      }
    }

    for (const item of uniqueArray) {
      const stockData = {
        itemId: item.id,
        branchId: item.branchId,
        qty: item.qty
      };

      await API.stocks.decrementStockItem(stockData);
    }

    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully made sale.',
      showConfirmButton: false,
      timer: 1500
    });

    return res.data.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });

    // If any error occurs, clear the saved data
    const formData = {
      customerId: data.customerId === GENERAL_CUSTOMER ? customerId : '',
      saleId
    };
    await API.sale.clearSaleEntry(formData);
  }
};

export const updateSale = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  let customerId;
  let saleId = data.id;

  let oldCart = data.oldCartsItem;
  let newCart = data.cartsItem;

  console.log('rabbi', data.cartsItem);

  try {
    const deletedItems = findDeletedItems(oldCart, newCart);
    const addedItems = findAddedItems(oldCart, newCart);
    const updatedItems = findUpdatedItems(oldCart, newCart);

    console.log('rabbi added, ', addedItems);
    console.log('rabbi deleted, ', deletedItems);
    console.log('rabbi updated, ', updatedItems);

    if (data.customerId === GENERAL_CUSTOMER) {
      const customerData = {
        name: data.customerName,
        phone: data.customerPhone,
        email: '',
        address: data.customerAddress
      };

      const customerRes = await API.customers.addCustomer(customerData);
      customerId = customerRes.data.data.id;
    } else {
      customerId = data.customerId;
    }

    const saleData = {
      customerId: customerId,
      paymentMethodId: data.paymentMethodId,
      userId: data.userId,
      date: data.date,
      notes: data.note,
      sub_total: data.subTotal,
      discount: data.discount,
      paid: data.paid,
      transport_cost: data.transportCost,
      due: data.due,
      vat: data.vat,
      vat_value: data.vatValue,
      total: data.total
    };

    // update sale data and get the sale id
    await API.sale.updateSale(saleId, saleData);

    // update sale item
    for (const item of updatedItems) {
      // get detail id from old cart item only for update cart item
      const existingCartItem = oldCart.find(
        (i) =>
          i.itemId === item.itemId &&
          i.s_code === item.s_code &&
          i.sizeId === item.sizeId &&
          i.colorId === item.colorId
      );

      const itemData = {
        orderDetailItemId: existingCartItem.id,
        itemId: item.itemId,
        qty: item.qty,
        unit_price: item.rate,
        total: item.total,
        description: item.description,
        colorId: item.colorId,
        sizeId: item.sizeId,
        s_code: item.s_code,
        discount: item.discount,
        branchId: item.branchId
      };

      const saleOrderRes = await API.sale.updateSaleItem(saleId, itemData);

      const qty =
        item.qty -
        data.oldCartsItem.find(
          (o1) =>
            o1.itemId === item.itemId && o1.s_code === item.s_code && o1.branchId === item.branchId
        ).qty;
      // decrement qty from stock
      if (qty > 0) {
        const itemVariationStock = {
          qty,
          itemId: item.itemId,
          branchId: item.branchId,
          colorId: item.colorId,
          sizeId: item.sizeId,
          orderDetailsId: saleOrderRes.data.data.id
        };

        await API.stocks.decrementItemVariationStock(itemVariationStock);
        const stockReportData = {
          qty,
          date: saleData.date,
          itemId: item.itemId,
          branchId: item.branchId,
          saleOrderId: saleId,
          purchaseOrderId: '',
          trans_type: 'stock_out'
        };

        await API.stocks.stockReport(stockReportData);
      } else if (qty < 0) {
        // add stock variation
        const itemVariationStock = {
          qty: Math.abs(qty),
          itemId: item.itemId,
          branchId: item.branchId,
          colorId: item.colorId,
          sizeId: item.sizeId
        };

        await API.stocks.addStockItemVariation(itemVariationStock);
        const stockReportData = {
          qty: Math.abs(qty),
          date: saleData.date,
          itemId: item.itemId,
          branchId: item.branchId,
          saleOrderId: saleId,
          purchaseOrderId: '',
          trans_type: 'stock_in'
        };

        await API.stocks.stockReport(stockReportData);
      }
    }

    // add new sale item
    for (const item of addedItems) {
      const itemData = {
        itemId: item.id,
        qty: item.qty,
        unit_price: item.rate,
        total: item.total,
        description: item.description,
        colorId: item.colorId,
        sizeId: item.sizeId,
        s_code: item.s_code,
        discount: item.discount,
        branchId: item.branchId
      };

      const saleOrderRes = await API.sale.addSaleItem(saleId, itemData);

      // add stock variation
      const itemVariationStock = {
        qty: item.qty,
        itemId: item.id,
        branchId: item.branchId,
        colorId: item.colorId,
        sizeId: item.sizeId,
        orderDetailsId: saleOrderRes.data.data.id
      };

      await API.stocks.decrementItemVariationStock(itemVariationStock);
      const stockReportData = {
        qty: item.qty,
        date: saleData.date,
        itemId: item.id,
        branchId: item.branchId,
        saleOrderId: saleId,
        purchaseOrderId: '',
        trans_type: 'stock_out'
      };

      await API.stocks.stockReport(stockReportData);
    }

    // delete sale item
    for (const item of deletedItems) {
      await API.sale.deleteSaleItem(item.id);

      // add stock variation
      const itemVariationStock = {
        qty: item.qty,
        itemId: item.itemId,
        branchId: item.branchId,
        colorId: item.colorId,
        sizeId: item.sizeId
      };

      await API.stocks.addStockItemVariation(itemVariationStock);
      const stockReportData = {
        qty: item.qty,
        date: saleData.date,
        itemId: item.itemId,
        branchId: item.branchId,
        saleOrderId: saleId,
        purchaseOrderId: '',
        trans_type: 'stock_in'
      };

      await API.stocks.stockReport(stockReportData);

      // add item to stock
      const stockData = {
        itemId: item.itemId,
        branchId: item.branchId,
        qty: item.qty
      };

      await API.stocks.addStockItem(stockData);
    }

    // Unique object of array with all qty
    const uniqueArray = [];
    for (const item of data.cartsItem) {
      let existingQty = 0;
      let itemId;
      let branchId;
      // Get the existing item
      const oldItem = data.oldCartsItem.find(
        (o1) =>
          o1.itemId === item.itemId && o1.s_code === item.s_code && o1.branchId === item.branchId
      );

      // if exists get existing qty
      if (oldItem) {
        itemId = oldItem.itemId;
        existingQty = oldItem.qty;
        branchId = oldItem.branchId;
      } else {
        itemId = item.id;
        branchId = item.branchId;
      }

      const index = uniqueArray.findIndex((i) => i.id === itemId && i.branchId === branchId);

      if (index !== -1) {
        uniqueArray[index].qty += parseInt(item.qty);
      } else {
        uniqueArray.push({
          id: itemId,
          branchId,
          qty: item.qty
        });
      }

      // remove existing qty
      uniqueArray.map((i) => {
        if (i.id === itemId) {
          i.qty -= existingQty;
        }
      });
    }

    console.log(uniqueArray);

    for (const item of uniqueArray) {
      // decrement qty from stock
      if (item.qty > 0) {
        const stockData = {
          itemId: item.id,
          branchId: item.branchId,
          qty: item.qty
        };

        await API.stocks.decrementStockItem(stockData);
      } else {
        // add item to stock
        const stockData = {
          itemId: item.id,
          branchId: item.branchId,
          qty: Math.abs(item.qty)
        };

        await API.stocks.addStockItem(stockData);
      }
    }

    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: 'Sale Updated',
      text: 'You have successfully updated a sale.',
      showConfirmButton: false,
      timer: 1500
    });

    return saleId;
  } catch (err) {
    console.log('rabbi', err);
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const acceptSaleOrder = (id, from_date, to_date) => async (dispatch) => {
  try {
    const availabilityRes = await API.stocks.checkStockAvailability(id);
    if (!availabilityRes.data.success) {
      return availabilityRes.data.data;
    }

    const res = await API.sale.getSaleDetails(id);
    const saleData = res.data.data;

    for (const item of saleData.saleOrderItems) {
      // add stock variation
      const itemVariationStock = {
        qty: item.qty,
        itemId: item.itemId,
        branchId: item.branchId,
        colorId: item.colorId,
        sizeId: item.sizeId,
        orderDetailsId: item.id
      };

      await API.stocks.decrementItemVariationStock(itemVariationStock);
      const stockReportData = {
        qty: item.qty,
        date: saleData.date,
        itemId: item.itemId,
        branchId: item.branchId,
        saleOrderId: saleData.id,
        purchaseOrderId: '',
        trans_type: 'stock_out'
      };

      await API.stocks.stockReport(stockReportData);
    }

    // Unique object of array with all qty
    const uniqueArray = [];
    for (const item of saleData.saleOrderItems) {
      const index = uniqueArray.findIndex(
        (i) => i.itemId === item.itemId && i.branchId === item.branchId
      );
      if (index !== -1) {
        uniqueArray[index].qty += parseInt(item.qty);
      } else {
        uniqueArray.push(item);
      }
    }

    for (const item of uniqueArray) {
      const stockData = {
        itemId: item.itemId,
        branchId: item.branchId,
        qty: item.qty
      };

      await API.stocks.decrementStockItem(stockData);
    }

    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: 'Sale successful',
      text: 'You have successfully accept a sale.',
      showConfirmButton: false,
      timer: 1500
    });

    const customerRes = API.customers.getCustomer(saleData.customerId);
    if (customerRes.data.data) {
      const result = await Swal.fire({
        title: 'Want to send a sms to customer?',
        text: 'A order confirmation sms with invoice Id and total amount will be sent to customer phone.  ',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes, Send SMS!'
      });
      if (result.isConfirmed) {
        const MESSAGE = `Order confirmed from OWBD, INV#${saleData.id}, Total amount ${Number(saleData.total)} \nThank You.`;
        await API.sms.sendSms(customerRes.data.data.phone, MESSAGE);
      }
    }

    if (from_date && to_date) {
      dispatch(getSaleList({ from_date, to_date }));
    } else {
      dispatch(getSaleDetails(id));
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getSaleId = () => async (dispatch) => {
  try {
    const res = await API.sale.getSaleId();
    dispatch({ type: GET_SALE_ID, payload: res.data.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const exchangeSale = (data, saleId) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const exchangeData = {
      customerId: data.customerId,
      paymentMethodId: data.paymentMethodId,
      userId: data.userId,
      date: data.date,
      notes: data.note,
      sub_total: data.subTotal,
      discount: data.discount,
      paid: data.paid,
      transport_cost: data.transportCost,
      due: data.due,
      vat: data.vat,
      vat_value: data.vatValue,
      total: data.total
    };

    // add sale exchange data and get the sale exchange id
    const exchangeRes = await API.sale.saleExchange(saleId, exchangeData);

    // Add all the item including variations item in sale details
    // add stock variation
    // add item to stock
    for (const item of data.cartsItem) {
      const itemData = {
        itemId: item.id,
        qty: item.qty,
        unit_price: item.rate,
        total: item.total,
        description: item.description,
        colorId: item.colorId,
        sizeId: item.sizeId,
        s_code: item.s_code,
        is_first: 'true',
        discount: item.discount,
        saleExchangeId: exchangeRes.data.data.id,
        branchId: item.branchId
      };

      const saleOrderRes = await API.sale.addSaleItem(saleId, itemData);

      // add stock variation
      const itemVariationStock = {
        qty: item.qty,
        itemId: item.id,
        branchId: item.branchId,
        colorId: item.colorId,
        sizeId: item.sizeId,
        orderDetailsId: saleOrderRes.data.data.id
      };

      await API.stocks.decrementItemVariationStock(itemVariationStock);
      const stockReportData = {
        qty: item.qty,
        date: data.date,
        itemId: item.id,
        branchId: item.branchId,
        saleOrderId: saleId,
        purchaseOrderId: '',
        trans_type: 'stock_out'
      };

      await API.stocks.stockReport(stockReportData);
    }

    // Unique object of array with all qty
    const uniqueArray = [];
    for (const item of data.cartsItem) {
      const index = uniqueArray.findIndex((i) => i.id === item.id && i.branchId === item.branchId);
      if (index !== -1) {
        uniqueArray[index].qty += parseInt(item.qty);
      } else {
        uniqueArray.push(item);
      }
    }

    for (const item of uniqueArray) {
      const stockData = {
        itemId: item.id,
        branchId: item.branchId,
        qty: item.qty
      };

      await API.stocks.decrementStockItem(stockData);
    }

    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: exchangeRes.data.message,
      text: 'You have successfully made sale exchange.',
      showConfirmButton: false,
      timer: 1500
    });

    return exchangeRes.data.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getSaleList =
  ({ page, size, from_date, to_date, all, search, isExportData, statusId }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.sale.getSaleList({ page, size, from_date, to_date, all, search, statusId });
      dispatch({ type: LOADING_FALSE });

      if (isExportData) return res.data.data;

      dispatch({
        type: SALE,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getPendingSales =
  ({ from_date, to_date, size, page, isExportData }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.sale.getPendingSales({ from_date, to_date, size, page });
      dispatch({ type: LOADING_FALSE });
      if (isExportData) return res.data.data;
      dispatch({
        type: PENDING_SALE,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getSaleExchanges =
  ({ page, size, from_date, to_date, isExportData }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.sale.getSalesExchangeList({ page, size, from_date, to_date });
      dispatch({ type: LOADING_FALSE });
      if (isExportData) return res.data.data;
      dispatch({
        type: SALE_EXCHANGES,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getSaleExchangeDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.sale.getSaleExchangeDetails(id);
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: SALE_EXCHANGE_DETAILS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getSaleDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.sale.getSaleDetails(id);
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: SALE_DETAILS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getPackagingDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.sale.getPackagingDetails(id);
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: PACKAGING_DETAILS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getExchangePackagingDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.sale.getExchangePackagingDetails(id);
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: PACKAGING_DETAILS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addSaleReturn = (id, data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.sale.addSaleReturn(id, data);
    dispatch({ type: LOADING_FALSE });

    if (!res.data.success) {
      await Swal.fire('Return Invoice Failed', res.data.message, 'error');
      return;
    }

    await Swal.fire(res.data.message, 'Successfully sale returned and stock adjustment', 'success');

    dispatch(getSaleDetails(id));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getSaleReturnList =
  ({ page, size, from_date, to_date, isExportData }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.sale.getSaleReturnList({ page, size, from_date, to_date });
      dispatch({ type: LOADING_FALSE });
      if (isExportData) return res.data.data;
      dispatch({
        type: SALE_RETURN,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const paySaleReturn = (data, from_date, to_date) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.sale.paySaleReturn(data);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire(res.data.message, 'Successfully returned amount paid.', 'success');

    dispatch(getSaleReturnList({ from_date, to_date }));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getSaleReturnsDetails = (saleId) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.sale.getSaleReturnsDetails(saleId);
    dispatch({ type: LOADING_FALSE });

    dispatch({
      type: SALE_RETURN_DETAILS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addSaleOrderPayment = (data, from_date, to_date) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.sale.addSaleOrderPayment(data);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'Successfully sale payment added',
      showConfirmButton: false,
      timer: 1500
    });

    const result = await Swal.fire({
      title: 'Want to send a sms to customer?',
      text: "A successful payment receive sms with invoice Id and due amount will be sent to customer phone.  ",
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes, Send SMS!'
    })
    if (result.isConfirmed) {
      const MESSAGE = `Successfully payment received for INV#${data.id} \nThank You.`;
      await API.sms.sendSms(data?.phone, MESSAGE);
    }

    if (from_date && to_date) {
      dispatch(getSaleList({ from_date, to_date }));
    } else {
      dispatch(getSaleDetails(data.id));
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addExchangeOrderPayment = (data, from_date, to_date) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.sale.addExchangeOrderPayment(data);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire(res.data.message, 'Successfully sale exchange payment added', 'success');

    dispatch(getSaleExchanges({ from_date, to_date }));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addPackaging =
  (data, saleId, isExchange = false) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.sale.addPackaging(data);
      dispatch({ type: LOADING_FALSE });

      await Swal.fire(res.data.message, 'Successfully item packaged', 'success');

      if (isExchange) {
        dispatch(getExchangePackagingDetails(saleId));
      } else {
        dispatch(getPackagingDetails(saleId));
      }
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const clearPackedItems =
  (data, saleId, isExchange = false) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.sale.clearPackedItems(data);
      dispatch({ type: LOADING_FALSE });

      await Swal.fire(res.data.message, 'Packed items cleared to 0, repack items', 'success');

      if (isExchange) {
        dispatch(getExchangePackagingDetails(saleId));
      } else {
        dispatch(getPackagingDetails(saleId));
      }
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const markDeliver = (saleId) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.sale.markDeliver(saleId);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire(res.data.message, 'Successfully handed over for delivery', 'success');

    window.history.back();
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const markExchangeDeliver = (exchangeId) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.sale.markExchangeDeliver(exchangeId);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire(res.data.message, 'Successfully handed over for delivery', 'success');

    window.history.back();
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const cancelSale =
  (id, data = {}) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.sale.cancelSale(id, data);
      dispatch({ type: LOADING_FALSE });

      await Swal.fire({
        icon: 'success',
        title: res.data.message,
        text: 'You have successfully cancelled a sale.',
        showConfirmButton: false,
        timer: 1500
      });

      window.location.reload();
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const addQuotation = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  let customerId;
  let quotationId;

  try {
    // If customer is a general customer, save a customer first
    if (data.customerId === GENERAL_CUSTOMER) {
      const customerData = {
        name: data.customerName,
        phone: data.customerPhone,
        email: '',
        address: data.customerAddress
      };

      const customerRes = await API.customers.addCustomer(customerData);
      customerId = customerRes.data.data.id;
    } else {
      customerId = data.customerId;
    }

    const quotationData = {
      customerId: customerId,
      paymentMethodId: data.paymentMethodId,
      userId: data.userId,
      date: data.date,
      notes: data.note,
      sub_total: data.subTotal,
      discount: data.discount,
      transport_cost: data.transportCost,
      vat: data.vat,
      vat_value: data.vatValue,
      total: data.total
    };

    // Add sale data and get the sale ID
    const res = await API.sale.addQuotation(quotationData);

    quotationId = res.data.data.id;

    // Add all the item including variations item in sale details
    for (const item of data.cartsItem) {
      const itemData = {
        itemId: item.id,
        qty: item.qty,
        unit_price: item.rate,
        total: item.total,
        description: item.description,
        colorId: item.colorId,
        sizeId: item.sizeId,
        s_code: item.s_code,
        discount: item.discount,
        branchId: item.branchId
      };

      await API.sale.addQuotationItem(quotationId, itemData);
    }

    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully added quotation.',
      showConfirmButton: false,
      timer: 1500
    });

    return res.data.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateQuotation = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  let customerId;
  let quotationId = data.id;

  let oldCart = data.oldCartsItem;
  let newCart = data.cartsItem;

  try {
    const deletedItems = findDeletedItems(oldCart, newCart);
    const addedItems = findAddedItems(oldCart, newCart);
    const updatedItems = findUpdatedItems(oldCart, newCart);

    if (data.customerId === GENERAL_CUSTOMER) {
      const customerData = {
        name: data.customerName,
        phone: data.customerPhone,
        email: '',
        address: data.customerAddress
      };

      const customerRes = await API.customers.addCustomer(customerData);
      customerId = customerRes.data.data.id;
    } else {
      customerId = data.customerId;
    }

    const quotationData = {
      customerId: customerId,
      paymentMethodId: data.paymentMethodId,
      userId: data.userId,
      date: data.date,
      notes: data.note,
      sub_total: data.subTotal,
      discount: data.discount,
      transport_cost: data.transportCost,
      vat: data.vat,
      vat_value: data.vatValue,
      total: data.total
    };

    // update quotation data
    await API.sale.updateQuotation(quotationId, quotationData);

    // update quotation item
    for (const item of updatedItems) {
      // get detail id from old cart item only for update cart item
      const existingCartItem = oldCart.find(
        (i) =>
          i.itemId === item.itemId &&
          i.s_code === item.s_code &&
          i.sizeId === item.sizeId &&
          i.colorId === item.colorId
      );

      const itemData = {
        quotationItemId: existingCartItem.id,
        itemId: item.itemId,
        qty: item.qty,
        unit_price: item.rate,
        total: item.total,
        description: item.description,
        colorId: item.colorId,
        sizeId: item.sizeId,
        s_code: item.s_code,
        discount: item.discount,
        branchId: item.branchId
      };

      await API.sale.updateQuotationItem(quotationId, itemData);
    }

    // add new quotation item
    for (const item of addedItems) {
      const itemData = {
        itemId: item.itemId,
        qty: item.qty,
        unit_price: item.rate,
        total: item.total,
        description: item.description,
        colorId: item.colorId,
        sizeId: item.sizeId,
        s_code: item.s_code,
        discount: item.discount,
        branchId: item.branchId
      };

      await API.sale.addQuotationItem(quotationId, itemData);
    }

    // delete quotation item
    for (const item of deletedItems) {
      await API.sale.deleteQuotationItem(item.id);
    }

    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: 'Quotation Updated!',
      text: 'You have successfully updated a quotation.',
      showConfirmButton: false,
      timer: 1500
    });

    return quotationId;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getQuotationList =
  ({ page, size, from_date, to_date, isExportData }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.sale.getQuotationList({ page, size, from_date, to_date });
      dispatch({ type: LOADING_FALSE });
      if (isExportData) return res.data.data;
      dispatch({
        type: QUOTATION,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getQuotationDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.sale.getQuotationDetails(id);
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: QUOTATION_DETAILS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const deleteQuotation = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.sale.deleteQuotation(id);
    dispatch({ type: LOADING_FALSE });

    const dialogRes = await Swal.fire(
      res.data.message,
      'You have successfully deleted a quotation.',
      'success'
    );
    if (dialogRes) {
      window.history.back();
    }
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};
