import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSupplierPayment } from '../../../actions/suppliers';
import { useHistory } from 'react-router-dom';
import FilterMaterialTable from '../../common/FilterMaterialTable';

function SupplierPayments({
  isLoading,
  supplierId,
  getSupplierPayment,
  suppliers: { supplierPayments }
}) {
  const history = useHistory();

  const handleDetails = (id) => {
    history.push({
      pathname: '/suppliers/add-payment',
      search: `?id=${id}`
    });
  };

  const tableHead = [
    { field: 'date', title: 'Date' },
    { field: 'trans_type', title: 'Type' },
    { field: 'supplier.name', title: 'Supplier Name' },
    { field: 'supplier.phone', title: 'Supplier Phone' },
    { field: 'bank_tran.bank_account.account_name', title: 'Account' },
    { field: 'description', title: 'Description' },
    { field: 'reference', title: 'Reference' },
    { field: 'amount', title: 'Amount (৳)' }
  ];

  return (
    <Fragment>
      <div className="container-fluid">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Add Supplier Payment</h5>
                <p className={'mb-0'}>Add and manage supplier payments</p>
              </div>
              <div>
                <a
                  onClick={() => handleDetails(supplierId)}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add Payment
                </a>
              </div>
            </div>
          </div>
        </div>

        <FilterMaterialTable
          title={'Supplier Payments'}
          isLoading={isLoading}
          columns={tableHead}
          data={supplierPayments}
          tableContainerClass={'mt-4'}
          fetchMethod={getSupplierPayment}
          isCustomPagination
          args={{
            id: supplierId
          }}
          options={{
            rowStyle: (rowData) => {
              return {
                fontSize: '14px',
                color: '#000'
              };
            }
          }}
        />
      </div>
    </Fragment>
  );
}

SupplierPayments.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  suppliers: PropTypes.object.isRequired,
  getSupplierPayment: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  suppliers: state.suppliers
});

export default connect(mapStateToProps, { getSupplierPayment })(SupplierPayments);
