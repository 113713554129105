import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Breadcrumb from '../../../layout/Breadcrumb';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import { addRole } from '../../../../actions/system';
import { ROLES } from '../../../../permissions';
import { ADMINISTRATION_SECTIONS } from '../../../../utils';

function AddRole({ isLoading, addRole, auth: { permissions } }) {
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    description: ''
  });

  const { name, description } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === ROLES)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const saveNewRole = (e) => {
    e.preventDefault();

    addRole({ name, description });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/administration'}>Administration</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/administration/add-role'}>Add Role</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={ADMINISTRATION_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form id="card-content" onSubmit={(e) => saveNewRole(e)}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items center">
              <div>
                <h5>Create new role</h5>
                <p>Create and manage roles</p>
              </div>
              <div>
                <button type={'submit'} className="btn btn-primary p-2 me-2" disabled={isLoading}>
                  Save
                </button>
                <button
                  onClick={onCancelButtonClicked}
                  type={'button'}
                  disabled={isLoading}
                  className="btn btn-danger p-2 text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'name'}>
                    Name <span className={'text-danger'}>*</span>
                  </label>
                  <input
                    type="text"
                    name={'name'}
                    required
                    value={name}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter role name'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'description'}>Description</label>
                  <input
                    type="text"
                    name={'description'}
                    value={description}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter description'}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

AddRole.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  addRole: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  auth: state.auth
});

export default connect(mapStateToProps, { addRole })(AddRole);
