import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { addSupplier } from '../../../actions/suppliers';
import { SUPPLIER_ENTRY } from '../../../permissions';
import { SUPPLIER_SECTIONS } from '../../../utils';

function AddSupplier({ isLoading, addSupplier, auth: { permissions } }) {
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    address: ''
  });

  const { name, phone, email, address } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === SUPPLIER_ENTRY)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const saveSupplier = async (e) => {
    e.preventDefault();

    const data = await addSupplier(formData);
    if (data) {
      history.push({
        pathname: '/suppliers/details',
        search: `?id=${data.id}`
      });
    }
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/suppliers'}>Suppliers</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/suppliers/add-supplier'}>Add Supplier</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={SUPPLIER_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form id="card-content" onSubmit={(e) => saveSupplier(e)}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items center">
              <div>
                <Fragment>
                  <h5>Add New Supplier</h5>
                  <p>Add and manage new suppliers</p>
                </Fragment>
              </div>
              <div>
                <button type={'submit'} className="btn btn-primary p-2 me-2" disabled={isLoading}>
                  Save
                </button>
                <button
                  onClick={onCancelButtonClicked}
                  type={'button'}
                  disabled={isLoading}
                  className="btn btn-danger p-2 text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className="card-body mt-2">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'name'}>
                    Name <span className={'text-danger'}>*</span>
                  </label>
                  <input
                    type="text"
                    name={'name'}
                    required
                    value={name}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter supplier name'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'phone'}>
                    Phone <span className={'text-danger'}>*</span>
                  </label>
                  <input
                    type="text"
                    name={'phone'}
                    value={phone}
                    required
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter phone'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'email'}>Email</label>
                  <input
                    type="email"
                    name={'email'}
                    value={email}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter email'}
                  />
                </div>
              </div>

              <div className="col-md-4 mt-4">
                <div className="form-group">
                  <label htmlFor={'address'}>Address</label>
                  <input
                    type="text"
                    name={'address'}
                    value={address}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter address'}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

AddSupplier.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  addSupplier: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  auth: state.auth
});

export default connect(mapStateToProps, { addSupplier })(AddSupplier);
