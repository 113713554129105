import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import moment from 'moment';
import { getIncomeExpenseReports } from '../../../actions/reports';
import { INCOME_EXPENSE_REPORTS } from '../../../permissions';
import FilterLayout from '../../layout/FilterLayout';
import { numberWithCommas, REPORTS_SECTIONS } from '../../../utils';
import ReactToPrint from 'react-to-print';

function IncomeVsExpenseReports({
  isLoading,
  getIncomeExpenseReports,
  auth: { permissions },
  reports: { incomeExpenseReports }
}) {
  const from_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const to_date = moment().format('YYYY-MM-DD');
  let history = useHistory();
  const componentRef = useRef();

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === INCOME_EXPENSE_REPORTS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getIncomeExpenseReports({ from_date, to_date });
  }, []);

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/reports'}>Reports</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/reports/income'}>Income Expense Reports</Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={REPORTS_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <FilterLayout handleActions={getIncomeExpenseReports} />

        {incomeExpenseReports && (
          <Fragment>
            <div id="card-content" className={'mt-4'}>
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5>Print Profit/Loss Report</h5>
                  </div>
                  <div>
                    <ReactToPrint
                      trigger={() => (
                        <button className="btn btn-primary me-2">
                          <i className="fas fa-print me-2" /> Print
                        </button>
                      )}
                      content={() => componentRef.current}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div id="card-content" className={'mt-4'} ref={componentRef}>
              <div className="card-header income-expense">
                <h5 className={'text-center'}>Profit/Loss</h5>
                <p className={'text-center'}>
                  Date from {from_date} To {to_date}
                </p>

                <div className="row mt-5">
                  <div className="col-md-3 offset-1">
                    <p>Sales</p>
                    <p>Total Sales</p>
                    <p>Sales Return</p>
                    <p>Sales Exchange</p>
                    <h6>Net Sales</h6>
                    <h6>Total Revenue</h6>

                    <p>Opening Inventory</p>
                    <p>Local Purchase</p>
                    <p>Purchase Return</p>
                    <p>Net Purchase</p>
                    <p>Current Stock</p>
                    <p>Total Closing Stock</p>
                    <h6>Total Cost of good Sold</h6>
                    <h6>Gross Profit</h6>

                    {incomeExpenseReports.expenses &&
                      incomeExpenseReports.expenses.map((item) => <p>{item.name}</p>)}

                    <h6>Total Expenses</h6>
                    <h6>Net Profit</h6>
                  </div>

                  <div className="col-md-2 text-end">
                    <p>{numberWithCommas(incomeExpenseReports.sales)}</p>
                    <p>-</p>
                    <p>-</p>
                    <p>{numberWithCommas(incomeExpenseReports.totalExchange)}</p>
                    <p>-</p>
                    <p>-</p>

                    <p>-</p>
                    <p>{numberWithCommas(incomeExpenseReports.localPurchse)}</p>
                    <p>{numberWithCommas(incomeExpenseReports.purchaseReturn)}</p>
                    <p>-</p>
                    <p>{numberWithCommas(incomeExpenseReports.currentStock)}</p>
                    <p>-</p>
                    <p>-</p>
                    <p>-</p>

                    {incomeExpenseReports.expenses &&
                      incomeExpenseReports.expenses.map((item) => (
                        <p>{numberWithCommas(item.amount)}</p>
                      ))}

                    <p>-</p>
                    <p>-</p>
                  </div>

                  <div className="col-md-2 text-end">
                    <p>-</p>
                    <p>{numberWithCommas(incomeExpenseReports.totalSales)}</p>
                    <p>{numberWithCommas(incomeExpenseReports.salesReturn)}</p>
                    <p>-</p>
                    <p>-</p>
                    <p>-</p>

                    <p>{numberWithCommas(incomeExpenseReports.openingInventory)}</p>
                    <p>-</p>
                    <p>-</p>
                    <p>-</p>
                    <p>-</p>
                    <p>{numberWithCommas(incomeExpenseReports.totalClosingStock)}</p>
                    <p>-</p>
                    <p>-</p>

                    {incomeExpenseReports.expenses &&
                      incomeExpenseReports.expenses.map((item) => <p>-</p>)}

                    <p>-</p>
                    <p>-</p>
                  </div>

                  <div className="col-md-2 text-end">
                    <p>-</p>
                    <p>-</p>
                    <p>-</p>
                    <p>-</p>
                    <p>{numberWithCommas(incomeExpenseReports.netSales)}</p>
                    <p>{numberWithCommas(incomeExpenseReports.totalRevenue)}</p>

                    <p>-</p>
                    <p>-</p>
                    <p>-</p>
                    <p>-</p>
                    <p>-</p>
                    <p>-</p>
                    <p>{numberWithCommas(incomeExpenseReports.totalCostOfGoods)}</p>
                    <p>{numberWithCommas(incomeExpenseReports.grossProfit)}</p>

                    {incomeExpenseReports.expenses &&
                      incomeExpenseReports.expenses.map((item) => <p>-</p>)}

                    <p>{numberWithCommas(incomeExpenseReports.totalExpense)}</p>
                    <p>{numberWithCommas(incomeExpenseReports.netProfit)}</p>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

IncomeVsExpenseReports.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getIncomeExpenseReports: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  reports: state.reports,
  auth: state.auth
});

export default connect(mapStateToProps, { getIncomeExpenseReports })(IncomeVsExpenseReports);
