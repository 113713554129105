import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import { addDesignation, getDesignation, updateDesignation } from '../../../../actions/employee';
import Breadcrumb from '../../../layout/Breadcrumb';
import LoadingBackdrop from '../../../layout/LoadingBackdrop';
import Swal from 'sweetalert2';
import { DESIGNATIONS } from '../../../../permissions';
import { EMPLOYEE_SECTIONS } from '../../../../utils';

function AddEditDesignation({
  isLoading,
  addDesignation,
  updateDesignation,
  auth: { permissions },
  getDesignation,
  location
}) {
  const [designation, setDesignation] = useState(null);
  const history = useHistory();
  const initFormData = {
    name: designation ? designation.name : '',
    description: designation ? designation.description : ''
  };
  const [formData, setFormData] = useState(initFormData);

  const { name, description } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === DESIGNATIONS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      getDesignationDetails(id);
    }
  }, []);

  useEffect(() => {
    setFormData(initFormData);
  }, [designation]);

  const getDesignationDetails = (id) => {
    getDesignation(id).then((data) => {
      setDesignation(data);
    });
  };

  const createDesignation = async () => {
    await addDesignation(formData);
    const dialogRes = await Swal.fire(
      'Designation added!',
      'New designation added successfully',
      'success'
    );
    if (dialogRes) {
      history.goBack();
    }
  };

  const onCancelButtonClicked = (e) => {
    history.goBack();
  };

  const saveDesignation = (e) => {
    e.preventDefault();

    designation ? updateDesignation(designation.id, formData) : createDesignation();
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee'}>Designation</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee/designation/all'}>Designations</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee/designation/new'}>
          {designation ? 'Edit Designation' : 'Add Designation'}
        </Link>
      </li>
    </ol>
  );

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={EMPLOYEE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <form id="card-content" onSubmit={(e) => saveDesignation(e)}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items center">
              <div>
                {designation ? (
                  <Fragment>
                    <h5>Edit Designation</h5>
                    <p>Edit existing designation details</p>
                  </Fragment>
                ) : (
                  <Fragment>
                    <h5>Add New Designation</h5>
                    <p>Add a new designation</p>
                  </Fragment>
                )}
              </div>
              <div>
                <button type={'submit'} className="btn btn-primary p-2 me-2" disabled={isLoading}>
                  Save
                </button>
                <button
                  onClick={onCancelButtonClicked}
                  type={'button'}
                  disabled={isLoading}
                  className="btn btn-danger p-2 text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className="card-body mt-2">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'name'}>
                    Name <span className={'text-danger'}>*</span>
                  </label>
                  <input
                    type="text"
                    name={'name'}
                    required
                    value={name}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter name'}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={'description'}>Description</label>
                  <input
                    type="text"
                    name={'description'}
                    value={description}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder={'Enter description'}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  auth: state.auth
});

export default connect(mapStateToProps, {
  addDesignation,
  updateDesignation,
  getDesignation
})(AddEditDesignation);
