import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import moment from 'moment';
import { getStocks, setInventorySearch, stockTransfer } from '../../../actions/stocks';
import { getProductImage, getStockVariation } from '../../../utils';
import { base_url_image } from '../../../api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableCell,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getBranches } from '../../../actions/system';
import Swal from 'sweetalert2';
import { Pagination } from '@material-ui/lab';
import { DEFAULT_BRANCH } from '../../../const';
import { SHOW_PURCHASE_PRICE } from '../../../permissions';
import NumberInput from '../../common/NumberInput';

function AllStock({
  isLoading,
  stockTransfer,
  getStocks,
  setInventorySearch,
  getBranches,
  auth: { permissions },
  stocks: { stocks, searchText },
  system: { branches }
}) {
  const [stockTransferDialog, setStockTransferDialog] = useState(false);
  const [products, setProducts] = useState([]);
  const initValues = {
    date: moment().format('YYYY-MM-DD'),
    variationStockId: '',
    fromBranchId: '',
    toBranchId: '',
    qty: 0,
    description: '',
    maxQty: 0
  };
  const [formData, setFormData] = useState(initValues);

  const { variationStockId, fromBranchId, toBranchId, qty, date, maxQty, description } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    if (stocks) {
      setProducts(getStockVariation(stocks?.paginatedData));
    }
  }, [stocks]);

  useEffect(() => {
    if (searchText) {
      setTimeout(() => {
        getStocks({ search: searchText, size: 10 });
      }, 500);
    } else {
      getStocks({ search: searchText, size: 10 });
    }
  }, [searchText]);

  const openStockTransferDialog = (data) => {
    setStockTransferDialog(true);

    console.log(data);

    setFormData({
      ...formData,
      variationStockId: data.variationItem.id,
      fromBranchId: data.branchId,
      qty: data.variationItem.qty,
      maxQty: data.variationItem.qty,
      description: `${data.item.code} - ${data.variationDesc}`
    });
  };

  const closeStockTransferDialog = () => {
    setStockTransferDialog(false);
  };

  const onBranchSelected = (e, value) => {
    e.preventDefault();

    setFormData({
      ...formData,
      toBranchId: value ? value.id : ''
    });
  };

  const submitStockTransfer = (e) => {
    e.preventDefault();

    if (!toBranchId) {
      closeStockTransferDialog();
      Swal.fire('Invalid Branch', 'Please select a to branch.', 'warning');
      return;
    }

    if (qty <= 0) {
      closeStockTransferDialog();
      Swal.fire('Invalid Qty', 'Please select qty greater than 0', 'warning');
      return;
    }

    if (toBranchId === fromBranchId) {
      closeStockTransferDialog();
      Swal.fire('Invalid Branch!', 'You can not select same branch for transfer.', 'warning');
      return;
    }

    stockTransfer(formData);
    closeStockTransferDialog();
    setFormData(initValues);
  };

  const handleProductSearch = (value) => {
    setInventorySearch(value);
  };

  const adminTable = [
    {
      title: 'Image',
      field: 'color.image',
      editable: 'never',
      render: (rowData) => (
        <img
          className={'stock-product-image'}
          src={base_url_image + getProductImage(rowData)}
          alt={rowData.id}
        />
      )
    },
    { field: 'item.code', title: 'Code' },
    { field: 'item.name', title: 'Name' },
    { field: 'inventory_desc', title: 'Description' },
    {
      field: 'variationItem.qty',
      title: 'Quantity',
      render: (rowData) =>
        rowData.qty < 3 ? (
          <NumberInput
            value={rowData.variationItem.qty}
            style={{
              maxWidth: 80,
              border: 'none',
              backgroundColor: '#FFCCCB',
              textAlign: 'center'
            }}
            disabled
          />
        ) : (
          <NumberInput
            value={rowData.variationItem.qty}
            style={{
              maxWidth: 80,
              border: 'none',
              fontWeight: 600,
              padding: 4,
              textAlign: 'center'
            }}
            disabled
          />
        )
    },
    {
      field: 'color.name',
      title: 'Color',
      render: (rowData) =>
        rowData.color ? (
          <span
            // className={`p-1 rounded me-1 ${rowData.color.name.toLowerCase() === 'white' ? 'text-dark border' : 'text-light'}`}
            className={'border bg-light p-1 rounded me-1 font-weight-bold'}
          >
            {rowData.color.name}
          </span>
        ) : null
    },
    {
      field: 'size.name',
      title: 'Size',
      render: (rowData) =>
        rowData.size ? (
          <span className={'border bg-light p-1 rounded me-1'}>{rowData.size.name}</span>
        ) : null
    },
    {
      field: 'variationPrice',
      title: 'Retail (MRP)',
      cellStyle: { width: '10%' },
      render: (rowData) => (
        <span className={'border bg-light p-1 rounded me-1'}>
          {rowData.variationPrice || rowData.item.price}
        </span>
      )
    },
    { field: 'item.type.name', title: 'Type' },
    {
      field: 'item.unit.name',
      title: 'Unit',
      render: (rowData) =>
        rowData.item.unit && (
          <span className={'border bg-light p-1 rounded me-1'}>{rowData.item.unit.name}</span>
        )
    },
    {
      field: 'branch.name',
      title: 'Branch',
      render: (rowData) => (
        <span
          className={`p-1 border rounded me-1 ${
            rowData.branch.name === DEFAULT_BRANCH && 'text-bg-blue'
          }`}
        >
          {rowData.branch.name}
        </span>
      )
    }
  ];

  if (permissions.find((item) => item.name === SHOW_PURCHASE_PRICE)) {
    adminTable.splice(7, 0, {
      field: 'variationPurchasePrice',
      title: 'Purchase (৳)',
      cellStyle: { width: '10%' },
      render: (rowData) => (
        <span className={'border bg-light p-1 rounded me-1'}>
          {rowData.variationPurchasePrice || rowData.item.purchase_price}
        </span>
      )
    });
  }

  // const renderChildTable = (rowData, item) => {
  //     const header = [
  //         {field: '', title: ''},
  //         {
  //             field: 'item.name', title: 'Variation', render: rowData => (
  //                 <p className={"mb-0 text-center"}>{item.name} &emsp; {rowData.color ? <span
  //                     className={"border bg-light p-1 rounded me-1"}>{rowData.color.name}</span> : null} {rowData.size ?
  //                     <span
  //                         className={"border bg-light p-1 rounded"}>{rowData.size.name}</span> : null}</p>
  //             )
  //         },
  //         {
  //             field: 'qty', title: 'Variation Quantity',
  //             render: rowData => (
  //                 <input
  //                     type="number"
  //                     className="form-control text-center"
  //                     value={rowData.qty}
  //                     style={{maxWidth: 100, border: 'none', height: 25, fontWeight: 600}}
  //                     disabled
  //                 />
  //             )
  //         },
  //         {field: '', title: ''},
  //         {field: '', title: ''},
  //     ];
  //
  //     return <div className="d-grid">
  //         <div className={"overflow-auto"}>
  //             <MaterialTable
  //                 columns={header}
  //                 data={rowData.stockVariations}
  //                 isLoading={isLoading}
  //                 totalCount={rowData.stockVariations.length}
  //                 options={{
  //                     header: false,
  //                     toolbar: false,
  //                     padding: "dense",
  //                     rowStyle: rowData => {
  //                         return {
  //                             fontSize: '14px',
  //                             color: '#000'
  //                         };
  //                     },
  //                     paging: false
  //                 }}
  //             />
  //
  //         </div>
  //     </div>
  // }

  return (
    <Fragment>
      <div className="container-fluid p-0">
        <div id="card-content" className={'shadow-none'}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Product Variation Stocks</h5>
                <p className={'mb-0'}>Check product stocks and variations stocks with prices.</p>
              </div>
            </div>
          </div>

          {stocks && (
            <div className="card-body d-grid">
              <div className={'overflow-auto'}>
                <MaterialTable
                  title={'Stocks'}
                  columns={adminTable}
                  data={products}
                  isLoading={isLoading}
                  totalCount={products.length}
                  key={products.length < 10 ? 10 : products.length}
                  options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                      backgroundColor: '#F6F9FC',
                      color: '#000'
                    },
                    rowStyle: (rowData) => {
                      return {
                        fontSize: '14px',
                        color: '#000'
                      };
                    },
                    padding: 'dense',
                    pageSize: products.length < 20 ? 20 : products.length,
                    showFirstLastPageButtons: true,
                    exportAllData: true,
                    exportFileName: `Stocks - ${moment(new Date()).format('LL')}`,
                    exportButton: true,
                    searchFieldAlignment: 'left',
                    searchFieldStyle: {
                      marginLeft: '3rem'
                    },
                    searchText: searchText,
                    searchAutoFocus: true
                  }}
                  onSearchChange={handleProductSearch}
                  actions={[
                    (rowData) => ({
                      icon: 'sync_alt',
                      onClick: () => openStockTransferDialog(rowData),
                      isFreeAction: true,
                      tooltip: 'Stock Transfer'
                    })
                  ]}
                  components={{
                    Pagination: (props) => (
                      <TableCell className="text-center d-flex justify-content-end pagination mt-2">
                        <Pagination
                          color={'primary'}
                          count={stocks.lastPage}
                          page={stocks.currentPage}
                          siblingCount={2}
                          onChange={(e, value) =>
                            getStocks({ page: value, search: searchText, size: 5 })
                          }
                        />
                      </TableCell>
                    )
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <Dialog
        open={stockTransferDialog}
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        onClose={closeStockTransferDialog}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">Stock Transfer</DialogTitle>
        <DialogContent>
          <div className="card-body">
            <React.Fragment>
              <div className="form-group">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Date <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="date"
                      name={'date'}
                      defaultValue={date}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Choose a date'}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      From Branch <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Autocomplete
                      id={'select-branch'}
                      options={branches}
                      renderOption={(option) => option.name}
                      getOptionLabel={(option) => option.name}
                      defaultValue={branches.find((option) => option.id === fromBranchId)}
                      freeSolo
                      size={'small'}
                      disabled
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Select One'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      To Branch <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <Autocomplete
                      id={'select-branch'}
                      options={branches.filter((option) => option.id !== fromBranchId)}
                      onChange={(event, value) => onBranchSelected(event, value)}
                      renderOption={(option) => option.name}
                      getOptionLabel={(option) => option.name}
                      freeSolo
                      size={'small'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Select One'}
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label className={'m-md-0 mb-1'}>
                      Qty <span className={'text-danger'}>*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <NumberInput
                      name={'qty'}
                      required
                      min={1}
                      max={maxQty}
                      step={1}
                      defaultValue={qty}
                      onChange={(e) => onChange(e)}
                      placeholder={'Enter qty'}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="row d-flex align-items-center">
                  <div className="col-4">
                    <label htmlFor={'description'}>Description</label>
                  </div>
                  <div className="col-8">
                    <input
                      type="text"
                      name={'description'}
                      value={description}
                      disabled
                      onChange={(e) => onChange(e)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeStockTransferDialog} color="secondary">
            Cancel
          </Button>
          <Button color="primary" onClick={(e) => submitStockTransfer(e)}>
            Transfer
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

AllStock.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getStocks: PropTypes.func.isRequired,
  getBranches: PropTypes.func.isRequired,
  system: PropTypes.object.isRequired,
  stockTransfer: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  stocks: state.stocks,
  system: state.system,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getStocks,
  getBranches,
  stockTransfer,
  setInventorySearch
})(AllStock);
