import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import MaterialTable from 'material-table';
import moment from 'moment';
import { getPurchaseReportsByDate } from '../../../actions/reports';
import { PURCHASE_REPORTS } from '../../../permissions';
import { numberWithCommas, REPORTS_SECTIONS } from '../../../utils';

function PurchaseReportsByDate({
  isLoading,
  getPurchaseReportsByDate,
  auth: { permissions },
  reports: { dayPurchaseReports },
  location
}) {
  let history = useHistory();
  const [purchaseDate, setPurchaseDate] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const date = query.get('date');

    if (date) {
      setPurchaseDate(date);
      getPurchaseReportsByDate(date);
    }
  }, []);

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === PURCHASE_REPORTS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  const handlePurchaseDetails = (id) => {
    history.push({
      pathname: '/purchase/details',
      search: `?id=${id}`
    });
  };

  const handleSupplierDetails = (id) => {
    history.push({
      pathname: '/suppliers/details',
      search: `?id=${id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/reports'}>Reports</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/reports/purchase'}>Purchase Reports</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={`/reports/purchase/by-date?date=${purchaseDate}`}>{purchaseDate}</Link>
      </li>
    </ol>
  );

  const tableHead = [
    {
      field: 'id',
      title: 'Inv. No',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handlePurchaseDetails(rowData.id)}
        >{`INV-${rowData.id}`}</span>
      )
    },
    {
      field: 'supplier.name',
      title: 'Supplier',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleSupplierDetails(rowData.supplier.id)}
        >
          {rowData.supplier.name}
        </span>
      )
    },
    { field: 'qty', title: 'Total QTY', render: (rowData) => numberWithCommas(rowData.qty) },
    {
      field: 'purchaseValue',
      title: 'Total Purchase (৳)',
      render: (rowData) => numberWithCommas(rowData.purchaseValue)
    },
    {
      field: 'costValue',
      title: 'Total Costs (৳)',
      render: (rowData) => numberWithCommas(rowData.costValue)
    }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={REPORTS_SECTIONS} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>{`Purchase Report On ${purchaseDate}`}</h5>
              </div>
            </div>
          </div>
        </div>
        {dayPurchaseReports && (
          <Fragment>
            <div id="card-content" className={'mt-4'}>
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                  <div></div>
                  <div className={'text-center'}>
                    <h5>৳{numberWithCommas(dayPurchaseReports.report.itemsQty)}</h5>
                    <p className={'mb-0'}>Total Quantity</p>
                  </div>
                  <div className={'text-center'}>
                    <h5>৳{numberWithCommas(dayPurchaseReports.report.purchasesValue)}</h5>
                    <p className={'mb-0'}>Purchase Value</p>
                  </div>
                  <div className={'text-center'}>
                    <h5>৳{numberWithCommas(dayPurchaseReports.report.costValue)}</h5>
                    <p className={'mb-0'}>Costs Value</p>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>

            <div id="card-content" className={'mt-4'}>
              <div className={'overflow-auto'}>
                <MaterialTable
                  title={'Purchase Reports'}
                  columns={tableHead}
                  data={dayPurchaseReports?.paginatedData}
                  isLoading={isLoading}
                  totalCount={dayPurchaseReports?.paginatedData.length}
                  options={{
                    headerStyle: {
                      backgroundColor: '#F6F9FC',
                      color: '#000'
                    },
                    rowStyle: () => {
                      return {
                        fontSize: '14px',
                        color: '#000'
                      };
                    },
                    padding: 'default',
                    pageSize: 20,
                    actionsColumnIndex: -1,
                    pageSizeOptions: [10, 20, 25, 50],
                    showFirstLastPageButtons: true,
                    exportAllData: true,
                    exportFileName: `Purchase Reports - ${moment(new Date()).format('LL')}`,
                    exportButton: true,
                    searchFieldAlignment: 'left',
                    searchFieldStyle: {
                      marginLeft: '3rem'
                    }
                  }}
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

PurchaseReportsByDate.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  reports: state.reports,
  auth: state.auth
});

export default connect(mapStateToProps, { getPurchaseReportsByDate })(PurchaseReportsByDate);
