import {
  LOADING,
  LOADING_FALSE,
  PURCHASE,
  PURCHASE_DAMAGE_RETURN,
  PURCHASE_DAMAGE_RETURN_DETAILS,
  PURCHASE_DETAILS,
  PURCHASE_RETURN,
  PURCHASE_RETURN_DETAILS
} from './types';
import { returnErrors } from './messages';
import API from '../api';
import Swal from 'sweetalert2';
import { GENERAL_SUPPLIER } from '../const';
import { findAddedItems, findDeletedItems, findUpdatedItems } from '../utils';

export const addPurchase = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    // If supplier is a general supplier, save a supplier first
    let supplierId;

    if (data.supplierId === GENERAL_SUPPLIER) {
      const supplierData = {
        name: data.supplierName,
        phone: data.supplierPhone,
        email: '',
        address: data.supplierAddress
      };

      const supplierRes = await API.suppliers.addSupplier(supplierData);
      supplierId = supplierRes.data.data.id;
    } else {
      supplierId = data.supplierId;
    }

    const purchaseData = {
      supplierId: supplierId,
      bankAccountId: data.bankAccountId,
      userId: data.userId,
      date: data.date,
      notes: data.note,
      sub_total: data.subTotal,
      discount: data.discount,
      paid: data.paid,
      transport_cost: data.transportCost,
      due: data.due,
      vat: data.vat,
      vat_value: data.vatValue,
      total: data.total
    };

    // Add purchase data and get the purchase ID
    const res = await API.purchase.addPurchase(purchaseData);

    const purchaseId = res.data.data.id;

    // Add all the item including variations item in purchase details
    // add stock variation
    // add item to stock
    for (const item of data.cartsItem) {
      const itemData = {
        itemId: item.id,
        qty: item.qty,
        unit_price: item.rate,
        total: item.total,
        description: item.description,
        colorId: item.colorId,
        sizeId: item.sizeId,
        s_code: item.s_code,
        is_first: 'true',
        branchId: item.branchId
      };

      await API.purchase.addPurchaseItem(purchaseId, itemData);

      // add stock variation
      const itemVariationStock = {
        qty: item.qty,
        itemId: item.id,
        branchId: item.branchId,
        colorId: item.colorId,
        sizeId: item.sizeId,
        unit_price: item.rate
      };

      await API.stocks.addStockItemVariation(itemVariationStock);
      const stockReportData = {
        qty: item.qty,
        date: purchaseData.date,
        itemId: item.id,
        branchId: item.branchId,
        saleOrderId: '',
        purchaseOrderId: purchaseId,
        trans_type: 'stock_in'
      };

      await API.stocks.stockReport(stockReportData);
    }

    // Unique object of array with all qty
    const uniqueArray = [];
    for (const item of data.cartsItem) {
      const index = uniqueArray.findIndex((i) => i.id === item.id && i.branchId === item.branchId);
      if (index !== -1) {
        uniqueArray[index].qty += parseInt(item.qty);
      } else {
        uniqueArray.push(item);
      }
    }

    for (const item of uniqueArray) {
      const stockData = {
        itemId: item.id,
        branchId: item.branchId,
        qty: item.qty
      };

      await API.stocks.addStockItem(stockData);
    }

    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully made purchase.',
      showConfirmButton: false,
      timer: 1500
    });

    return res.data.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updatePurchase = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  let supplierId;
  let purchaseId = data.id;

  let oldCart = data.oldCartsItem;
  let newCart = data.cartsItem;

  try {
    const deletedItems = findDeletedItems(oldCart, newCart);
    const addedItems = findAddedItems(oldCart, newCart);
    const updatedItems = findUpdatedItems(oldCart, newCart);

    if (data.supplierId === GENERAL_SUPPLIER) {
      const supplierData = {
        name: data.supplierName,
        phone: data.supplierPhone,
        email: '',
        address: data.supplierAddress
      };

      const supplierRes = await API.suppliers.addSupplier(supplierData);
      supplierId = supplierRes.data.data.id;
    } else {
      supplierId = data.supplierId;
    }

    const purchaseData = {
      supplierId: supplierId,
      bankAccountId: data.bankAccountId,
      userId: data.userId,
      date: data.date,
      notes: data.note,
      sub_total: data.subTotal,
      discount: data.discount,
      paid: data.paid,
      transport_cost: data.transportCost,
      due: data.due,
      vat: data.vat,
      vat_value: data.vatValue,
      total: data.total
    };

    // update purchase data and get the purchase id
    await API.purchase.updatePurchase(purchaseId, purchaseData);

    // update purchase item
    for (const item of updatedItems) {
      // get detail id from old cart item only for update cart item
      const existingCartItem = oldCart.find(
        (i) =>
          i.itemId === item.itemId &&
          i.s_code === item.s_code &&
          i.sizeId === item.sizeId &&
          i.colorId === item.colorId
      );

      const itemData = {
        orderDetailItemId: existingCartItem.id,
        itemId: item.itemId,
        qty: item.qty,
        unit_price: item.rate,
        total: item.total,
        description: item.description,
        colorId: item.colorId,
        sizeId: item.sizeId,
        s_code: item.s_code,
        branchId: item.branchId
      };

      await API.purchase.updatePurchaseItem(purchaseId, itemData);

      const qty =
        item.qty -
        data.oldCartsItem.find(
          (o1) =>
            o1.itemId === item.itemId &&
            o1.s_code === item.s_code &&
            o1.branchId === item.branchId &&
            o1.rate === item.rate
        ).qty;
      console.log(qty);
      if (qty > 0) {
        // add stock variation
        const itemVariationStock = {
          qty: Math.abs(qty),
          itemId: item.itemId,
          branchId: item.branchId,
          colorId: item.colorId,
          sizeId: item.sizeId,
          unit_price: item.rate
        };
        await API.stocks.addStockItemVariation(itemVariationStock);
        const stockReportData = {
          qty: Math.abs(qty),
          date: purchaseData.date,
          itemId: item.itemId,
          branchId: item.branchId,
          saleOrderId: '',
          purchaseOrderId: purchaseId,
          trans_type: 'stock_in'
        };

        await API.stocks.stockReport(stockReportData);
      } else {
        // decrement qty from stock
        const itemVariationStock = {
          qty: Math.abs(qty),
          itemId: item.itemId,
          branchId: item.branchId,
          colorId: item.colorId,
          sizeId: item.sizeId,
          unit_price: item.rate
        };

        await API.stocks.decrementItemVariationStock(itemVariationStock);
        const stockReportData = {
          qty: Math.abs(qty),
          date: purchaseData.date,
          itemId: item.itemId,
          branchId: item.branchId,
          saleOrderId: '',
          purchaseOrderId: purchaseId,
          trans_type: 'stock_out'
        };

        await API.stocks.stockReport(stockReportData);
      }
    }

    // add new purchase item
    for (const item of addedItems) {
      const itemData = {
        itemId: item.id,
        qty: item.qty,
        unit_price: item.rate,
        total: item.total,
        description: item.description,
        colorId: item.colorId,
        sizeId: item.sizeId,
        s_code: item.s_code,
        branchId: item.branchId
      };

      await API.purchase.addPurchaseItem(purchaseId, itemData);

      // add stock variation
      const itemVariationStock = {
        qty: item.qty,
        itemId: item.id,
        branchId: item.branchId,
        colorId: item.colorId,
        sizeId: item.sizeId,
        unit_price: item.rate
      };

      await API.stocks.addStockItemVariation(itemVariationStock);
      const stockReportData = {
        qty: item.qty,
        date: purchaseData.date,
        itemId: item.id,
        branchId: item.branchId,
        saleOrderId: '',
        purchaseOrderId: purchaseId,
        trans_type: 'stock_in'
      };

      await API.stocks.stockReport(stockReportData);
    }

    // delete purchase item
    for (const item of deletedItems) {
      await API.purchase.deletePurchaseItem(item.id);

      // decrement qty from stock
      const itemVariationStock = {
        qty: item.qty,
        itemId: item.itemId,
        branchId: item.branchId,
        colorId: item.colorId,
        sizeId: item.sizeId,
        unit_price: item.rate
      };

      await API.stocks.decrementItemVariationStock(itemVariationStock);
      const stockReportData = {
        qty: item.qty,
        date: purchaseData.date,
        itemId: item.itemId,
        branchId: item.branchId,
        saleOrderId: '',
        purchaseOrderId: purchaseId,
        trans_type: 'stock_out'
      };

      await API.stocks.stockReport(stockReportData);

      // add item to stock
      const stockData = {
        itemId: item.itemId,
        branchId: item.branchId,
        qty: item.qty
      };

      await API.stocks.decrementStockItem(stockData);
    }

    // Unique object of array with all qty
    const uniqueArray = [];
    for (const item of data.cartsItem) {
      let existingQty = 0;
      let itemId;
      let branchId;
      // Get the existing item
      const oldItem = data.oldCartsItem.find(
        (o1) =>
          o1.itemId === item.itemId && o1.s_code === item.s_code && o1.branchId === item.branchId
      );

      // if exists get existing qty
      if (oldItem) {
        itemId = oldItem.itemId;
        existingQty = oldItem.qty;
        branchId = oldItem.branchId;
      } else {
        itemId = item.id;
        branchId = item.branchId;
      }

      const index = uniqueArray.findIndex((i) => i.id === itemId && i.branchId === branchId);

      if (index !== -1) {
        uniqueArray[index].qty += parseInt(item.qty);
      } else {
        uniqueArray.push({
          id: itemId,
          branchId,
          qty: item.qty
        });
      }

      // remove existing qty
      uniqueArray.map((i) => {
        if (i.id === itemId) {
          i.qty -= existingQty;
        }
      });
    }

    for (const item of uniqueArray) {
      if (item.qty > 0) {
        // add item to stock
        const stockData = {
          itemId: item.id,
          branchId: item.branchId,
          qty: Math.abs(item.qty)
        };

        await API.stocks.addStockItem(stockData);
      } else {
        // decrement qty from stock
        const stockData = {
          itemId: item.id,
          branchId: item.branchId,
          qty: Math.abs(item.qty)
        };

        await API.stocks.decrementStockItem(stockData);
      }
    }

    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: 'Purchase Updated',
      text: 'You have successfully updated a purchase.',
      showConfirmButton: false,
      timer: 1500
    });

    return purchaseId;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getPurchaseList =
  ({ page, size, from_date, to_date, search, all, isExportData }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.purchase.getPurchaseList({
        page,
        size,
        from_date,
        to_date,
        search,
        all
      });
      dispatch({ type: LOADING_FALSE });
      if (isExportData) return res.data.data;
      dispatch({
        type: PURCHASE,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getPurchaseDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.purchase.getPurchaseDetails(id);
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: PURCHASE_DETAILS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const addPurchaseReturn = (id, data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.purchase.addPurchaseReturn(id, data);
    dispatch({ type: LOADING_FALSE });

    if (!res.data.success) {
      await Swal.fire('Return Invoice Failed', res.data.message, 'error');
      return;
    }

    await Swal.fire(
      res.data.message,
      'Successfully purchase returned and stock adjustment',
      'success'
    );

    dispatch(getPurchaseDetails(id));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getPurchaseReturnList =
  ({ size, page, from_date, to_date, isExportData }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.purchase.getPurchaseReturnList({ size, page, from_date, to_date });
      dispatch({ type: LOADING_FALSE });
      if (isExportData) return res.data.data;
      dispatch({
        type: PURCHASE_RETURN,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getPurchaseDamageReturnList =
  ({ size, page, from_date, to_date, isExportData }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const res = await API.purchase.getPurchaseDamageReturnList({
        size,
        page,
        from_date,
        to_date
      });
      dispatch({ type: LOADING_FALSE });
      if (isExportData) return res.data.data;
      dispatch({
        type: PURCHASE_DAMAGE_RETURN,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const addPurchaseOrderPayment = (data, from_date, to_date) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.purchase.addPurchaseOrderPayment(data);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire(res.data.message, 'Successfully purchase payment added', 'success');

    dispatch(getPurchaseList({ from_date, to_date }));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const cancelPurchase = (id) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.purchase.cancelPurchase(id);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully cancelled a purchase.',
      showConfirmButton: false,
      timer: 1500
    });

    window.history.back();
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getPurchaseReturnsDetails = (purchaseId) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.purchase.getPurchaseReturnsDetails(purchaseId);
    dispatch({ type: LOADING_FALSE });

    dispatch({
      type: PURCHASE_RETURN_DETAILS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getPurchaseDamageReturnsDetails = (purchaseId) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const res = await API.purchase.getPurchaseDamageReturnsDetails(purchaseId);
    dispatch({ type: LOADING_FALSE });

    dispatch({
      type: PURCHASE_DAMAGE_RETURN_DETAILS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};
