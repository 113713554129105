import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCustomerPayment } from '../../../actions/customers';
import { useHistory } from 'react-router-dom';
import FilterMaterialTable from '../../common/FilterMaterialTable';

function CustomerPayments({
  isLoading,
  customerId,
  getCustomerPayment,
  customers: { customerPayments }
}) {
  const history = useHistory();

  const handleDetails = (id) => {
    history.push({
      pathname: '/customers/add-payment',
      search: `?id=${id}`
    });
  };

  const handleSaleDetails = (id) => {
    history.push({
      pathname: '/sale/details',
      search: `?id=${id}`
    });
  };

  const tableHead = [
    { field: 'date', title: 'Date' },
    {
      field: 'saleOrderId',
      title: 'Inv. No',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleSaleDetails(rowData.saleOrderId)}
        >{`INV-${rowData.saleOrderId}`}</span>
      )
    },
    { field: 'customer.name', title: 'Customer Name' },
    { field: 'customer.phone', title: 'Customer Phone' },
    { field: 'bank_tran.bank_account.account_name', title: 'Account' },
    { field: 'description', title: 'Description' },
    { field: 'reference', title: 'Reference' },
    { field: 'amount', title: 'Amount (৳)' }
  ];

  return (
    <Fragment>
      <div className="container-fluid">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Add Customer Payment</h5>
                <p className={'mb-0'}>Add and manage customer payments</p>
              </div>
              <div>
                <a
                  onClick={() => handleDetails(customerId)}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add Payment
                </a>
              </div>
            </div>
          </div>
        </div>

        <FilterMaterialTable
          title={'Customer Payments'}
          isLoading={isLoading}
          columns={tableHead}
          data={customerPayments}
          tableContainerClass={'mt-4'}
          fetchMethod={getCustomerPayment}
          isCustomPagination
          args={{
            id: customerId
          }}
          options={{
            rowStyle: (rowData) => {
              return {
                fontSize: '14px',
                color: '#000'
              };
            }
          }}
        />
      </div>
    </Fragment>
  );
}

CustomerPayments.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  customers: PropTypes.object.isRequired,
  getCustomerPayment: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  customers: state.customers
});

export default connect(mapStateToProps, { getCustomerPayment })(CustomerPayments);
