import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import MaterialTable from 'material-table';
import moment from 'moment';
import { deleteEmployee, getEmployeeList } from '../../../actions/employee';
import { Autocomplete, Pagination } from '@material-ui/lab';
import Swal from 'sweetalert2';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableCell,
  TextField
} from '@material-ui/core';
import { createUser } from '../../../actions/auth';
import { getRoles } from '../../../actions/system';
import { EMPLOYEE } from '../../../permissions';
import { EMPLOYEE_SECTIONS } from '../../../utils';

function AllEmployee({
  isLoading,
  deleteEmployee,
  getEmployeeList,
  createUser,
  getRoles,
  auth: { permissions },
  employee: { employee },
  system: { roles }
}) {
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [accountForm, setAccountForm] = useState({
    employeeId: '',
    email: '',
    username: '',
    password: '',
    confirm_password: '',
    roleId: ''
  });
  const { employeeId, email, username, password, confirm_password } = accountForm;
  const onChange = (e) => setAccountForm({ ...accountForm, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === EMPLOYEE)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getEmployeeList({});
    getRoles();
  }, []);

  const editEmployee = (e, id) => {
    e.preventDefault();
    history.push({
      pathname: '/employee/new',
      search: `?id=${id}`
    });
  };

  const hideOpenDialog = (isOpen) => {
    setDialogOpen(isOpen);
  };

  const onRoleSelected = (e, value) => {
    e.preventDefault();

    setAccountForm({
      ...accountForm,
      roleId: value ? value.id : ''
    });
  };

  const makeEmployeeAUser = (e, id) => {
    setAccountForm({
      ...accountForm,
      employeeId: id
    });

    setDialogOpen(true);
  };

  const deleteAEmployee = (e, id) => {
    e.preventDefault();

    Swal.fire({
      title: 'Are you sure?',
      text: "All the data related this employee will be deleted. You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEmployee(id);
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    createUser(accountForm);
    hideOpenDialog(false);
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee'}>Employee</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/employee/all'}>All</Link>
      </li>
    </ol>
  );

  const tableHead = [
    { field: 'id', title: 'SL' },
    { field: 'name', title: 'Name' },
    { field: 'phone', title: 'Phone' },
    { field: 'email', title: 'Email' },
    { field: 'designation.name', title: 'Designation' },
    { field: 'department.name', title: 'Department' },
    { field: 'join_date', title: 'Joined' },
    { field: 'salary', title: 'Salary' }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={EMPLOYEE_SECTIONS} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>New Employee Entry</h5>
                <p className={'mb-0'}>Add a new employee</p>
              </div>
              <div>
                <Link
                  to={'/employee/new'}
                  className="btn btn-primary p-2 rounded text-decoration-none"
                >
                  <i className="far fa-plus-square me-2" /> Add Employee
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="card-content" className={'mt-4'}>
          <div className={'overflow-auto'}>
            <MaterialTable
              title={'All employee'}
              columns={tableHead}
              data={employee?.paginatedData}
              isLoading={isLoading}
              totalCount={employee?.total}
              options={{
                headerStyle: {
                  backgroundColor: '#F6F9FC',
                  color: '#000'
                },
                rowStyle: () => {
                  return {
                    fontSize: '14px',
                    color: '#000'
                  };
                },
                padding: 'default',
                pageSize: 20,
                actionsColumnIndex: -1,
                pageSizeOptions: [10, 20, 25, 50],
                showFirstLastPageButtons: true,
                exportAllData: true,
                exportFileName: `All employee - ${moment(new Date()).format('LL')}`,
                exportButton: true,
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  marginLeft: '3rem'
                }
              }}
              components={{
                Pagination: (props) => (
                  <TableCell className="text-center d-flex justify-content-end pagination mt-2">
                    <Pagination
                      color={'primary'}
                      count={employee?.lastPage}
                      page={employee?.currentPage}
                      siblingCount={2}
                      onChange={(e, value) => getEmployeeList({ page: value })}
                    />
                  </TableCell>
                )
              }}
              onRowClick={(e, rowData) => editEmployee(e, rowData.id)}
              actions={[
                (rowData) => ({
                  icon: 'person_add',
                  onClick: (e) => makeEmployeeAUser(e, rowData.id),
                  tooltip: 'Make user'
                }),
                (rowData) => ({
                  icon: 'edit',
                  onClick: (e) => editEmployee(e, rowData.id),
                  tooltip: 'Edit'
                }),
                (rowData) => ({
                  icon: 'delete',
                  onClick: (e) => deleteAEmployee(e, rowData.id),
                  tooltip: 'Delete'
                })
              ]}
            />
          </div>
        </div>
      </div>

      <Dialog
        open={dialogOpen}
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        onClose={() => hideOpenDialog(false)}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">Make employee a user</DialogTitle>
        <DialogContent>
          <div className="card-body">
            <React.Fragment>
              <div className="form-group">
                <p className="mb-2">
                  <strong>Role*</strong>
                </p>
                <Autocomplete
                  onChange={(event, value) => onRoleSelected(event, value)}
                  options={roles}
                  margin="normal"
                  fullWidth
                  renderOption={(option) => option.name}
                  getOptionLabel={(option) => option.name}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      placeholder={'Choose a role'}
                      variant="outlined"
                    />
                  )}
                />
              </div>

              <p className="mt-3 mb-0">
                <strong>Username*</strong>
              </p>

              <TextField
                id="username"
                value={username}
                onChange={(e) => onChange(e)}
                margin="normal"
                name={'username'}
                placeholder="Enter your username"
                type="text"
                fullWidth
                required
                variant={'outlined'}
              />
              <p className="mt-2 mb-0">
                <strong>Email*</strong>
              </p>

              <TextField
                id="email"
                value={email}
                onChange={(e) => onChange(e)}
                margin="normal"
                name={'email'}
                placeholder="Enter email address"
                type="email"
                fullWidth
                variant={'outlined'}
              />

              <p className="mt-2 mb-0">
                <strong>Password*</strong>
              </p>

              <TextField
                id="password"
                value={password}
                onChange={(e) => onChange(e)}
                margin="normal"
                name={'password'}
                placeholder="Enter password"
                type="password"
                fullWidth
                variant={'outlined'}
              />
              <p className="mt-2 mb-0">
                <strong>Confirm Password*</strong>
              </p>

              <TextField
                id="confirm_password"
                value={confirm_password}
                onChange={(e) => onChange(e)}
                margin="normal"
                name={'confirm_password'}
                placeholder="Enter password again"
                type="password"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') onSubmit(e);
                }}
                fullWidth
                variant={'outlined'}
              />
            </React.Fragment>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => hideOpenDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button color="primary" disabled={!employeeId} onClick={(e) => onSubmit(e)}>
            Add User
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

AllEmployee.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getEmployeeList: PropTypes.func.isRequired,
  deleteEmployee: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  employee: state.employee,
  system: state.system,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getEmployeeList,
  deleteEmployee,
  createUser,
  getRoles
})(AllEmployee);
