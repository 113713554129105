import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { base_url_image } from '../../../api';
import { deleteProduct, getProducts } from '../../../actions/products';
import { MODIFY_PRODUCTS, PRODUCT_ENTRY, SHOW_PURCHASE_PRICE, STOCKS } from '../../../permissions';
import { getInventoryStatistics } from '../../../actions/dashboard';
import { getBranches } from '../../../actions/system';
import { setInventorySearch } from '../../../actions/stocks';
import { numberWithCommas } from '../../../utils';
import FilterMaterialTable from '../../common/FilterMaterialTable';

function AllInventory({
  isLoading,
  getProducts,
  products,
  setInventorySearch,
  getBranches,
  getInventoryStatistics,
  auth: { permissions },
  dashboard: { inventoryStatistics },
  system: { branches },
  stocks: { searchText }
}) {
  const history = useHistory();

  useEffect(() => {
    getInventoryStatistics();
    getBranches();
  }, []);

  const handleProductDetails = (id) => {
    history.push({
      pathname: '/inventory/productDetails',
      search: `?id=${id}`
    });
  };

  const handleProductBarcodePrint = (code, name) => {
    history.push({
      pathname: '/inventory/print-barcodes',
      search: `?code=${code}&name=${name}`
    });
  };

  const moveTo = (link) => {
    history.push(link);
  };

  const adminTable = [
    {
      title: 'Image',
      field: 'image_1',
      editable: 'never',
      render: (rowData) =>
        (rowData.image_1 || rowData.inventory_image) && (
          <Fragment>
            <img
              className={'list-product-image'}
              src={base_url_image + `${rowData.inventory_image ?? rowData.image_1}`}
              alt={rowData.code}
            />
          </Fragment>
        )
    },
    { field: 'code', title: 'Code', editable: 'never' },
    { field: 'name', title: 'Name' },
    {
      title: 'Categories',
      field: 'categories',
      editable: 'never',
      render: (rowData) =>
        rowData.categories.map((category) => (
          <p key={category.id} className={'multiple_options'}>
            {category.name}
          </p>
        ))
    },
    {
      title: 'Colors',
      field: 'colors',
      editable: 'never',
      render: (rowData) =>
        rowData.colors.map((color) => (
          <p key={color.id} className={'multiple_options'}>
            {color.name}
          </p>
        ))
    },
    {
      title: 'Sizes',
      field: 'sizes',
      editable: 'never',
      render: (rowData) =>
        rowData.sizes.map((size) => (
          <p key={size.id} className={'multiple_options'}>
            {size.name}
          </p>
        ))
    },
    { field: 'brand.name', title: 'Brand' },
    { field: 'type.name', title: 'Type' },
    { field: 'unit.name', title: 'Unit' }
  ];

  if (permissions.find((item) => item.name === STOCKS)) {
    adminTable.splice(7, 0, { field: 'totalStocks', title: 'Total Stocks' });
  }

  if (branches.length > 0) {
    branches.forEach((branch) =>
      adminTable.push({
        field: 'locations',
        title: `${branch.name}`,
        render: (rowData) =>
          rowData.locations.map((location) => (
            <p key={location.id}>{location.branch.branchId === branch.id ? location.name : ''}</p>
          ))
      })
    );
  }

  adminTable.push({ field: 'rackLoc.name', title: 'Rack Loc' });

  if (permissions.find((item) => item.name === SHOW_PURCHASE_PRICE)) {
    adminTable.push({ field: 'purchase_price', title: 'Purchase (৳)' });
  }

  adminTable.push({ field: 'price', title: 'Retail (MRP)' });

  return (
    <>
      <div className="container-fluid p-0">
        <div id="card-content" className={'shadow-none'}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Inventory</h5>
                <p className={'mb-0'}>Create and manage your inventory</p>
              </div>
              <div>
                {permissions.find((item) => item.name === PRODUCT_ENTRY) && (
                  <button
                    className={'btn btn-primary'}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={() => moveTo('/inventory/product')}>
                    <i className="far fa-plus-square me-2" /> Add Product
                  </button>
                )}
                {/*<Menu*/}
                {/*    id="simple-menu"*/}
                {/*    anchorEl={anchorEl}*/}
                {/*    keepMounted*/}
                {/*    open={Boolean(anchorEl)}*/}
                {/*    onClose={handleClose}*/}
                {/*>*/}
                {/*    <MenuItem onClick={e => moveTo("/inventory/product")}>Add product</MenuItem>*/}
                {/*    <MenuItem onClick={handleClose}>Import products</MenuItem>*/}
                {/*</Menu>*/}
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="row mt-4 heading_columns">
              {inventoryStatistics && (
                <Fragment>
                  <div className="col-md-2 border-right">
                    <p>Total products</p>
                    <h4>{numberWithCommas(inventoryStatistics.totalItems)}</h4>
                  </div>
                  {permissions.find((item) => item.name === STOCKS) ? (
                    <Fragment>
                      <div className="col-md-2  border-right">
                        <p>Total stock qty</p>
                        <h4>{numberWithCommas(inventoryStatistics.totalStockQty)}</h4>
                      </div>
                      {permissions.find((item) => item.name === SHOW_PURCHASE_PRICE) && (
                        <>
                          <div className="col-md-2  border-right">
                            <p>Cost Value of Stock</p>
                            <h4>
                              ৳{numberWithCommas(parseFloat(inventoryStatistics.stockCostValue))}
                            </h4>
                          </div>
                          <div className="col-md-2  border-right">
                            <p>Retail Value of Stock</p>
                            <h4>
                              ৳{numberWithCommas(parseFloat(inventoryStatistics.stockSalesValue))}
                            </h4>
                          </div>
                        </>
                      )}
                      <div className="col-md-2  border-right">
                        <p>Low stock products</p>
                        <h4>{numberWithCommas(inventoryStatistics.lowStockItems)}</h4>
                      </div>
                      <div className="col-md-2">
                        <p>Out stock products</p>
                        <h4>{numberWithCommas(inventoryStatistics.outOfStockItems)}</h4>
                      </div>
                    </Fragment>
                  ) : (
                    <div className="col-md-6" />
                  )}
                </Fragment>
              )}
              {/*<div className="col-md-4 text-end">*/}
              {/*    <button className="btn btn-primary">Download products <i*/}
              {/*        className="ms-2 fas fa-cloud-download-alt"/></button>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>

        <FilterMaterialTable
          title={'All Products'}
          isLoading={isLoading}
          columns={adminTable}
          data={products}
          tableContainerClass={'mt-4 d-grid'}
          fetchMethod={getProducts}
          search={searchText}
          setSearch={setInventorySearch}
          showFilterLayout={false}
          isCustomPagination
          options={{
            headerStyle: {
              backgroundColor: '#F6F9FC',
              color: '#667085',
              whiteSpace: 'nowrap'
            },
            rowStyle: (rowData) => {
              return {
                fontSize: '14px',
                color: '#000',
                backgroundColor:
                  (rowData.totalStocks === 0 && '#ffd9d9') || (rowData.totalStocks < 3 && '#fdefd3')
              };
            }
          }}
          onRowClick={(event, rowData) => handleProductDetails(rowData.id)}
          actions={[
            permissions.find((item) => item.name === MODIFY_PRODUCTS) &&
              ((rowData) => ({
                icon: 'edit',
                onClick: () => handleProductDetails(rowData.id),
                isFreeAction: true,
                tooltip: 'Edit product'
              })),
            (rowData) => ({
              icon: 'qr_code',
              onClick: () => handleProductBarcodePrint(rowData.code, rowData.name),
              tooltip: 'Print Barcodes'
            })
          ]}
        />
      </div>
    </>
  );
}

AllInventory.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getProducts: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  products: state.products.products,
  auth: state.auth,
  dashboard: state.dashboard,
  system: state.system,
  stocks: state.stocks
});

export default connect(mapStateToProps, {
  getProducts,
  deleteProduct,
  getInventoryStatistics,
  getBranches,
  setInventorySearch
})(AllInventory);
