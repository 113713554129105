import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { Tooltip } from '@material-ui/core';
import { updateSubCategoryToMain } from '../../../actions/products';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

const DraggableCategory = ({
  category,
  handleDialogOpen,
  handleDialogEditOpen,
  updateSubCategoryToMain,
  TYPE_CATEGORY,
  TYPE_SUBCATEGORY
}) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: category.id
  });

  const containerStyle = {
    transform: CSS.Transform.toString(transform),
    transformOrigin: '0 0 ',
    transition
  };

  const handleSubToMain = (id, title) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to make "${title}" a main category?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await updateSubCategoryToMain({ id: id });
        await Swal.fire('Updated!', '', 'success');
      }
    });
  };

  return (
    <div className="col-md-3 mb-4" ref={setNodeRef} style={containerStyle}>
      <div
        id="card-content-cat"
        style={{
          background: isDragging ? 'transparent' : '#FFF'
        }}
      >
        {!isDragging ? (
          <>
            <div className="card-header">
              <div className={'d-flex justify-content-between mb-4'}>
                <div className={'d-flex align-items-center gap-3'}>
                  <i
                    className="fa fa-arrows ms-2 user-select-none"
                    style={{
                      color: '#919191',
                      fontSize: '1.4rem',
                      cursor: 'grab'
                    }}
                    {...listeners}
                    {...attributes}
                  />
                  <h5 className={'m-0 user-select-none'}>{category.name}</h5>
                </div>
                <div className={'d-flex gap-3 align-items-center'}>
                  <i
                    className="fa fa-plus hover fa-lg me-2 mt-1"
                    onClick={() => handleDialogOpen(TYPE_SUBCATEGORY, category.id)}
                  />
                  <i
                    className="far fa-edit hover fa-lg me-2 mt-1"
                    onClick={() => handleDialogEditOpen(TYPE_CATEGORY, category.id, category.name)}
                  />
                </div>
              </div>
            </div>
            <Droppable droppableId={category.name}>
              {(provided) => (
                <div
                  className="card-body overflow-auto"
                  style={{ height: '450px' }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <ul className={'list-group gap-2'}>
                    {category.subcategories?.map((subCat, index) => {
                      return (
                        <Draggable
                          key={subCat?.id}
                          draggableId={subCat?.name + subCat?.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <li
                              className="list-group-item d-flex justify-content-between align-items-center me-2 px-2 py-3 rounded-2 drag-hover"
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                border: snapshot.isDragging
                                  ? '1px dashed #1652F0'
                                  : '1px dashed #DEE2E6',
                                background: snapshot.isDragging && 'rgb(227,238,255)'
                              }}
                              ref={provided.innerRef}
                            >
                              <div className={'d-flex align-items-center gap-3'}>
                                <i
                                  className="fa fa-arrows ms-2 user-select-none"
                                  style={{ color: '#919191' }}
                                  {...provided.dragHandleProps}
                                />
                                <p
                                  className="m-0 font-weight-bold"
                                  style={{ fontSize: '14px', userSelect: 'none' }}
                                >
                                  {subCat?.name}
                                </p>
                              </div>
                              <div>
                                <Tooltip title={'Edit subcategory'}>
                                  <i
                                    className="far fa-edit fa-md me-2"
                                    style={{
                                      cursor: 'pointer'
                                    }}
                                    onClick={() =>
                                      handleDialogEditOpen(TYPE_SUBCATEGORY, subCat.id, subCat.name)
                                    }
                                  />
                                </Tooltip>

                                <Tooltip title={'Make main category'}>
                                  <i
                                    className="fa fa-bars fa-md ms-3 me-2"
                                    style={{
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => handleSubToMain(subCat.id, subCat.name)}
                                  />
                                </Tooltip>
                              </div>
                            </li>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                </div>
              )}
            </Droppable>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default connect(null, {
  updateSubCategoryToMain
})(DraggableCategory);
