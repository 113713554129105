import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import { getSalesHistory } from '../../../actions/reports';
import { SALES_HISTORY_REPORTS } from '../../../permissions';
import { getBranches } from '../../../actions/system';
import { getCustomers } from '../../../actions/customers';
import AllBranchSelector from '../../common/AllBranchSelector';
import AllCustomerSelected from '../../common/AllCustomerSelected';
import { numberWithCommas, REPORTS_SECTIONS } from '../../../utils';
import FilterMaterialTable from '../../common/FilterMaterialTable';

function SaleHistoryReports({
  isLoading,
  getSalesHistory,
  getBranches,
  getCustomers,
  customers: { customers },
  auth: { permissions },
  reports: { salesHistory },
  system: { branches }
}) {
  let history = useHistory();

  const [formData, setFormData] = useState({
    customerId: '',
    branchId: ''
  });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === SALES_HISTORY_REPORTS)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    getCustomers({});
    getBranches();
  }, []);

  const handleSaleDetails = (id) => {
    history.push({
      pathname: '/sale/details',
      search: `?id=${id}`
    });
  };

  const handleCustomerDetails = (id) => {
    history.push({
      pathname: '/customers/details',
      search: `?id=${id}`
    });
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/reports'}>Reports</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/reports/sales-history'}>Sales History</Link>
      </li>
    </ol>
  );

  const tableHead = [
    { field: 'date', title: 'Date' },
    {
      field: 'id',
      title: 'Inv. No',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleSaleDetails(rowData.id)}
        >{`INV-${rowData.id}`}</span>
      )
    },
    {
      field: 'customer.name',
      title: 'Customer',
      render: (rowData) => (
        <span
          className={'font-weight-bold link-primary hover'}
          onClick={() => handleCustomerDetails(rowData.customer.id)}
        >
          {rowData.customer.name}
        </span>
      )
    },
    { field: 'customer.phone', title: 'Phone' },
    { field: 'itemsQty', title: 'Qty', render: (rowData) => numberWithCommas(rowData.itemsQty) },
    { field: 'total', title: 'Sales (৳)', render: (rowData) => numberWithCommas(rowData.total) },
    {
      field: 'costValue',
      title: 'Costs (৳)',
      render: (rowData) => numberWithCommas(rowData.costValue)
    },
    { field: 'paid', title: 'Total Paid (৳)', render: (rowData) => numberWithCommas(rowData.paid) },
    { field: 'due', title: 'Total Due (৳)', render: (rowData) => numberWithCommas(rowData.due) },
    { field: 'profit', title: 'Profit (৳)', render: (rowData) => numberWithCommas(rowData.profit) },
    {
      field: 'profitMargin',
      title: 'ProfitMargin(%)',
      render: (rowData) => numberWithCommas(rowData.profitMargin)
    }
  ];

  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={REPORTS_SECTIONS} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-between">
                    <AllBranchSelector
                      formData={formData}
                      setFormData={setFormData}
                      branches={branches}
                      all={true}
                    />
                  </div>
                </div>
              </div>

              {customers && (
                <div className="col-md-3">
                  <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between">
                      <AllCustomerSelected
                        formData={formData}
                        setFormData={setFormData}
                        customers={customers}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div id="card-content" className={'mt-4'}>
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div></div>
              <div className={'text-center'}>
                <h5>৳{numberWithCommas(salesHistory?.report.itemsQty)}</h5>
                <p className={'mb-0'}>Quantity</p>
              </div>
              <div className={'text-center'}>
                <h5>৳{numberWithCommas(salesHistory?.report.salesValue)}</h5>
                <p className={'mb-0'}>Sales Value</p>
              </div>
              <div className={'text-center'}>
                <h5>৳{numberWithCommas(salesHistory?.report.costValue)}</h5>
                <p className={'mb-0'}>Cost Value</p>
              </div>
              <div className={'text-center'}>
                <h5>৳{numberWithCommas(salesHistory?.report.profit)}</h5>
                <p className={'mb-0'}>Profit</p>
              </div>
              <div></div>
            </div>
          </div>
        </div>

        <FilterMaterialTable
          title={'Sales History Reports'}
          isLoading={isLoading}
          columns={tableHead}
          data={salesHistory}
          tableContainerClass={'mt-4'}
          fetchMethod={getSalesHistory}
          isCustomPagination
          args={formData}
          options={{
            rowStyle: (rowData) => {
              return {
                fontSize: '14px',
                color: '#000'
              };
            }
          }}
        />
      </div>
    </Fragment>
  );
}

SaleHistoryReports.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getSalesHistory: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  reports: state.reports,
  auth: state.auth,
  system: state.system,
  customers: state.customers
});

export default connect(mapStateToProps, { getSalesHistory, getBranches, getCustomers })(
  SaleHistoryReports
);
