import { COUPON, COUPONS, LOADING, LOADING_FALSE } from './types';
import API from '../api';
import Swal from 'sweetalert2';
import { returnErrors } from './messages';

export const addCoupon = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.coupons.addCoupon(data);
    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully created a coupon.',
      showConfirmButton: false,
      timer: 1500
    });

    return res.data.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const getCoupons =
  ({ page, size, search, isExportData }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const res = await API.coupons.getCoupons({ page, size, search });
      dispatch({ type: LOADING_FALSE });
      if (isExportData) return res.data.data;
      dispatch({
        type: COUPONS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOADING_FALSE });
    }
  };

export const getCoupon = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.coupons.getCoupon(id);
    dispatch({ type: LOADING_FALSE });
    dispatch({
      type: COUPON,
      payload: res.data.data
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const updateCoupon = (data) => async (dispatch) => {
  console.log('data', data);
  dispatch({ type: LOADING });
  try {
    const res = await API.coupons.updateCoupon(data);

    dispatch({ type: LOADING_FALSE });

    await Swal.fire({
      icon: 'success',
      title: res.data.message,
      text: 'You have successfully updated a coupon.',
      showConfirmButton: false,
      timer: 1500
    });

    window.history.back();
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};

export const deleteCoupon = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const res = await API.coupons.deleteCoupon(id);
    dispatch({ type: LOADING_FALSE });
    await Swal.fire(res.data.message, 'You have successfully deleted a coupon.', 'success');
    dispatch(getCoupons({}));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOADING_FALSE });
  }
};
