import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import Breadcrumb from '../../layout/Breadcrumb';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { addPurchaseReturn, cancelPurchase, getPurchaseDetails } from '../../../actions/purchase';
import MaterialTable from 'material-table';
import numWords from 'num-words';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch
} from '@material-ui/core';
import Swal from 'sweetalert2';
import { MODIFY_PURCHASE, PURCHASE_INVOICES } from '../../../permissions';
import moment from 'moment';
import { PURCHASE_SECTIONS, REGEX_STRING } from '../../../utils';
import NumberInput from '../../common/NumberInput';

function PurchaseDetails({
  isLoading,
  cancelPurchase,
  auth: { permissions },
  addPurchaseReturn,
  purchase: { purchaseDetails },
  getPurchaseDetails,
  location
}) {
  const history = useHistory();
  const [purchaseReturnDialog, setPurchaseReturnDialog] = useState(false);
  const [returnForm, setReturnForm] = useState({
    is_damage: false,
    reason: '',
    returned_qty: ''
  });
  const [returnItem, setReturnItem] = useState({});

  const { reason, returned_qty, is_damage } = returnForm;

  const onChange = (e) => setReturnForm({ ...returnForm, [e.target.name]: e.target.value });

  useEffect(() => {
    if (permissions.length > 0) {
      if (!permissions.find((item) => item.name === PURCHASE_INVOICES)) {
        history.push('/access-denied');
      }
    }
  }, [permissions]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id) {
      getPurchaseDetails(id);
    }
  }, []);

  const calculateTwoIntegersFromIntegersOfArray = (array) => {
    if (array.length === 0) return 0;

    const add = (a, b) => a + b;
    return array.reduce(add);
  };

  const moveTo = (link, id) => {
    history.push({
      pathname: link,
      search: `?id=${id}`
    });
  };

  const handleDamageSwitch = (event) => {
    setReturnForm({
      ...returnForm,
      is_damage: event.target.checked
    });
  };

  const showOrHidePurchaseReturnedDialog = (value, item = {}) => {
    setPurchaseReturnDialog(value);
    setReturnItem(item);
    setReturnForm({
      ...returnForm,
      reason: '',
      returned_qty: ''
    });
  };

  const savePurchaseReturn = (e) => {
    e.preventDefault();

    if (returnItem.qty < parseInt(returned_qty)) {
      Swal.fire('Invalid quantity', 'Returned qty higher than purchased qty', 'warning').then();
      showOrHidePurchaseReturnedDialog(false);
      return;
    }

    const data = {
      branchId: returnItem.branchId,
      orderDetailId: returnItem.id,
      returned_qty: returned_qty,
      reason: reason,
      is_damage
    };

    addPurchaseReturn(purchaseDetails.id, data);

    showOrHidePurchaseReturnedDialog(false);
  };

  const cancelPurchaseInvoice = (e) => {
    e.preventDefault();

    Swal.fire({
      title: 'Cancel Invoice?',
      text: "Are you sure? You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        cancelPurchase(purchaseDetails.id);
      }
    });
  };

  const goBackButton = () => {
    history.goBack();
  };

  const printSale = (id) => {
    history.push({
      pathname: '/purchase/print',
      search: `?id=${id}`
    });
  };

  const tableHead = [
    {
      field: 'tableData.id',
      title: 'SL',
      cellStyle: {
        width: '5%'
      },
      render: (rowData) => {
        return rowData.tableData.id + 1;
      }
    },
    {
      field: 'description',
      title: 'Description',
      cellStyle: {
        width: '35%'
      },
      render: (rowData) => {
        return rowData.description.includes('(') ? (
          <p className={'m-0'}>
            {rowData.description.split('(')[0]} (
            <span className={'font-weight-bold'}>{REGEX_STRING.exec(rowData.description)[1]}</span>)
          </p>
        ) : (
          rowData.description
        );
      }
    },
    { field: 'branch.name', title: 'Branch' },
    { field: 'qty', title: 'Quantity' },
    { field: 'totalReturned', title: 'Returned' },
    { field: 'unit_price', title: 'P. Rate (৳)' },
    { field: 'total', title: 'Amount (৳)' }
  ];

  const invoiceActivityHeader = [
    {
      field: 'createdAt',
      title: 'Date',
      render: (rowData) => moment(rowData.createdAt).format('MMM DD')
    },
    {
      field: 'description',
      title: 'Description',
      cellStyle: {
        width: '60%'
      },
      render: (rowData) => {
        return rowData.description.includes('(') ? (
          <p className={'m-0'}>
            {rowData.description.split('(')[0]} (
            <span className={'font-weight-bold'}>{REGEX_STRING.exec(rowData.description)[1]}</span>)
          </p>
        ) : (
          rowData.description
        );
      }
    },
    {
      field: 'qty',
      title: 'Qty',
      render: (rowData) => (rowData.qty === 0 ? '-' : rowData.qty)
    },
    { field: 'user.username', title: 'By' }
  ];

  const paymentHistoryHeader = [
    { field: 'reference', title: 'INV No.' },
    { field: 'payment_method.name', title: 'Method' },
    { field: 'amount', title: 'Amount (৳)' },
    { field: 'user.username', title: 'By' }
  ];

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/purchase'}>Purchase</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/purchase/all'}>Invoices</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={`/purchase/details?id=${purchaseDetails && purchaseDetails.id}`}>Details</Link>
      </li>
    </ol>
  );
  return (
    <Fragment>
      <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={PURCHASE_SECTIONS} />
      <LoadingBackdrop loading={isLoading} />

      <div className="container-fluid mt-4">
        <div id="card-content">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Purchase Details</h5>
                <p className="mb-0">See purchase details, manage returns and edit purchase</p>
              </div>
              <div>
                {permissions.find((item) => item.name === MODIFY_PURCHASE) && (
                  <button
                    className={'btn btn-primary me-2'}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) =>
                      moveTo('/purchase/update', purchaseDetails && purchaseDetails.id)
                    }
                  >
                    <i className="fas fa-pen me-2" /> Edit Purchase
                  </button>
                )}

                <button
                  className={'btn btn-primary me-2'}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(e) => printSale(purchaseDetails && purchaseDetails.id)}
                >
                  <i className="fas fa-print me-2" /> Print
                </button>

                {permissions.find((item) => item.name === MODIFY_PURCHASE) && (
                  <button
                    className={'btn btn-danger text-white me-2'}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => cancelPurchaseInvoice(e)}
                  >
                    <i className="far fa-times-circle me-2" /> Cancel Invoice
                  </button>
                )}
                <button className="btn btn-outline-primary" onClick={goBackButton}>
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <div id="card-content" className={'mt-4'}>
              <div className="card-body">
                <div className="row justify-content-between">
                  <div className="col-md-5">
                    <p className={' mb-1'}>
                      <span className={'font-weight-bold'}>Supplier Name: &emsp;</span>{' '}
                      {purchaseDetails ? purchaseDetails.supplier.name : ''}
                    </p>
                    <p className={'mb-1'}>
                      <span className={'font-weight-bold'}>Supplier Phone: &emsp;</span>{' '}
                      {purchaseDetails ? purchaseDetails.supplier.phone : ''}
                    </p>
                    {purchaseDetails && (
                      <p>
                        <span className={'font-weight-bold'}>Address: &emsp;</span>{' '}
                        {purchaseDetails.supplier.address}
                      </p>
                    )}
                  </div>
                  <div className="col-md-5">
                    <p className={' mb-1'}>
                      <span className={'font-weight-bold'}>Invoice No: &emsp;</span>{' '}
                      {purchaseDetails ? `PO-${purchaseDetails.id}` : ''}
                    </p>
                    <p className={' mb-1'}>
                      <span className={'font-weight-bold'}>Date: &emsp;</span>{' '}
                      {purchaseDetails ? purchaseDetails.date : ''}
                    </p>
                    <p className={' mb-1'}>
                      <span className={'font-weight-bold'}>Status: &emsp;</span>{' '}
                      {purchaseDetails ? purchaseDetails.status.name : ''}
                    </p>
                  </div>
                </div>

                {purchaseDetails && (
                  <div className={'mt-4 d-grid'}>
                    <div style={{ overflow: 'auto' }} className={'sales-table'}>
                      <MaterialTable
                        columns={tableHead}
                        data={purchaseDetails?.purchaseOrderItems}
                        totalCount={purchaseDetails?.purchaseOrderItems.length}
                        options={{
                          toolbar: false,
                          exportButton: false,
                          showTitle: false,
                          headerStyle: {
                            padding: 4,
                            backgroundColor: '#F5F7FA'
                          },
                          padding: 'default',
                          pageSize: purchaseDetails?.purchaseOrderItems.length,
                          paging: false,
                          actionsColumnIndex: -1
                        }}
                        actions={[
                          (rowData) => ({
                            icon: 'cached',
                            isFreeAction: true,
                            tooltip: 'Purchase return',
                            onClick: () => showOrHidePurchaseReturnedDialog(true, rowData)
                          })
                        ]}
                      />
                    </div>

                    <div className="row justify-content-end mt-4">
                      <div className="col-2 p-0">
                        <p className={'font-weight-bold'}>Total Qty:</p>
                        <p className={'font-weight-bold'}>Returned Qty:</p>
                        <p className={'font-weight-bold'}>Sub Total:</p>
                        <p className={'font-weight-bold'}>vat:</p>
                        <p className={'font-weight-bold'}>Transport Cost:</p>
                        <p className={'font-weight-bold mb-0'}>Discount:</p>

                        <div className="divider" />
                        <p className={'font-weight-bold'}>Total:</p>
                        <p className={'font-weight-bold'}>Paid:</p>
                        <p className={'font-weight-bold mb-0'}>Returned:</p>

                        <div className="divider" />
                        <p className={'font-weight-bold text-danger'}>Due:</p>
                      </div>
                      <div className="col-1 p-0">
                        <p>
                          {purchaseDetails
                            ? calculateTwoIntegersFromIntegersOfArray(
                                purchaseDetails.purchaseOrderItems.map((item) => item.qty)
                              )
                            : 0}
                        </p>
                        <p>{purchaseDetails.totalReturned ? purchaseDetails.totalReturned : 0}</p>
                        <p>{purchaseDetails ? purchaseDetails.sub_total : 0}</p>
                        <p>{purchaseDetails ? purchaseDetails.vat + '%' : 0}</p>
                        <p>{purchaseDetails ? purchaseDetails.transport_cost : 0}</p>
                        <p className={'mb-0'}>{purchaseDetails ? purchaseDetails.discount : 0}</p>

                        <div className="divider" />
                        <p>{purchaseDetails ? purchaseDetails.total : 0}</p>
                        <p>{purchaseDetails ? purchaseDetails.paid : 0}</p>
                        <p className={'mb-0'}>
                          {purchaseDetails.returnedAmount ? purchaseDetails.returnedAmount : 0}
                        </p>
                        <div className="divider" />

                        <p className={'text-danger'}>{purchaseDetails ? purchaseDetails.due : 0}</p>
                      </div>
                      <div className="col-1" />
                    </div>

                    <div className={'mt-3'}>
                      {/*<p><strong>Total (in*/}
                      {/*    word): </strong>{`${numWords(parseInt(purchaseDetails ? purchaseDetails.total : 0)).toUpperCase()} ONLY`}*/}
                      {/*</p>*/}
                      <p className={'mt-3'}>
                        <strong>Notes: </strong>
                        {purchaseDetails ? purchaseDetails.notes : ''}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="card-content" className={'mt-4'}>
              <div className="card-header">
                <h6>Invoice Activities</h6>
                <p>
                  All invoice activities. Returned and damage history will be listed here as well.
                </p>
              </div>
              <div className="card-body">
                {purchaseDetails && (
                  <div className={'mt-4 d-grid'}>
                    <div style={{ overflow: 'auto' }}>
                      <MaterialTable
                        columns={invoiceActivityHeader}
                        data={purchaseDetails?.invoiceHistory}
                        totalCount={purchaseDetails?.invoiceHistory.length}
                        options={{
                          toolbar: false,
                          exportButton: false,
                          showTitle: false,
                          headerStyle: {
                            padding: 4,
                            backgroundColor: '#F5F7FA'
                          },
                          padding: 'default',
                          pageSize: purchaseDetails?.purchaseOrderItems.length,
                          paging: false,
                          actionsColumnIndex: -1
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div id="card-content" className={'mt-4'}>
              <div className="card-header">
                <h6>Payments</h6>
                <p>All the payment history of this invoice.</p>
              </div>
              <div className="card-body">
                {purchaseDetails && (
                  <div className={'mt-3 d-grid'}>
                    <div style={{ overflow: 'auto' }}>
                      <MaterialTable
                        columns={paymentHistoryHeader}
                        data={purchaseDetails?.paymentHistory}
                        totalCount={purchaseDetails?.paymentHistory.length}
                        options={{
                          toolbar: false,
                          exportButton: false,
                          showTitle: false,
                          headerStyle: {
                            padding: 4,
                            backgroundColor: '#F5F7FA'
                          },
                          padding: 'default',
                          pageSize: purchaseDetails?.paymentHistory.length,
                          paging: false,
                          actionsColumnIndex: -1
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*PURCHASE RETURN DIALOG*/}
      <Dialog
        open={purchaseReturnDialog}
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        onClose={() => showOrHidePurchaseReturnedDialog(false)}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="alert-dialog-slide-title">Return an Item</DialogTitle>
        <DialogContent>
          <FormGroup>
            <FormControlLabel
              control={<Switch color="primary" checked={is_damage} onChange={handleDamageSwitch} />}
              label="Is damage?"
            />
          </FormGroup>

          <div className="form-group mt-3">
            <label htmlFor={'supplierPreDue'}>Returning reason</label>
            <input
              type="text"
              name={'reason'}
              value={reason}
              onChange={(e) => onChange(e)}
              placeholder={'Why you want to return'}
              className="form-control"
            />
          </div>

          <div className="form-group mt-3">
            <label htmlFor={'supplierPreDue'}>Item Quantity</label>
            <NumberInput
              name={'returned_qty'}
              value={returned_qty}
              onChange={(e) => onChange(e)}
              placeholder={'Enter quantity'}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => showOrHidePurchaseReturnedDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={parseInt(returned_qty) === 0 || isLoading}
            onClick={(e) => savePurchaseReturn(e)}
          >
            Return
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

PurchaseDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getPurchaseDetails: PropTypes.func.isRequired,
  purchase: PropTypes.object.isRequired,
  addPurchaseReturn: PropTypes.func.isRequired,
  cancelPurchase: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  purchase: state.purchase,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getPurchaseDetails,
  cancelPurchase,
  addPurchaseReturn
})(PurchaseDetails);
