import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../layout/Breadcrumb';
import { COUPON_SECTIONS } from '../../../utils';
import LoadingBackdrop from '../../layout/LoadingBackdrop';
import { Link, useHistory } from 'react-router-dom';
import { HomeRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addCoupon } from '../../../actions/coupon';
import { DISCOUNT_TYPE } from '../../../utils';
import moment from 'moment/moment';

const AddCoupon = ({ isLoading, addCoupon }) => {
  const history = useHistory();

  const [formData, setFormData] = useState({
    code: '',
    discount: '',
    discountType: '',
    validityFrom: moment().format('YYYY-MM-DD'),
    validityTo: moment().format('YYYY-MM-DD'),
    description: ''
  });

  const { code, discount, discountType, validityFrom, validityTo, description } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const saveCoupon = async (e) => {
    e.preventDefault();

    const data = await addCoupon(formData);
    if (data) {
      history.push({
        pathname: '/suppliers/details',
        search: `?id=${data.id}`
      });
    }
  };

  const onDisTypeChange = (e) => {
    const selectedType = DISCOUNT_TYPE.find((item) => item.name === e.target.value);
    if (selectedType) {
      setFormData({
        ...formData,
        discountType: selectedType.name
      });
    } else {
      setFormData({
        ...formData,
        discountType: ''
      });
    }
  };

  const onCancelButtonClicked = () => {
    history.goBack();
  };

  const breadcrumbs = (
    <ol className="breadcrumb">
      <li className="breadcrumb-item home">
        <Link to={'/home'}>
          <HomeRounded />
        </Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/website'}>Website</Link>
      </li>
      <li className="breadcrumb-item active">
        <Link to={'/website/add-coupon'}>Add Coupon</Link>
      </li>
    </ol>
  );

  return (
    <>
      <Fragment>
        <Breadcrumb breadcrumbs={breadcrumbs} sectionNames={COUPON_SECTIONS} />
        <LoadingBackdrop loading={isLoading} />

        <div className="container-fluid mt-4">
          <form id="card-content" onSubmit={(e) => saveCoupon(e)}>
            <div className="card-header">
              <div className="d-flex justify-content-between align-items center">
                <div>
                  <Fragment>
                    <h5>Add New Coupon</h5>
                    <p>Add and manage new coupons</p>
                  </Fragment>
                </div>
                <div>
                  <button type={'submit'} className="btn btn-primary p-2 me-2" disabled={isLoading}>
                    Save
                  </button>
                  <button
                    onClick={onCancelButtonClicked}
                    type={'button'}
                    disabled={isLoading}
                    className="btn btn-danger p-2 text-light"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body mt-2">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor={'code'}>
                      Code <span className={'text-danger'}>*</span>
                    </label>
                    <input
                      type="text"
                      name={'code'}
                      value={code}
                      onChange={(e) => onChange(e)}
                      required
                      className="form-control text-uppercase"
                      placeholder={'Enter code'}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor={'discount'}>
                      Discount <span className={'text-danger'}>*</span>
                    </label>
                    <input
                      type="text"
                      name={'discount'}
                      value={discount}
                      onChange={(e) => onChange(e)}
                      required
                      className="form-control"
                      placeholder={'Enter discount value'}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor={'discountType'}>
                      Discount Type <span className={'text-danger'}>*</span>
                    </label>
                    <select
                      name={'discountType'}
                      value={discountType}
                      onChange={(e) => onDisTypeChange(e)}
                      required
                      className="form-control"
                      placeholder={'Enter discount type'}
                    >
                      <option className="text-muted">Choose a type..</option>
                      {DISCOUNT_TYPE?.map((item) => (
                        <option value={item.name}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-4 mt-4">
                  <div className="form-group">
                    <label htmlFor={'validityFrom'}>
                      Validity From <span className={'text-danger'}>*</span>
                    </label>
                    <input
                      type="date"
                      name={'validityFrom'}
                      value={validityFrom}
                      onChange={(e) => onChange(e)}
                      required
                      className="form-control"
                      placeholder={'Enter start date'}
                    />
                  </div>
                </div>

                <div className="col-md-4 mt-4">
                  <div className="form-group">
                    <label htmlFor={'validityTo *'}>
                      Validity To <span className={'text-danger'}>*</span>
                    </label>
                    <input
                      type="date"
                      name={'validityTo'}
                      value={validityTo}
                      onChange={(e) => onChange(e)}
                      required
                      className="form-control"
                      placeholder={'Enter end date'}
                    />
                  </div>
                </div>

                <div className="col-md-4 mt-4">
                  <div className="form-group">
                    <label htmlFor={'description'}>Description</label>
                    <input
                      type="text"
                      name={'description'}
                      value={description}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                      placeholder={'Enter descriptions'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Fragment>
    </>
  );
};

AddCoupon.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  addCoupon: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  auth: state.auth
});

export default connect(mapStateToProps, { addCoupon })(AddCoupon);
